import {
	getSession,
} from 'Vulture/Session.js';

import {
	broncoURL,
} from 'Vulture/ENV.js';

export const fetchEntertainment = async () => {
	const session = getSession();

	let url = `${broncoURL}/entertainment`;

	const headers = {
		'X-Auth-Session': session.session,
	};

	try {
		const res = await fetch(url, {
			headers,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};

export const fetchEntertainmentCategories = async () => {
	const session = getSession();

	let url = `${broncoURL}/entertainment/category`;

	const headers = {
		'X-Auth-Session': session.session,
	};

	try {
		const res = await fetch(url, {
			headers,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};

export const postEntertainmentCategory = async (entertainmentCategories) => {
	const session = getSession();

	let url = `${broncoURL}/entertainment/category`;

	const headers = {
		'X-Auth-Session': session.session,
		'Content-Type':   'application/json',
	};

	const body = JSON.stringify(entertainmentCategories);

	try {
		const res = await fetch(url, {
			method: 'POST',
			headers,
			body,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};

export const patchEntertainmentCategory = async (categoryUUID, entertainmentCategory) => {
	const session = getSession();

	let url = `${broncoURL}/entertainment/category/${categoryUUID}`;

	const headers = {
		'X-Auth-Session': session.session,
		'Content-Type':   'application/json',
	};

	const body = JSON.stringify(entertainmentCategory);

	try {
		const res = await fetch(url, {
			method: 'PATCH',
			headers,
			body,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};

export const deleteEntertainmentCategory = async (categoryUUID) => {
	const session = getSession();

	let url = `${broncoURL}/entertainment/category/${categoryUUID}`;

	const headers = {
		'X-Auth-Session': session.session,
		'Content-Type':   'application/json',
	};

	try {
		const res = await fetch(url, {
			method: 'DELETE',
			headers,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};

export const fetchEntertainmentProviders = async () => {
	const session = getSession();

	let url = `${broncoURL}/entertainment/provider`;

	const headers = {
		'X-Auth-Session': session.session,
	};

	try {
		const res = await fetch(url, {
			headers,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};

export const fetchEntertainmentProviderFeeds = async () => {
	const session = getSession();

	let url = `${broncoURL}/entertainment/provider_feed`;

	const headers = {
		'X-Auth-Session': session.session,
	};

	try {
		const res = await fetch(url, {
			headers,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};

export const fetchEntertainmentProviderFeedResolutions = async () => {
	const session = getSession();

	let url = `${broncoURL}/entertainment/provider_feed_resolution`;

	const headers = {
		'X-Auth-Session': session.session,
	};

	try {
		const res = await fetch(url, {
			headers,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};

export const fetchEntertainmentCategoryProviderFeedResolutions = async () => {
	const session = getSession();

	let url = `${broncoURL}/entertainment/category_provider_feed_resolution`;

	const headers = {
		'X-Auth-Session': session.session,
	};

	try {
		const res = await fetch(url, {
			headers,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};

export const postEntertainmentCategoryProviderFeedResolution = async (categoryResolution) => {
	const session = getSession();

	let url = `${broncoURL}/entertainment/category_provider_feed_resolution`;

	const headers = {
		'X-Auth-Session': session.session,
		'Content-Type':   'application/json',
	};

	const body = JSON.stringify(categoryResolution);

	try {
		const res = await fetch(url, {
			method: 'POST',
			headers,
			body,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};

export const patchEntertainmentCategoryProviderFeedResolution = async (categoryUUID, categoryResolution) => {
	const session = getSession();

	let url = `${broncoURL}/entertainment/category_provider_feed_resolution/${categoryUUID}`;

	const headers = {
		'X-Auth-Session': session.session,
		'Content-Type':   'application/json',
	};

	const body = JSON.stringify(categoryResolution);

	try {
		const res = await fetch(url, {
			method: 'PATCH',
			headers,
			body,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};

export const deleteEntertainmentCategoryProviderFeedResolution = async (categoryResolutionUUID) => {
	const session = getSession();

	let url = `${broncoURL}/entertainment/category_provider_feed_resolution/${categoryResolutionUUID}`;

	const headers = {
		'X-Auth-Session': session.session,
		'Content-Type':   'application/json',
	};

	try {
		const res = await fetch(url, {
			method: 'DELETE',
			headers,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};

export const fetchComponentEntertainmentCategories = async (componentUUID) => {
	const session = getSession();

	let url = `${broncoURL}/entertainment/component/${componentUUID}/categories`;

	const headers = {
		'X-Auth-Session': session.session,
	};

	try {
		const res = await fetch(url, {
			headers,
		});

		const json = await res.json();

		return json;
	} catch (err) {
		console.error(err);
	}
};