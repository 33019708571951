import React from 'react';

import CustomIcon from '../Common/CustomIcon/CustomIcon';

import style from './EntertainmentEditor.module.css';

import {
	deleteEntertainmentCategory,
	patchEntertainmentCategory,
	postEntertainmentCategory,
	fetchEntertainment,
	postEntertainmentCategoryProviderFeedResolution,
	patchEntertainmentCategoryProviderFeedResolution,
	deleteEntertainmentCategoryProviderFeedResolution,
} from 'Vulture/Entertainment';

import {
	EntertainmentCategoryModal,
	EntertainmentFeedModal,
} from './EntertainmentModal';

import {
	getSession,
} from 'Vulture/Session.js';

class EntertainmentEditor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			categories:          [],
			providers:           [],
			feeds:               [],
			resolutions:         [],
			categoryResolutions: [],
			searchFilter:        '',
		};
	}

	toggleCategoryCollapse(uuid) {
		const {
			categories,
		} = this.state;

		const newCategories = categories.map((category) => {
			if (category.uuid === uuid) {
				return {
					...category,
					expanded: !category.expanded,
				};
			}

			return category;
		});

		this.setState({
			categories: newCategories,
		});
	}

	toggleCategoryActiveStatus(category) {
		patchEntertainmentCategory(category.uuid, {
			...category,
			active: !category.active,
		})
			.then(() => {
				this.fetchEntertainment();
			});
	}

	toggleFeedActiveStatus(categoryResolution) {
		patchEntertainmentCategoryProviderFeedResolution(categoryResolution.uuid, {
			...categoryResolution,
			active: !categoryResolution.active,
		})
			.then(() => {
				this.fetchEntertainment();
			});
	}

	expandAll() {
		const categories = this.state.categories.map((category) => {
			return {
				...category,
				expanded: true,
			};
		});
		this.setState({
			categories,
		});
	}

	collapseAll() {
		const categories = this.state.categories.map((category) => {
			return {
				...category,
				expanded: false,
			};
		});
		this.setState({
			categories,
		});
	}

	openCategoryModal(isEdit, category) {
		const {
			app,
		} = this.props;

		app.openModal({
			modalSize:  2,
			showModal:  true,
			modalProps: {
				title: isEdit ? 'Edit Category' : 'Add Category',
				jsx:   (
					<EntertainmentCategoryModal
						app={app}
						isEdit={isEdit}
						category={category}
						onSave={(updatedCategory) => {
							if (isEdit) {
								patchEntertainmentCategory(updatedCategory.uuid, updatedCategory)
									.then(() => {
										this.fetchEntertainment();
										app.closeModal();
									});
							} else {
								postEntertainmentCategory([ updatedCategory, ])
									.then(() => {
										this.fetchEntertainment();
										app.closeModal();
									});
							}
						}}
					/>
				),
			},
		});
	}

	openFeedModal(isEdit, category) {
		const {
			app,
		} = this.props;

		const {
			providers,
			feeds,
			resolutions,
			categoryResolutions,
		} = this.state;

		app.openModal({
			modalSize:  2,
			showModal:  true,
			modalProps: {
				title: isEdit ? 'Edit Feed' : 'Add Feed',
				jsx:   (
					<EntertainmentFeedModal
						app={app}
						category={category}
						providers={providers}
						feeds={feeds}
						resolutions={resolutions}
						categoryResolutions={categoryResolutions}
						isEdit={isEdit}
						onSave={(categoryResolution) => {
							if (isEdit) {
								patchEntertainmentCategoryProviderFeedResolution(categoryResolution.uuid, categoryResolution)
									.then(() => {
										this.fetchEntertainment();
										app.closeModal();
									});
							} else {
								postEntertainmentCategoryProviderFeedResolution([ categoryResolution, ])
									.then(() => {
										this.fetchEntertainment();
										app.closeModal();
									});
							}
						}}
					/>
				),
			},
		});
	}

	fetchEntertainment() {
		fetchEntertainment()
			.then((entertainment) => {
				this.setState({
					categories: entertainment.categories.map((category) => {
						category.feeds = entertainment.category_resolutions
							.filter((categoryResolution) => {
								return categoryResolution.entertainment_category_id === category.id;
							})
							.map((categoryResolution) => {
								const resolution = entertainment.resolutions.find((_resolution) => {
									return _resolution.id === categoryResolution.entertainment_provider_feed_resolution_id;
								});

								const feed = entertainment.feeds.find((_feed) => {
									return _feed.id === resolution.entertainment_provider_feed_id;
								});

								const provider = entertainment.providers.find((_provider) => {
									return _provider.id === feed.entertainment_provider_id;
								});

								return {
									...categoryResolution,
									resolution: resolution.resolution,
									name:       provider.display_name,
								};
							});
						category.expanded = true;

						return category;
					}),
					providers:           entertainment.providers,
					feeds:               entertainment.feeds,
					resolutions:         entertainment.resolutions,
					categoryResolutions: entertainment.category_resolutions,
				});
			});
	}

	componentDidMount() {
		this.fetchEntertainment();
	}

	render() {
		const {
			app,
		} = this.props;

		const {
			categories,
		} = this.state;

		const {
			pg = '',
		} = getSession();

		if (pg !== 'ia') {
			return '';
		}

		return (
			<div className={style.entertainment} data-ts-id="entertainmentEditorEntertainment">
				<h2 className={style.title} data-ts-id="entertainmentEditorTitle">Entertainment Categories</h2>
				<div className={style.content} data-ts-id="entertainmentEditorContent">
					<button
						className={`button ${style.openModalButton}`}
						onClick={(e) => {
							this.openCategoryModal(false);
						}}
						data-ts-id="entertainmentEditorAddNewCategoryButton"
					>
						Add New Category
					</button>
					<div className={style.thFilterContainer}>
						<input className={style.thFilter}
							id='datafilter'
							placeholder='Filter...'
							value={this.state.searchFilter}
							onChange={(e) => {
								this.setState({
									searchFilter: e.target.value,
								});
							}}/>
					</div>
					<div className={style.expandCollapseContainer} data-ts-id="entertainmentEditorExpandCollapseContainer">
						<button onClick={() => {
							this.expandAll();
						}} className={`button`} data-ts-id="entertainmentEditorExpandAllButton">Expand All</button>
						<button onClick={() => {
							this.collapseAll();
						}} className={`button`} data-ts-id="entertainmentEditorCollapseAllButton">Collapse All</button>
					</div>
					<div className={style.categories} data-ts-id="entertainmentEditorCategories">
						{categories
							.filter((category) => {
								if (this.state.searchFilter === '') {
									return true;
								}
								return category.display_name.toLowerCase().includes(this.state.searchFilter.toLowerCase());
							})
							.sort((a, b) => {
								return a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1;
							})
							.map((category, index) => {
								return (
									<div key={category.uuid} className={style.category} data-ts-id={`entertainmentEditorCategory-${category.uuid}`} data-ts-index={index}>
										<div onClick={() => {
											this.toggleCategoryCollapse(category.uuid);
										}} data-ts-id={`entertainmentEditorToggleCategoryCollapse-${category.uuid}`}>
											{category.expanded ? <CustomIcon icon='caret-down' color='#00BAFF' /> : <CustomIcon icon='caret-right' color='#00BAFF' />}
										</div>
										<div className={style.categoryContent} data-ts-id={`entertainmentEditorCategoryContent-${category.uuid}`}>
											<div className={style.categoryHeader} data-ts-id={`entertainmentEditorCategoryHeader-${category.uuid}`}>
												<img src={category.thumbnail_url} alt={category.display_name} data-ts-id={`entertainmentEditorCategoryImage-${category.uuid}`} />
												<div className={style.categoryText} data-ts-id={`entertainmentEditorCategoryText-${category.uuid}`}>
													<h3 data-ts-id={`entertainmentEditorCategoryTitle-${category.uuid}`}>{category.display_name}</h3>
													<p data-ts-id={`entertainmentEditorCategoryDescription-${category.uuid}`}>{category.description}</p>
												</div>
												<div className={style.categoryButtons} data-ts-id={`entertainmentEditorCategoryButtons-${category.uuid}`}>
													<div onClick={() => {
														this.openCategoryModal(true, category);
													}} data-ts-id={`entertainmentEditorEditCategoryButton-${category.uuid}`}>
														<CustomIcon icon='edit' color='#00BAFF'/>
													</div>
													<button onClick={() => {
														this.openFeedModal(false, category);
													}} className={`button ${style.addButton}`} data-ts-id={`entertainmentEditorAddFeedButton-${category.uuid}`}><CustomIcon icon='plus' color='white' /> Feed</button>
													<input
														className={`on-off on-off-label ${style.activeToggle}`}
														type='checkbox'
														checked={category.active}
														onChange={(e) => {
															this.toggleCategoryActiveStatus(category);
														}}
														data-ts-id={`entertainmentEditorToggleCategoryActive-${category.uuid}`}
													/>
													<div
														onClick={() => {
															const confirmDelete = confirm('Are you sure you want to delete this category? This may affect many different systems.');
															if (confirmDelete) {
																deleteEntertainmentCategory(category.uuid)
																	.then(() => {
																		this.fetchEntertainment();
																	});
															}
														}}
														data-ts-id={`entertainmentEditorDeleteCategoryButton-${category.uuid}`}
													>
														<CustomIcon icon='trash' color='#00BAFF' />
													</div>
												</div>
											</div>
											{category.expanded && (
												<div className={style.feeds} data-ts-id={`entertainmentEditorFeedsContainer-${category.uuid}`}>
													{category.feeds.map((feed, feedIndex) => {
														return (
															<div key={feed.uuid} className={style.feed} data-ts-id={`entertainmentEditorFeed-${feed.uuid}`} data-ts-index={feedIndex}>
																<span data-ts-id={`entertainmentEditorFeedName-${feed.uuid}`}>{feed.name}</span>
																<span data-ts-id={`entertainmentEditorFeedResolution-${feed.uuid}`}>{feed.resolution}</span>
																<div className={style.feedButtonsContainer} data-ts-id={`entertainmentEditorFeedButtonsContainer-${feed.uuid}`}>
																	<div onClick={() => {
																		this.openFeedModal(true, category);
																	}} data-ts-id={`entertainmentEditorEditFeedButton-${feed.uuid}`}>
																		<CustomIcon icon='edit' color='#00BAFF'/>
																	</div>
																	<input
																		className={`on-off on-off-label ${style.activeToggle}`}
																		type='checkbox'
																		checked={feed.active}
																		onChange={(e) => {
																			this.toggleFeedActiveStatus(feed);
																		}}
																		data-ts-id={`entertainmentEditorToggleFeedActive-${feed.uuid}`}
																	/>
																	<div
																		onClick={() => {
																			app.openModal({
																				modalSize:  1,
																				showModal:  true,
																				modalProps: {
																					title: 'Delete this feed?',
																					jsx:   (
																						<div className="confirm-modal-buttons">
																							<button className="button" onClick={(e) => {
																								e.preventDefault();
																								deleteEntertainmentCategoryProviderFeedResolution(feed.uuid)
																									.then(() => {
																										this.fetchEntertainment();
																										app.closeModal();
																									});
																							}}>Yes</button>
																							<button className="button" onClick={(e) => {
																								e.preventDefault();
																								app.closeModal();
																							}}>No</button>
																						</div>
																					),
																				},
																			});
																		}}
																		data-ts-id={`entertainmentEditorDeleteFeedButton-${feed.uuid}`}
																	>
																		<CustomIcon icon='trash' color='#00BAFF' />
																	</div>
																</div>
															</div>
														);
													})}
												</div>
											)}
										</div>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		);
	}
}

export default EntertainmentEditor;