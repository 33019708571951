import React from 'react';
import DatePicker from 'react-datepicker';

import CustomIcon from '../Common/CustomIcon/CustomIcon';
import CustomCheckbox from './Elements/Checkbox';
import {
	v4 as uuidv4,
} from 'uuid';

// Styles
import style from './Listings.module.css';

import {
	getFDValue,
} from 'Vulture/Helpers';

import {
	getSession,
} from 'Vulture/Session';
import {
	dlToggleCreateAnotherListing,
} from 'Vulture/DataLayer';

class ListingsModal extends React.Component {
	constructor(props) {
		super(props);

		let scheduling = false;
		let use_times =  false;
		let use_dates =  false;
		let use_days =   false;
		let start_time = '';
		let end_time =   '';
		let start_date = '';
		let end_date =   '';
		let days =       [];

		const {
			listingFD,
			listing,
		} = props;

		const schedulingJSON = listingFD?.[`listing.${listing.uuid}`]?.listing_scheduling;

		if (schedulingJSON !== undefined) {
			const parsedSchedulingJSON = JSON.parse(schedulingJSON);

			scheduling = parsedSchedulingJSON.scheduling;
			use_times = parsedSchedulingJSON.use_times;
			use_dates = parsedSchedulingJSON.use_dates;
			use_days = parsedSchedulingJSON.use_days;
			start_time = parsedSchedulingJSON.start_time;
			end_time = parsedSchedulingJSON.end_time;
			start_date = parsedSchedulingJSON.start_date;
			end_date = parsedSchedulingJSON.end_date;
			days = parsedSchedulingJSON.days;
		}

		this.state = {
			name:                         '',
			suite:                        '',
			elms:                         [],
			selectedContentCount:         0,
			assignContentData:            [],
			associatedComponents:         [],
			unassociatedComponents:       [],
			// activeListingUUID: '',
			originalAssociatedComponents: [],
			confirmModal:                 false,
			confirmModalContent:          '',
			createNewListingAfterThisOne: false,
			formerAssociatedComponents:   [],
			formerUnassociatedComponents: [],
			requiredFields:               [],

			listing,
			listingParents: [],

			scheduling,
			use_times,
			use_dates,
			use_days,
			start_time,
			end_time,
			start_date,
			end_date,
			days,
		};

		this.scrollToRef = React.createRef();
	}

	componentDidMount() {
		const {
			listingGroups,
		} = this.props;
		this.makeMaps();
		this.makeFields();

		this.filteredListingGroups = listingGroups
			.filter((listingGroup) => {
				return listingGroup.nonExclusive;
			});
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			listingFD,
			app,
		} = this.props;

		let {
			scheduling,
			use_times,
			use_dates,
			use_days,
			start_time,
			end_time,
			start_date,
			end_date,
			days,
			listing,
		} = this.state;

		if (JSON.stringify(prevProps.listingFD) !== JSON.stringify(listingFD)) {
			this.makeMaps();
			this.makeFields();
		}

		if (prevState.scheduling !== scheduling ||
			prevState.use_times !== use_times ||
			prevState.use_dates !== use_dates ||
			prevState.use_days !== use_days ||
			prevState.start_time !== start_time ||
			prevState.end_time !== end_time ||
			prevState.start_date !== start_date ||
			prevState.end_date !== end_date ||
			prevState.days !== days) {

			if (use_days && days.length === 7) {
				days = [];
				use_days = false;
			}

			if (days && days.length === 7) {
				use_days = false;
			}

			if (use_times && start_time === end_time) {
				start_time = '';
				end_time = '';
				use_times = false;
			}

			if (!start_date && !end_date) {
				use_dates = false;
			}

			if (!use_times && !use_days && !use_dates) {
				scheduling = false;
			}

			const listingScheduling = {
				scheduling,
				use_dates,
				start_date,
				end_date,
				use_times,
				start_time,
				end_time,
				use_days,
				days,
			};

			const keys = [
				`listing.${listing.uuid}.listing_scheduling`,
			];

			const vals = [
				JSON.stringify(listingScheduling),
			];

			app.setValue('listings', keys, vals);
		}
	}

	makeMaps() {
		const {
			listingToListingCollectionsMap,
			listingGroups,
			listingConfigMap,
			findRootListings,
			app,
			listingFD,
			listingConfigToListingsMap,
			activeListingConfig,
		} = this.props;

		const {
			listing,
		} = this.state;

		let listingM1Key = `listing.${listing?.uuid}`;
		let listingParents = [];

		// T1 listings
		if (activeListingConfig?.root) {
			for (let group of Object.values(listingGroups || {})) {
				if (Array.isArray(group.listingCollectionM1Keys) && group.listingCollectionM1Keys.length > 0) {
					let parentM1Key = group.listingCollectionM1Keys[0];
					let entityName = `${parentM1Key}.${listingM1Key}`;
					let relVal = getFDValue(listingFD, entityName);
					let checked = typeof relVal !== 'undefined';

					let parentObj = {
						checked,
						label:                   group.name || '',
						uuid:                    group.uuid || '',
						listingCollectionM1Keys: group.listingCollectionM1Keys,
						listingM1Key,
						entityName,
						t1:                      true,
					};

					listingParents.push(parentObj);
				}
			}
		}

		// T1+
		if (!activeListingConfig?.root) {
			let rootConfig;
			for (const uuid in listingConfigMap || {}) {
				if (listingConfigMap[uuid]?.root === true) {
					rootConfig = uuid;
					break;
				}
			}

			let parentListingConfigUUID = activeListingConfig?.parentUUID;
			let parentListingConfigM1Key = `listing_config.${parentListingConfigUUID}`;
			let rootParentListingConfigKey = `listing_config.${rootConfig}`;
			let parentListings = listingConfigToListingsMap?.[parentListingConfigM1Key];
			let rootParentListings = listingConfigToListingsMap?.[rootParentListingConfigKey];
			let pg = getSession()?.pg;

			const {
				parentsFirstColumnEntityKey,
				parentsFirstColumnAttr,
			} = this.props.parentsPrimaryColumnData || {};

			if (parentListings) {
				for (const parentM1Key of Object.keys(parentListings)) {
					let parentListingCollectionM1Keys = [];
					let rootListings = findRootListings(parentM1Key) || [];
					if (pg !== 'iu' && pg !== 'ia' && pg !== 'ea') {
						let hideListing = false;
						for (let rootListing of rootListings) {
							if (
								rootParentListings?.[rootListing]?.systems?.length === 0
							) {
								hideListing = true;
							} else if (listingToListingCollectionsMap[rootListing]) {
								parentListingCollectionM1Keys.push(
									listingToListingCollectionsMap[rootListing]
								);
								hideListing = false;
							}
						}

						if (hideListing) {
							continue; // Skip to the next iteration
						}
					}
					for (let rootListing of rootListings) {
						if (listingToListingCollectionsMap[rootListing]) {
							parentListingCollectionM1Keys.push(
								listingToListingCollectionsMap[rootListing]
							);
						}
					}

					const label =
						listingFD?.[parentM1Key]?.[parentsFirstColumnEntityKey]?.[
							parentsFirstColumnAttr
						] || '';
					parentListingCollectionM1Keys = parentListingCollectionM1Keys.flat();

					let parentLabels = new Set();
					for (let listingCollectionM1Key of parentListingCollectionM1Keys) {
						for (let group of listingGroups || []) {
							if (
								Array.isArray(group.listingCollectionM1Keys) &&
								group.listingCollectionM1Keys.includes(
									listingCollectionM1Key
								)
							) {
								parentLabels.add(group.name);
							}
						}
					}
					parentLabels = Array.from(parentLabels).join(', ');

					let entityName = `${parentM1Key}.${listingM1Key}`;
					let relVal = getFDValue(listingFD, entityName);
					let checked = typeof relVal !== 'undefined';

					let parentObj = {
						label,
						parentLabels,
						parentM1Key,
						listingM1Key,
						checked,
						entityName,
						t1: false,
					};

					listingParents.push(parentObj);
				}
			}
		}

		listingParents.sort((a, b) => {
			let aStr = a.label?.toLowerCase() || '';
			let bStr = b.label?.toLowerCase() || '';

			if (aStr > bStr) return 1;
			if (aStr < bStr) return -1;
			return 0;
		});

		const filteredListingParents = listingParents.filter(
			(listingParent) => {
				return listingParent.label && listingParent.label.trim() !== '';
			}
		);

		let selectedContentCount = filteredListingParents.filter((p) => {
			return p.checked;
		}).length;

		if (Array.isArray(this.state.listingParents)) {
			this.state.listingParents.forEach((listingParent, index) => {
				if (listingParent.checked && filteredListingParents[index]) {
					filteredListingParents[index].checked = true;
					app.setValue('listings', filteredListingParents[index].entityName, true);
				}
			});
		}

		this.setState({
			listingParents:    filteredListingParents,
			assignContentData: filteredListingParents,
			selectedContentCount,
		});
	}


	makeFields() {
		const {
			app,
			listingFD,
			accountUUID,
			buildingsDataArray,
			settingMap,
			listingConfigUUID,
			listingConfigToListingsMap,
			listingConfigIsIntegration,
			isEdit,
			activeListingConfig,
		} = this.props;

		const {
			listing,
			createNewListingAfterThisOne,
		} = this.state;

		let assignContentData = this.state.assignContentData;
		let placeholderContentData = this.state.assignContentData;
		let assignContentDataObject = {};

		let associatedComponents = [];
		let unassociatedComponents = [];
		if (createNewListingAfterThisOne) {
			this.state.assignContentData.forEach((assignedContent) => {
				if (assignedContent.checked) {
					associatedComponents.push(assignedContent.componentID);
				}
			});
		}

		if (!isEdit) {
			app.setValue('listings', [
				`account.${accountUUID}.listing.${listing.uuid}`,
				`listing.${listing.uuid}.listing_config.${listingConfigUUID}`,
			], [ true, true, ]);
		}

		if (!activeListingConfig.root) {
			const {
				parentsFirstColumnEntityKey, parentsFirstColumnAttr,
			} = this.props.parentsPrimaryColumnData;
			let parentEntityKey = `listing_config.${activeListingConfig.parentUUID}`;

			Object.keys(listingConfigToListingsMap[parentEntityKey]).forEach((listingM1KEy) => {
				let listingUUID = listingM1KEy.split('.')[1];
				let data = {
					componentID: listingUUID,
					name:        this.props.getParentComponentName(listingM1KEy, parentsFirstColumnEntityKey, parentsFirstColumnAttr),
					checked:     false,
				};

				if (this.props.isEdit && this.state.listing.parent_listings && this.state.listing.parent_listings.includes(`listing.${listingUUID}`)) {
					data.checked = true;
					if (!associatedComponents.includes(listingUUID)) {
						associatedComponents.push(listingUUID);
					}
				} else if (!unassociatedComponents.includes(listingUUID) && !associatedComponents.includes(listingUUID)) {
					unassociatedComponents.push(listingUUID);
				}

				assignContentDataObject[listingUUID] = data;
			});
		} else {
			// this.props.listingsGroups = listing_collection.*
			this.props.listingGroups.forEach((listingGroup) => {
				let data = {
					componentID:  listingGroup.uuid,
					name:         listingGroup.name.length ? listingGroup.name : listingGroup.uuid,
					checked:      false,
					nonExclusive: listingGroup.nonExclusive,
				};

				if (isEdit && this.props.listingCollectionHasListingGroupRelation(listingGroup, this.props.listing)) {
					data.checked = true;
					if (!associatedComponents.includes(listingGroup.uuid)) {
						associatedComponents.push(listingGroup.uuid);
					}
				} else if (!unassociatedComponents.includes(listingGroup.uuid) && !associatedComponents.includes(listingGroup.uuid)) {
					unassociatedComponents.push(listingGroup.uuid);
				}

				assignContentDataObject[listingGroup.uuid] = data;
			});
		}

		if (this.state.createNewListingAfterThisOne) {
			assignContentData = placeholderContentData;
		} else {
			assignContentData = Object.values(assignContentDataObject);
		}

		// sort assignContentData
		assignContentData.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});
		let elms = [];
		let candidateElements = [];

		for (let [ entityKey, info, ] of Object.entries(activeListingConfig)) {
			if (entityKey.startsWith('listing_column.')) {
				candidateElements.push({
					info,
					order: info.order,
					uuid:  entityKey.split('.')[1],
				});
			}
		}

		candidateElements.sort((a, b) => {
			if (a.order < b.order) {
				return -1;
			}
			if (a.order > b.order) {
				return 1;
			}
			return 0;
		});

		let requiredFields = [];

		candidateElements.forEach((candidate) => {
			let columnType = '';
			let columnDisplayName = '';
			Object.entries(candidate.info).forEach(([ subKey, infoValue, ]) => {
				if (subKey.startsWith('listing_column_type')) {
					columnType = infoValue.type;
					columnDisplayName = infoValue.display_name;
				}
			});

			let fieldType = '';

			let entity = {
				attr: {
					label: candidate.info.display_name,
				},
				name: `listing.${listing.uuid}.listing_column.${candidate.uuid}.${columnType}`,
				type: 'FIELD',
			};

			if (listingConfigIsIntegration) {
				entity.isDisabled = true;
			}

			switch (columnType) {
			case 'string':
				// TODO: add columnDisplayName === 'PDF' in phase 2
				if (columnDisplayName === 'Image' || columnDisplayName === 'Logo' || columnDisplayName === 'Photo') {
					fieldType = 'custom.vulture.elements.uploader';
					entity.attr.parent_directory = 'ts-listings';
					entity.type = 'Image';
					entity.attr.uuid = `${listing.uuid}/${candidate.uuid}`;
				} else if (columnDisplayName === 'PDF') {
					fieldType = 'custom.vulture.elements.uploader';
					entity.attr.parent_directory = 'ts-listings';
					entity.type = 'PDF';
					entity.attr.uuid = `${listing.uuid}/${candidate.uuid}`;
				} else if (columnDisplayName === 'Video') {
					fieldType = 'custom.vulture.elements.uploader';
					entity.attr.parent_directory = 'ts-listings';
					entity.type = 'Video';
					entity.attr.uuid = `${listing.uuid}/${candidate.uuid}`;
				} else if (columnDisplayName === 'QR Code') {
					fieldType = 'custom.vulture.qrcode';
					entity.attr.parent_directory = 'ts-listings';
					entity.attr.uuid = `${listing.uuid}/${candidate.uuid}`;
				} else if (columnDisplayName === 'SMS Message') {
					fieldType = 'custom.vulture.elements.phone.number';
				} else if (columnDisplayName === 'Email Message' || columnDisplayName === 'Teams') {
					fieldType = 'custom.vulture.elements.email';
				} else if (columnDisplayName === 'Slack') {
					fieldType = 'custom.vulture.elements.slack';
				} else if (columnDisplayName === 'Array') {
					entity.options = [];

					const arrayOptions = listingFD[`account.${accountUUID}`].array_options;

					if (arrayOptions && JSON.parse(arrayOptions) && JSON.parse(arrayOptions)[candidate.info.display_name]) {
						const parsedOptions = JSON.parse(arrayOptions);

						if (parsedOptions[candidate.info.display_name] && parsedOptions[candidate.info.display_name].options && parsedOptions[candidate.info.display_name].type) {
							entity.options = parsedOptions[candidate.info.display_name].options;

							const entityNameWithoutType = entity.name.split('.').slice(0, 4).join('.');
							entity.name = `${entityNameWithoutType}.${parsedOptions[candidate.info.display_name].type}`;
						}
					}

					fieldType = 'custom.vulture.elements.drop.down';
				} else if (columnDisplayName === 'Building Picker') {
					entity.options = [
						{
							label: 'No Building',
							value: '',
						},
					];

					buildingsDataArray.forEach((building) => {
						entity.options.push({
							label: building.vals.buildingName,
							value: building.m1Key.replace('building.', ''),
						});
					});

					fieldType = 'custom.vulture.elements.drop.down';
				} else if (columnDisplayName === 'Concatenator') {
					fieldType = 'custom.vulture.elements.text.small';

					entity = {
						...entity,
						attr: {
							...entity?.attr,
							htmlattr: {
								disabled: true,
							},
						},
					};
				} else if (columnDisplayName === 'Text Large') {
					fieldType = 'custom.vulture.elements.text.large';
				} else {
					fieldType = 'custom.vulture.elements.text.small';
				}
				break;
			case 'int':
				if (columnDisplayName === 'Directional Arrows') {
					fieldType = 'custom.vulture.elements.icon';
					entity.type = 'Directional Arrows';
				} else {
					fieldType = 'custom.vulture.elements.number';
				}
				break;
			case 'bool':
				fieldType = 'custom.vulture.elements.advanced.checkbox';
				break;
			case 'float':
				fieldType = 'custom.vulture.elements.float';
				break;
			case 'datetime':
				fieldType = 'custom.common.date';
				break;
			case 'time':
				fieldType = 'custom.common.date.time';
				break;
			case 'json':
				if (columnDisplayName === 'Primary Phone' || columnDisplayName === 'Phone') {
					fieldType = 'custom.vulture.elements.phone.number';
				}
				break;
			default:
				console.log(listing.uuid + ': Invalid type ' + columnType);
				break;
			}

			entity.fieldType = fieldType;

			requiredFields.push(entity);

			elms.push(
				<React.Fragment key={`listing.${listing.uuid}.listing_column.${candidate.uuid}`}>
					{app.renderEntity(entity, {
						fd: listingFD,
					})}
				</React.Fragment>
			);
		});

		this.setState({
			elms,
			assignContentData,
			associatedComponents,
			unassociatedComponents,
			requiredFields,
			originalAssociatedComponents: JSON.parse(JSON.stringify(associatedComponents)),
			createNewListingAfterThisOne: this.state.createNewListingAfterThisOne ? true : false,
		});
	}

	getSystem(m1, m1UUID) {
		const {
			listingFD,
		} = this.props;

		let parentM1Keys = [];
		for (let [ m1Key, m1Data, ] of Object.entries(listingFD)) {
			for (let m2Key of Object.keys(m1Data)) {
				if (m2Key === `${m1}.${m1UUID}`) {
					if (m1Key.startsWith('system.')) {
						return m1Key.split('.')[1];
					}

					parentM1Keys.push(m1Key);
				}
			}
		}

		for (let m1Key of parentM1Keys) {
			let systemUUID = this.getSystem(...m1Key.split('.'));
			if (typeof systemUUID === 'string') {
				return systemUUID;
			}
		}
	}

	getListingUUIDs(m1, m1UUID) {
		const {
			listingFD,
		} = this.props;

		let listingUUIDs = [];
		for (let [ m2Key, m2Data, ] of Object.entries(listingFD[`${m1}.${m1UUID}`])) {
			if (m2Key.startsWith('listing.')) {
				let m2UUID = m2Key.split('.')[1];
				listingUUIDs.push(m2UUID);

				for (let m3Key of Object.keys(m2Data)) {
					if (m3Key.startsWith('listing.')) {
						let childListingUUIDs = this.getListingUUIDs('listing', m2UUID);
						listingUUIDs = [ ...listingUUIDs, ...childListingUUIDs, ];
					}
				}
			}
		}

		return listingUUIDs;
	}

	filterContent() {
		let i, li, name, txtValue;
		li = document.querySelectorAll('li[data-selector="content-item"]');
		for (i = 0; i < li.length; i++) {
			if (!this.state.searchValue) {
				li[i].style.display = '';
				continue;
			}
			name = li[i].querySelectorAll('div[data-selector="content-name"]')[0];
			txtValue = name.textContent || name.innerText || '';
			if (txtValue && this.state.searchValue && txtValue.toUpperCase().indexOf(this.state.searchValue.toUpperCase()) > -1) {
				li[i].style.display = '';
			} else {
				li[i].style.display = 'none';
			}
		}
	}

	getTier1Parent(listingUUID) {
		const {
			listingFD,
		} = this.props;
		let uuidsToReturn = [];

		const parentEntityKeys = [];

		for (let [ m1Key, m1Data, ] of Object.entries(listingFD)) {
			for (let [ m2Key, m2Data, ] of Object.entries(m1Data)) {
				const entityKey = `${m1Key}.${m2Key}`;
				if (m2Key === `listing.${listingUUID}`) {
					parentEntityKeys.push(entityKey);
				}
			}
		}

		parentEntityKeys.forEach((entityKey) => {
			if (entityKey.startsWith('listing_collection')) {
				let m1ID = entityKey.split('.')[1];
				if (getFDValue(listingFD, `listing_collection.${m1ID}.listing.${listingUUID}`)) {
					uuidsToReturn.push(m1ID);
				}
			} else if (entityKey.startsWith('listing')) {
				let m1ID = entityKey.split('.')[1];
				uuidsToReturn = this.getTier1Parent(m1ID);
			}
		});

		return uuidsToReturn;
	}

	formateDateForDatePicker(date) {
		let formattedDate = new Date(date);
		return formattedDate;
	}

	formatDateForJSON(date) {
		if (date) {
			let iso = date.toISOString();
			return iso;
		}
		return null;
	}

	formatTimeForJSON(date) {
		let selectedDate = new Date(date);
		return `${selectedDate.getHours()}:${(selectedDate.getMinutes() < 10 ? '0' : '') + selectedDate.getMinutes()}:00`;
	}

	updateDays(day) {
		let days = [ ...this.state.days, ];

		if (days.includes(day)) {
			days.map((obj, i) => {
				if (obj === day) {
					days.splice(i, 1);
				}
			});
		} else {
			days.push(day);
		}
		days = days.sort((a, b) => {
			return a - b;
		});

		this.setState({
			days,
		});
	}

	getAssignContentData() {
		let elms = [];
		let groupName = null;
		let childClassName = style.groupName;

		Array.prototype.alphaSort = function (sortParameter) {
			function compare(aB, bB) {
				let a = aB[sortParameter];
				let b = bB[sortParameter];

				a = a.toLowerCase();
				b = b.toLowerCase();

				return a > b ? 1 : -1;
			}
			this.sort(compare);
		};

		//sort this before displaying the text
		this.state.assignContentData.alphaSort('name');
		this.state.assignContentData.map((potentialParentListings, i) => {
			if (!this.props.activeListingConfig.root) {
				let groupNameToDisplay = '';
				let groupNamesToAlphabetize = [];
				let tier1Parents = this.getTier1Parent(potentialParentListings.componentID);

				tier1Parents.forEach((parentUUID) => {
					this.props.listingGroups.forEach((group) => {
						if (group.uuid === parentUUID) {
							groupNameToDisplay += `${group.name}, `;
							groupNamesToAlphabetize.push(group.name);
						}
					});
				});
				groupNameToDisplay = groupNamesToAlphabetize.sort((a, b) => {
					let aA = a.toLowerCase();
					let bA = b.toLowerCase();
					if (bA > aA) {
						return -1;
					}
					if (aA < bA) {
						return 1;
					}
					return 0;
				}
				).join(', ');
				groupName = (
					<div className={style.contentGroupName}>{groupNameToDisplay}</div>
				);
				childClassName = `${style.groupName} ${style.childGroupName}`;
			}

			const ref = React.createRef();
			const handleClick = () => {
				return ref.current.scrollIntoView({
					behavior: 'smooth',
					block:    'start',
				});
			};
			elms.push(
				<li data-selector="content-item"
					className={childClassName}
					key={`listings-li-${i}`}
					ref={ref}
					id = {potentialParentListings.componentID}
					onClick={(e) => {
						let assignContentData = this.state.assignContentData;
						let checked = assignContentData[i].checked;
						assignContentData[i].checked = !checked;

						let selectedContentCount = this.state.selectedContentCount;

						// list of listing_collection UUIDs
						let associatedComponents = this.state.associatedComponents;

						let unassociatedComponents = this.state.unassociatedComponents;
						if (assignContentData[i].checked) {
							selectedContentCount++;
							associatedComponents.push(potentialParentListings.componentID);
							if (unassociatedComponents.includes(potentialParentListings.componentID)) {
								let indexToRemove = unassociatedComponents.indexOf(potentialParentListings.componentID);
								unassociatedComponents.splice(indexToRemove, 1);
							}
						}
						if (!assignContentData[i].checked) {
							selectedContentCount--;
							unassociatedComponents.push(potentialParentListings.componentID);
							if (associatedComponents.includes(potentialParentListings.componentID)) {
								let indexToRemove = associatedComponents.indexOf(potentialParentListings.componentID);
								associatedComponents.splice(indexToRemove, 1);
							}
						}

						this.setState({
							selectedContentCount,
							assignContentData,
							associatedComponents,
							unassociatedComponents,
						});
					}}
				>

					<div className={style.nameContainer}>
						<CustomCheckbox
							checked={potentialParentListings.checked}
						/>
						<div data-selector="content-name" className={`${style.contentBucket} ${style.contentBucketParent}`}>{potentialParentListings.name}</div>
					</div>
					{groupName}
				</li>
			);
		});

		return elms;
	}

	isDetailsEmpty() {
		const {
			listingFD,
		} = this.props;

		let numberOfFields = this.state.requiredFields.length;
		let numberOfEmptyFields = 0;

		this.state.requiredFields.forEach((field) => {
			if (!getFDValue(listingFD, field.name)) {
				numberOfEmptyFields++;
			}
		});

		if (numberOfEmptyFields === numberOfFields) {
			return true;
		}
		return false;
	}

	isFirstColumnEmpty() {
		const {
			listingFD,
			pendingChanges,
		} = this.props;

		const {
			requiredFields,
		} = this.state;

		let firstColumnValue = getFDValue(listingFD, requiredFields[0].name);
		firstColumnValue = firstColumnValue || pendingChanges[requiredFields[0].name];

		if (firstColumnValue === undefined || firstColumnValue === '') {
			return true;
		}

		return false;
	}

	checkURLFormat() {
		const {
			listingFD,
			pendingChanges,
		} = this.props;

		const pattern = /listing_column\.([a-zA-Z0-9-]+)\./;
		let urlColumnTypeUUID = '';

		// Gets UUID of listing_column_type with display_name 'URL'
		for (const [ m1Key, m1Data, ] of Object.entries(listingFD)) {
			if (m1Key.startsWith('listing_column_type')) {
				if (m1Data.display_name === 'URL') {
					urlColumnTypeUUID = m1Key.split('.')[1];

					break;
				}
			}
		}

		// For each pending change, if the column type is a URL, check if the value starts with https:// or is empty
		for (const [ m1Key, m1Data, ] of Object.entries(pendingChanges)) {
			const match = m1Key.match(pattern);

			if (match && match.length >= 2) {
				const urlColumnUUID = match[1];
				let columnTypeUUID = '';
				for (const [ m2Key, m2Data, ] of Object.entries(listingFD)) {
					if (m2Key.startsWith(`listing_column.${urlColumnUUID}`)) {
						for (const m3Key of Object.keys(m2Data)) {
							if (m3Key.startsWith('listing_column_type')) {
								columnTypeUUID = m3Key.split('.')[1];

								break;
							}
						}
					}
				}

				if (columnTypeUUID === urlColumnTypeUUID) {
					if (!m1Data.toLowerCase().startsWith('https://') && m1Data) {
						return false;
					}
				}
			}
		}

		return true;

	}

	setCheckbox(info) {
		const {
			app,
		} = this.props;

		let obj = {};

		let nonExclusiveSystemPresent = false;

		this.filteredListingGroups
			.forEach((listingGroup) => {
				if (nonExclusiveSystemPresent) {
					return;
				}

				for (const listingCollectionM1Key of info.listingCollectionM1Keys) {
					if (listingGroup.listingCollectionM1Keys.includes(listingCollectionM1Key)) {
						nonExclusiveSystemPresent = true;
						break;
					}
				}
			});

		const cb = () => {
			if (info.t1) {
				for (let listingCollectionM1Key of info.listingCollectionM1Keys) {
					obj[`${listingCollectionM1Key}.${info.listingM1Key}`] = !info.checked;
				}
			}

			if (!info.t1) {
				obj[`${info.parentM1Key}.${info.listingM1Key}`] = !info.checked;
			}

			info.checked = Object.values(obj)[0];

			app.setValue('listings', Object.keys(obj), Object.values(obj));
		};

		if (nonExclusiveSystemPresent) {
			this.setState({
				confirmModal:        true,
				confirmModalContent: (
					<>
						<p>{`You are ${!info.checked ? 'assigning this listing to' : 'unassigning this listing from'} a content group that is shared by other system groups.
						Please be aware that this action will affect other systems.`}</p>
						<div className="confirm-modal-buttons">
							<button className="button" onClick={(e) => {
								e.preventDefault();
								this.setState({
									confirmModal:        false,
									confirmModalContent: '',
								}, () => {
									cb();
								});
							}}>Yes</button>
							<button className="button" onClick={(e) => {
								this.setState({
									confirmModal: false,
								});
							}}>No</button>
						</div>
					</>
				),
			});
		} else {
			return cb();
		}
	}

	setAllCheckboxes(isChecked) {
		const {
			listingParents,
			associatedComponents,
		} = this.state;

		const {
			app,
		} = this.props;

		let keys = [];
		let values = [];

		let nonExclusiveSystemPresent = false;

		this.filteredListingGroups
			.forEach((listingGroup) => {
				if (associatedComponents.includes(listingGroup.uuid)) {
					nonExclusiveSystemPresent = true;
				}
			}, []);

		const cb = () => {
			for (let info of listingParents) {
				let obj = {};
				if (info.t1) {
					for (let listingCollectionM1Key of info.listingCollectionM1Keys) {
						obj[`${listingCollectionM1Key}.${info.listingM1Key}`] = isChecked;
					}
				} else {
					obj[`${info.parentM1Key}.${info.listingM1Key}`] = isChecked;
				}
				info.checked = isChecked;
				keys.push(...Object.keys(obj));
				values.push(...Object.values(obj));
			}

			app.setValue('listings', keys, values);
		};

		if (nonExclusiveSystemPresent) {
			this.setState({
				confirmModal:        true,
				confirmModalContent: (
					<>
						<p>{`You are ${isChecked ? 'assigning this listing to' : 'unassigning this listing from'} a content group that is shared by other system groups.
						Please be aware that this action will affect other systems.`}</p>
						<div className="confirm-modal-buttons">
							<button className="button" onClick={(e) => {
								e.preventDefault();
								this.setState({
									confirmModal:        false,
									confirmModalContent: '',
								}, () => {
									cb();
								});
							}}>Yes</button>
							<button className="button" onClick={(e) => {
								this.setState({
									confirmModal: false,
								});
							}}>No</button>
						</div>
					</>
				),
			});
		} else {
			return cb();
		}
	}

	render() {
		const {
			listingFD,
			listingConfigUUID,
			app,
			systemGroupUUID,
			listingConfigIsIntegration,
			fetchListingDataByListingConfig,
		} = this.props;

		let {
			scheduling,
			use_dates,
			start_date,
			end_date,
			use_times,
			start_time,
			end_time,
			use_days,
			days,
			associatedComponents,
		} = this.state;


		let startDate = null;
		if (start_date) {
			startDate = this.formateDateForDatePicker(start_date);
		}

		let endDate = null;
		if (end_date) {
			endDate = this.formateDateForDatePicker(end_date);
		}
		let pg = getSession().pg;
		let isPropertyManager = (pg !== 'ia' && pg !== 'iu' && pg !== 'ea');

		let parentSelected = false;
		for (let parentObj of this.state.listingParents) {
			if (parentObj.checked) {
				parentSelected = true;
				break;
			}
		}

		// DatePicker component does not accept just a time.
		// This code block instantiates a new Date and then assigns only the relavent hour and minute
		let startTime = null;
		if (start_time) {
			let hoursMinutesSeconds = start_time.split(':');
			let stubDate = new Date();
			stubDate.setHours(hoursMinutesSeconds[0]);
			stubDate.setMinutes(hoursMinutesSeconds[1]);
			stubDate.setSeconds('00');
			startTime = stubDate;
		}

		// DatePicker component does not accept just a time.
		// This code block instantiates a new Date and then assigns only the relavent hour and minute
		let endTime = null;
		if (end_time) {
			let hoursMinutesSeconds = end_time.split(':');
			let stubDate = new Date();
			stubDate.setHours(hoursMinutesSeconds[0]);
			stubDate.setMinutes(hoursMinutesSeconds[1]);
			stubDate.setSeconds('00');
			endTime = stubDate;
		}

		const maxTime = new Date();
		maxTime.setHours(23);
		maxTime.setMinutes(45);
		maxTime.setSeconds(0);

		const {
			listingParents,
		} = this.state;

		let checkContainer = <></>;
		if (!this.props.isEdit) {
			checkContainer =
				<div className={style.checkContainer}>
					<div className={style.checkbox} onClick={(e) => {
						e.preventDefault();

						dlToggleCreateAnotherListing({
							option: !this.state.createNewListingAfterThisOne,
						});

						this.setState({
							createNewListingAfterThisOne: !this.state.createNewListingAfterThisOne,
						});
					}}>
						<CustomCheckbox
							checked={this.state.createNewListingAfterThisOne}
						/>
					</div>

					<p className={style.listingAdd}>Create another listing after this one</p>
				</div>;
		}

		let bottomButton =
			<button
				onClick={(e) => {
					if (this.props.app.hasPendingChanges()) {
						this.setState({
							confirmModal:        true,
							confirmModalContent:
								<>
									<p>By cancelling you will lose your unsaved changes. Are you sure you want to continue?</p>
									<div className="confirm-modal-buttons">
										<button className="button-outlined" onClick={(e) => {
											this.setState({
												confirmModal: false,
											});
											app.closeModal();
										}}>Yes</button>
										<button className="button" onClick={(e) => {
											this.setState({
												confirmModal: false,
											});
										}}>No</button>
									</div>
								</>,
						});
					} else {
						app.closeModal();
					}
				}}
				className="button-outlined"
			>
				Cancel
			</button>;

		let saveButton = (
			<button
				onClick={(e) => {
					if (this.isFirstColumnEmpty()) {
						alert('You must enter data into the first column to continue saving.');
						return;
					}

					if (isPropertyManager) {
						if (!parentSelected) {
							alert('You must assign content to at least one item from the list on the right.  If no items exist, the property you are filtering by does not have permission to add to these listings.');
							return;
						}
					}

					if (!this.checkURLFormat()) {
						alert('URLs must start with HTTPS://');
						return;
					}

					e.preventDefault();

					let listingParents = [];
					let saveMethod = 'saveDataAndCloseModal';
					if (this.state.createNewListingAfterThisOne) {
						listingParents = this.state.listingParents;
						saveMethod = 'saveData';
					}

					let nextUUID = uuidv4();

					let keys = [];
					let vals = [];
					for (let parentObj of listingParents) {
						if (parentObj.t1) {
							for (let listingCollectionM1Key of parentObj.listingCollectionM1Keys) {
								keys.push(`${listingCollectionM1Key}.listing.${nextUUID}`);
								vals.push(parentObj.checked);
							}
						}

						if (!parentObj.t1) {
							keys.push(`${parentObj.parentM1Key}.listing.${nextUUID}`);
							vals.push(parentObj.checked);
						}
					}

					app[saveMethod]('listings', async () => {
						console.log('SAVE');

						if (this.state.createNewListingAfterThisOne) {
							const firstCheckedIndex = this.state.listingParents.findIndex((item) => {
								return item.checked;
							});

							const scrollToTarget = this.scrollToRef.current.children[firstCheckedIndex];

							if (scrollToTarget) {
								scrollToTarget.scrollIntoView({
									behavior: 'smooth',
								});
							}

							this.setState({
								listing: {
									uuid:            nextUUID,
									parent_listings: [],
								},
							}, () => {
								app.setValue('', keys, vals, () => {
									this.makeMaps();
									this.makeFields();
								});
							});
						}
					});
				}}
				className={`button ${!app.hasPendingChanges() ? 'button-disabled no-click' : ''}`}
			>
				<CustomIcon icon='save' /> Save
			</button>
		);

		if (this.props.isEdit) {
			bottomButton =
				<button
					onClick={(e) => {
						const children = [];
						let nonExclusiveSystemPresent = false;

						this.filteredListingGroups
							.forEach((listingGroup) => {
								if (associatedComponents.includes(listingGroup.uuid)) {
									nonExclusiveSystemPresent = true;
								}
							}, []);


						for (let [ m1Key, m1Data, ] of Object.entries(listingFD)) {
							if (m1Key === `listing.${this.state.listing.uuid}`) {
								for (let [ m2Key, m2Data, ] of Object.entries(m1Data)) {
									const sections = m2Key.split('.').length;
									const entityKey = `${m1Key}.${m2Key}`;
									if (m2Key.startsWith('listing.') && sections === 2) {
										children.push(entityKey);
									}
								}
							}
						}

						let numberOfChildren = children.length;
						if (nonExclusiveSystemPresent) {
							this.setState({
								confirmModal:        true,
								confirmModalContent: (
									<>
										<p>Cannot delete listings that are assigned to systems outside this group.</p>
										<div className="confirm-modal-buttons">
											<button className="button" onClick={(e) => {
												e.preventDefault();
												this.setState({
													confirmModal:        false,
													confirmModalContent: '',
												});
											}}>Okay</button>
										</div>
									</>
								),
							});
						} else if (numberOfChildren > 0) {
							let message = `This listing currently has ${numberOfChildren} record(s) associated to it. Please delete the associated record(s) first.`;
							this.setState({
								confirmModal:        true,
								confirmModalContent: (
									<>
										<p>{message}</p>
										<div className="confirm-modal-buttons">
											<button className="button" onClick={(e) => {
												e.preventDefault();
												this.setState({
													confirmModal:        false,
													confirmModalContent: '',
												});
											}}>Okay</button>
										</div>
									</>
								),
							});
						} else {
							this.setState({
								confirmModal:        true,
								confirmModalContent:
									<>
										<p>Are you sure you want to delete this listing?</p>
										<div className="confirm-modal-buttons">
											<button className="button-outlined" onClick={(e) => {
												if (systemGroupUUID) {
													const {
														listingParents,
													} = this.state;

													let keys = [];
													let values = [];

													for (let info of listingParents) {
														let obj = {};
														if (info.t1) {
															for (let listingCollectionM1Key of info.listingCollectionM1Keys) {
																obj[`${listingCollectionM1Key}.${info.listingM1Key}`] = false;
																obj[`${listingCollectionM1Key}.listing_config.${listingConfigUUID}`] = true;
															}
														} else {
															obj[`${info.parentM1Key}.${info.listingM1Key}`] = false;
														}
														info.checked = false;
														keys.push(...Object.keys(obj));
														values.push(...Object.values(obj));
													}

													app.setValue('listings', keys, values, () => {
														app.saveDataAndCloseModal(() => {});
													});
												} else {
													app.deleteM1('listing', [ this.state.listing.uuid, ], () => {
														app.closeModal(() => {
															fetchListingDataByListingConfig(false);
														});
													});
												}
											}}>Yes</button>
											<button className="button" onClick={(e) => {
												this.setState({
													confirmModal: false,
												});
											}}>No</button>
										</div>
									</>,
							});
						}
					}}
					className="button-outlined"
				>
					<CustomIcon
						icon='trash'
					/> Delete
				</button>;
		}

		let confirmModal = '';
		if (this.state.confirmModal === true) {
			confirmModal =
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.confirmModalContent}
					</div>
				</div>;
		}

		let schedulingElm = '';

		if (this.props.listingConfigHasScheduling) {
			schedulingElm = <>
				<div className={style.sectionLabel}>Scheduling</div>
				<div className={style.scheduling}>
					<div className={style.contentToggle}>
						<input
							className='on-off on-off-label'
							type='checkbox'
							checked={this.state.scheduling}
							onChange={(e) => {
								scheduling = !scheduling;
								if (!scheduling) {
									use_dates = false;
									start_date = '';
									end_date = '';
									use_times = false;
									start_time = '';
									end_time = '';
									use_days = false;
									days = [];
								}
								this.setState({
									scheduling,
									use_dates,
									start_date,
									end_date,
									use_times,
									start_time,
									end_time,
									use_days,
									days,
								});
							}}
						/> Use Scheduling
					</div>
					<div className={`${style.contentToggle} ${scheduling ? style.available : style.notAvailable}`}>
						<input
							className='on-off on-off-label'
							type='checkbox'
							checked={use_dates}
							onChange={() => {
								use_dates = !use_dates;
								if (!use_dates) {
									start_date = '';
									end_date = '';
								}
								this.setState({
									use_dates,
									start_date,
									end_date,
								});
							}}
						/> Use Dates <button className={style.clearButton} onClick={() => {
							start_date = '';
							end_date = '';
							this.setState({
								start_date,
								end_date,
							});
						}}>Clear Dates</button>
						<div className={`${style.datePicker} ${use_dates ? style.show : style.hide}`}>
							<div className={style.startDate}>
								<div className={`label ${style.label}`}>
									<label>Start Date:</label>
								</div>
								<DatePicker
									selected={startDate}
									minDate={new Date()}
									maxDate={end_date ? Date.parse(end_date) : null}
									onChange={(e) => {
										let formattedDate = this.formatDateForJSON(e);
										start_date = formattedDate;
										this.setState({
											start_date,
										});
									}}
								/>
							</div>
							<div className={style.endDate}>
								<div className={`label ${style.label}`}>
									<label>End Date:</label>
								</div>
								<DatePicker
									selected={endDate}
									minDate={start_date ? Date.parse(start_date) : new Date()}
									onChange={(e) => {
										let formattedDate = this.formatDateForJSON(e);
										end_date = formattedDate;
										this.setState({
											end_date,
										});
									}}
								/>
							</div>
						</div>
					</div>
					<div className={`${style.contentToggle} ${scheduling ? style.available : style.notAvailable}`}>
						<input
							className='on-off on-off-label'
							type='checkbox'
							checked={use_times}
							onChange={(e) => {
								use_times = !use_times;
								if (!use_times) {
									start_time = '';
									end_time = '';
								}
								this.setState({
									use_times,
									start_time,
									end_time,
								});
							}}
						/> Use Times
						<button className={style.clearButton} onClick={() => {
							start_time = '';
							end_time = '';
							this.setState({
								start_time,
								end_time,
							});
						}}>Clear Times</button>
						<div className={`${style.timePicker} ${use_times ? style.show : style.hide}`}>
							<div className={style.startDate}>
								<div className={`label ${style.label}`}>
									<label>Start Time:</label>
								</div>
								<DatePicker
									selected={startTime}
									showTimeSelect
									showTimeSelectOnly
									timeFormat="hh:mm:aa"
									timeIntervals={15}
									dateFormat="hh:mm:aa"
									onChange={(e) => {
										start_time = this.formatTimeForJSON(e);
										this.setState({
											start_time,
										});
									}}
								/>
							</div>
							<div className={style.endDate}>
								<div className={`label ${style.label}`}>
									<label>End Time:</label>
								</div>
								<DatePicker
									selected={endTime}
									//these two are causing a crash when End Time is clicked before a Start Time exists. Commenting them out hotfixes it
									//but let's find a more complete solution too
									//minTime={startTime}
									//maxTime={maxTime}
									showTimeSelect
									showTimeSelectOnly
									timeFormat="hh:mm:aa"
									timeIntervals={15}
									dateFormat="hh:mm:aa"
									onChange={(e) => {
										end_time = this.formatTimeForJSON(e);
										this.setState({
											end_time,
										});
									}}
								/>
							</div>
						</div>
					</div>
					<div className={`${style.contentToggle} ${style.noLine} ${scheduling ? style.available : style.notAvailable}`}>
						<input
							className='on-off on-off-label'
							type='checkbox'
							checked={use_days}
							onChange={(e) => {
								use_days = !use_days;
								if (!use_days) {
									days = [];
								}
								this.setState({
									use_days,
									days,
								});
							}}
						/> Use Days
						<div className={`${style.dayPicker} ${use_days ? style.show : style.hide}`}>
							<div className="weekday-selector">
								<input type="checkbox" id="weekday-sun" className="weekday" checked={days.includes(0)} onChange={(e) => {
									this.updateDays(0);
								}} />
								<label htmlFor="weekday-sun">Sun</label>
								<input type="checkbox" id="weekday-mon" className="weekday" checked={days.includes(1)} onChange={(e) => {
									this.updateDays(1);
								}} />
								<label htmlFor="weekday-mon">Mon</label>
								<input type="checkbox" id="weekday-tue" className="weekday" checked={days.includes(2)} onChange={(e) => {
									this.updateDays(2);
								}} />
								<label htmlFor="weekday-tue">Tue</label>
								<input type="checkbox" id="weekday-wed" className="weekday" checked={days.includes(3)} onChange={(e) => {
									this.updateDays(3);
								}} />
								<label htmlFor="weekday-wed">Wed</label>
								<input type="checkbox" id="weekday-thu" className="weekday" checked={days.includes(4)} onChange={(e) => {
									this.updateDays(4);
								}} />
								<label htmlFor="weekday-thu">Thu</label>
								<input type="checkbox" id="weekday-fri" className="weekday" checked={days.includes(5)} onChange={(e) => {
									this.updateDays(5);
								}} />
								<label htmlFor="weekday-fri">Fri</label>
								<input type="checkbox" id="weekday-sat" className="weekday" checked={days.includes(6)} onChange={(e) => {
									this.updateDays(6);
								}} />
								<label htmlFor="weekday-sat">Sat</label>
							</div>
						</div>
					</div>
				</div>
			</>;
		}

		let column2Label = 'Assign Content';

		return (
			<>
				{confirmModal}
				<div className={style.listingsModal}>
					<div className={style.listing}>{this.state.name}</div>
					<div className={style.listing}>{this.state.suite}</div>
					<div className={`${style.listingsModalContainer} ${listingConfigIsIntegration && style.integrationModalContainer}`}>
						<div className={style.column1}>
							<div className={style.sectionLabel}>Details</div>
							{this.state.elms}
						</div>
						<div className={style.column2}>
							<div className={style.sectionLabel}>{column2Label}</div>
							<input className={style.contentFilter} type="text" data-selector="filter" value={this.state.searchValue} onChange={(e) => {
								return this.setState({
									searchValue: e.target.value,
								});
							}} onKeyUp={this.filterContent.bind(this)} placeholder="Search list..." />
							<div className={style.contentControls}>
								<div>Selected: <span>{this.state.selectedContentCount}</span> of <span>{this.state.assignContentData.length}</span></div>
								<div>
									<CustomIcon icon="chevron-left" />
									<CustomIcon icon="chevron-right" />
								</div>
							</div>
							<ul data-selector="content-list" className={style.contentAssign} ref={this.scrollToRef}>
								{listingParents.map((info, i) => {
									let className = style.groupName;
									let groupName = '';

									if (!this.props.activeListingConfig.root) {
										className = `${style.groupName} ${style.childGroupName}`;
										groupName = (
											<div className={style.contentGroupName}>GN: {info.parentLabels}</div>
										);
									}

									return (
										<li data-selector="content-item"
											className={className}
											key={`listings-li-${info.entityName}`}
											id={info.entityName}
											onClick={(e) => {
												this.setCheckbox(info);
											}}
										>
											<div className={style.nameContainer}>
												<CustomCheckbox
													checked={info.checked}
												/>
												<div
													className={`${style.contentBucket} ${style.contentBucketParent}`}
													data-selector="content-name"
												>
													{info.label}
												</div>
											</div>
											{groupName}
										</li>
									);
								})}

								{/* {this.getAssignContentData()} */}
							</ul>
							<div>
								<button
									className={style.multiselectButton}
									onClick={() => {
										this.setAllCheckboxes(true);
									}}>
									Select all
								</button>
								<button
									className={style.multiselectButton}
									onClick={() => {
										this.setAllCheckboxes(false);
									}}>
									Select none
								</button>
							</div>
							{schedulingElm}
						</div>
					</div>

					<div className={style.bottom}>
						{checkContainer}
						{bottomButton}
						{saveButton}
					</div>
				</div>

			</>
		);
	}
}

export default ListingsModal;