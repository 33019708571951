import React from 'react';
import axios from 'axios';

import {
	getSession,
} from 'Vulture/Session.js';

import {
	vultureENV,
	broncoURL,
	commitHash,
} from 'Vulture/ENV.js';
import { s3, AWS } from 'S3';

const pelicanBucketName = 'ts-pelican';
const pelicanResizedBucketName = 'ts-pelican-resized';
const libraryBucketName = 'ts-condor-assets';
const customCSSBucketName = 'ts-condor-custom-css';

export const prepPendingSlideshowChanges = (pendingSlideshowChanges) => {
	const keys = [];
	const undoKeys = [];
	const vals = [];
	const undoVals = [];

	Object.values(pendingSlideshowChanges).forEach((pendingSlideshowChange) => {
		const oldSlide = pendingSlideshowChange.oldSlide;
		const newSlide = pendingSlideshowChange.newSlide;

		const oldComponentUUIDs = [ ...oldSlide.component_uuids, ];
		const newComponentUUIDs = [ ...newSlide.component_uuids, ];

		if (JSON.stringify(oldComponentUUIDs) !== JSON.stringify(newComponentUUIDs)) {
			const updatedSlideComponentUUIDs = updateSlideComponentUUIDs(newSlide, oldComponentUUIDs);
			keys.push(...updatedSlideComponentUUIDs.keys);
			undoKeys.push(...updatedSlideComponentUUIDs.undoKeys);
			vals.push(...updatedSlideComponentUUIDs.vals);
			undoVals.push(...updatedSlideComponentUUIDs.undoVals);
		}
	});

	return {
		keys,
		vals,
		undoKeys,
		undoVals,
	};
};

export const addObject = (file, objKey) => {
	return new Promise((resolve, reject) => {
		s3.upload(
			{
				Body:   file,
				Bucket: pelicanBucketName,
				Key:    objKey,
				ACL:    'public-read',
			},
			{
				partSize:  5 * 1024 * 1024,
				queueSize: 5,
			},
			(err, data) => {
				if (err) {
					reject(err, data);
					return;
				}

				let functionName = 'CreateThumbnail';
				if (~file.type.indexOf('video')) {
					functionName = 'CreateVideoThumbnailClone';
				}

				let payload = {
					bucket: pelicanBucketName,
					key:    objKey,
				};

				let lambdaParams = {
					FunctionName: functionName,
					Payload:      JSON.stringify(payload),
				};

				const lambda = new AWS.Lambda();

				// Invoke lambda to create thumbnail
				lambda.invoke(lambdaParams, (lambdaErr, lambdaRes) => {
					let lambdaData;
					try {
						lambdaData = JSON.parse(lambdaRes.Payload);
					} catch (err) {
						reject({
							message: err,
						});
						return;
					}

					// NOTE: https://docs.aws.amazon.com/apigateway/latest/developerguide/handle-errors-in-lambda-integration.html
					// NOTE: - Lambda errors are returned as 200 OK responses by default
					if (lambdaData && lambdaData.errorMessage) {
						// Rollback image
						handleDeleteItems([
							{
								Key: objKey,
							},
						]);
						reject({
							message: lambdaData.errorMessage,
						}, lambdaData);
					} else {
						resolve(objKey);
					}
				});
			}
		);
	});
};

export const copyImageFromLibrary = (imgKey, objKey) => {
	return new Promise((resolve, reject) => {
		s3.copyObject(
			{
				ACL:        'public-read',
				Bucket:     pelicanBucketName,
				CopySource: `/${libraryBucketName}/${imgKey}`,
				Key:        objKey,
			},
			(err, data) => {
				if (err) {
					reject(err, data);
					return;
				}

				const payload = {
					bucket: pelicanBucketName,
					key:    objKey,
				};

				// Library only contains images not videos
				const lambdaParams = {
					FunctionName: 'CreateThumbnail',
					Payload:      JSON.stringify(payload),
				};

				const lambda = new AWS.Lambda();

				// Invoke lambda to create thumbnail
				lambda.invoke(lambdaParams, (lambdaErr, lambdaRes) => {
					let lambdaData;
					try {
						lambdaData = JSON.parse(lambdaRes.Payload);
					} catch (err) {
						reject({
							message: err,
						});
						return;
					}

					// NOTE: https://docs.aws.amazon.com/apigateway/latest/developerguide/handle-errors-in-lambda-integration.html
					// NOTE: - Lambda errors are returned as 200 OK responses by default
					if (lambdaData && lambdaData.errorMessage) {
						// Rollback image
						handleDeleteItems([
							{
								Key: objKey,
							},
						]);
						reject({
							message: lambdaData.errorMessage,
						}, lambdaData);
					} else {
						resolve(objKey);
					}
				});
			}
		);
	});
};

export const copyFileFromPelicanBucket = (fileKey, objKey, type) => {
	return new Promise((resolve, reject) => {
		s3.copyObject(
			{
				ACL:        'public-read',
				Bucket:     pelicanBucketName,
				CopySource: `/${pelicanBucketName}/${fileKey}`,
				Key:        objKey,
			},
			(err, data) => {
				if (err) {
					reject(err, data);
					return;
				}

				const payload = {
					bucket: pelicanBucketName,
					key:    objKey,
				};

				// Library only contains images not videos
				let functionName = 'CreateThumbnail';
				if (type === 'video') {
					functionName = 'CreateVideoThumbnailClone';
				}

				const lambdaParams = {
					FunctionName: functionName,
					Payload:      JSON.stringify(payload),
				};

				const lambda = new AWS.Lambda();

				// Invoke lambda to create thumbnail
				lambda.invoke(lambdaParams, (lambdaErr, lambdaRes) => {
					let lambdaData;
					try {
						lambdaData = JSON.parse(lambdaRes.Payload);
					} catch (err) {
						reject({
							message: err,
						});
						return;
					}

					// NOTE: https://docs.aws.amazon.com/apigateway/latest/developerguide/handle-errors-in-lambda-integration.html
					// NOTE: - Lambda errors are returned as 200 OK responses by default
					if (lambdaData && lambdaData.errorMessage) {
						// Rollback image
						handleDeleteItems([
							{
								Key: objKey,
							},
						]);
						reject({
							message: lambdaData.errorMessage,
						}, lambdaData);
					} else {
						resolve(objKey);
					}
				});
			}
		);
	});
};

export const structureAddJSON = (formData, objKey) => {
	// Check for video thumbnails and remove --video.jpg from JSON file.
	if (~objKey.indexOf('--video.jpg')) {
		objKey = objKey.replace('--video.jpg', '');
	}

	const newObj = {
		file:           objKey,
		kind:           formData.kind || formData.fileType,
		days:           formData.days || [],
		description:    formData.description || '',
		duration:       formData.duration || '7',
		end_date:       formData.end_date || '',
		end_time:       formData.end_time || '',
		order:          formData.order || '1',
		scheduling:     formData.scheduling || false,
		start_date:     formData.start_date || '',
		start_time:     formData.start_time || '',
		tags:           formData.tags || [],
		use_dates:      formData.use_dates || false,
		use_days:       formData.use_days || false,
		use_times:      formData.use_times || false,
		resolution:     formData.resolution || '',
		component_type: 'pelican_slide',
	};

	return newObj;
};

export const structureEditJSON = (formData) => {
	if (formData && formData.days.length === 7) {
		formData.days.length = 0;
		formData.useDays = false;
	}

	// Check for video thumbnails and remove --video.jpg from JSON file.
	if (~formData.key.indexOf('--video.jpg')) {
		formData.key = formData.key.replace('--video.jpg', '');
	}

	const newObj = {
		file:           formData.file,
		kind:           formData.kind,
		days:           formData.days,
		description:    formData.description,
		duration:       formData.duration,
		end_date:       formData.end_date,
		end_time:       formData.end_time,
		order:          formData.order,
		scheduling:     formData.scheduling,
		start_date:     formData.start_date,
		start_time:     formData.start_time,
		tags:           formData.tags,
		use_dates:      formData.use_dates,
		use_days:       formData.use_days,
		use_times:      formData.use_times,
		resolution:     formData.resolution,
		component_type: formData.component_type,
	};

	return newObj;
};

export const handleDeleteItems = (itemList) => {
	return new Promise((resolve, reject) => {
		s3.deleteObjects(
			{
				Bucket: pelicanResizedBucketName,
				Delete: {
					Objects: itemList,
				},
			},
			(err, data) => {
				if (err) {
					reject(err, data);
					return;
				}

				itemList.forEach((item) => {
					// Check for video thumbnails and remove --video.jpg to delete JSON from ts-pelican bucket.
					if (item.Key && ~item.Key.indexOf('--video.jpg')) {
						item.Key = item.Key.replace('--video.jpg', '');
					}
				});

				s3.deleteObjects(
					{
						Bucket: pelicanBucketName,
						Delete: {
							Objects: itemList,
						},
					},
					(_err, _data) => {
						if (_err) {
							reject(_err, _data);
						} else {
							resolve(_data);
						}
					}
				);
			}
		);
	});
};

export const getPhotosFromBucket = (prefix) => {
	return new Promise((resolve, reject) => {
		const photosToReturn = [];

		s3.listObjects(
			{
				Bucket:    libraryBucketName,
				Delimiter: '/',
				Prefix:    `${prefix}/`,
			},
			(err, data) => {
				if (err) {
					reject(err, data);
					return;
				}

				if (data && data.Contents) {
					if (data.Contents.length === 0) {
						resolve({
							photos: [],
						});

						return;
					}

					data.Contents.forEach((obj, i) => {
						let keyArray = obj.Key.split('/');
						if (keyArray[3] === '') {
							return;
						}
						let imgObj = {};
						imgObj.assetURL = `https://s3.amazonaws.com/${libraryBucketName}/${obj.Key}`;
						imgObj.imgURL = `https://${libraryBucketName}.s3.amazonaws.com/${(obj.Key)}`;

						let img = new Image();
						img.addEventListener('load', function () {
							imgObj.resolution = `${this.naturalWidth}px x ${this.naturalHeight}px`;
						});
						img.src = imgObj.imgURL;

						let k = `img-${i}`;
						imgObj.imgElm = (
							<img key={k} src={imgObj.imgURL} />
						);
						imgObj.checked = false;
						imgObj.index = i; //check if this is being used
						imgObj.lastModified = obj.LastModified;
						imgObj.name = keyArray[3];
						imgObj.size = `${Math.floor(obj.Size / 1024)} kb`;
						imgObj.imgKey = obj.Key;
						photosToReturn.push(imgObj);
					});

					let sortedPhotosToReturn = [];
					sortedPhotosToReturn = photosToReturn.sort((a, b) => {
						return b.lastModified - a.lastModified;
					});

					if (prefix === `${vultureENV}/ts-library/stock`) {
						resolve({
							stockPhotos: sortedPhotosToReturn,
						});

						return;
					}

					resolve({
						photos: sortedPhotosToReturn,
					});
				}
			}
		);
	});
};

export const addSlideData = (newSlide, objKey, pelicanJsonUUID) => {
	const keys = [];
	const undoKeys = [];
	const vals = [];
	const undoVals = [];
	const slide_uuid = newSlide.key;

	const slideData = structureAddJSON(newSlide, objKey);

	keys.push(`component.${slide_uuid}.setting.${pelicanJsonUUID}.json`);
	undoKeys.push(`component.${slide_uuid}.setting.${pelicanJsonUUID}`);
	vals.push(JSON.stringify(slideData));
	undoVals.push(false);

	return {
		keys,
		vals,
		undoKeys,
		undoVals,
	};
};

export const updateSlideData = (updatedSlide, pelicanJsonUUID) => {
	const keys = [];
	const undoKeys = [];
	const vals = [];
	const undoVals = [];
	const slide_uuid = updatedSlide.key;

	const slideData = structureEditJSON(updatedSlide);

	keys.push(`component.${slide_uuid}.setting.${pelicanJsonUUID}.json`);
	undoKeys.push(`component.${slide_uuid}.setting.${pelicanJsonUUID}`);
	vals.push(JSON.stringify(slideData));
	undoVals.push(false);

	return {
		keys,
		vals,
		undoKeys,
		undoVals,
	};
};

export const addSlideComponentUUIDs = (updatedSlide) => {
	const keys = [];
	const undoKeys = [];
	const vals = [];
	const undoVals = [];
	const slide_uuid = updatedSlide.key;

	updatedSlide.component_uuids.forEach((UUID) => {
		keys.push(`component.${UUID}.component.${slide_uuid}`);
		undoKeys.push(`component.${UUID}.component.${slide_uuid}`);
		vals.push(true);
		undoVals.push(false);
	});

	return {
		keys,
		vals,
		undoKeys,
		undoVals,
	};
};

export const updateSlideComponentUUIDs = (updatedSlide, oldComponentUUIDs) => {
	const keys = [];
	const undoKeys = [];
	const vals = [];
	const undoVals = [];
	const slide_uuid = updatedSlide.key;

	updatedSlide.component_uuids.forEach((UUID) => {
		if (!oldComponentUUIDs.includes(UUID)) {
			keys.push(`component.${UUID}.component.${slide_uuid}`);
			undoKeys.push(`component.${UUID}.component.${slide_uuid}`);
			vals.push(true);
			undoVals.push(false);
		}
	});

	oldComponentUUIDs.forEach((UUID) => {
		if (!updatedSlide.component_uuids.includes(UUID)) {
			keys.push(`component.${UUID}.component.${slide_uuid}`);
			undoKeys.push(`component.${UUID}.component.${slide_uuid}`);
			vals.push(false);
			undoVals.push(true);
		}
	});

	return {
		keys,
		vals,
		undoKeys,
		undoVals,
	};
};

export const copyFileFromCustomCSSBucket = (fileKey, objKey) => {
	return new Promise((resolve, reject) => {
		s3.copyObject(
			{
				ACL:        'public-read',
				Bucket:     customCSSBucketName,
				CopySource: `/${customCSSBucketName}/${fileKey}`,
				Key:        objKey,
			},
			(err, data) => {
				if (err) {
					reject(err, data);
				}
				resolve(objKey);

			}
		);
	});
};

export const fetchContentSidebar = async (accountUUID, systemGroupUUID) => {
	const session = getSession();

	let url = `${broncoURL}/account/content`;
	let body = {
		user:         session.user,
		account:      accountUUID,
		system_group: systemGroupUUID,
	};

	const headers = {
		headers: {
			'X-Auth-Session':      session.session,
			'X-Spark-API-version': commitHash,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		return {
			'error':   true,
			'message': err.message,
		};
	}
};

export const fetchPelicanData = async (accountUUID, pelicanUUIDS) => {
	const session = getSession();

	let url = `${broncoURL}/pelican`;
	let body = {
		account_uuid:       accountUUID,
		pelican_uuids:      pelicanUUIDS,
		include_unassigned: true,
	};

	const headers = {
		headers: {
			'X-Auth-Session': session.session,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		throw new Error({
			error:   true,
			message: err.message,
		});
	}
};

export const fetchListingDataByListingConfig = async (accountUUID, systemGroupUUID, listingConfigUUID) => {
	const session = getSession();

	let url = `${broncoURL}/listing_config/listing_collections`;
	let body = {
		account_uuid:        accountUUID,
		system_group_uuid:   systemGroupUUID,
		listing_config_uuid: listingConfigUUID,
	};

	const headers = {
		headers: {
			'X-Auth-Session': session.session,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};

export const deleteListings = async (listingUUIDs) => {
	const session = getSession();

	const headers = {
		headers: {
			'X-Auth-Session': session.session,
		},
	};

	let url = `${broncoURL}/delete/listings`;
	let body = {
		listing_uuids: listingUUIDs,
	};

	try {
		let res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};

export const generateConcatenatedColumns = async (listingConfigUUID, listingUUID) => {
	const session = getSession();

	const headers = {
		headers: {
			'X-Auth-Session': session.session,
		},
	};

	const url = `${broncoURL}/listing_config/generate_concatenated_columns`;
	const body = {
		listing_config_uuid: listingConfigUUID,
		listing_uuid:        listingUUID,
	};

	try {
		const res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};