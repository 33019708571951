import React from 'react';
import style from './TemplateSkeleton.module.css';
import CustomIcon from '../Common/CustomIcon/CustomIcon';

class ListingCollectionWarning extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};
	}
	render() {
		return (
			<React.Fragment>
				<div className={style.listingWarningModalContainer}>
					<p>
						You must create a Listing Group for this Listing Collection before
						you are able to choose a Listing Config.
					</p>
					<div className={style.warningModalButton}>
						<button
							className='button'
							onClick={(e) => {
								e.preventDefault();
								this.props.app.closeModal();
							}}
						>
							<CustomIcon icon='undo' />
							Go Back
						</button>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ListingCollectionWarning;
