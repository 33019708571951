import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon.js';

import {
	v4 as uuidv4,
} from 'uuid';

import style from './ListingConfig.module.css';

import {
	buildTestData,
} from 'Vulture/TestData.js';
import NewListingModal from './NewListingModal.js';

import {
	providerListToColumnMap,
} from './listingIntegrationModels.js';

import {
	fetchListingColumnTypes,
} from 'Vulture/Listing.js';

import {
	ListingConfigTier,
} from './ListingConfigTier.js';

class ListingConfig extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			listingColumnTypeOptions: [],
		};
	}

	async componentDidMount() {
		await this.fetchListingColumnTypes();
	}

	// Fetch all the possible listing_config_column entities
	async fetchListingColumnTypes() {
		const contents = await fetchListingColumnTypes();

		if (contents) {
			let listingColumnTypeOptions = [];
			if (contents?.data) {
				contents.data.forEach((listing_column_type) => {
					const {
						display_name,
						uuid,
					} = listing_column_type;

					listingColumnTypeOptions.push({
						label: display_name,
						value: uuid,
					});
				});
			}

			this.setState({
				listingColumnTypeOptions,
			});
		}
	}

	handleMakeNewListingConfig(parentM1, parentM1UUID) {
		const {
			app,
		} = this.props;

		const newListingConfigUUID = uuidv4();
		const newKeyVals = {
			// Add listing_config entity.
			[`listing_config.${newListingConfigUUID}.content_group_count`]: 1,
			[`listing_config.${newListingConfigUUID}.data_width`]:          80,
			[`listing_config.${newListingConfigUUID}.display_name`]:        '',

			// Add parent_to_listing_config relation.
			[`${parentM1}.${parentM1UUID}.listing_config.${newListingConfigUUID}`]: {},
		};

		app.createListingConfig(newListingConfigUUID, parentM1, parentM1UUID);
		app.setValue('account_settings', Object.keys(newKeyVals), Object.values(newKeyVals));
	}

	handleImportListingConfig(parentM1, parentM1UUID, dataSetUUID) {
		const {
			app,
			dataSets,
		} = this.props;

		const tiers = JSON.parse(dataSets[dataSetUUID].json).tiers;

		let parent = parentM1;
		let parentUUID = parentM1UUID;

		const newKeyVals = {};
		const newListingConfigUUIDs = [];

		for (let i = 0; i < tiers.length; i++) {
			const newListingConfigUUID = uuidv4();
			newKeyVals[`listing_config.${newListingConfigUUID}.content_group_count`] = 1;
			newKeyVals[`listing_config.${newListingConfigUUID}.data_width`] = 80;
			newKeyVals[`listing_config.${newListingConfigUUID}.display_name`] = tiers[i].listName;

			newKeyVals[`${parent}.${parentUUID}.listing_config.${newListingConfigUUID}`] = {};

			const data = tiers[i].data;

			for (let j = 0; j < data.length; j++) {
				const newListingColumnUUID = uuidv4();

				newKeyVals[`listing_column.${newListingColumnUUID}.display_name`] = data[j].label;
				newKeyVals[`listing_column.${newListingColumnUUID}.listing_column_type.${data[j].type.value}`] = {};

				newKeyVals[`listing_config.${newListingConfigUUID}.listing_column.${newListingColumnUUID}`] = true;
				newKeyVals[`listing_config.${newListingConfigUUID}.listing_column.${newListingColumnUUID}.order`] = j + 1;
				newKeyVals[`listing_config.${newListingConfigUUID}.listing_column.${newListingColumnUUID}.width`] = Math.floor(100 / data.length);
			}


			parent = 'listing_config';
			parentUUID = newListingConfigUUID;
			newListingConfigUUIDs.push(newListingConfigUUID);
		}

		app.createListingConfigs(newListingConfigUUIDs, parentM1, parentM1UUID);
		app.setValue('account_settings', Object.keys(newKeyVals), Object.values(newKeyVals));
	}

	handleIntegrateListingConfig(parentM1, parentM1UUID, integrationProvider, selectedIntegrationList) {
		const {
			app,
			settingMap,
		} = this.props;

		const {
			listingColumnTypeOptions,
		} = this.state;

		const providerUUID = settingMap['listing_integration_provider'].uuid;
		const providerListUUID = settingMap['listing_integration_list'].uuid;

		const newKeyVals = {};
		const newListingConfigUUIDs = [];

		const newListingConfigUUID = uuidv4();
		newKeyVals[`listing_config.${newListingConfigUUID}.content_group_count`] = 1;
		newKeyVals[`listing_config.${newListingConfigUUID}.data_width`] = 80;
		newKeyVals[`listing_config.${newListingConfigUUID}.display_name`] = selectedIntegrationList;

		newKeyVals[`listing_config.${newListingConfigUUID}.setting.${providerUUID}`] = true;
		newKeyVals[`listing_config.${newListingConfigUUID}.setting.${providerUUID}.string`] = integrationProvider.toLowerCase();
		newKeyVals[`listing_config.${newListingConfigUUID}.setting.${providerListUUID}`] = true;
		newKeyVals[`listing_config.${newListingConfigUUID}.setting.${providerListUUID}.string`] = selectedIntegrationList.toLowerCase();

		const columnsToBuild = providerListToColumnMap[integrationProvider][selectedIntegrationList];

		Object.entries(columnsToBuild).forEach(([ column, columnType, ], i, array) => {
			const newListingColumnUUID = uuidv4();
			const columnTypeUUID = listingColumnTypeOptions.find((listingColumnTypeOption) => {
				return listingColumnTypeOption.label === columnType;
			}).value;

			newKeyVals[`listing_column.${newListingColumnUUID}.display_name`] = column;
			newKeyVals[`listing_column.${newListingColumnUUID}.listing_column_type.${columnTypeUUID}`] = true;

			newKeyVals[`listing_config.${newListingConfigUUID}.listing_column.${newListingColumnUUID}`] = true;
			newKeyVals[`listing_config.${newListingConfigUUID}.listing_column.${newListingColumnUUID}.order`] = i + 1;
			newKeyVals[`listing_config.${newListingConfigUUID}.listing_column.${newListingColumnUUID}.width`] = Math.floor(100 / array.length);
		});

		newKeyVals[`${parentM1}.${parentM1UUID}.listing_config.${newListingConfigUUID}`] = {};

		newListingConfigUUIDs.push(newListingConfigUUID);

		app.createListingConfigs(newListingConfigUUIDs, parentM1, parentM1UUID);
		app.setValue('account_settings', Object.keys(newKeyVals), Object.values(newKeyVals));
	}

	openNewListingModal(parentM1, parentM1UUID) {
		const {
			app,
			dataSets,
		} = this.props;

		app.openModal({
			modalSize:  4,
			showModal:  true,
			modalProps: {
				title: 'New Listing',
				jsx:   (
					<NewListingModal
						app={this.props.app}
						dataSets={dataSets}
						newListing={() => {
							this.handleMakeNewListingConfig(parentM1, parentM1UUID);
						}}
						importListing={(selectedDataSet) => {
							this.handleImportListingConfig(parentM1, parentM1UUID, selectedDataSet);
						}}
						integrateListing={(selectedIntegration, selectedIntegrationList) => {
							this.handleIntegrateListingConfig(parentM1, parentM1UUID, selectedIntegration, selectedIntegrationList);
						}}
						closeModal={() => {
							app.setState({
								modal:      false,
								modalProps: {},
							});
						}}
					/>
				),
			},
		});
	}

	findChildEntitiesForRender(m1, parentUUID) {
		const {
			listingConfigMap,
		} = this.props;

		let childEntities = [];
		if (listingConfigMap[parentUUID]) {
			for (let childUUID of listingConfigMap[parentUUID].childTiers) {
				childEntities.push({
					uuid: childUUID,
					data: listingConfigMap[childUUID],
				});
			}
		}

		return childEntities;
	}

	renderListingConfigs() {
		const {
			app,
			accountUUID,
			fd,
			listingConfigMap,
			settingMap,
		} = this.props;

		// Find the tier one listing_config entities.
		let listingConfigTierOneEntities = [];
		for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key.startsWith('account.')) {
				for (let m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('listing_config.')) {
						let m2UUID = m2Key.split('.')[1];
						listingConfigTierOneEntities.push(m2UUID);
					}
				}
			}
		}

		let listNumber = 0;

		return (
			<div>
				{listingConfigTierOneEntities
					.map((childListingConfigUUID) => {
						listNumber++;

						return (
							<div
								className={style.listingContainer}
								key={childListingConfigUUID}
							>
								<ListingConfigTier
									app={app}
									fd={fd}
									accountUUID={accountUUID}
									parentM1='account'
									parentM1UUID={accountUUID}
									listingConfigMap={listingConfigMap}
									listingConfigUUID={childListingConfigUUID}
									settingMap={settingMap}
									listNumber={listNumber}
									listingColumnTypeOptions={this.state.listingColumnTypeOptions}
									pendingChanges={this.props.pendingChanges}
									messageLabels={this.props.messageLabels}

									openNewListingModal={this.openNewListingModal.bind(this)}
									findChildEntitiesForRender={this.findChildEntitiesForRender.bind(this)}
									handleMakeNewListingConfig={this.handleMakeNewListingConfig.bind(this)}
								/>
							</div>
						);
					})}
			</div>
		);
	}

	render() {
		const {
			accountUUID,
		} = this.props;

		return (
			<React.Fragment>
				<h2 className={style.headerText}>Dashboard List Management</h2>
				<button
					className={`button ${style.button}`}
					onClick={(e) => {
						e.preventDefault();
						this.openNewListingModal('account', accountUUID);
					}}
					style={{
						width: 'fit-content',
					}}
					data-testing-info={buildTestData('button--newList')}

				>
					<CustomIcon icon='plus' /> New List
				</button>

				{this.renderListingConfigs()}
			</React.Fragment>
		);
	}
}

export default ListingConfig;