import React from 'react';

import Tracker from '@openreplay/tracker';

//import * as Sentry from "@sentry/react";
import * as Sentry from '@sentry/browser';
import {
	Integrations,
} from '@sentry/tracing';

import kali from 'kali';
import axios from 'axios';
import md5 from 'md5';
import zlib from 'zlib';

import HeaderView from 'Crow/Form/Field/Custom/Vulture/Header.js';
import AccountsView from 'Crow/Form/Field/Custom/Vulture/Accounts.js';
import SystemsView from 'Crow/Form/Field/Custom/Vulture/Systems.js';
import ContentView from 'Crow/Form/Field/Custom/Vulture/Content.js';
import AccountSettingsView from 'Crow/Form/Field/Custom/Vulture/AccountSettings.js';
import LibraryView from 'Crow/Form/Field/Custom/Vulture/Library.js';
import ModalView from 'Crow/Form/Field/Custom/Vulture/CustomModal.js';
import SupportView from 'Crow/Form/Field/Custom/Vulture/Support.js';
import AccountUsersView from 'Crow/Form/Field/Custom/Vulture/AccountUsers';
import UsersView from 'Crow/Form/Field/Custom/Vulture/Users';

import CrowFieldCustomVultureElementsTextSmall from 'Crow/Form/Field/Custom/Vulture/Elements/TextSmall.js';
import CrowFieldCustomVultureElementsTextLarge from 'Crow/Form/Field/Custom/Vulture/Elements/TextLarge.js';
import CrowFieldCustomCommonDate from 'Crow/Form/Field/Custom/Common/Date/Date.field.js';
import CrowFieldCustomCommonDateTimezone from 'Crow/Form/Field/Custom/Common/Date/Timezone.field.js';
import CrowFieldCustomCommonDateTime from 'Crow/Form/Field/Custom/Common/Date/Time.field.js';
import CrowFieldCustomVultureElementsPhoneMessage from 'Crow/Form/Field/Custom/Vulture/Elements/PhoneMessage.js';

import SplitScreenView from 'Vulture/View/SplitScreen.js';
import LoginView from 'Vulture/View/Login.js';
import LoadingView from 'Vulture/View/Loading.js';
import SidebarView from 'Vulture/View/Sidebar.js';
import PendingChangesModalView from 'Vulture/View/PendingChangesModal.js';
import ScreenshotView from 'Crow/Form/Field/Custom/Vulture/Screenshot';

import SystemProgrammingView from 'Crow/Form/Field/Custom/Vulture/SystemProgramming.js';

import {
	parseEntityName,
	mergeFD,
	makeFDFromDD,
} from 'Crow/Common/Crow.js';

import {
	hasSession,
	getSession,
	delSession,
} from 'Vulture/Session.js';

import {
	fetchAccount,
	fetchAccountFD,
	// fetchAccounts,
	fetchAccountSystem,
	fetchAccountSystems,
	fetchAccountTemplates,
} from 'Vulture/Account.js';

import API from 'Vulture/lib/API.js';

import {
	saveData,
	deleteM1,
} from 'Vulture/Data.js';

import {
	broncoURL,
	vultureENV,
} from 'Vulture/ENV.js';

import {
	prepPendingSlideshowChanges,
} from 'Vulture/Content';

import {
	registerListingIntegration,
} from 'Vulture/Listing';

import {
	applySystemTemplateAssignment,
} from 'Vulture/SystemProgramming';

import {
	updateViewData,
} from 'Vulture/Slideshow';

import './App.css';

import CrowFieldCustomVultureElementsFloat from 'Crow/Form/Field/Custom/Vulture/Elements/Float.js';
import CrowFieldCustomVultureElementsNumber from 'Crow/Form/Field/Custom/Vulture/Elements/Number.js';
import CrowFieldCustomVultureElementsIcon from 'Crow/Form/Field/Custom/Vulture/Elements/Icon.js';
import CrowFieldCustomVultureDropDown from 'Crow/Form/Field/Custom/Vulture/Elements/DropDown.js';
import CrowFieldCustomVultureResolutionDropDown from 'Crow/Form/Field/Custom/Vulture/Elements/ResolutionDropDown.js';
import CrowFieldCustomVultureAdvancedCheckbox from 'Crow/Form/Field/Custom/Vulture/Elements/AdvancedCheckbox.js';
import CrowFieldCustomVultureThemeEditor from 'Crow/Form/Field/Custom/Vulture/ThemeEditor.js';
import CrowFieldCustomVultureElementsPhoneNumber from 'Crow/Form/Field/Custom/Vulture/Elements/PhoneNumber.js';
import CrowFieldCustomVultureElementsEmail from 'Crow/Form/Field/Custom/Vulture/Elements/Email.js';
import CrowFieldCustomVultureElementsSlack from 'Crow/Form/Field/Custom/Vulture/Elements/Slack.js';
import QrCodeComponent from 'Crow/Form/Field/Custom/Vulture/QrCode.js';

// // Google Tag Manager
import TagManager from 'react-gtm-module';
import {
	getFDValue,
} from 'Vulture/Helpers';
import ImageUploaderComponent from 'Crow/Form/Field/Custom/Vulture/Elements/Uploader.js';
import SystemAssignmentView from 'Crow/Form/Field/Custom/Vulture/SystemAssignment';
import EntertainmentEditor from 'Crow/Form/Field/Custom/Vulture/EntertainmentEditor';
import DataPresetsView from 'Crow/Form/Field/Custom/Vulture/DataPresetsView';
import MFAView from 'Vulture/View/MFA';
import {
	s3,
} from 'S3';
import {
	fetchBuildings,
} from 'Vulture/Buildings';

// OpenReplay: don't add code above this!
const tracker = new Tracker({
	projectKey: '7319513123710080',
});

tracker.start({
	metadata: {
		env: vultureENV,
	},
});

// Google Tag Manager
let tagManagerArgs = {
	gtmId: 'GTM-P6VSDCR',
};
TagManager.initialize(tagManagerArgs);

if (vultureENV !== 'dev') {
	// Sentry
	Sentry.init({
		dsn:          'https://b16470ab676746309ef9e65a1db0a304@o577885.ingest.sentry.io/5733659',
		integrations: [
			new Integrations.BrowserTracing(),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

console.log('COMMITHASH=' + window._env_.COMMITHASH);

const fetchComponent = async function (componentUUID) {
	const session = getSession();

	let url = `${broncoURL}/content/component`;
	let headers = {
		'X-Auth-Session': session.session,
	};
	let body = {
		component: componentUUID,
	};

	try {
		let res = await axios.post(url, body, {
			headers,
		});
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		debugger;
		this.setState({
			confirmModal:        true,
			confirmModalContent: <>
				<p>A network error has occured.</p>
				<div className="confirm-modal-buttons">
					<button className="button-outlined" onClick={(e) => {
						this.setState({
							confirmModal: false,
						});
						this.fetchComponent(componentUUID);
					}}>Retry</button>
					<button className="button" onClick={(e) => {
						this.setState({
							confirmModal: false,
						});
					}}>Return</button>
				</div>
			</>,
		});
	}
};

// TODO: fully remove this from SystemProgramming
const initializeWindowForm = () => {
	window.form = {
		orgVals: {},
		newVals: {},
		rawKeys: [],

		props: {
			showLoader: (b) => {
				console.warn('REMOVE:');
				console.trace(' - form.props.showLoader:', b);
			},
		},

		hasNewVals: () => {
			return Boolean(Object.keys(window.form.newVals).length > 0);
		},

		_rawData: () => {
			let vals = Object.assign({}, window.form.orgVals);
			for (let [ m1Key, m1Data, ] of Object.entries(window.form.newVals)) {
				if (!vals[m1Key]) {
					vals[m1Key] = m1Data;
					continue;
				}

				vals[m1Key] = Object.assign({}, vals[m1Key], m1Data);
			}

			return vals;
		},

		_makeRawKeys(rawData = {}) {
			let rawKeys = [];
			for (let [ m1Key, m1Data, ] of Object.entries(rawData)) {
				rawKeys.push(m1Key);
				for (let [ m2Key, m2Data, ] of Object.entries(m1Data)) {
					if (!~m2Key.indexOf('.')) {
						// col
						rawKeys.push(`${m1Key}.${m2Key}`);
					} else {
						// rel
						rawKeys.push(`${m1Key}.${m2Key}`);
						for (let attr of Object.keys(m2Data)) {
							// attr
							rawKeys.push(`${m1Key}.${m2Key}.${attr}`);
						}
					}
				}
			}

			return rawKeys;
		},

		makeRawKeys: () => {
			if (window.form.rawKeys.length === 0) {
				window.form.rawKeys = window.form._makeRawKeys(window.form._rawData());
			}

			return window.form.rawKeys;
		},

		_getMatchingKeys(rawKeys = [], scopes = []) {
			let matchingKeys = [];
			scopes.forEach((scope) => {
				if (!scope.startsWith('*')) {
					// scope = `^${scope}$`;
				}

				let scopeStr = scope
					// *.*.* > *\\.*\\.*
					.replace(/\./g, '\\.')
					// *\\.*\\.* > .*\\..*\\..*
					.replace(/\*/g, '[A-Za-z0-9-_]+');

				let scopeRE = new RegExp(`^${scopeStr}$`);
				// console.log(scope, scopeRE);

				rawKeys.forEach((key) => {
					if (scopeRE.test(key)) {
						matchingKeys.push(key);
					}
				});
			});

			return matchingKeys;
		},

		getMatchingKeys: (scopes = []) => {
			if (!Array.isArray(scopes)) {
				scopes = [ scopes, ];
			}

			// TODO: don't make rawKeys each time
			return window.form._getMatchingKeys(window.form.makeRawKeys(), scopes);
		},

		_getMatchingVals(matchingKeys = []) {
			let rawData = window.form._rawData();

			let matchingVals = {};
			matchingKeys.forEach((entityKey) => {
				let s = entityKey.split('.');
				let l = s.length;

				let [ m1, m1ID, col, ] = s;
				let m1Key = `${m1}.${m1ID}`;

				if (l === 'm1.id.col'.split('.').length) {
					matchingVals[`${m1Key}.${col}`] = rawData[m1Key][col];
				}

				if (l >= 'm1.id.m2.id'.split('.').length) {
					let [ , , m2, m2ID, attr, ] = s;
					let m2Key = `${m2}.${m2ID}`;

					matchingVals[`${m1Key}.${m2Key}`] = true;
					if (attr) {
						if (!rawData[m1Key] || !rawData[m1Key][m2Key] || typeof rawData[m1Key][m2Key][attr] === 'undefined') {
							// debugger;
						}

						matchingVals[`${m1Key}.${m2Key}.${attr}`] = rawData[m1Key][m2Key][attr];
					}
				}
			});

			return matchingVals;
		},

		getValues: (scopes = []) => {
			let values = {};
			for (let [ k, v, ] of Object.entries(window.form.getMatchingVals(scopes))) {
				values[k] = {
					value: v,
				};
			}

			return values;
		},

		getMatchingVals: (scopes = []) => {
			if (!Array.isArray(scopes)) {
				scopes = [ scopes, ];
			}

			return window.form._getMatchingVals(window.form.getMatchingKeys(scopes));
		},

		getValue: (entityName) => {
			if (~entityName.indexOf('*')) {
				return window.form.getValues([ entityName, ]);
			}

			let vals = Object.assign({}, window.form.orgVals, window.form.newVals);
			if (entityName.endsWith('._bundle')) {
				// debugger;
			}

			let l = entityName.split('.').length;
			let [ m1, m1UUID, m2, m2UUID, attr, ] = entityName.split('.');
			let m1Key = `${m1}.${m1UUID}`;
			let m2Key = `${m2}.${m2UUID}`;

			if (!vals[m1Key]) {
				return null;
			}

			try {
				if (l === 3) {
					let col = m2;
					return vals[m1Key][col];
				}
				if (l === 4) {
					if (!vals[m1Key][m2Key]) {
						return;
					}
					return vals[m1Key][m2Key];
				}
				if (l === 5) {
					if (!vals[m1Key][m2Key]) {
						return;
					}
					return vals[m1Key][m2Key][attr];
				}
			} catch (err) {
				console.error(err);
				// debugger;
			}
		},

		setValue: (entityNames = [], values = []) => {
			if (typeof entityNames === 'string') {
				entityNames = [ entityNames, ];
				values = [ values, ];
			}

			if (entityNames.length !== values.length) {
				return;
			}

			for (let i = 0; i < entityNames.length; i++) {
				let entityName = entityNames[i];
				let value = values[i];

				let l = entityName.split('.').length;
				let [ m1, m1UUID, m2, m2UUID, attr, ] = entityName.split('.');
				let m1Key = `${m1}.${m1UUID}`;
				let m2Key = `${m2}.${m2UUID}`;

				if (!window.form.newVals[m1Key]) {
					window.form.newVals[m1Key] = {};
				}
				if (l > 3 && !window.form.newVals[m1Key][m2Key]) {
					window.form.newVals[m1Key][m2Key] = {};
				}

				if (l === 3) {
					let col = m2;
					window.form.newVals[m1Key][col] = value;
				}
				if (l === 4 && value !== true) {
					window.form.newVals[m1Key][m2Key] = value;
				}
				if (l === 5) {
					window.form.newVals[m1Key][m2Key][attr] = value;
				}
			}
			window.form.rawKeys = window.form._makeRawKeys(window.form._rawData());
		},

		parseEntityName: (entityName) => {
			return parseEntityName(entityName);
		},

		req: (action, method, uri, opts = {}, cb = () => { }, ...params) => {
			console.log('ACTION:', action);
			console.log('URI:', uri, method);

			// TODO: move Admin Forms V2 to Cookie.set('sesion_key')
			let sessionKey = getSession().session;
			if (!opts.headers) {
				opts.headers = {};
			}
			opts.headers['X-Auth-Session'] = sessionKey;

			new kali(opts)[method](uri, {
				success: (_kali, res, crowRes) => {
					if (action === 'POST_FORM_DATA') {
						console.log(crowRes);

						let values = window.form._rawData();
						Object.values(crowRes.data).forEach((m1DataGroup) => {
							Object.entries(m1DataGroup).forEach(([ m1Key, m1Data, ]) => {
								values[m1Key] = Object.assign({}, values[m1Key], m1Data);
							});
						});

						Object.entries(values).forEach(([ m1Key, m1Data, ]) => {
							Object.entries(m1Data).forEach(([ m2Key, m2Data, ]) => {
								if (m1Key.split('.')[0] === 'account') {
									if (m2Key.split('.')[0] === 'template' && m2Data === false) {
										delete values[m1Key][m2Key];
										delete values[m2Key];
									}
								}

								if (m1Key.split('.')[0] === 'system') {
									if (m2Key.split('.')[0] === 'component' && m2Data === false) {
										delete values[m1Key][m2Key];
									}
									if (m2Key.split('.')[0] === 'template' && m2Data === false) {
										delete values[m1Key][m2Key];
									}
								}
							});
						});

						// TODO: Update matchingKeys cache.

						window.form.orgVals = values;
						window.form.newVals = {};
						window.form.rawKeys = window.form._makeRawKeys(window.form._rawData());

						if (typeof cb === 'function') {
							return cb(...params);
						}
					}
				},

				failure: (_kali, res, err) => {
					console.log(_kali);
					console.log(err);

					if (res.status === 503) {
						// TODO: handle 503 maintenance mode
					}
				},
			}, true);
		},

		postFormData: (e, cb = false, ...params) => {
			if (Object.keys(window.form.newVals || {}).length === 0) {
				return;
			}

			// TODO: move to bronco when _cache is gone
			for (let m1Key of Object.keys(window.form.newVals)) {
				if (m1Key.startsWith('template_skeleton.')) {
					let data = zlib.gzipSync(JSON.stringify(window.form.newVals[m1Key].data)).toString('base64');
					let hash = md5(data);

					window.form.newVals[m1Key].data = data;
					window.form.newVals[m1Key].hash = hash;
				}
			}

			// m1.id.col: val
			// m1.id.m2.id: true/false
			// m1.id.m2.id.attr: val
			let dd = {};
			Object.entries(window.form.newVals).forEach(([ m1Key, m1Data, ]) => {
				// cols
				Object.entries(m1Data).forEach(([ col, val, ]) => {
					if (!~col.indexOf('.')) {
						let k = `${m1Key}.${col}`;
						dd[k] = val;
					}
				});
				// rels/attr
				Object.entries(m1Data).forEach(([ m2Key, m2Data, ]) => {
					if (~m2Key.indexOf('.')) {
						let k = `${m1Key}.${m2Key}`;
						dd[k] = Boolean(window.form.newVals[m1Key][m2Key]);
						Object.entries(m2Data).forEach(([ attr, val, ]) => {
							let k = `${m1Key}.${m2Key}.${attr}`;
							dd[k] = val;
						});
					}
				});
			});

			let body = {
				data_format: 'DD',
				data:        dd,
			};
			console.log(body);

			let uri = `${broncoURL}/w/edit`;
			window.form.req('POST_FORM_DATA', 'post', uri, {
				body,
			}, cb, ...params);
		},
	};
};

initializeWindowForm();

const defaultState = {
	// /account/*
	accountList:      false,
	accountName:      '',
	accountSystems:   {},
	accountSystem:    {},
	accountTemplates: {},
	accountUUID:      '',
	systemGroupUUID:  '',

	// TODO: avoid this?
	accountFD:        {},
	listingConfigMap: {},
	slideshow:        {},
	settingMap:       {},

	// /content/*
	componentUUID:       null,
	componentMap:        {},
	componentTypeMap:    {},
	componentSettingMap: {},
	settingUUIDMap:      {},
	entertainment: 	     false,

	//modal
	confirmModal:        false,
	confirmModalContent: {},
	// app.*
	loading:             false,
	pendingRequests:     0,
	// used to override app.load control of loading
	forceLoading:        false,
	modal:               false,
	modalProps:          {},
	libraryModal:        false,
	libraryModalProps:   {},
	photos:              [],
	stockPhotos:         [],

	// ?
	pendingChanges:          {},
	pendingSlideshowChanges: {},

	accountFDWithPendingChanges: {},

	// ?
	componentTypes: [],
	systemData:     {},
	systemUUID:     '',
	bucketName:     'ts-condor-assets',

	// for displaying system expirations
	nearestSystemExpiration: undefined,
	expirationDate:          undefined,

	buildingsDataArray: [],
};

// TODO: function DashboardMaintenanceView(props) {}
class DashboardView extends React.Component {
	constructor(props) {
		super(props);

		document.title = 'TouchSource Cloud Customer Dashboard';

		this.state = {
			...JSON.parse(JSON.stringify(defaultState)),
		};

		this.setSystemGroupUUID = this.setSystemGroupUUID.bind(this);
	}

	clearState() {
		this.setState(JSON.parse(JSON.stringify(defaultState)));
	}

	componentDidMount() {
		const {
			router,
		} = this.props;

		const {
			params,
		} = router;

		if (params.mode === 'calendar' || params.mode === 'connect') {
			let provider = window.location.pathname.split('/').pop();

			let url = new window.URL(window.location.href);
			let code = url.searchParams.get('code');

			sessionStorage.content_auth_component = params.account;
			sessionStorage.content_auth_provider = provider;
			sessionStorage.content_auth_code = code;

			this.redirect({
				// to:     `/content/${sessionStorage.content_account_uuid}?provider=${provider}&code=${code}`,
				to:     `/content/${sessionStorage.content_account_uuid}`,
				reload: true,
				search: false,
			});

			return;
		}

		if (!hasSession()) {
			let pn = window.location.pathname;

			if (pn !== '/' && pn !== '/login' && !pn.startsWith('/password/reset') && !pn.startsWith('/register')) {
				this.redirect({
					to:       '/',
					redirect: false,
				});

				return;
			}

			return;
		}

		if (hasSession()) {
			this.apiFetchUserAccounts();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			router,
		} = this.props;

		const {
			params,
		} = router;


		if (prevProps.router.params.mode !== params.mode) {
			console.log('MODE:', params.mode || 'NO_MODE');

			if (prevProps.router.params.mode === 'system_programming') {
				window.location.reload();
				return;
			}

			if (!params.mode || params.mode === 'undefined') {
				if (params.account) {
					this.redirect({
						to:     `/systems/${params.account}`,
						reload: false,
					});

					return;
				}
			}

			if (params.mode === 'accounts' && !params.account) {
				this.setState({
					accountUUID: '',
					accountName: '',
				});
			}

			// logout
			if (!params.mode && !params.account) {
				this.clearState();
			}
		}

		if (prevProps.router.params.account !== params.account) {
			console.log('ACCOUNT:', params.account || 'NO_ACCOUNT');

			if (params.mode === 'system_programming' || params.mode === 'account_settings') {
				this.setState({
					accountFD: {},
				});

				return;
			}
		}

		if (prevState.accountFD !== this.state.accountFD || prevState.pendingChanges !== this.state.pendingChanges) {
			this.setState({
				accountFDWithPendingChanges: mergeFD(this.state.accountFD, makeFDFromDD(this.state.pendingChanges)),
			});
		}
	}

	logout() {
		console.log('Attempting to log out');
		delSession();
		this.setState(JSON.parse(JSON.stringify(defaultState)));

		initializeWindowForm();

		this.redirect({
			to: '/',
		});
	}

	redirect(args) {
		const {
			router,
		} = this.props;

		let search = true;
		if (args.search === false) {
			search = false;
		}

		if (search) {
			args.to += window.location.search;
		}

		router.redirect(args);
	}

	params() {
		const {
			router,
		} = this.props;

		return router.params;
	}

	load(cb = async () => { }) {
		this.setState((prevState) => {
			return {
				loading:         true,
				pendingRequests: prevState.pendingRequests + 1,
			};
		}, async () => {
			try {
				await cb();
			} catch (err) {
				console.error(err);
				this.setState({
					confirmModal:        true,
					confirmModalContent: <>
						<p>A network error has occured.</p>
						<div className="confirm-modal-buttons">
							<button className="button" onClick={(e) => {
								this.setState({
									confirmModal: false,
								});
							}}>Return</button>
						</div>
					</>,
				});
				return this.setState((prevState) => {
					return {
						lastErr:         err.message,
						loading:         false,
						pendingRequests: prevState.pendingRequests - 1,
					};
				});
			}

			return this.setState((prevState) => {
				return {
					loading:         false,
					pendingRequests: prevState.pendingRequests - 1,
				};
			});
		});
	}

	saveDataAndCloseModal(name, cb = () => { }) {
		this.saveData(name, () => {
			this.closeModal(cb);
		});

		this.saveSlideshowData(() => {
			this.setState({
				modal:      false,
				modalProps: {},
			}, () => {
				cb();
			});
		});
	}

	saveData(name, cb = () => { }) {
		const {
			accountFD,
			pendingChanges,
			componentUUID,
			entertainment,
		} = this.state;

		if (Object.keys(pendingChanges).length === 0) {
			// debugger;
			if (typeof cb === 'function') {
				return cb();
			}
			return;
		}

		let body = {
			data_format: 'DD',
			data:        pendingChanges,
		};
		this.load(async () => {
			let saveRes = await saveData(body);
			console.log('SAVE_RES:', saveRes);
			if (saveRes === undefined) {
				this.setNetworkErrorModal();
			}
			let states = [ 'accountFD', ];

			let updates = {
				accountFD,
				pendingChanges: {},
			};
			for (let [ m1Key, saveResFD, ] of Object.entries(saveRes.data)) {
				states.forEach((k) => {
					updates[k] = mergeFD(updates[k], saveResFD);
				});
			}

			for (let [ entityKey, value, ] of Object.entries(pendingChanges)) {
				if (value === null) {
					states.forEach((k) => {
						updates[k] = mergeFD(updates[k], makeFDFromDD({
							[entityKey]: value,
						}));
					});
				}
			}

			for (let [ entityKey, value, ] of Object.entries(pendingChanges)) {
				let s = entityKey.split('.');

				if (s.length === 4 && value === false) {
					let [ m1, m1UUID, m2, m2UUID, ] = s;

					let m1Key = `${m1}.${m1UUID}`;
					let m2Key = `${m2}.${m2UUID}`;

					states.forEach((k) => {
						if (updates[k][m1Key]) {
							if (updates[k][m1Key][m2Key]) {
								Reflect.deleteProperty(updates[k][m1Key], m2Key);
							}
						}
					});
				}
			}

			this.setState(updates, () => {
				if (componentUUID && !entertainment) {
					this.fetchComponent(componentUUID);
				}

				if (typeof cb === 'function') {
					return cb(saveRes);
				}
			});
		});
	}

	deleteM1(m1, m1UUIDs = [], cb = () => { }, onlyCB = false) {
		const {
			accountFD,
		} = this.state;

		this.load(async () => {
			let updates = {
				accountFD,
				pendingChanges: {},
			};

			for (let m1UUID of m1UUIDs) {
				let childM2Key = `${m1}.${m1UUID}`;
				let delRes = await deleteM1(m1, m1UUID);
				if (!delRes && m1UUIDs.length > 1) {
					continue;
				}

				console.log('DELETE_RES:', delRes);

				if (typeof cb === 'function' && onlyCB) {
					return cb(delRes);
				}

				if (!delRes.deleted) {
					if (typeof cb === 'function') {
						return cb(delRes);
					}

					continue;
				}

				if (delRes.deleted) {
					let parentM1Keys = [];
					for (let [ m1Key, m1Data, ] of Object.entries(accountFD)) {
						for (let m2Key of Object.keys(m1Data)) {
							if (m2Key === childM2Key) {
								parentM1Keys.push(m1Key);
							}
						}
					}

					let states = [ 'accountFD', ];
					for (let parentM1Key of parentM1Keys) {
						states.forEach((k) => {
							if (!updates[k][parentM1Key]) {
								return;
							}

							Reflect.deleteProperty(updates[k][parentM1Key], childM2Key);
						});
					}

					states.forEach((k) => {
						// remove m1 and all m2 relations
						Reflect.deleteProperty(updates[k], childM2Key);
					});
				}
			}

			this.setState(updates, () => {
				if (typeof cb === 'function') {
					return cb();
				}
			});
		});
	}

	saveSlideshowData(cb = () => { }) {
		const {
			componentUUID,
			pendingSlideshowChanges,
		} = this.state;
		if (Object.keys(pendingSlideshowChanges).length === 0) {
			return;
		}

		const broncoPendingChanges = prepPendingSlideshowChanges(pendingSlideshowChanges);

		if (broncoPendingChanges) {
			this.setValue('slideshow', broncoPendingChanges.keys, broncoPendingChanges.vals, () => {
				this.saveData('slideshow', () => {
					this.setState({
						pendingSlideshowChanges: {},
					}, () => {
						if (componentUUID) {
							this.fetchComponent(componentUUID);
						}

						if (typeof cb === 'function') {
							return cb();
						}
					});
				});
			});
		} else {
			this.setState({
				pendingSlideshowChanges: {},
			}, () => {
				if (componentUUID) {
					this.fetchComponent(componentUUID);
				}

				if (typeof cb === 'function') {
					return cb();
				}
			});
		}
	}

	handleOutdatedCommithashValidation(res) {
		if (res === 'outdated commithash') {
			if (window.location.search.includes('outdated_commithash=true')) {
				this.setCommitHashErrorModal();
				// this.logout();
				// window.location.replace('/');
			} else {
				window.location.search = '?outdated_commithash=true';
			}
			return true;
		}
		return false;
	}

	async apiFetchUserAccounts(cb = () => {}) {
		this.setState({
			loading: true,
		});

		let userUUID = getSession().user;

		try {
			let accountsResponse = await API.findMapRecords('user', userUUID, 'account');

			let accountPromises = accountsResponse.map((account) => {
				return API.findRecord('account', account.account_uuid);
			});
			let accountResponses = await Promise.all(accountPromises);

			let accountList = [];
			for (let account of accountResponses) {
				accountList.push({
					[account.uuid]: account.display_name,
				});
			}

			accountList.sort((a, b) => {
				let aName = Object.values(a)[0];
				let bName = Object.values(b)[0];

				if (aName > bName) {
					return 1;
				}

				if (aName < bName) {
					return -1;
				}

				return 0;
			});

			this.setState({
				accountList,
			}, () => {
				const {
					params,
				} = this.props.router;

				if (params.account) {
					this.setAccountUUID(params.account);
				}
			});
		} catch (err) {
			console.error(err);
		} finally {
			this.setState({
				loading: false,
			}, () => {
				if (typeof cb === 'function') {
					return cb();
				}
			});
		}
	}

	fetchAccounts() {
		this.load(async () => {
			let accountsRes = await fetchAccount();
			if (this.handleOutdatedCommithashValidation(accountsRes)) {
				return;
			}
			let accountList = [];
			for (let [ m1Key, m1Data, ] of Object.entries(accountsRes.userFD.data)) {
				if (m1Key.startsWith('account.')) {
					let m1UUID = m1Key.split('.')[1];
					let m1Name = m1Data['display_name'];

					accountList.push({
						[m1UUID]: m1Name,
					});
				}
			}

			accountList.sort((a, b) => {
				let aName = Object.values(a)[0];
				let bName = Object.values(b)[0];

				if (aName > bName) {
					return 1;
				}

				if (aName < bName) {
					return -1;
				}

				return 0;
			});

			this.setState({
				accountList,
			}, () => {
				const {
					params,
				} = this.props.router;

				if (accountList.length === 1) {
					let accountUUID = Object.keys(accountList[0]);
					this.redirect({
						to: `/systems/${accountUUID}`,
					});
				}

				// if (accountList.length > 1) {
				// 	this.redirect({
				// 		to: '/accounts',
				// 	});
				// }

				if (params.account) {
					this.setAccountUUID(params.account);
				}
			});
		});
	}

	fetchAccount(accountUUID, systemGroupUUID) {
		const {
			accountList,
		} = this.state;

		this.load(async () => {
			let accountRes = await fetchAccount(accountUUID, systemGroupUUID);

			if (!accountRes.listingConfig) {
				// debugger;
			}

			let accountUUIDs = [];
			for (let accountListObj of accountList) {
				accountUUIDs.push(Object.keys(accountListObj)[0]);
			}

			let accountName = this.state.accountName;
			if (!accountName) {
				accountName = accountRes.data[`account.${accountUUID}`].display_name;
			}

			if (!~accountUUIDs.indexOf(accountUUID)) {
				accountList.push({
					[accountUUID]: accountName,
				});
			}

			this.setState({
				accountName,
				accountList,
				accountFD:        accountRes.data,
				listingConfigMap: accountRes.listingConfig,
				settingMap:       accountRes.settings,
			}, () => {
				// TODO: if only 1 system?
				// TODO: drop them at /content ?

				let params = this.params();
				if (params.mode === 'accounts') {
					this.redirect({
						to: `/systems/${accountUUID}`,
					});
				} else {
					this.redirect({
						to: `/${params.mode}/${accountUUID}`,
					});
				}

				this.fetchAccountBuildings();
			});
		});
	}

	fetchAccountTemplates(accountUUID) {
		this.load(async () => {
			let accountRes = await fetchAccountTemplates(accountUUID);
			if (this.handleOutdatedCommithashValidation(accountRes)) {
				return;
			}

			this.setState({
				accountTemplates: accountRes.data,
				listingConfigMap: accountRes.listingConfig,
			});
		});
	}

	fetchAccountSystems(accountUUID) {
		this.load(async () => {
			let accountRes = await fetchAccountSystems(accountUUID);
			if (this.handleOutdatedCommithashValidation(accountRes)) {
				return;
			}

			this.setState({
				accountSystem:    {},
				accountSystems:   accountRes.data,
				listingConfigMap: accountRes.listingConfig,
			});
		});
	}

	fetchAccountSystem(accountUUID, systemUUID) {
		this.load(async () => {
			let accountRes = await fetchAccountSystem(accountUUID, systemUUID);
			if (this.handleOutdatedCommithashValidation(accountRes)) {
				return;
			}

			this.setState({
				accountSystem: accountRes.data,
			});
		});
	}

	fetchAccountBuildings() {
		const {
			accountUUID,
			systemGroupUUID,
		} = this.state;

		fetchBuildings(accountUUID, systemGroupUUID)
			.then((buildingsData) => {
				const buildingsDataArray = [];

				Object.entries(buildingsData)
					.forEach(([ m1Key, m1Val, ]) => {
						const buildingData = {
							m1Key,
							isChecked: false,
							vals:      {
								buildingName: m1Val.display_name,
							},
							system_group: m1Val.system_group_uuid,
						};

						buildingsDataArray.push(buildingData);
					});

				this.setState({
					buildingsDataArray,
				});
			});
	}

	async _fetchAccountFD(accountUUID, cb = () => {}) {
		let accountRes = await fetchAccountFD(accountUUID);

		this.setState({
			accountFD: accountRes.data,
		}, () => {
			if (typeof cb === 'function') {
				return cb();
			}
		});
	}

	fetchAccountFD(accountUUID, cb = () => {}) {
		this.load(
			this._fetchAccountFD.bind(this, accountUUID, cb)
		);
	}

	setAccountUUID(accountUUID) {
		const {
			accountList,
		} = this.state;

		if (this.state.accountUUID === accountUUID && !this.state.systemGroupUUID) {
			console.log('NO_SWITCH_REQUIRED');
			return;
		}

		let accountObjs = accountList.filter((accountObj) => {
			let m1UUID = Object.keys(accountObj)[0];
			if (m1UUID === accountUUID) {
				return true;
			}
		});

		let accountName;
		if (accountObjs.length === 1) {
			accountName = accountObjs[0][accountUUID];
		}

		this.setState({
			accountUUID,
			accountName,
			systemGroupUUID: '',
		}, () => {
			this.fetchAccount(accountUUID);
		});
	}

	setSystemGroupUUID(systemGroupUUID) {
		const {
			accountUUID,
		} = this.state;

		this.setState({
			systemGroupUUID,
		}, () => {
			this.fetchAccount(accountUUID, systemGroupUUID);
		});
	}

	fetchComponent(componentUUID, isEntertainment = false) {
		this.load(async () => {
			const componentRes = await fetchComponent(componentUUID);

			let settingUUIDMap = {};
			for (let [ settingName, settingInfo, ] of Object.entries(componentRes.settingMap)) {
				settingUUIDMap[settingInfo.uuid] = settingName;
			}

			this.setState({
				componentUUID,
				componentMap:        componentRes.componentMap,
				componentTypeMap:    componentRes.componentTypeMap,
				componentSettingMap: componentRes.settingMap,
				settingUUIDMap,
				listingConfigUUID:   '',
				slideshow:           {},
				entertainment:       isEntertainment,
			});
		});
	}

	fetchSlideshow(slideshow = {}) {
		this.setState({
			componentUUID:       '',
			componentMap:        {},
			componentTypeMap:    {},
			componentSettingMap: {},
			settingUUIDMap:      {},
			listingConfigUUID:   '',
			slideshow,
			entertainment:       false,
		});
	}

	fetchEntertainment(componentUUID) {
		this.load(async () => {
			const componentRes = await fetchComponent(componentUUID);

			let settingUUIDMap = {};
			for (let [ settingName, settingInfo, ] of Object.entries(componentRes.settingMap)) {
				settingUUIDMap[settingInfo.uuid] = settingName;
			}

			this.setState({
				componentUUID,
				componentMap:        componentRes.componentMap,
				componentTypeMap:    componentRes.componentTypeMap,
				componentSettingMap: componentRes.settingMap,
				settingUUIDMap,
				listingConfigUUID:   '',
				slideshow:           false,
				entertainment:       true,
			});
		});
	}

	openModal(modalProps) {
		modalProps.app = this;

		if (modalProps.library) {
			this.setState({
				libraryModal:      true,
				libraryModalProps: modalProps,
			});

			return;
		}

		this.setState({
			modal: true,
			modalProps,
		});
	}

	closeModal(cb = () => {}) {
		this.setState({
			modal:          false,
			modalProps:     {},
			pendingChanges: {},
		}, () => {
			cb();
		});
	}

	closeModalAndKeepPendingChanges(cb = () => { }) {
		this.setState({
			modal: false,
		}, () => {
			cb();
		});
	}

	closeLibraryModal(cb = () => {}) {
		this.setState({
			libraryModal:      false,
			libraryModalProps: {},
		}, () => {
			cb();
		});
	}

	openPendingChangesModal(name, noSaveCB = () => {}, saveCB = () => {}) {
		const modalProps = {
			modalSize:  1,
			showModal:  true,
			modalProps: {
				title:     'You Have Unsaved Changes',
				omitClose: true,
				jsx:       (
					<PendingChangesModalView
						app={this}
						name={name}
						noSaveCB={noSaveCB}
						saveCB={saveCB}
					/>
				),
			},
		};

		this.setState({
			modal: true,
			modalProps,
		});
	}

	clearPendingChanges() {
		this.setState({
			pendingChanges:          {},
			pendingSlideshowChanges: {},
		});
	}

	clearPendingChangesAndCloseModal(name, noSaveCB = () => {}) {
		this.setState({
			pendingChanges:          {},
			pendingSlideshowChanges: {},
			modal:                   false,
			modalProps:              {},
		}, () => {
			noSaveCB();
		});
	}

	createListingConfig(newListingConfigUUID, parentM1, parentM1UUID) {
		const {
			listingConfigMap,
		} = this.state;

		const initialSettings =	{
			childTiers:          [],
			content_group_count: 1,
			data_width:          80,
			display_name:        '',
			label:               '',
			listing:             true,
			mobile_button_order: 0,
			mobile_click_event:  0,
			mobile_dialing:      false,
			mobile_display:      false,
			mobile_wayfinding:   false,
			renderName:          'ListingsGroup',
		};

		const newListingConfigMap = {
			...listingConfigMap,
		};

		newListingConfigMap[newListingConfigUUID] = initialSettings;

		if (parentM1 === 'listing_config') {
			newListingConfigMap[parentM1UUID].childTiers.push(newListingConfigUUID);
		}

		this.setState({
			listingConfigMap: newListingConfigMap,
		});
	}

	createListingConfigs(newListingConfigUUIDs, parentM1, parentM1UUID) {
		const {
			listingConfigMap,
		} = this.state;

		const newListingConfigMap = {
			...listingConfigMap,
		};

		let _parentM1 = parentM1;
		let _parentM1UUID = parentM1UUID;

		for (let i = 0; i < newListingConfigUUIDs.length; i++) {
			const initialSettings =	{
				childTiers:          [],
				content_group_count: 1,
				data_width:          80,
				display_name:        '',
				label:               '',
				listing:             true,
				mobile_button_order: 0,
				mobile_click_event:  0,
				mobile_dialing:      false,
				mobile_display:      false,
				mobile_wayfinding:   false,
				renderName:          'ListingsGroup',
			};

			const newListingConfigUUID = newListingConfigUUIDs[i];


			newListingConfigMap[newListingConfigUUID] = initialSettings;

			if (_parentM1 === 'listing_config') {
				newListingConfigMap[_parentM1UUID].childTiers.push(newListingConfigUUID);
			}

			_parentM1 = 'listing_config';
			_parentM1UUID = newListingConfigUUID;
		}

		this.setState({
			listingConfigMap: newListingConfigMap,
		});
	}

	deleteListingConfig(listingConfigUUID) {
		const {
			listingConfigMap,
		} = this.state;

		const newListingConfigMap = {
			...listingConfigMap,
		};

		const parentUUIDs = Object.entries(listingConfigMap).find((entry) => {
			if (entry[1].childTiers.includes(listingConfigUUID)) {
				return true;
			}
		});

		if (parentUUIDs && parentUUIDs.length > 0) {
			newListingConfigMap[parentUUIDs[0]].childTiers = [];
		}

		delete newListingConfigMap[listingConfigUUID];

		this.setState({
			listingConfigMap: newListingConfigMap,
		});
	}

	setValue(name, entityNames, values, cb = () => {}) {
		const {
			accountFD,
			pendingChanges,
		} = this.state;

		let newPendingChanges = JSON.parse(JSON.stringify(pendingChanges));
		if (!newPendingChanges) {
			newPendingChanges = {};
		}

		for (const key in newPendingChanges) {
			if (typeof newPendingChanges[key] === 'string') {
				newPendingChanges[key] = newPendingChanges[key].trim();
			}
		}

		if (!Array.isArray(entityNames)) {
			entityNames = [ entityNames, ];
		}
		if (!Array.isArray(values)) {
			values = [ values, ];
		}

		if (entityNames.length !== values.length) {
			if (entityNames.length !== 1 || !entityNames[0].endsWith('._bundle')) {
				throw Error('keys.length !== vals.length');
			}
		}

		entityNames.forEach((entityName, i) => {
			let value = values[i];
			if (entityName.endsWith('._bundle')) {
				value = values;
			}

			// newPendingChanges[name][entityName] = value;
			newPendingChanges[entityName] = value;

			// TODO: check if value = accountFD so as not
			// TODO: to store pendingChanges that aren't real
			let info = parseEntityName(entityName);

			if (info.type === 'rel') {
				let m1Key = `${info.m1}.${info.m1ID}`;
				let m2Key = `${info.m2}.${info.m2ID}`;

				let orgValue = (accountFD[m1Key] || {})[m2Key];
				if (orgValue && value === true) {
					Reflect.deleteProperty(newPendingChanges, entityName);
				}
				if (!orgValue && value === false) {
					// Reflect.deleteProperty(newPendingChanges, entityName);
				}
			}
		});

		// console.log('CHANGES:', newPendingChanges);
		this.setState({
			pendingChanges: newPendingChanges,
		}, () => {
			if (typeof cb === 'function') {
				return cb();
			}
		});
	}

	delValue(name, entityNames, cb = () => {}) {
		const {
			accountFD,
			pendingChanges,
		} = this.state;

		if (!Array.isArray(entityNames)) {
			entityNames = [ entityNames, ];
		}

		let newPendingChanges = JSON.parse(JSON.stringify(pendingChanges));
		entityNames.forEach((entityName, i) => {
			let l = entityName.split('.').length;
			let [ m1, m1ID, m2, m2ID, ] = entityName.split('.');

			let m1Key = `${m1}.${m1ID}`;
			let m2Key = `${m2}.${m2ID}`;

			if (l === 2 || l === 4) {
				// remove m1.m1ID.*?.*?.*
				Object.keys(newPendingChanges).filter((key) => {
					if (key.startsWith(entityName)) {
						return true;
					}
				}).forEach((key) => {
					Reflect.deleteProperty(newPendingChanges, key);
				});

				if (l === 2) {
					if (accountFD[m1Key]) {
						// newPendingChanges[entityName] = false;
					}
				}

				if (l === 4) {
					if (accountFD[m1Key] && accountFD[m1Key][m2Key]) {
						newPendingChanges[entityName] = false;
					}
				}
			}
		});

		this.setState({
			pendingChanges: newPendingChanges,
		}, () => {
			if (typeof cb === 'function') {
				return cb();
			}
		});
	}

	delPendingChange(entityNames = [], cb = () => {}) {
		if (!Array.isArray(entityNames)) {
			entityNames = [ entityNames, ];
		}

		let pendingChanges = Object.assign({}, this.state.pendingChanges);
		for (let entityName of entityNames) {
			Reflect.deleteProperty(pendingChanges, entityName);
		}

		this.setState({
			pendingChanges,
		}, () => {
			if (typeof cb === 'function') {
				return cb();
			}
		});
	}

	hasPendingChanges(name) {
		const {
			pendingChanges,
		} = this.state;
		return (Object.keys(pendingChanges).length > 0);
	}

	hasPendingSlideshowChanges() {
		const {
			pendingSlideshowChanges,
		} = this.state;
		return (Object.keys(pendingSlideshowChanges).length > 0);
	}

	injectContentPendingChanges(componentMap) {
		let {
			pendingChanges,
			componentSettingMap,
			settingUUIDMap,
		} = this.state;

		pendingChanges = makeFDFromDD(pendingChanges);
		if (Object.keys(pendingChanges).length === 0) {
			return componentMap;
		}

		let newComponentMap = JSON.parse(JSON.stringify(componentMap));
		for (let [ m1Key, m1Data, ] of Object.entries(pendingChanges)) {
			if (m1Key.startsWith('component.')) {
				for (let [ m2Key, m2Data, ] of Object.entries(m1Data)) {
					if (m2Key.startsWith('setting.')) {
						let componentUUID = m1Key.split('.')[1];
						if (!componentMap[componentUUID]) {
							continue;
						}

						let settingUUID = m2Key.split('.')[1];
						let settingName = settingUUIDMap[settingUUID];
						let setting = componentSettingMap[settingName];

						let value = m2Data[setting.type];
						newComponentMap[componentUUID].settings[settingName] = value;
					}
				}
			}
		}

		return newComponentMap;
	}

	setNetworkErrorModal(retry = true) {
		this.setState({
			confirmModal:        true,
			confirmModalContent: <>
				<p>A network error has occured.</p>
				<div className="confirm-modal-buttons">
					{retry && <button className="button-outlined" onClick={(e) => {
						this.setState({
							confirmModal: false,
						});
						this.saveData();
					}}>Retry</button>}
					<button className="button" onClick={(e) => {
						this.setState({
							confirmModal: false,
						});
					}}>Return</button>
				</div>
			</>,
		});
	}

	setCommitHashErrorModal() {
		this.setState({
			confirmModal:        true,
			confirmModalContent: <>
				<p>You have an outdated version of the Spark Dashboard.</p>
				<p>Please, clear locally stored data from Spark from your browser and retry.</p>
				<div className="confirm-modal-buttons">
					<button className="button-outlined" onClick={() => {
						this.setState({
							confirmModal: false,
						});

						this.logout();

					}}>Logout</button>
					<button className="button" onClick={() => {
						this.setState({
							confirmModal: false,
						});
					}}>Cancel</button>
				</div>
			</>,
		});
	}

	renderEntity (entity, props = {
		fd: {},
	}) {
		let mode = this.params().mode;
		let fieldProps = {
			...props,
			...entity,
			app:        this,
			key:        entity.name,
			entity,
			value:      getFDValue(props.fd, entity.name),
			setValue:   this.setValue.bind(this, mode, entity.name),
			bucketName: this.state.bucketName,
		};
		if (Object.keys(props.fd).length > 0 && !fieldProps.value) {
			// debugger;
		}

		let Field;
		switch (entity.fieldType) {
		// case 'custom.vulture.infobox':
		// 	Field = CrowFieldCustomVultureInfobox;
		// 	break;
		// case 'custom.vulture.elements.OnOff':
		// 	Field = CrowFieldCustomVultureElementsOnOff;
		// 	break;
		case 'custom.vulture.elements.float':
			Field = CrowFieldCustomVultureElementsFloat;
			break;
		case 'custom.vulture.elements.text.small':
			Field = CrowFieldCustomVultureElementsTextSmall;
			break;
		case 'custom.vulture.elements.text.large':
			Field = CrowFieldCustomVultureElementsTextLarge;
			break;
		case 'custom.vulture.elements.phone.number':
			Field = CrowFieldCustomVultureElementsPhoneNumber;
			break;
		case 'custom.vulture.elements.number':
			Field = CrowFieldCustomVultureElementsNumber;
			break;
		case 'custom.vulture.elements.icon':
			Field = CrowFieldCustomVultureElementsIcon;
			break;
		case 'custom.vulture.elements.email':
			Field = CrowFieldCustomVultureElementsEmail;
			break;
		case 'custom.vulture.elements.slack':
			Field = CrowFieldCustomVultureElementsSlack;
			break;
		case 'custom.vulture.elements.uploader':
			fieldProps.component = {
				settings: {
					source:          fieldProps.value || '',
					parentDirectory: fieldProps.attr.parent_directory || '',
					isListings:      true,
				},
			};
			Field = ImageUploaderComponent;
			break;
			// case 'custom.vulture.elements.json':
			// 	Field = CrowFieldCustomVultureElementsJSON;
			// 	break;
		case 'custom.vulture.qrcode':
			fieldProps.component = {

				settings: {
					url:             fieldProps.value || '',
					parentDirectory: fieldProps.attr.parent_directory || '',
					isListings:      true,
				},
			};
			Field = QrCodeComponent;
			break;
		case 'custom.vulture.elements.drop.down':
			Field = CrowFieldCustomVultureDropDown;
			break;
		case 'custom.vulture.elements.phone.message':
			Field = CrowFieldCustomVultureElementsPhoneMessage;
			break;
		case 'custom.vulture.elements.resolution.drop.down':
			Field = CrowFieldCustomVultureResolutionDropDown;
			break;
		case 'custom.vulture.elements.advanced.checkbox':
			Field = CrowFieldCustomVultureAdvancedCheckbox;
			break;
		case 'custom.common.date':
			Field = CrowFieldCustomCommonDate.field;
			break;
		case 'custom.common.date.timezone':
			Field = CrowFieldCustomCommonDateTimezone.field;
			break;
		case 'custom.common.date.time':
			Field = CrowFieldCustomCommonDateTime.field;
			break;
			// case 'custom.common.maintenance.mode':
			// 	Field =
		default:
			Field = function (props) {
				return (
					<div
						key={fieldProps.key}
					>invalid_field: {entity.fieldType}</div>
				);
			};
		}

		if (Field.name === 'custom.common.date.timezone') {
			// debugger;
		}

		return (
			<Field {...fieldProps} />
		);
	}

	getHashedActiveAccountName() {
		return md5(this.state.accountUUID);
	}

	getStockAndDynamicPhotos() {
		this.getPhotosFromBucket(`${vultureENV}/ts-library/${this.getHashedActiveAccountName()}`);
		this.getPhotosFromBucket(`${vultureENV}/ts-library/stock`);
	}

	getPhotosFromBucket(prefix) {
		const {
			app,
		} = this.props;
		let photosToReturn = [];
		s3.listObjects(
			{
				Bucket:    this.state.bucketName,
				Delimiter: '/',
				Prefix:    `${prefix}/`,
			},
			(err, data) => {
				if (err) {
					console.error(err);
					return;
				}
				if (data && data.Contents) {
					if (data.Contents.length === 0) {
						this.setState({
							photos:        [],
							photosChecked: false,
						});

						return;
					}

					data.Contents.forEach((obj, i) => {

						let keyArray = obj.Key.split('/');
						if (keyArray[3] === '') {
							return;
						}
						let imgObj = {};
						imgObj.assetURL = `https://s3.amazonaws.com/${this.state.bucketName}/${obj.Key}`;
						imgObj.imgURL = `https://${this.state.bucketName}.s3.amazonaws.com/${(obj.Key)}`;

						let img = new Image();
						img.addEventListener('load', function () {
							imgObj.resolution = `${this.naturalWidth}px x ${this.naturalHeight}px`;
						});
						img.src = imgObj.imgURL;

						let k = `img-${i}`;
						imgObj.imgElm = (
							<img key={k} src={imgObj.imgURL} />
						);
						imgObj.checked = false;
						imgObj.index = i; //check if this is being used
						imgObj.lastModified = obj.LastModified;
						imgObj.name = keyArray[3];
						imgObj.size = `${Math.floor(obj.Size / 1024)} kb`;
						imgObj.imgKey = obj.Key;
						photosToReturn.push(imgObj);

					});
					let sortedPhotosToReturn = [];
					sortedPhotosToReturn = photosToReturn.sort((a, b) => {
						return b.lastModified - a.lastModified;
					});
					if (prefix === `${vultureENV}/ts-library/stock`) {
						this.setState({
							stockPhotos:   sortedPhotosToReturn,
							photosChecked: false,
						});

						return;
					}

					this.setState({
						photos:        sortedPhotosToReturn,
						photosChecked: false,
					});

				}
			}
		);
	}

	uploadPhotosToBucket(file, objKey, bucketName = this.state.bucketName) {
		s3.upload(
			{
				Body:   file,
				Bucket: bucketName,
				Key:    objKey,
				ACL:    'public-read',
			},
			(err, data) => {
				if (err) {
					console.error(err, data);
					alert(`There was an error uploading your file: `, err.message);
					return;
				}
				return;
			}
		);
	}

	deletePhotosFromBucket(file, objKey, cb = () => { }) {
		this.setState({
			loading: true,
		}, async () => {
			s3.deleteObject({
				Bucket: this.state.bucketName,
				Key:    objKey,
			}, (err, data) => {
				if (err) {
					console.error(err);
					return;
				}
				this.getPhotosFromBucket();
				this.setState({
					loading: false,
				});
				return;
			});
		});
	}

	async registerListingIntegration(accountUUID, listingConfigUUID, provider, providerList, email, authCode, mallID) {
		const {
			accountFD,
		} = this.state;

		const listingConfigFD = await registerListingIntegration(accountUUID, listingConfigUUID, provider, providerList, email, authCode, mallID);

		Object.entries(listingConfigFD).forEach(([ m1Key, m1Val, ]) => {
			accountFD[m1Key] = m1Val;
		});

		this.setState({
			accountFD,
		});
	}

	async updateViewData(data, entityKey) {
		const {
			accountFD,
		} = this.state;

		let viewFD;

		try {
			viewFD = await updateViewData(data, entityKey);
		} catch (err) {
			console.error(err);
			alert(`Error Saving: ${err}`);
			return false;
		}

		if (!viewFD) {
			const err = 'No data returned from updateViewData';
			console.error(err);
			alert(`Error Saving: ${err}`);
			return false;
		}


		Object.entries(viewFD).forEach(([ m1Key, m1Val, ]) => {
			accountFD[m1Key] = m1Val[m1Key];
		});

		return new Promise((resolve) => {
			this.setState({
				accountFD,
			}, () => {
				resolve(true);
				alert('Success');
			});
		});
	}

	async applySystemTemplateAssignment(accountUUID, assignmentMap) {
		const updatedFD = await applySystemTemplateAssignment(accountUUID, assignmentMap);

		if (!updatedFD) {
			return;
		}

		const {
			accountFD,
			accountSystems,
		} = this.state;

		this.setState({
			accountFD: {
				...accountFD,
				...updatedFD,
			},
			accountSystems: {
				...accountSystems,
				...updatedFD,
			},
		});
	}

	render() {
		const {
			accountFD,
			accountList,
			accountName,
			accountSystem,
			accountSystems,
			accountTemplates,
			accountUUID,
			listingConfigUUID,
			listingConfigMap,
			entertainment,
			slideshow,
			componentUUID,
			componentMap,
			componentTypeMap,
			componentSettingMap,
			settingUUIDMap,
			settingMap,
			pendingChanges,
			pendingSlideshowChanges,
			loading,
			pendingRequests,
			forceLoading,
			modal,
			modalProps,
			libraryModal,
			libraryModalProps,
			photos,
			stockPhotos,
			bucketName,
			systemGroupUUID,
			buildingsDataArray,
			accountFDWithPendingChanges,
		} = this.state;

		// TODO: mode instead of window.location.pathname
		if (!hasSession() && window.location.pathname === '/') {
			return (
				<SplitScreenView
					app={this}
				/>
			);
		}

		let loginPathnames = [
			'/password/reset',
			'/password',
			'/register',
			'/login',
			'/mfa',
		];

		let isLoginPathname = false;
		loginPathnames.forEach((pn) => {
			if (isLoginPathname) {
				return;
			}

			isLoginPathname = window.location.pathname.startsWith(pn);
		});

		if (!hasSession() && !isLoginPathname) {
			// if session expires w/ modal open
			// then it can return w/ modal open
			this.closeModal();

			// this.redirect({
			// 	to: '/login',
			// });

			this.logout();

			return '';
		}

		if (!hasSession() || isLoginPathname) {
			if (window.location.pathname.startsWith('/password/reset')) {
				return (
					<LoginView
						app={this}
						mode={'resetPassword'}
					/>
				);
			}
			if (window.location.pathname.startsWith('/register')) {
				return (
					<LoginView
						app={this}
						mode={'register'}
					/>
				);
			}
			if (window.location.pathname.startsWith('/mfa')) {
				return (
					<>
						{(loading || forceLoading || pendingRequests > 0) &&
							<LoadingView
								app={this}
							/>
						}
						<MFAView
							app={this}
						/>
					</>
				);
			}

			return (
				<LoginView
					app={this}
				/>
			);
		}

		let params = this.params();

		let fd = {};
		switch (params.mode) {
		// case 'account_settings':
		// case 'content':
		// case 'screenshots':
		// case 'accounts':
		// case 'accountUsers':
		// case 'support':
		// case 'systems':
		// case 'theme_editor':
		// case 'users':
		// 	fd = mergeFD(accountFD, makeFDFromDD(pendingChanges));
		// 	break;

		case 'system_programming':
			fd = mergeFD(mergeFD(mergeFD({}, accountTemplates), accountSystems), accountSystem);
			break;

		default:
			fd = mergeFD(accountFD, makeFDFromDD(pendingChanges));
		}

		let fdLoading = false;

		if (accountUUID && Object.keys(fd).length === 0) {
			fdLoading = true;
		}

		if (params.mode === 'users' ||
			params.mode === 'library' ||
			params.mode === 'screenshots' ||
			params.mode === 'system_assignment' ||
			params.mode === 'data_presets' ||
			params.mode === 'entertainment') {
			fdLoading = false;
		}

		let confirmModal = '';
		if (this.state.confirmModal === true) {
			confirmModal = (
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.confirmModalContent}
					</div>
				</div>
			);
		}

		return (
			<React.Fragment>
				{confirmModal}
				{(loading || fdLoading || forceLoading || pendingRequests > 0) &&
					<LoadingView
						app={this}
					/>
				}

				{modal &&
					<ModalView
						app={this}
						accountUUID={accountUUID}
						fd={accountFDWithPendingChanges}
						settingMap={settingMap}
						pendingChanges={pendingChanges}
						{...modalProps}
					/>
				}

				{libraryModal &&
					<ModalView
						app={this}
						accountUUID={accountUUID}
						fd={accountFDWithPendingChanges}
						settingMap={settingMap}
						pendingChanges={pendingChanges}
						photos={photos}
						stockPhotos={stockPhotos}
						{...libraryModalProps}
					/>
				}

				<HeaderView
					app={this}
					mode={params.mode}
					accountUUID={accountUUID}
					accountName={accountName}
					accountList={accountList || []}
					settingMap={settingMap}
					systemGroupUUID={systemGroupUUID}
					fd={accountFDWithPendingChanges}
				/>

				<SidebarView
					app={this}
					accountUUID={accountUUID}
				/>

				{(params.mode === 'accounts' && Array.isArray(accountList)) &&
					<AccountsView
						app={this}
						accountUUID={accountUUID}
						fd={accountFDWithPendingChanges}
						accountList={accountList}
					/>
				}

				{(params.mode === 'calendar' || params.mode === 'connect') &&
					<div
						style={{
							marginTop:  '75px',
							marginLeft: '75px',
						}}
					>
						<input value="email" name="email-input" />
						<button
							onClick={(e) => {
								let email = document.getElementsByName('email-input')[0].value;
								axios.post(`http://localhost:9999/calendar/auth/google?email=${email}`)
									.then((res) => {
										console.log(res);
										debugger;
									})
									.catch((err) => {
										console.error(err);
										debugger;
									});
							}}
						>
							login
						</button>
						{/* {params.mode === 'calendar' && */}
						<div>
							<p>auth_state: {new URL(location.href).searchParams.get('state')}</p>
							<p> auth_code: {new URL(location.href).searchParams.get('code')}</p>
							<p>auth_scope: {new URL(location.href).searchParams.get('scope')}</p>
						</div>
						{/* } */}
					</div>
				}

				{params.mode === 'support' &&
					<SupportView
						app={this}
						accountUUID={accountUUID}
						fd={accountFDWithPendingChanges}
					/>
				}

				{(accountUUID && Object.keys(accountFD).length > 0 && params.mode === 'systems') &&
					<SystemsView
						app={this}
						accountUUID={accountUUID}
						fd={accountFDWithPendingChanges}
						settingMap={settingMap}
						systemGroupUUID={systemGroupUUID}
						setSystemGroupUUID={this.setSystemGroupUUID}
					/>
				}

				{(accountUUID && params.mode === 'library') &&
					// TODO: keep mounted in background for less S3 calls?
					<LibraryView
						app={this}
						accountUUID={accountUUID}
						fd={accountFDWithPendingChanges}
						photos={photos}
						stockPhotos={stockPhotos}
					/>
				}

				{(accountUUID && params.mode === 'content') &&
					<ContentView
						app={this}
						accountUUID={accountUUID}
						systemGroupUUID={systemGroupUUID}
						buildingsDataArray={buildingsDataArray}
						fd={accountFDWithPendingChanges}
						settingMap={settingMap}
						componentUUID={componentUUID}
						componentMap={this.injectContentPendingChanges(componentMap)}
						componentTypeMap={componentTypeMap}
						componentSettingMap={componentSettingMap}
						settingUUIDMap={settingUUIDMap}
						listingConfigUUID={listingConfigUUID}
						listingConfigMap={listingConfigMap}
						entertainment={entertainment}
						slideshow={slideshow}
						pendingChanges={pendingChanges}
						pendingSlideshowChanges={pendingSlideshowChanges}
						bucketName={bucketName}
					/>
				}

				{(accountUUID && params.mode === 'account_settings') &&
					<AccountSettingsView
						app={this}
						accountUUID={accountUUID}
						fd={accountFDWithPendingChanges}
						pendingChanges={pendingChanges}
						listingConfigMap={listingConfigMap}
						settingMap={settingMap}
					/>
				}

				{(accountUUID && params.mode === 'system_programming') &&
					<SystemProgrammingView
						app={this}
						accountUUID={accountUUID}
						fd={mergeFD(mergeFD(mergeFD({}, accountTemplates), accountSystems), accountSystem)}
						pendingChanges={pendingChanges}
						settingMap={settingMap}
						listingConfigMap={listingConfigMap}
					/>
				}

				{(params.mode === 'screenshots') &&
					<ScreenshotView
						app={this}
						fd={accountFDWithPendingChanges}
					/>
				}

				{(params.mode === 'data_presets') &&
					<DataPresetsView
						app={this}
					/>
				}

				{(accountUUID && params.mode === 'accountUsers') &&
					<AccountUsersView
						app={this}
						accountUUID={accountUUID}
						fd={accountFDWithPendingChanges}
						mode={params.mode}
						form={window.form}
					/>
				}

				{(accountUUID && params.mode === 'theme_editor') &&
					<CrowFieldCustomVultureThemeEditor
						app={this}
						accountUUID={accountUUID}
						fd={accountFDWithPendingChanges}
						mode={params.mode}
						form={window.form}
						settingMap={settingMap}
					/>
				}

				{(params.mode === 'system_assignment') &&
					<SystemAssignmentView
						app={this}
						accountUUID={accountUUID}
						fd={accountFDWithPendingChanges}
						mode={params.mode}
					/>
				}

				{(params.mode === 'entertainment') &&
					<EntertainmentEditor
						app={this}
						accountUUID={accountUUID}
						fd={fd}
						mode={params.mode}
					/>
				}

				{(params.mode === 'users') &&
					<UsersView
						app={this}
						accountUUID={accountUUID}
						fd={accountFDWithPendingChanges}
						mode={params.mode}
						form={window.form}
					/>
				}

			</React.Fragment>
		);
	}
}

const VultureFormLogin = DashboardView;
export default VultureFormLogin;