export const combineSubrows = (currentItem) => {
	let subRows = [];
	currentItem.subRows.forEach((subRow) => {
		subRows.push(subRow);
		if (subRow.subRows.length > 0) {
			subRows = subRows.concat(combineSubrows(subRow));
		}
	});
	return subRows;
};

export const getFilteredHierarchy = (fd, parentUUID, systemGroupUUID, isChild = false) => {
	if (!systemGroupUUID) {
		return fd;
	}

	let filteredParentData = {};
	for (const [m1Key, m1Val] of Object.entries(fd[parentUUID] || {})) {
		if (!m1Key.startsWith('system_group.')) {
			filteredParentData[m1Key] = m1Val;
		}
	}

	let filteredHierarchy = {};
	for (const [m1Key, m1Val] of Object.entries(fd[parentUUID] || {})) {
		if (isChild || m1Key === `system_group.${systemGroupUUID}`)  {
			const childHierarchy = getFilteredHierarchy(fd, m1Key, systemGroupUUID, true);

			filteredHierarchy[parentUUID] = filteredParentData;
			filteredHierarchy[parentUUID][m1Key] = {};
			filteredHierarchy[m1Key] = m1Val;
			filteredHierarchy = {
				...filteredHierarchy,
				...childHierarchy,
			};
		} else if (m1Key.startsWith('system_group.')) {
			const childHierarchy = getFilteredHierarchy(fd, m1Key, systemGroupUUID, isChild);

			if (Object.keys(childHierarchy).length > 0) {
				filteredHierarchy[parentUUID] = filteredParentData;
				filteredHierarchy[parentUUID][m1Key] = {};
				filteredHierarchy[m1Key] = m1Val;
				filteredHierarchy = {
					...filteredHierarchy,
					...childHierarchy,
				};
			}
		}
	}

	return filteredHierarchy;
};