import aws from 'aws-sdk';
export const AWS = aws;

AWS.config.update({
    region:      'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-1:dd01a076-d5c9-474e-a031-cb3bcc6f105c',
    }),
});

export const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {},
});