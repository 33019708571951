import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

import style from './Screenshot.module.css';

import {
	getSession,
} from 'Vulture/Session';

import {
	broncoURL,
	vultureENV,
} from 'Vulture/ENV';

import ScreenshotModal from './ScreenshotModal';
import {
	s3,
} from 'S3';

class ScreenshotView extends React.Component {
	constructor(props) {
		super(props);
	
		// Get the current date
		const now = new Date();
	
		// Calculate 7 days ago
		const sevenDaysAgo = new Date();
		sevenDaysAgo.setDate(now.getDate() - 7);
	
		this.state = {
			photos: [],
			sort: {
				label: 'File Size',
				value: 'size',
			},
			startDate: sevenDaysAgo,
			endDate: now,
			sortAscDesc: 'asc',
	
			selectedAccount: null,
			accountOptions: [],
			accountSelectorDisabled: true,
	
			tlcs: {},
		};
	
		this.sortingOptions = [
			{
				label: 'Date',
				value: 'lastModified',
			},
			{
				label: 'Time',
				value: 'time',
			},
			{
				label: 'File Size',
				value: 'size',
			},
		];
	
		this.sortingMethods = {
			lastModified: (a, b) => {
				if (this.state.sortAscDesc === 'desc') {
					return b.lastModified - a.lastModified;
				}
	
				return a.lastModified - b.lastModified;
			},
			time: (a, b) => {
				const dateA = new Date(a.lastModified);
				const dateB = new Date(b.lastModified);
	
				const timeA = dateA.getHours() * 3600 + dateA.getMinutes() * 60 + dateA.getSeconds();
				const timeB = dateB.getHours() * 3600 + dateB.getMinutes() * 60 + dateB.getSeconds();
				if (this.state.sortAscDesc === 'desc') {
					return timeB - timeA;
				}
				return timeA - timeB;
			},
			size: (a, b) => {
				if (this.state.sortAscDesc === 'desc') {
					return Number(b.size.split(' ')[0]) - Number(a.size.split(' ')[0]);
				}
				return Number(a.size.split(' ')[0]) - Number(b.size.split(' ')[0]);
			},
		};
	}

	getScreenshots() {
		const prefix = `${vultureENV}`;
		const bucketName = 'ts-screen-shots';

		// Helper function to fetch all objects with pagination
		const fetchAllObjects = async (bucketName, prefix) => {
			let isTruncated = true;
			let continuationToken = null;
			const allObjects = [];

			try {
				while (isTruncated) {
					const params = {
						Bucket: bucketName,
						Delimiter: '/',
						Prefix: `${prefix}/`,
						ContinuationToken: continuationToken,
					};

					// Fetch a batch of objects
					const data = await s3.listObjectsV2(params).promise();
					if (data && data.Contents) {

						// ignore directories
						data.Contents = data.Contents.filter((obj) => {
							const keyArray = obj.Key.split('/');
							if (keyArray[1] === '') return false;
							return true;
						});

						allObjects.push(...data.Contents);
					}

					isTruncated = data.IsTruncated;
					continuationToken = data.NextContinuationToken;
				}

				return allObjects;
			} catch (error) {
				console.error('Error fetching objects from S3:', error);
				throw error;
			}
		};

		// Main logic to fetch and process screenshots
		fetchAllObjects(bucketName, prefix)
			.then((objects) => {
				if (objects.length === 0) {
					this.setState({ photos: [] });
					return;
				}

				const photosToReturn = objects
					.map((obj, i) => {
						const keyArray = obj.Key.split('/');
						if (keyArray[1] === '') return null;

						// Extract directory and filename
						const baseDirectory = keyArray.slice(0, -1).join('/'); // Everything except the filename
						const fileName = keyArray[keyArray.length - 1]; // Just the filename

						const imgObj = {
							assetURL: `https://s3.amazonaws.com/ts-screen-shots/${obj.Key}`,
							imgURL: `https://ts-screen-shots.s3.amazonaws.com/${obj.Key}`, // Original image URL
							webpURL: `https://ts-screen-shots.s3.amazonaws.com/${baseDirectory}/webp/${fileName.replace(/\.\w+$/, '.webp')}`, // WebP URL
							tlcUUID: fileName.split('.')[0], // Extract UUID from filename
							lastModified: obj.LastModified,
							size: `${Math.floor(obj.Size / 1024)} kb`,
							imgKey: obj.Key,
						};

						return imgObj;
					})
					.filter(Boolean); // Remove nulls from the array

				const sortedPhotosToReturn = photosToReturn.sort(this.sortingMethods[this.state.sort.value]);

				this.setState({
					photos: sortedPhotosToReturn,
				});
			})
			.catch((error) => {
				console.error('Error processing screenshots:', error);
			});
	}

	componentDidMount() {
		this.getScreenshots();
		this.getAccountsData();
	}

	getAccountsData() {
		const session = getSession();
		const url = `${broncoURL}/account/list`;
		const body = {
			user: session.user,
		};

		axios.post(url, body, {})
			.then((res) => {
				if (res.status !== 200) {
					throw Error(`Invalid response from ${broncoURL}/account/list.`);
				}

				return res.data;
			})
			.then((accountsFD) => {
				const accountOptions = Object.entries(accountsFD)
					.map(([accountUUID, accountVal,]) => {
						return {
							value: accountUUID,
							label: accountVal.display_name,
							tlcs: accountVal.tlcs,
						};
					});

				this.setState({
					accountOptions,
					accountSelectorDisabled: false,
				});
			})
			.catch((err) => {
				alert(err);
			});
	}

	requestTLCList(accountUUID) {
		const {
			tlcs,
		} = this.state;

		if (tlcs[accountUUID]) {
			return;
		}

		const session = getSession();
		const url = `${broncoURL}/account/tlc`;
		const body = {
			user: session.user,
			account: accountUUID,
		};

		axios.post(url, body, {})
			.then((res) => {
				if (res.status !== 200) {
					throw Error(`Invalid response from ${broncoURL}/account/list.`);
				}

				return res.data;
			})
			.then((data) => {
				this.setState({
					tlcs: {
						...tlcs,
						[accountUUID]: data,
					},
				});
			})
			.catch((err) => {
				alert(err);
			});
	}

	handleError = (event) => {
		const imgElement = event.target;
		imgElement.onerror = null; // Prevent infinite loop
		const parent = imgElement.parentNode;
		if (parent && parent.children.length >= 2) {
			parent.children[0].srcset = imgElement.src;
			parent.children[1].srcset = imgElement.src;
		}
	};

	render() {
		const {
			accountOptions,
			selectedAccount,
			accountSelectorDisabled,
			tlcs,
		} = this.state;

		const filteredPhotos = this.state.photos
			.filter((photo) => {
				if (this.state.startDate === '' || this.state.endDate === '') {
					return true;
				}

				const lastModifiedDate = new Date(photo.lastModified);
				lastModifiedDate.setHours(0);
				lastModifiedDate.setMinutes(0);
				lastModifiedDate.setSeconds(0);
				lastModifiedDate.setMilliseconds(0);

				if (this.state.endDate >= lastModifiedDate && lastModifiedDate >= this.state.startDate) {
					return true;
				}

				return false;
			})
			.filter((photo) => {
				if (selectedAccount === null) {
					return true;
				} else if (tlcs[selectedAccount.value] && tlcs[selectedAccount.value].includes(photo.tlcUUID)) {
					return true;
				}

				return false;
			});

		return (
			<div className={`systems-page fade-in`}>
				<div className={style.pageHeader}>
					<h2>Screenshots</h2>
					<div className={style.headerDetails}>The screenshots taken from your systems.</div  >
				</div>
				<div className={style.content}>
					<div className="label">
						<label>Sort by (</label>
						<button className={style.ascDescButton} onClick={() => {
							const nextPhotos = this.state.photos.reverse();
							const nextSortAscDesc = this.state.sortAscDesc === 'desc' ? 'asc' : 'desc';

							this.setState({
								photos: nextPhotos,
								sortAscDesc: nextSortAscDesc,
							});
						}}>{this.state.sortAscDesc}</button>
						<label>):</label>
					</div>
					<Select
						options={this.sortingOptions}
						onChange={(e) => {
							const sortedPhotos = this.state.photos.sort(this.sortingMethods[e.value]);

							this.setState({
								sort: e,
								photos: sortedPhotos,
							});
						}}
						value={this.state.sort}
					/>
					<div className={`${style.datePicker} ${style.show}`}>
						<div className={style.startDate}>
							<div className={`label ${style.label}`}>
								<label>Start Date:</label>
								<button className={style.clearButton} onClick={() => {
									this.setState({
										startDate: '',
									});
								}}>clear</button>
							</div>
							<DatePicker
								selected={this.state.startDate}
								maxDate={this.state.endDate ? Date.parse(this.state.endDate) : new Date()}
								onChange={(e) => {
									this.setState({
										startDate: e,
									});
								}}
							/>
						</div>
						<div className={style.endDate}>
							<div className={`label ${style.label}`}>
								<label>End Date:</label>
								<button className={style.clearButton} onClick={() => {
									this.setState({
										endDate: '',
									});
								}}>clear</button>
							</div>
							<DatePicker
								selected={this.state.endDate}
								minDate={this.state.startDate ? Date.parse(this.state.startDate) : null}
								maxDate={new Date()}
								onChange={(e) => {
									this.setState({
										endDate: e,
									});
								}}
							/>
						</div>
						<div className="label">
							<label>Source Account:</label>
							<button className={style.clearButton} onClick={() => {
								this.setState({
									selectedAccount: null,
								});
							}}>clear</button>
						</div>
						<Select
							options={accountOptions}
							onChange={(e) => {
								this.setState({
									selectedAccount: e,
								});
								this.requestTLCList(e.value);
							}}
							isDisabled={accountSelectorDisabled}
							value={selectedAccount}
						/>
						<div className={style.endDate}>
							<div className={`label ${style.label}`}>
								<label>{`Results: ${filteredPhotos.length}`}</label>
							</div>
						</div>
					</div>
					<div className={style.gallery}>
						{filteredPhotos.map((obj, i) => {
							return (
								<div className={style.card} key={i}>
									<div className={style.cardContainer}>
										<div
											className={style.image}
											onClick={(e) => {
												e.preventDefault();
												this.props.app.openModal({
													modalSize: 2,
													showModal: true,
													modalProps: {
														title: obj.tlcUUID,
														customClass: 'test',
														pendingChanges: false,
														jsx: (
															<ScreenshotModal
																obj={obj}
																screenshotURL={obj.imgURL}
																app={this.props.app}
															/>
														),
													},
												});
											}}
										>
											<picture>
												{/* WebP source */}
												<source
													srcSet={obj.webpURL}
													type="image/webp"
												/>

												<source
													srcSet={obj.imgURL}
													type="image/webp"
												/>
												{/* Fallback source */}
												<img
													src={obj.imgURL}
													alt={`Screenshot of ${obj.tlcUUID}`}
													onError={this.handleError}
												/>
											</picture>
										</div>
										<div className={style.dimensions}>TLC UUID: {obj.tlcUUID}</div>
										<div className={style.dimensions}>
											Date: {obj.lastModified.toString()}
										</div>
										<div className={style.dimensions}>Size: {obj.size}</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default ScreenshotView;