import React from 'react';
import {
	v4 as uuidv4,
} from 'uuid';
import md5 from 'md5';

// Components
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import SlideshowTable from './SlideshowTable';
import ViewWindow from '../Common/ViewWindow/ViewWindow.js';
import CustomCheckbox from './Elements/Checkbox';

// Import styles
import style from './SlideshowTable.module.css';
// import { HighlightSharp } from '@material-ui/icons';
import {
	getFDValue,
} from 'Vulture/Helpers';

import {
	addObject,
	addSlideComponentUUIDs,
	addSlideData,
	copyImageFromLibrary,
	handleDeleteItems,
	structureAddJSON,
	structureEditJSON,
	updateSlideComponentUUIDs,
	updateSlideData,
} from 'Vulture/Content';

import {
	vultureENV,
} from 'Vulture/ENV';

const convertTime = (time) => {
	const hoursMinutesSeconds = time.split(':');

	let hour = parseInt(hoursMinutesSeconds[0]);

	let amPM = 'am';
	if (hour > 11) {
		amPM = 'pm';
	}
	if (hour > 12) {
		hour = hour - 12;
	}
	if (hour === 0) {
		hour = 12;
	}

	const formattedTime = hour + ':' + hoursMinutesSeconds[1] + amPM;
	return formattedTime;
};

const enrichSlide = (slideData) => {
	const retval = {
		...slideData,
	};

	retval.imgURL = `https://ts-pelican-resized.s3.amazonaws.com/${retval.file}`;

	if (~retval.kind.indexOf('video')) {
		retval.imgURL += '--video.jpg';
	} else if (~retval.kind.indexOf('html')) {
		retval.fallbackURL = `https://ts-public-images.s3.us-east-1.amazonaws.com/url-icon.svg`;
		const origin = new URL(slideData.file).origin;
		retval.imgURL = `${origin}/favicon.ico`;
	}

	const src = retval.imgURL;

	retval.imgElm = (
		<img
			key={`img-${slideData.key}`}
			src={src}
			// alt={retval.description || ''}
			onError={(e) => {
				e.target.src = retval.fallbackURL || '';
				e.target.onerror = null;
			}}
		/>
	);

	if (retval.scheduling) {
		if (retval.use_days && retval.days.length > 0) {
			const dayMap = new Map();
			dayMap.set(0, 'Sn');
			dayMap.set(1, 'M');
			dayMap.set(2, 'T');
			dayMap.set(3, 'W');
			dayMap.set(4, 'Th');
			dayMap.set(5, 'F');
			dayMap.set(6, 'S');

			retval.daysForSearch = [];

			const dayList = retval.days.map((value, i) => {
				switch (value) {
				case 0:
					retval.daysForSearch.push('sunday');
					break;
				case 1:
					retval.daysForSearch.push('monday');
					break;
				case 2:
					retval.daysForSearch.push('tuesday');
					break;
				case 3:
					retval.daysForSearch.push('wednesday');
					break;
				case 4:
					retval.daysForSearch.push('thursday');
					break;
				case 5:
					retval.daysForSearch.push('friday');
					break;
				case 6:
					retval.daysForSearch.push('saturday');
					break;
				default:
					break;
				}

				return (
					<div className={style.day} key={`dayList_${i}`}>
						{dayMap.get(value)}
					</div>
				);
			});

			retval.daysCol = <div className={style.days}>{dayList}</div>;
		}

		if (retval.use_dates) {
			const startISO = new Date(retval.start_date);
			const startMonth = startISO.getMonth();
			const startDate = startISO.getDate();
			const startYear = startISO.getFullYear().toString().substring(2);

			const endISO = new Date(retval.end_date);
			const endMonth = endISO.getMonth();
			const endDate = endISO.getDate();
			const endYear = endISO.getFullYear().toString().substring(2);

			if (retval.start_date) {
				retval.startDate = `${startMonth + 1}/${startDate}/${startYear}`;
			}
			if (retval.end_date) {
				retval.endDate = `${endMonth + 1}/${endDate}/${endYear}`;
			}
		}

		if (retval.use_times) {
			if (retval.start_time) {
				retval.startTime = `${convertTime(retval.start_time)}`;
			}
			if (retval.end_time) {
				retval.endTime = `${convertTime(retval.end_time)}`;
			}
		}
	}

	return retval;
};

class SlideshowView extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			UITableConfig:              {},
			userUITableConfig:          {},
			entityKeyToUpdate:          '',
			systemName:                 '',
			componentUUID:              '',
			columnsToHide:              [],
			colsEqual100:               true,
			galleryData:                [],
			deletedSlides:              [],
			showViewWindow:             false,
			systemToSystemGroupObjects: [],
			pageNavigationBlocked:      false,
			uniqueSystemGroupIDs:       [],
			confirmModal:               false,
			confirmModalContent:        '',
		};

		this.dataKeys = [
			'Image',
			'Name',
			'Resolution',
			'Order',
			'Start Date',
			'End Date',
			'Start Time',
			'End Time',
			'Days',
		];

		this.columnIDs = [
			'imgElm',
			'description',
			'resolution',
			'order',
			'startDate',
			'endDate',
			'startTime',
			'endTime',
			'daysCol',
		];
	}

	componentDidMount() {
		this.getSlideshowSettings();
		this.resetGalleryData([]);

		if (Object.keys(this.state.UITableConfig).length === 0) {
			this.getUITableConfig();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.state !== nextState) {
			return true;
		}

		if (this.props.pelicanData !== nextProps.pelicanData || this.props.slideshow !== nextProps.slideshow) {
			return true;
		}

		return false;
	}

	componentDidUpdate(prevProps) {
		const {
			slideshow,
			settingMap,
			pelicanData,
		} = this.props;

		if (JSON.stringify(prevProps.slideshow) !== JSON.stringify(slideshow)) {
			this.getSlideshowSettings();

			if (Object.keys(this.state.UITableConfig).length === 0) {
				this.getUITableConfig();
			}
		}

		if (JSON.stringify(prevProps.pelicanData) !== JSON.stringify(pelicanData)) {
			this.resetGalleryData(pelicanData);
		}

		if (JSON.stringify(prevProps.settingMap) !== JSON.stringify(settingMap)) {
			this.getSlideshowSettings();
		}
	}

	resetGalleryData(pelicanData = []) {
		const {
			deletedSlides,
		} = this.state; // Access the deletedSlides from the state

		if (pelicanData.length === 0) {
			return;
		}

		const galleryData = Object.entries(pelicanData)
			.map(([ key, slide, ]) => {
				// only assign the key(slideUUID) when the
				// pelicanData comes from the network response
				if (!slide.key) {
					slide.key = key;
				}

				return enrichSlide(slide);
			})
			// Filter out slides that have been marked as deleted
			.filter((slide) => {
				return !deletedSlides.includes(slide.key);
			})
			.sort((a, b) => {
				if (a.description.toLowerCase() > b.description.toLowerCase()) {
					return 1;
				}
				return -1;
			});

		this.setState({
			galleryData,
		});
	}

	getSystemToSystemGroupObjectInfo() {
		const {
			fd,
			slideshow,
		} = this.props;

		let info = {};
		let bundles = [];
		let systems = [];

		let systemKeys = Object.keys(slideshow.system_slideshow_map);
		for (let m1Key of Object.keys(fd)) {
			if (m1Key.startsWith('system.')) {
				let m1UUID = m1Key.split('.')[1];

				if (~systemKeys.indexOf(m1UUID)) {
					let systemToSystemGroupObject = {
						isBundled:         false,
						systemDisplayName: getFDValue(fd, `system.${m1UUID}.display_name`),
						systemID:          m1UUID,
						componentUUIDs:    slideshow.system_slideshow_map[m1UUID],
					};

					Object.entries(fd).forEach(([ key, val, ]) => {
						if (key.startsWith('component_group.') && slideshow.label === val.group_label) {
							if (val[`system.${m1UUID}`]) {
								systemToSystemGroupObject.isBundled = true;
								systemToSystemGroupObject.bundleID = key.split('.')[1];
								systemToSystemGroupObject.bundleName = val.display_name;
								systemToSystemGroupObject.nonExclusive = val.non_exclusive;
								systemToSystemGroupObject.systems = Object.keys(val).filter((key) => {
									return key.startsWith('system.');
								});
							}
						}
					});

					if (systemToSystemGroupObject.isBundled) {
						bundles.push(systemToSystemGroupObject);
					}

					if (!systemToSystemGroupObject.isBundled) {
						systems.push(systemToSystemGroupObject);
					}
				}
			}
		}

		info.bundles = bundles;
		info.systems = systems;

		return info;
	}

	getSlideshowSettings() {
		const {
			slideshow,
		} = this.props;

		let systemName = `Slideshow ${slideshow.slideshow_number}`;
		let systemToSystemGroupObjects = [];
		let info = this.getSystemToSystemGroupObjectInfo();

		info.bundles.sort((a, b) => {
			if (b.bundleName > a.bundleName) {
				return -1;
			}
			if (b.bundleName < a.bundleName) {
				return 1;
			}
			return 0;
		});

		info.systems.sort((a, b) => {
			if (b.systemDisplayName > a.systemDisplayName) {
				return -1;
			}
			if (b.systemDisplayName < a.systemDisplayName) {
				return 1;
			}
			return 0;
		});

		systemToSystemGroupObjects.push(...info.bundles);
		systemToSystemGroupObjects.push(...info.systems);
		let uniqueSystemGroupIDs = [];
		systemToSystemGroupObjects.forEach((systemgroup) => {
			if (systemgroup.isBundled && uniqueSystemGroupIDs.indexOf(systemgroup.bundleID) === -1) {
				uniqueSystemGroupIDs.push(systemgroup.bundleID);
			}
			if (!systemgroup.isBundled) {
				Object.keys(systemgroup.componentUUIDs).forEach((systemUUID) => {
					if (uniqueSystemGroupIDs.indexOf(systemUUID) === -1) {
						uniqueSystemGroupIDs.push(systemUUID);
					}
				});
			}
		});

		this.setState({
			systemName,
			systemToSystemGroupObjects,
			uniqueSystemGroupIDs,
		});

	}

	addObject(file, formData, cb = () => {}) {
		const {
			accountUUID,
			settingMap,
			app,
		} = this.props;

		app.setState({
			forceLoading: true,
		});

		const keys = [];
		const undoKeys = [];
		const vals = [];
		const undoVals = [];

		formData.key = `_${uuidv4()}`;

		let extension = '';
		const fileType = file.type;
		const typeSegments = fileType.split('/');
		if (typeSegments[1]) {
			extension = typeSegments[1];
		}
		const seededHash = md5(`${new Date().getTime()}_${file.name}`);

		const objKey = `${vultureENV}/${accountUUID}/${seededHash}.${extension}`;

		const addedSlideComponentUUIDs = addSlideComponentUUIDs(formData);
		keys.push(...addedSlideComponentUUIDs.keys);
		undoKeys.push(...addedSlideComponentUUIDs.undoKeys);
		vals.push(...addedSlideComponentUUIDs.vals);
		undoVals.push(...addedSlideComponentUUIDs.undoVals);

		const addedSlideData = addSlideData(formData, objKey, settingMap['pelican_json'].uuid);
		keys.push(...addedSlideData.keys);
		undoKeys.push(...addedSlideData.undoKeys);
		vals.push(...addedSlideData.vals);
		undoVals.push(...addedSlideData.undoVals);

		app.setValue('slideshow', keys, vals, () => {
			app.saveData('slideshow', (response) => {
				formData.key = Object.values(response.keys)[0].split('.')[1];
				addObject(file, objKey)
					.then(() => {
						const data = structureAddJSON(formData, objKey);
						data.component_uuids = formData.component_uuids;
						data.key = formData.key;
						return this.appendGalleryData(data);
					})
					.then(() => {
						this.props.buildPelicanData();
						cb();
						app.setState({
							forceLoading: false,
						});
					})
					.catch((err) => {
						app.setValue('slideshow', undoKeys, undoVals, () => {
							app.saveData('slideshow', () => {
								console.error(err);
								alert(`There was an error uploading your file: ${err.message}`);
								cb();
								app.setState({
									forceLoading: false,
								});
							});
						});
					});
			});
		});

	}

	copyImageFromLibrary(imgKey, formData, cb = () => {}) {
		const {
			accountUUID,
			settingMap,
			app,
		} = this.props;

		app.setState({
			forceLoading: true,
		});

		const keys = [];
		const undoKeys = [];
		const vals = [];
		const undoVals = [];

		formData.key = `_${uuidv4()}`;

		const splitImgKey = imgKey.split('.');
		const extension = splitImgKey[splitImgKey.length - 1];

		const seededHash = md5(`${new Date().getTime()}_${imgKey}`);

		const objKey = `${vultureENV}/${accountUUID}/${seededHash}.${extension}`;

		const addedSlideComponentUUIDs = addSlideComponentUUIDs(formData);
		keys.push(...addedSlideComponentUUIDs.keys);
		undoKeys.push(...addedSlideComponentUUIDs.undoKeys);
		vals.push(...addedSlideComponentUUIDs.vals);
		undoVals.push(...addedSlideComponentUUIDs.undoVals);

		const addedSlideData = addSlideData(formData, objKey, settingMap['pelican_json'].uuid);
		keys.push(...addedSlideData.keys);
		undoKeys.push(...addedSlideData.undoKeys);
		vals.push(...addedSlideData.vals);
		undoVals.push(...addedSlideData.undoVals);

		app.setValue('slideshow', keys, vals, () => {
			app.saveData('slideshow', (response) => {
				formData.key = Object.values(response.keys)[0].split('.')[1];
				copyImageFromLibrary(imgKey, objKey)
					.then(() => {
						const data = structureAddJSON(formData, objKey);
						data.component_uuids = formData.component_uuids;
						data.key = formData.key;
						return this.appendGalleryData(data);
					})
					.then(() => {
						this.props.buildPelicanData();
						cb();
						app.setState({
							forceLoading: false,
						});
					})
					.catch((err) => {
						app.setValue('slideshow', undoKeys, undoVals, () => {
							app.saveData('slideshow', () => {
								console.error(err);
								alert(`There was an error uploading your file: ${err.message}`);
								cb();
								app.setState({
									forceLoading: false,
								});
							});
						});
					});
			});
		});
	}

	addIframe(formData, cb = () => {}) {
		const {
			settingMap,
			app,
		} = this.props;

		const keys = [];
		const vals = [];

		formData.key = `_${uuidv4()}`;

		const objKey = formData.file;

		const addedSlideComponentUUIDs = addSlideComponentUUIDs(formData);
		keys.push(...addedSlideComponentUUIDs.keys);
		vals.push(...addedSlideComponentUUIDs.vals);

		const addedSlideData = addSlideData(formData, objKey, settingMap['pelican_json'].uuid);
		keys.push(...addedSlideData.keys);
		vals.push(...addedSlideData.vals);

		app.setValue('slideshow', keys, vals, () => {
			app.saveData('slideshow', () => {
				this.props.buildPelicanData();
				cb();
			});
		});
	}

	editObject(formData, oldFormData, cb = () => {}) {
		const {
			settingMap,
			app,
		} = this.props;

		app.setState({
			forceLoading: true,
		});

		const keys = [];
		const vals = [];

		const updatedSlideComponentUUIDs = updateSlideComponentUUIDs(formData, oldFormData.component_uuids);
		keys.push(...updatedSlideComponentUUIDs.keys);
		vals.push(...updatedSlideComponentUUIDs.vals);

		const updatedSlideData = updateSlideData(formData, settingMap['pelican_json'].uuid);
		keys.push(...updatedSlideData.keys);
		vals.push(...updatedSlideData.vals);

		app.setValue('slideshow', keys, vals, () => {
			app.saveData('slideshow', () => {
				const data = structureEditJSON(formData);
				data.component_uuids = formData.component_uuids;
				data.key = formData.key;
				this.appendGalleryData(data);
				this.props.buildPelicanData();
				cb();
				app.setState({
					forceLoading: false,
				});
			});
		});
	}

	handleDeleteItems(fileList, UUID, formData, cb = () => {}) {
		const {
			settingMap,
			app,
		} = this.props;

		app.setState({
			forceLoading: true,
		});

		const keys = [ `component.${UUID}.setting.${settingMap['pelican_json'].uuid}`, ];
		const undoKeys = [ `component.${UUID}.setting.${settingMap['pelican_json'].uuid}.json`, ];
		const vals = [ false, ];
		const undoVals = [ JSON.stringify(structureEditJSON(formData)), ];

		app.setValue('slideshow', keys, vals, () => {
			app.saveData('slideshow', () => {
				handleDeleteItems(fileList)
					.then(() => {
						this.setState((prevState) => {
							return {
								deletedSlides: [ ...prevState.deletedSlides, UUID, ],
							};
						}, () => {
							this.resetGalleryData(this.state.galleryData);
						});
					})
					.then(() => {
						this.props.buildPelicanData();
						cb();
						app.setState({
							forceLoading: false,
						});
					})
					.catch((err) => {
						app.setValue('slideshow', undoKeys, undoVals, () => {
							app.saveData('slideshow', () => {
								console.error(err);
								cb();
								app.setState({
									forceLoading: false,
								});
							});
						});
					});
			});
		});
	}

	appendGalleryData(data) {
		const {
			galleryData,
		} = this.state;

		const enrichedData = enrichSlide(data);

		const currentSlide = galleryData.find((slide) => {
			return slide.file === enrichedData.file;
		});

		if (currentSlide) {
			Object.assign(currentSlide, enrichedData);

			this.setState({
				galleryData,
			});
			return;
		}

		const newSlide = enrichSlide(data);

		galleryData.push(newSlide);

		const newGalleryData = [ ...galleryData, ].sort((a, b) => {
			if (a.description.toLowerCase() > b.description.toLowerCase()) {
				return 1;
			}
			return -1;
		});

		this.setState({
			galleryData: newGalleryData,
		});
	}

	checkGroupForComponentRelation(systemToSystemGroupObject, item) {
		let hasRelation = false;

		Object.keys(systemToSystemGroupObject.componentUUIDs).forEach((componentUUID) => {
			if (item.component_uuids.includes(componentUUID)) {
				hasRelation = true;
			}
		});

		return hasRelation;
	}

	toggleGroup(systemID, ...rowSlides) {
		const {
			app,
			pendingSlideshowChanges,
			slideshow,
		} = this.props;

		const {
			galleryData,
			systemToSystemGroupObjects,
		} = this.state;

		let nonExclusiveSystemPresent = false;
		let addOrRemove = true;

		systemToSystemGroupObjects
			.filter((systemToSystemGroupObject) => {
				return systemToSystemGroupObject.nonExclusive;
			})
			.forEach((systemToSystemGroupObject) => {
				if (systemToSystemGroupObject.systems.includes(`system.${systemID}`)) {
					nonExclusiveSystemPresent = true;
				}
			});
	
		let slideshowIndexes = [];
		for (let rowSlide of rowSlides) {
			let slideshowIndex = galleryData.findIndex((gallerySlide) => {
				return gallerySlide.key === rowSlide.key;
			});

			slideshowIndexes.push(slideshowIndex);
		}

		for (let slideshowIndex of slideshowIndexes) {
			let slide = galleryData[slideshowIndex];
			let groupIDs = Object.keys(slideshow.system_slideshow_map[systemID]);

			const contains = slide.component_uuids.find((componentUUID) => {
				return groupIDs.includes(componentUUID);
			});

			if (contains) {
				addOrRemove = false;
			}
		}

		const cb = () => {
			for (let slideshowIndex of slideshowIndexes) {
				let slide = galleryData[slideshowIndex];
				let groupIDs = Object.keys(slideshow.system_slideshow_map[systemID]);
	
				if (!pendingSlideshowChanges[slide.key]) {
					pendingSlideshowChanges[slide.key] = {
						oldSlide: JSON.parse(JSON.stringify(slide)),
					};
				}
	
				const contains = slide.component_uuids.find((componentUUID) => {
					return groupIDs.includes(componentUUID);
				});
	
				if (contains) {
					slide.component_uuids = slide.component_uuids.filter((componentUUID) => {
						return !groupIDs.includes(componentUUID);
					});
				} else {
					slide.component_uuids.push(groupIDs[0]);
				}
	
				galleryData[slideshowIndex] = slide;
				pendingSlideshowChanges[slide.key].newSlide = slide;
	
				//Logic to avoid storing "useless" pending changes
				pendingSlideshowChanges[slide.key].newSlide.component_uuids.sort();
				pendingSlideshowChanges[slide.key].oldSlide.component_uuids.sort();
	
				if (JSON.stringify(pendingSlideshowChanges[slide.key].newSlide) === JSON.stringify(pendingSlideshowChanges[slide.key].oldSlide)) {
					Reflect.deleteProperty(pendingSlideshowChanges, slide.key);
				}
			}

			this.setState({
				galleryData,
			});
	
			let shouldAlert = false;
			for (let slideshowIndex of slideshowIndexes) {
				if (galleryData[slideshowIndex].component_uuids.length === 0) {
					shouldAlert = true;
					break;
				}
			}
	
			if (shouldAlert) {
				alert('Slides with no assigned systems will not be displayed anywhere!');
			}
	
			app.setState({
				pendingSlideshowChanges,
			});
		};

		if (nonExclusiveSystemPresent) {
			this.setState({
				confirmModal:        true,
				confirmModalContent: (
					<>
						<p>{`You are ${addOrRemove ? 'assigning this slide to' : 'unassigning this slide from'} a content group that is shared by other system groups.
						Please be aware that this action will affect other systems.`}</p>
						<div className="confirm-modal-buttons">
							<button className="button" onClick={(e) => {
								e.preventDefault();
								this.setState({
									confirmModal:        false,
									confirmModalContent: '',
								}, () => {
									cb();
								});
							}}>Yes</button>
							<button className="button" onClick={(e) => {
								e.preventDefault();
								this.setState({
									confirmModal:        false,
									confirmModalContent: '',
								});
							}}>No</button>
						</div>
					</>
				),
			});
		} else {
			return cb();
		}
	}

	toggleContentGroups(toggle) {
		this.hideOrUnhideColumn(null, toggle ? 1 : 0, true, this.state.systemToSystemGroupObjects);
	}

	openView() {
		this.setState({
			showViewWindow: true,
		});
	}

	closeView() {
		this.setState({
			showViewWindow: false,
		});
	}
	getIdToUse(id) {
		let systems = [];
		let returnID = id;

		this.state.systemToSystemGroupObjects.forEach((systemToSystemGroupObject, i) => {
			if (systems.includes(systemToSystemGroupObject.systemID)) {
				return;
			}
			if (systemToSystemGroupObject.isBundled) {
				if (systems.includes(systemToSystemGroupObject.bundleID)) {
					return;
				}

				systems.push(systemToSystemGroupObject.bundleID);
			} else {

				systems.push(systemToSystemGroupObject.systemID);
			}
			let idToUse = Object.keys(systemToSystemGroupObject.componentUUIDs).filter((uuid) => {
				if (uuid === systemToSystemGroupObject.bundleID) {
					return false;
				}
				return true;
			})[0];
			if (id.split('.')[1] === systemToSystemGroupObject.bundleID) {
				returnID = 'groups.' + idToUse;
			}
		});
		return returnID;
	}

	hideOrUnhideColumn(idArg, checkedCount, toggleAll = false, groupUUIDs = []) {
		let columnsToHide = [ ...this.state.columnsToHide, ];
		let userUITableConfig = {
			...this.state.userUITableConfig,
		};

		// Handle toggling visibility for all columns
		if (toggleAll) {
			if (checkedCount) {
				// Unhide all columns by clearing the columnsToHide array
				columnsToHide = [];
			} else {
				// Hide all columns by adding their IDs to columnsToHide
				columnsToHide = groupUUIDs.map((group) => {
					return this.getIdToUse('groups.' + group.systemID);
				});
			}
		} else { // Handle individual column hiding/unhiding
			let id = checkedCount > 0 ? idArg : this.getIdToUse(idArg);

			if (!columnsToHide.includes(id)) { // Hide Column
				if (!(checkedCount === 1 || idArg === 'imgElm')) {
					columnsToHide.push(id);
					if (id !== idArg) columnsToHide.push(idArg);

					// Logic for adjusting column widths when hiding
					let addColWidth = 0;
					userUITableConfig.columns.forEach((col, i) => {
						if (col.id === id) {
							addColWidth = Math.floor(parseInt(col.width) / (userUITableConfig.columns.length - 1));
							userUITableConfig.columns[i].width = 0;
						}
					});

					let currentTotalWidth = 0;
					let visibleIndexArray = [];
					userUITableConfig.columns.forEach((col, i) => {
						if (col.id !== id && !columnsToHide.includes(col.id)) {
							visibleIndexArray.push(i);
							let colWidth = parseInt(userUITableConfig.columns[i].width);
							colWidth += addColWidth;
							userUITableConfig.columns[i].width = `${colWidth}`;
						}
						currentTotalWidth += parseInt(userUITableConfig.columns[i].width);
						if (i === userUITableConfig.columns.length - 1 && currentTotalWidth !== 100) {
							this.adjustRemainingWidths(visibleIndexArray, currentTotalWidth, userUITableConfig.columns);
						}
					});
				}
			} else { // Unhide Column
				columnsToHide = columnsToHide.filter((columnId) => {
					return columnId !== id && columnId !== idArg;
				});

				// Logic for adjusting column widths when unhiding
				let colWidthSum = this.calculateVisibleColumnsWidth(userUITableConfig.columns, columnsToHide);
				if (colWidthSum < 100) {
					this.redistributeWidths(colWidthSum, userUITableConfig.columns, columnsToHide);
				} else {
					// Reset to default configuration if all columns are visible
					userUITableConfig = JSON.parse(JSON.stringify(this.state.UITableConfig));
				}
			}
		}

		this.setState({
			columnsToHide,
			userUITableConfig,
		}, () => {
			this.setVariables();
		});
	}

	adjustRemainingWidths(visibleIndexArray, currentTotalWidth, columns) {
		if (visibleIndexArray.length === 1) {
			columns[visibleIndexArray[0]].width = 100;
		} else {
			let extraWidth = 100 - currentTotalWidth;
			let widthToAdd = extraWidth / visibleIndexArray.length;
			visibleIndexArray.forEach((index) => {
				columns[index].width = parseInt(columns[index].width) + widthToAdd;
			});
		}
	}

	calculateVisibleColumnsWidth(columns, columnsToHide) {
		let visibleColWidthSum = 0;
		columns.forEach((col) => {
			if (!columnsToHide.includes(col.id)) {
				visibleColWidthSum += parseInt(col.width);
			}
		});
		return visibleColWidthSum;
	}

	redistributeWidths(colWidthSum, columns, columnsToHide) {
		let visibleColumnIndexes = [];
		columns.forEach((col, i) => {
			if (!columnsToHide.includes(col.id) && parseInt(col.width) > 0) {
				visibleColumnIndexes.push(i);
			}
		});

		let widthToRedistribute = 100 - colWidthSum;
		let additionalWidthPerColumn = widthToRedistribute / visibleColumnIndexes.length;

		visibleColumnIndexes.forEach((index) => {
			columns[index].width = parseInt(columns[index].width) + additionalWidthPerColumn;
		});
	}

	getUITableConfig() {
		const {
			fd,
			settingMap,
		} = this.props;

		let UITableConfig = this.state.UITableConfig;


		if (!settingMap['ui_table_config']) {
			console.warn('account setup without ui_table_config');
			return;
		}

		if (Object.keys(UITableConfig).length === 0) {
			let stringJSON;
			let entityKeyToUpdate = '';

			if (settingMap['ui_table_config']) {
				let uiTableConfigUUID = settingMap['ui_table_config'].uuid;
				const entityKeys = [];

				for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
					if (m1Key.startsWith('component.')) {
						for (let m2Key of Object.keys(m1Data)) {
							if (m2Key === `setting.${uiTableConfigUUID}`) {
								entityKeys.push(`${m1Key}.${m2Key}.json`);
							}
						}
					}
				}

				entityKeys.forEach((entityKey) => {
					const fdValue = getFDValue(fd, entityKey);
					if (fdValue) {
						if (JSON.parse(fdValue).content_label === 'Slideshow') {
							stringJSON = getFDValue(fd, entityKey);
							entityKeyToUpdate = entityKey;
						}
					}
				});
			}

			try {
				UITableConfig = JSON.parse(stringJSON);
			} catch (err) {
				console.log('Could not parse stringJSON: ', stringJSON || undefined);

				// Set UITableConfig to default(?) values.
				UITableConfig = {
					'content_label': 'Slideshow',
					'columns':       [
						{
							'data':  'image',
							'label': 'Image',
							'order': 1,
							'width': '20',
						}, {
							'data':  'name',
							'label': 'Name',
							'order': 2,
							'width': '30',
						}, {
							'data':  'resolution',
							'label': 'Resolution',
							'order': 3,
							'width': '20',
						}, {
							'data':  'order',
							'label': 'Order',
							'order': 4,
							'width': '10',
						}, {
							'data':  'startDate',
							'id':    'startDate',
							'label': 'Start Date',
							'order': 5,
							'width': '10',
						}, {
							'data':  'endDate',
							'id':    'endDate',
							'label': 'End Date',
							'order': 6,
							'width': '10',
						}, {
							'data':  'startTime',
							'id':    'startTime',
							'label': 'Start Time',
							'order': 7,
							'width': '0',
						}, {
							'data':  'endTime',
							'id':    'endTime',
							'label': 'End Time',
							'order': 8,
							'width': '0',
						}, {
							'data':  'daysCol',
							'id':    'daysCol',
							'label': 'Days',
							'order': 9,
							'width': '0',
						},
					],
					'sections': [
						{
							'label': 'Data',
							'width': '60',
						}, {
							'label':  'Content Groups',
							'width':  '3',
							'hidden': false,
						},
					],
				};
			}

			let columnsToHide = this.state.columnsToHide;
			UITableConfig.columns.forEach((column, i) => {
				switch (column.data) {
				case 'image':
					if (parseInt(column.width) === 0 && !columnsToHide.includes('imgElm')) {
						columnsToHide.push('imgElm');
					}
					UITableConfig.columns[i]['id'] = 'imgElm';
					break;
				case 'name':
					if (parseInt(column.width) === 0 && !columnsToHide.includes('description')) {
						columnsToHide.push('description');
					}
					UITableConfig.columns[i]['id'] = 'description';
					break;
				case 'start date':
					if (parseInt(column.width) === 0 && !columnsToHide.includes('startDate')) {
						columnsToHide.push('startDate');
					}
					UITableConfig.columns[i]['id'] = 'startDate';
					break;
				case 'end date':
					if (parseInt(column.width) === 0 && !columnsToHide.includes('endDate')) {
						columnsToHide.push('endDate');
					}
					UITableConfig.columns[i]['id'] = 'endDate';
					break;
				case 'start time':
					if (parseInt(column.width) === 0 && !columnsToHide.includes('startTime')) {
						columnsToHide.push('startTime');
					}
					UITableConfig.columns[i]['id'] = 'startTime';
					break;
				case 'end time':
					if (parseInt(column.width) === 0 && !columnsToHide.includes('endTime')) {
						columnsToHide.push('endTime');
					}
					UITableConfig.columns[i]['id'] = 'endTime';
					break;
				case 'days':
					if (parseInt(column.width) === 0 && !columnsToHide.includes('daysCol')) {
						columnsToHide.push('daysCol');
					}
					UITableConfig.columns[i]['id'] = 'daysCol';
					break;
				case 'resolution':
					if (parseInt(column.width) === 0 && !columnsToHide.includes('resolution')) {
						columnsToHide.push('resolution');
					}
					UITableConfig.columns[i]['id'] = 'resolution';
					break;
				case 'order':
					if (parseInt(column.width) === 0 && !columnsToHide.includes('order')) {
						columnsToHide.push('order');
					}
					UITableConfig.columns[i]['id'] = 'order';
					break;
				default:
					break;
				}
			});

			this.setState({
				columnsToHide,
				UITableConfig,
				userUITableConfig: JSON.parse(JSON.stringify(UITableConfig)),
				entityKeyToUpdate,
			}, () => {
				this.setVariables();
			});
		}
	}

	decimal_or_1(value) {
		let num = parseInt(value);
		if (num === 100) {
			return 1;
		}
		return num / 100;
	}

	exportCSV() {
		const {
			fd,
			accountUUID,
		} = this.props;

		const {
			galleryData,
			systemName,
			systemToSystemGroupObjects,
		} = this.state;

		const accountName = fd[`account.${accountUUID}`].display_name;
		const accountNameWithUnderscores = accountName.split(' ').join('_');

		const columnHeaders = [];

		const componentToSystemsMap = {};

		systemToSystemGroupObjects
			.sort((a, b) => {
				if (a.isBundled) {
					return a.bundleName > b.bundleName ? 1 : -1;
				}
				return a.systemDisplayName > b.systemDisplayName ? 1 : -1;
			})
			.forEach((systemToSystemGroupObject) => {
				Object.keys(systemToSystemGroupObject.componentUUIDs).forEach((componentUUID) => {
					componentToSystemsMap[componentUUID] = componentToSystemsMap[componentUUID] || [];
					if (!componentToSystemsMap[componentUUID].includes(systemToSystemGroupObject.systemDisplayName)) {
						if (systemToSystemGroupObject.isBundled) {
							componentToSystemsMap[componentUUID].push(systemToSystemGroupObject.bundleName);
						} else {
							componentToSystemsMap[componentUUID].push(systemToSystemGroupObject.systemDisplayName);
						}
					}
				});
			});

		columnHeaders.push(`"Name"`);
		columnHeaders.push(`"Start Date"`);
		columnHeaders.push(`"End Date"`);
		columnHeaders.push(`"Start Time"`);
		columnHeaders.push(`"End Time"`);
		columnHeaders.push(`"Days"`);
		columnHeaders.push(`"Order"`);
		columnHeaders.push(`"Resolution"`);
		columnHeaders.push(`"Duration"`);
		columnHeaders.push(`"Systems"`);

		const bodyRows = [];

		galleryData.forEach((slide) => {
			const row = [];
			row.push(`"${slide.description || ''}"`);
			row.push(`"${slide.startDate || ''}"`);
			row.push(`"${slide.endDate || ''}"`);
			row.push(`"${slide.startTime || ''}"`);
			row.push(`"${slide.endTime || ''}"`);
			const days = slide.days.map((day) => {
				switch (day) {
				case 0:
					return 'Sunday';
				case 1:
					return 'Monday';
				case 2:
					return 'Tuesday';
				case 3:
					return 'Wednesday';
				case 4:
					return 'Thursday';
				case 5:
					return 'Friday';
				case 6:
					return 'Saturday';
				default:
					return '';
				}
			}).join(', ');
			row.push(`"${days || ''}"`);
			row.push(`"${slide.order || ''}"`);
			row.push(`"${slide.resolution || ''}"`);
			row.push(`"${slide.duration || ''}"`);
			const systems = slide.component_uuids.map((componentUUID) => {
				return componentToSystemsMap[componentUUID].join(', ');
			});
			row.push(`"${systems || ''}"`);
			bodyRows.push(row);
		});

		for (let shift = 0; shift < bodyRows.length; shift += 5000) {
			let csvContent = 'data:text/csv;charset=utf-8,';

			const rows = [];

			rows.push(columnHeaders);

			rows.push(...bodyRows.slice(0 + shift, 5000 + shift));

			rows.forEach((rowArray) => {
				let row = rowArray.join(',');
				csvContent += row + '\r\n';
			});

			let encodedUri = encodeURI(csvContent);
			encodedUri = encodedUri.replaceAll('#', '%23');
			let link = document.createElement('a');
			link.setAttribute('href', encodedUri);

			link.setAttribute('download', `${accountNameWithUnderscores}_${systemName}_${(shift / 5000) + 1}.csv`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	setVariables() {
		const {
			userUITableConfig,
		} = this.state;

		let root = document.documentElement;

		let sideNavWidth = parseInt(getComputedStyle(document.body).getPropertyValue('--sideNavWidth'));
		let slideshowWidth = 100 - sideNavWidth;

		// SETTINGS: loop over these in the future
		let numGroupColsShown = userUITableConfig.sections[1].width;

		let slideshowDataColumnsPercentage = `.${userUITableConfig.sections[0].width}`;

		let slideshowDataWidth = slideshowWidth * slideshowDataColumnsPercentage;
		let slideshowContentGroupsTotalWidth = slideshowWidth * (1 - slideshowDataColumnsPercentage);

		let slideshowContentGroupColWidth = slideshowContentGroupsTotalWidth / numGroupColsShown;

		const slideshowWidthCols = userUITableConfig.columns
			.map((column) => {
				return this.decimal_or_1(column.width) * slideshowDataWidth;
			});

		slideshowWidthCols.forEach((width, i) => {
			root.style.setProperty(`--slideshowWidthCol${i}`, `${width}vw`);
		});

		// Replace this code for Listings to be dynamically generated and passed to SlideshowTable.js
		root.style.setProperty('--slideshowDataColumnsPercentage', `.${userUITableConfig.sections[0].width}`);
		root.style.setProperty('--numGroupColsShown', userUITableConfig.sections[1].width);
		root.style.setProperty('--slideshowContentGroupColWidth', `${slideshowContentGroupColWidth}vw`);
	}

	getColumns(showAdvanced, filterTerms = '') {
		let {
			columnsToHide,
			UITableConfig,
		} = this.state;
		let data = [];
		let contentGroups = [];
		let groupNames = [];
		let checkedDataColumnCount = 0;

		this.dataKeys.forEach((dataKey, i) => {
			let columnConfig = {};
			if (showAdvanced) {
				columnConfig = UITableConfig.columns[i];
			}
			let checked = !columnsToHide.includes(this.columnIDs[i]);
			if (checked) {
				checkedDataColumnCount++;
			}

			let disabledCheckmark = '';
			if (dataKey === 'Image') {
				disabledCheckmark = 'disabledCheckmark';
			}

			let individualWidthInput = (
				<div className={style.devRow}>
					<div className={`${style.columnLabel} ${style.inputLabel}`}>
						{dataKey}
					</div>
					<input className={`${style.input} ${style.inputSmall}`}
						id={`${dataKey}HeaderWidth`}
						defaultValue={columnConfig?.width}
						onBlur={(e) => {
							this.checkColumnWidth();
						}}
					></input>
					<div>%</div>
				</div>
			);

			let elm = (
				<div className={style.tagElement} key={`${dataKey}_${i}_${i}`}>
					<div key={`${dataKey}_${i}`} className={`${style.checkbox} ${style[disabledCheckmark]}`} onClick={(e) => {
						e.preventDefault();
						this.hideOrUnhideColumn(this.columnIDs[i], checkedDataColumnCount);
					}}>
						<CustomCheckbox checked={checked}/>
					</div>
					{!showAdvanced ? dataKey : individualWidthInput}
				</div>
			);

			data.push(elm);

			if (i === (showAdvanced && this.dataKeys.length - 1)) {
				let directions = (
					<div
						key={`directions_${i}`}
						className={this.state.colsEqual100 === true ? style.colDirectionsCont : `${style.colDirectionsContError} ${style.colDirectionsCont}`}
					>
						Columns must = 100%
					</div>
				);
				data.push(directions);
			}
		});

		let groupsInput = (<div className={style.sectionHeaderText}>Content Groups</div>);
		let widthInput = null;
		let sections = UITableConfig.sections;
		if (showAdvanced) {
			let groupWidth = '';
			let dataWidth = '';
			let groupsIndex = 0;
			let dataIndex = 0;

			sections.forEach((section, i) => {
				if (section.label === 'Content Groups') {
					groupWidth = section.width;
					groupsIndex = i;
				}
				if (section.label === 'Data') {
					dataWidth = section.width;
					dataIndex = i;
				}
			});
			groupsInput = (
				<React.Fragment>
					<div className={style.sectionHeader}>
						<div className={style.sectionHeaderText}>Content Groups</div>
						<div className={style.contentGroupSettings}>
							<div className={style.contentGroupWidth}>
								<input className={`${style.input} ${style.inputSmall}`}
									id='groupColumnWidth'
									defaultValue={groupWidth}
									onBlur={(e) => {
										UITableConfig.sections[groupsIndex].width = e.target.value;
										this.setState({
											UITableConfig,
										});
									}}
								></input>
							</div>
						</div>
					</div>
				</React.Fragment>
			);
			widthInput = (
				<div className={style.dataColumnWidth}>
					<input className={`${style.input} ${style.inputSmall}`}
						id='dataColumnWidth'
						defaultValue={dataWidth}
						onBlur={(e) => {
							UITableConfig.sections[dataIndex].width = e.target.value;
							this.setState({
								UITableConfig,
							});
						}}
					></input>
					<div>%</div>
				</div>
			);
		}

		contentGroups.push(groupsInput);
		this.state.systemToSystemGroupObjects
			.sort((groupA, groupB) => {
				if ((groupA?.systemDisplayName || '').toLowerCase() > (groupB?.systemDisplayName || '').toLowerCase()) {
					return 1;
				}

				return -1;
			})
			.forEach((group, i) => {
				let groupName = group.bundleName ? group.bundleName : group.systemDisplayName;
				if (groupNames.includes(groupName)) {
					return;
				}
				groupNames.push(groupName);
				let idToUse = group.systemID;
				let groupID = `groups.${idToUse}`;
				let checked = !columnsToHide.includes(groupID);

				let elm = (
					<div className={style.tagElement} key={`${groupID}_${i}_${i}`}>
						<div key={`${groupID}_${i}`} className={style.checkbox} onClick={(e) => {
							e.preventDefault();
							this.hideOrUnhideColumn(groupID);
						}}>
							<CustomCheckbox checked={checked}/>
						</div>
						{groupName}
					</div>
				);

				if (groupName && groupName.toLowerCase().includes(filterTerms.toLowerCase())) {
					contentGroups.push(elm);
				}
			});
		let saveButton = (
			<button
				className='button'
				onClick={(e) => {
					e.preventDefault();
					this.saveCallback();
				}}
			>
				<CustomIcon icon='save'/> Save
			</button>
		);

		return (
			<div className={style.viewWindowContent}>
				<div className={style.dataColumn}>
					<div className={style.sectionHeader}>
						<div className={style.sectionHeaderText}>Data</div>
						{showAdvanced ? widthInput : null}
					</div>
					{data}
					{showAdvanced &&
						<div className={style.saveSettingsButton}>
							{saveButton}
						</div>
					}
				</div>
				<div className={style.contentGroupsColumn}>
					{contentGroups}
				</div>
			</div>
		);
	}

	checkColumnWidth() {
		let totalWidth = 0;
		this.dataKeys.forEach((dataKey) => {
			let columnMetaData = document.getElementById(`${dataKey}HeaderWidth`);
			let columnWidth = columnMetaData.value || '0';
			totalWidth += parseInt(columnWidth);
		});
		let colsEqual100 = totalWidth === 100;
		this.setState({
			colsEqual100,
		});
	}

	saveCallback() {
		const {
			app,
		} = this.props;

		const {
			UITableConfig,
			columnsToHide,
		} = this.state;

		let savedJSON = [];
		let finalJSON = {};

		let totalWidth = 0;
		this.dataKeys.forEach((dataKey, i) => {
			let columnMetaData = document.getElementById(`${dataKey}HeaderWidth`);
			let columnWidth = columnMetaData.value;
			totalWidth += parseInt(columnWidth);
			let json = {
				'data':  `${dataKey.toLowerCase()}`,
				'label': `${dataKey}`,
				'order': i + 1,
				'width': columnWidth,
			};
			savedJSON.push(json);
		});
		if (totalWidth !== 100) {
			this.setState({
				colsEqual100: false,
			});
			return;
		}
		finalJSON['content_label'] = 'Slideshow';
		finalJSON['columns'] = savedJSON;
		finalJSON['sections'] = UITableConfig.sections;

		finalJSON.columns.forEach((column, i) => {
			switch (column.data) {
			case 'image':
				finalJSON.columns[i]['id'] = 'imgElm';
				break;
			case 'name':
				finalJSON.columns[i]['id'] = 'description';
				if (finalJSON.columns[i]['width'] === '0') {
					if (!columnsToHide.includes('description')) {
						columnsToHide.push('description');
					}
				}
				break;
			case 'start date':
				finalJSON.columns[i]['id'] = 'startDate';
				if (finalJSON.columns[i]['width'] === '0') {
					if (!columnsToHide.includes('startDate')) {
						columnsToHide.push('startDate');
					}
				}
				break;
			case 'end date':
				finalJSON.columns[i]['id'] = 'endDate';
				if (finalJSON.columns[i]['width'] === '0') {
					if (!columnsToHide.includes('endDate')) {
						columnsToHide.push('endDate');
					}
				}
				break;
			case 'start time':
				finalJSON.columns[i]['id'] = 'startTime';
				if (finalJSON.columns[i]['width'] === '0') {
					if (!columnsToHide.includes('startTime')) {
						columnsToHide.push('startTime');
					}
				}
				break;
			case 'end time':
				finalJSON.columns[i]['id'] = 'endTime';
				if (finalJSON.columns[i]['width'] === '0') {
					if (!columnsToHide.includes('endTime')) {
						columnsToHide.push('endTime');
					}
				}
				break;
			case 'days':
				finalJSON.columns[i]['id'] = 'daysCol';
				if (finalJSON.columns[i]['width'] === '0') {
					if (!columnsToHide.includes('daysCol')) {
						columnsToHide.push('daysCol');
					}
				}
				break;
			case 'resolution':
				finalJSON.columns[i]['id'] = 'resolution';
				if (finalJSON.columns[i]['width'] === '0') {
					if (!columnsToHide.includes('resolution')) {
						columnsToHide.push('resolution');
					}
				}
				break;
			case 'order':
				finalJSON.columns[i]['id'] = 'order';
				if (finalJSON.columns[i]['width'] === '0') {
					if (!columnsToHide.includes('order')) {
						columnsToHide.push('order');
					}
				}
				break;
			default:
				break;
			}
		});
		this.setState({
			UITableConfig:     finalJSON,
			userUITableConfig: JSON.parse(JSON.stringify(finalJSON)),
			colsEqual100:      true,
			columnsToHide,
		}, async () => {
			this.setVariables();
			await app.updateViewData(JSON.stringify(finalJSON), this.state.entityKeyToUpdate);
		});
	}

	render() {
		const galleryData = this.state.galleryData || [];

		const {
			app,
			fd,
			accountUUID,
			systemGroupUUID,
		} = this.props;

		let finalWidths = [];
		if (this.state.userUITableConfig.sections) {
			let sideNavWidth = parseInt(getComputedStyle(document.body).getPropertyValue('--sideNavWidth'));
			let slideshowWidth = 100 - sideNavWidth;
			let slideshowDataColumnsPercentage = `.${this.state.userUITableConfig.sections[0].width}`;
			let slideshowDataWidth = slideshowWidth * slideshowDataColumnsPercentage;
			this.state.userUITableConfig.columns.forEach((col) => {
				finalWidths.push(this.decimal_or_1(col.width) * slideshowDataWidth);
			});
		}

		let confirmModal = '';
		if (this.state.confirmModal === true) {
			confirmModal = (
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.confirmModalContent}
					</div>
				</div>
			);
		}

		const rowKeys = [];

		const filteredRows = galleryData.filter((row) => {
			if (rowKeys.includes(row.key)) {
				return false;
			}
			rowKeys.push(row.key);
			return true;
		});

		return (
			<>
				{confirmModal}
				<>
					<ViewWindow
						show={this.state.showViewWindow}
						close={this.closeView.bind(this)}
						getColumns={this.getColumns.bind(this)}
						customWidth={'50vw'}
						toggleContentGroups={this.toggleContentGroups.bind(this)}
						showViewAllButtons={this.state.parentsPrimaryColumnData}
						isSlideshow={true}
					/>

					<SlideshowTable
						array={filteredRows}
						app={app}
						fd={fd}
						accountUUID={accountUUID}
						systemGroupUUID={systemGroupUUID}
						addObject={this.addObject.bind(this)}
						editObject={this.editObject.bind(this)}
						addIframe={this.addIframe.bind(this)}
						copyImageFromLibrary={this.copyImageFromLibrary.bind(this)}
						buildPelicanData={this.props.buildPelicanData}
						deleteObjects={this.handleDeleteItems.bind(this)}
						systemToSystemGroupObjects={this.state.systemToSystemGroupObjects}
						checkGroupForComponentRelation={this.checkGroupForComponentRelation.bind(this)}
						toggleGroup={this.toggleGroup.bind(this)}
						columnsToHide={this.state.columnsToHide}
						finalWidths={finalWidths}
						openView={this.openView.bind(this)}
						componentUUID={this.state.componentUUID}
						exportCSV={this.exportCSV.bind(this)}
					/>
				</>
			</>
		);
	}
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['custom.vulture.slideshow'] = SlideshowView;
export default SlideshowView;