import React from 'react';

import CustomIcon from 'Crow/Form/Field/Custom/Common/CustomIcon/CustomIcon.js';

import style from 'Vulture/View/Login.module.css';
import {
	broncoURL,
} from 'Vulture/ENV';

class ResetPasswordView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			pass:           '',
			confirmPass:    '',
			status:         '',
			statusMsg:      [],
			buttonDisabled: false,
		};
	}

	isValid() {
		let specialCharRegex = new RegExp('^(?=.*[!@#$%^&*])');
		let numRegex = new RegExp('^(?=.*[0-9])');
		let uppercaseRegex = new RegExp('^(?=.*[A-Z])');
		let lowercaseRegex = new RegExp('^(?=.*[a-z])');

		// Invalid status
		if (this.state.pass !== this.state.confirmPass) {
			this.setState({
				status:    'invalid',
				statusMsg: [ 'Confirm password does not match.', ],
			});
			return;
		}

		let status = 'valid';
		let msg = [];

		if (!specialCharRegex.test(this.state.pass)) {
			status = 'invalid';
			msg.push('Password must contain at least one special character !@#$%&*');
		}

		if (!numRegex.test(this.state.pass)) {
			status = 'invalid';
			msg.push('Password must contain at least one number.');
		}

		if (!uppercaseRegex.test(this.state.pass)) {
			status = 'invalid';
			msg.push('Password must contain at least one uppercase character.');
		}

		if (!lowercaseRegex.test(this.state.pass)) {
			status = 'invalid';
			msg.push('Password must contain at least one lowercase character.');
		}

		if (this.state.pass.length < 8) {
			status = 'invalid';
			msg.push('Password must be a minimum of 8 characters.');
		}

		if (status === 'invalid') {
			this.setState({
				status,
				statusMsg: msg,
			});
			return;
		}

		// Warning status
		if (this.state.pass.length < 12) {
			status = 'warning';
			msg.push('Password Strength: Weak');
		}

		if (status === 'warning') {
			this.setState({
				status,
				statusMsg: msg,
			});
			return;
		}

		// Valid status
		this.setState({
			status,
			statusMsg: [ 'Password Strength: Strong', ],
		});
	}

	async resetPassword() {
		const {
			app,
		} = this.props;

		if (this.state.status === 'invalid') {
			return;
		}

		const params = app.params();
		const user = params.user;
		const auth = params.auth;

		const url = `${broncoURL}/password/reset`;

		try {
			const res = await fetch(url, {
				method:  'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					user,
					auth,
					pass: this.state.pass,
				}),
			});

			if (!res.status === 200) {
				this.setState({
					status:    'invalid',
					statusMsg: [ 'Password reset was already used, please request a new password reset link.', ],
				});

				return;
			}

			const data = await res.json();
			if (data.error) {
				if (data.statusMsg) {
					this.setState({
						status:    'invalid',
						statusMsg: data.statusMsg,
					});
				} else {
					this.setState({
						status:    'invalid',
						statusMsg: [ 'Password reset was already used, please request a new password reset link.', ],
					});
				}

				return;
			}

			this.setState({
				status:         'valid',
				statusMsg:      [ 'Your password has been successfully reset.', ],
				buttonDisabled: true,
			});
		} catch (err) {
			console.error(err);
			this.setState({
				status:    'invalid',
				statusMsg: [ 'Password reset was already used, please request a new password reset link.', ],
			});
		}
	}

	render() {
		const {
			app,
		} = this.props;

		let msgStyle = '';
		switch (this.state.status) {
		case 'invalid':
			msgStyle = style.error;
			break;
		case 'warning':
			msgStyle = style.warning;
			break;
		case 'valid':
			msgStyle = style.valid;
			break;
		default:
			msgStyle = '';
		}

		let buttonClass = '';
		if (this.state.buttonDisabled) {
			buttonClass = `no-click ${style.buttonDisabled}`;
		}

		let title = (<h1>Reset Password</h1>);
		let text = (<p>Please enter a new password.</p>);
		let buttonText = 'Reset Password';
		if (this.props.isRegister) {
			title = (<div className={style.welcomeImage}>
				<span>Welcome to</span>
				<img src="https://ts-public-images.s3.amazonaws.com/TouchSourceSPARK_logo_FINAL_TS_Spark_PX.png" alt="The TouchSource logo"/>
			</div>);
			text = (<p>Setup your password.</p>);
			buttonText = 'Setup Password';
		}


		return (
			<form
				onSubmit={(e) => {
					e.preventDefault();
					this.resetPassword();
				}}
			>
				{title}
				{text}
				<div className={style.password}>
					<CustomIcon icon='key' />
					<input
						placeholder='Password'
						type='password'
						onChange={(e) => {
							this.setState({
								pass: e.target.value,
							}, () => {
								this.isValid();
							});
						}}
						value={this.state.pass}
					/>
				</div>
				<div className={style.password}>
					<CustomIcon icon='key' />
					<input
						placeholder='Confirm Password'
						type='password'
						onChange={(e) => {
							this.setState({
								confirmPass: e.target.value,
							}, () => {
								this.isValid();
							});
						}}
						value={this.state.confirmPass}
					/>
				</div>

				<div className={`${msgStyle}`}>
					{this.state.status === 'invalid' ?
						<CustomIcon icon='exclamation-triangle' /> :
						null
					}

					{this.state.statusMsg.map((msg, i) => {
						return (
							<div key={`msg_${i}`}>
								{msg}
							</div>
						);
					})}
				</div>

				<button type='submit' className={buttonClass}>
					<CustomIcon icon='lock' /> {buttonText}
				</button>

				<div
					className={style.link}
					onClick={() => {
						app.redirect({
							to: '/login',
						});

						// Go back to the login screen
						this.props.setMode('login');

						this.setState({
							pass:        '',
							confirmPass: '',
							status:      '',
							statusMsg:   [],
						});
					}}
				>&larr; Go back</div>
			</form>
		);
	}
}

export default ResetPasswordView;