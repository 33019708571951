
import React from 'react';


import {
	v4 as uuidv4,
} from 'uuid';
import 'jsoneditor/dist/jsoneditor.css';

import {
	mergeFD,
} from 'Crow/Common/Crow.js';

export class Clipboard extends React.Component {
	constructor(props) {
		super(props);
	}

	filterFD(unfilteredFD) {
		let filteredFD = {};
		for (let [ m1Key, m1Data, ] of Object.entries(unfilteredFD)) {
			if (m1Key === 'clone_parent_id') {
				continue;
			}

			if (m1Key.startsWith('component.')) {
				let s = JSON.stringify(m1Data);
				if (!~s.indexOf('component_type.')) {
					continue;
				}
			}

			filteredFD[m1Key] = m1Data;
		}

		return filteredFD;
	}

	_copy(fd, copiedFD, m1Key) {
		if (fd[m1Key].type === 'pelican_slide') {
			return;
		}

		copiedFD[m1Key] = this.filterFD(fd[m1Key]);
		for (let [ m2Key, m2Data, ] of Object.entries(fd[m1Key])) {
			copiedFD[m1Key][m2Key] = m2Data;

			if (~m2Key.indexOf('.')) {
				if (!fd[m2Key]) {
					continue;
				}

				this._copy(fd, copiedFD, m2Key);
			}
		}
	}

	copy(templateSkeletonM1Key, entityType) {
		const {
			accountUUID,
			templateSkeleton,
		} = this.props;

		const {
			activeEntity,
			flatData,
		} = templateSkeleton.state;

		if (entityType === 'top_level_condor') {
			let childM1Keys = Object.keys(activeEntity).filter((m1Key) => {
				return m1Key.startsWith('component.');
			});

			for (let m1Key of childM1Keys) {
				this.copy(m1Key, activeEntity[m1Key].type || '');
			}

			return;
		}

		let clipboard = JSON.parse(sessionStorage.template_skeleton_clipboard || '{}');
		if (!Array.isArray(clipboard.entries)) {
			clipboard = {
				accountUUID,
				listings: false,
				entries:  [],
			};
		}

		let copiedFD = {};
		this._copy(flatData, copiedFD, templateSkeletonM1Key);
		clipboard.entries.push({
			root: templateSkeletonM1Key,
			fd:   copiedFD,
		});

		for (let entry of clipboard.entries) {
			entry.fd[entry.root].parent = null;

			for (let [ childM1Key, m1Data, ] of Object.entries(entry.fd)) {
				let parentM1Key = m1Data.parent;
				if (parentM1Key) {
					if (!entry.fd[parentM1Key]) {
						entry.fd[parentM1Key] = {};
					}
					entry.fd[parentM1Key][childM1Key] = {};
				}
			}

			if (~(JSON.stringify(entry.fd).indexOf('listing_collection.'))) {
				clipboard.listings = true;
			}
		}

		this.clearSlideEntries(clipboard);

		sessionStorage.template_skeleton_clipboard = JSON.stringify(clipboard);

		templateSkeleton.setState({
			clipboard: true,
		});
	}

	clearSlideEntries(clipboard) {
		clipboard.entries.forEach((entry) => {
			const fd = {
				...entry.fd,
			};

			Object.entries(entry.fd).forEach(([ m1Key, m1Val, ]) => {
				if (m1Val.description && (m1Val.description === 'brood' ||
					m1Val.description.startsWith('pelican') ||
					m1Val.description.startsWith('monarch') ||
					m1Val.description.startsWith('peacock') ||
					m1Val.description.startsWith('entertainment') ||
					m1Val.description === 'broadsign_feed')) {
					Reflect.deleteProperty(entry.fd, m1Key);
				}
			});

			Object.keys(entry.fd[entry.root]).forEach((m1Key) => {
				if (m1Key.startsWith('component.')) {
					if (fd[m1Key]?.description === 'brood') {
						Reflect.deleteProperty(entry.fd[entry.root], m1Key);
					}
				}
			});
		});
	}

	removeListings(entry) {
		for (let [ m1Key, m1Data, ] of Object.entries(entry.fd)) {
			for (let m2Key of Object.keys(m1Data)) {
				if (m2Key.startsWith('listing_column_group.')) {
					Reflect.deleteProperty(entry.fd[m1Key], m2Key);
				}

				if (m2Key.startsWith('listing_config.')) {
					Reflect.deleteProperty(entry.fd[m1Key], m2Key);
				}

				if (m2Key.startsWith('listing.')) {
					Reflect.deleteProperty(entry.fd[m1Key], m2Key);
				}
			}

			if (m1Key.startsWith('listing_column_group.')) {
				Reflect.deleteProperty(entry.fd, m1Key);
			}

			if (m1Key.startsWith('listing_config.')) {
				Reflect.deleteProperty(entry.fd, m1Key);
			}
		}
	}

	paste(removeListings = true) {
		const {
			accountUUID,
			templateSkeletonM1Key,
			templateSkeleton,
		} = this.props;

		const {
			flatData,
		} = templateSkeleton.state;

		let clipboard = JSON.parse(sessionStorage.template_skeleton_clipboard || '{}');
		if (!Array.isArray(clipboard.entries)) {
			return;
		}

		let accountChanged = false;
		if (clipboard.accountUUID !== accountUUID) {
			accountChanged = true;
		}

		if (accountChanged || removeListings) {
			for (let entry of clipboard.entries) {
				this.removeListings(entry);
			}
		}

		for (let entry of clipboard.entries) {
			let uuids = {};
			for (let m1Key of Object.keys(entry.fd)) {
				if (m1Key.startsWith('component.')) {
					uuids[m1Key.split('.')[1]] = `_${uuidv4()}`;
				}

				if (m1Key.startsWith('listing_collection.')) {
					uuids[m1Key.split('.')[1]] = `_${uuidv4()}`;
				}

				if (m1Key.startsWith('listing_group.')) {
					uuids[m1Key.split('.')[1]] = `_${uuidv4()}`;
				}

				if (m1Key.startsWith('listing_column_group.')) {
					uuids[m1Key.split('.')[1]] = `_${uuidv4()}`;
				}
			}

			let s = JSON.stringify(entry.fd);
			for (let [ oldUUID, newUUID, ] of Object.entries(uuids)) {
				s = s.replace(new RegExp(oldUUID, 'g'), newUUID);
			}
			entry.fd = JSON.parse(s);

			entry.root = `component.${uuids[entry.root.split('.')[1]]}`;
		}

		let pasteFD = JSON.parse(JSON.stringify(flatData));
		for (let entry of clipboard.entries) {
			pasteFD[templateSkeletonM1Key][entry.root] = {};
			pasteFD = mergeFD(pasteFD, entry.fd);
			pasteFD[entry.root].parent = templateSkeletonM1Key;
		}

		templateSkeleton.setState({
			flatData: pasteFD,
		}, () => {
			// this.clear();
		});
	}

	clear() {
		const {
			templateSkeleton,
		} = this.props;

		sessionStorage.template_skeleton_clipboard = '{}';

		templateSkeleton.setState({
			clipboard: false,
		});
	}

	copied(m1Key) {
		let clipboard = JSON.parse(sessionStorage.template_skeleton_clipboard || '{}');

		return Boolean(~clipboard.entries.map((entry) => {
			return entry.root;
		}).indexOf(m1Key));
	}

	render() {
		const {
			app,
			templateSkeletonM1Key,
			entity,
			templateSkeleton,
		} = this.props;

		const {
			clipboard: clipboardHasEntries,
		} = templateSkeleton.state;

		let clipboard = JSON.parse(sessionStorage.template_skeleton_clipboard || '{}');

		let copyEnabled = false;
		if (entity.type === 'top_level_condor' || entity.type === 'layout_group') {
			copyEnabled = true;
		}

		let copyEnabledComponents = [
			// 'animated_scroller',
			'emergency_message',
			// 'info_box_group',
			// 'info_box',
			// 'listing_collection',
			// 'listing_scroller',
			'mobile',
			// 'navigation_button',
			'navigation',
		];

		if (~copyEnabledComponents.indexOf(entity.type)) {
			copyEnabled = true;
		}

		let pasteEnabled = false;
		if (clipboardHasEntries && (entity.type === 'top_level_condor' || entity.type === 'layout_group')) {
			pasteEnabled = true;
		}

		if (!copyEnabled && !pasteEnabled) {
			return '';
		}

		let hasListings = false;
		if (clipboardHasEntries) {
			if (this.copied(templateSkeletonM1Key)) {
				return (
					<strong>copied</strong>
				);
			}

			if (clipboard.listings) {
				hasListings = true;
			}
		}

		let copyButtonLabel = 'copy';
		if (clipboardHasEntries) {
			copyButtonLabel = `copy(${Object.keys(clipboard.entries).length})`;
		}
		if (entity.type === 'top_level_condor') {
			copyButtonLabel = 'copy children';
		}

		let pasteButton1Label = 'paste';
		let pasteButton2Label = 'paste';
		if (clipboardHasEntries) {
			pasteButton1Label = `paste(${Object.keys(clipboard.entries).length})`;
			pasteButton2Label = `paste(${Object.keys(clipboard.entries).length})`;
		}
		if (hasListings) {
			pasteButton1Label = `${pasteButton1Label} -L`;
			pasteButton2Label = `${pasteButton2Label} +L`;
		}

		let setting = app.state.settingMap['custom_tlc_builder_name'];
		let pasteHoverText = (clipboard.entries || []).map((entry) => {
			try {
				return entry.fd[entry.root][`setting.${setting.uuid}`][setting.type];
			} catch (err) {
				return entry.fd[entry.root].display_name;
			}
		}).join('\n');

		return (
			<div
				style={{
					marginTop: '7px',
					display:   'flex',
				}}
			>
				{pasteEnabled &&
					<>
						<button
							className={`button`}
							onClick={this.clear.bind(this)}
						>
							X
						</button>
						&nbsp;
					</>
				}

				{copyEnabled &&
					<button
						className={`button`}
						onClick={this.copy.bind(this, templateSkeletonM1Key, entity.type)}
					>
						{copyButtonLabel}
					</button>
				}

				{pasteEnabled &&
					<>
						&nbsp;
						<button
							className={`button`}
							onClick={this.paste.bind(this, true)}
							title={pasteHoverText}
						>
							{pasteButton1Label}
						</button>

						{hasListings &&
							<>
								&nbsp;
								<button
									className={`button`}
									onClick={this.paste.bind(this, false)}
									title={pasteHoverText}
								>
									{pasteButton2Label}
								</button>
							</>
						}
					</>
				}
			</div>
		);
	}
}