import React from 'react';

import Select from 'react-select';
import CustomIcon from '../Common/CustomIcon/CustomIcon';

import style from './Listings.module.css';

import {
	createBuildings,
	deleteBuildings,
} from 'Vulture/Buildings';

class BuildingsModal extends React.Component {
	constructor(props) {
		super(props);

		let selectedSystemGroup = {
			label: 'Select System Group',
			value: '',
		};

		this.systemGroups = [
			...Object.entries(props.tableSettings)
				.filter(([ m1Key, ]) => {
					if (!props.systemGroupUUID) {
						return true;
					}
					return m1Key === `system_group.${props.systemGroupUUID}`;
				}).map(([ m1Key, display_name, ]) => {
					const m1UUID = m1Key.split('.')[1];

					const option = {
						label: display_name,
						value: m1UUID,
					};

					if (m1UUID === props.originalSystemGroupUUID) {
						selectedSystemGroup = option;
					}

					return option;
				}),
		];

		if (this.systemGroups.length === 1) {
			selectedSystemGroup = this.systemGroups[0];
		}

		this.state = {
			buildingName: props.buildingName || '',
			selectedSystemGroup,
		};
	}

	componentDidMount() {
	}

	saveChanges() {
		const {
			app,
			accountUUID,
			buildingM1,
			isEdit,
			closeModal,
		} = this.props;

		const {
			buildingName,
			selectedSystemGroup,
		} = this.state;

		if (!isEdit) {
			createBuildings(accountUUID, buildingName, selectedSystemGroup.value)
				.then(() => {
					app.fetchAccountBuildings();
					closeModal();
				});
		} else {
			const newChanges = {};
			newChanges[`${buildingM1}.system_group_uuid`] = '';

			if (selectedSystemGroup.value) {
				newChanges[`${buildingM1}.system_group_uuid`] = selectedSystemGroup.value;
			}

			newChanges[`${buildingM1}.display_name`] = buildingName;

			app.setValue('buildings', Object.keys(newChanges), Object.values(newChanges), () => {
				app.saveData('buildings', () => {
					app.fetchAccountBuildings();
					closeModal();
				});
			});
		}
	}

	render() {
		const {
			closeModal,
			systemGroupUUID,
			isEdit,
			app,
			buildingM1,
		} = this.props;

		const {
			buildingName,
			selectedSystemGroup,
		} = this.state;

		let defaultValue = {
			label: 'Select System Group',
			value: '',
		};

		if (systemGroupUUID !== '') {
			defaultValue = this.systemGroups[0];
		}

		let saveButtonClassName = 'button';

		if (selectedSystemGroup.value === '') {
			saveButtonClassName += ' button-disabled';
		}

		let secondButton = (
			<button
				onClick={(e) => {
					e.preventDefault();
					closeModal();
				}}
				className='button button-outlined'
			>
				<CustomIcon icon='undo' /> Go Back
			</button>
		);

		if (isEdit) {
			secondButton = (
				<button
					onClick={(e) => {
						e.preventDefault();

						const {
							buildingsView,
						} = this.props;

						const param = [
							{
								m1Key: buildingM1,
							},
						];

						buildingsView.setState({
							confirmModal:        true,
							confirmModalContent: (
								<>
									<p>{`Delete this building?`}</p>
									<div className="confirm-modal-buttons">
										<button className="button" onClick={(e) => {
											e.preventDefault();
											deleteBuildings(param)
												.finally(() => {
													closeModal();
													app.fetchAccountBuildings();
												});
											buildingsView.setState({
												confirmModal:        false,
												confirmModalContent: '',
											});
										}}>Yes</button>
										<button className="button" onClick={(e) => {
											e.preventDefault();
											buildingsView.setState({
												confirmModal:        false,
												confirmModalContent: '',
											});
										}}>No</button>
									</div>
								</>
							),
						});
					}}
					className='button button-outlined'
				>
					<CustomIcon icon='trash' /> Delete
				</button>
			);
		}

		return (
			<>
				<div className={style.listingsModal}>
					<div className={style.listing}>{this.state.name}</div>
					<div className={style.listing}>{this.state.suite}</div>
					<div className={`${style.listingsModalContainer} ${this.state.isIntegration && style.integrationModalContainer}`}>
						<div className={style.column1}>
							<div className={style.sectionLabel}>Details</div>
							<div className={`text-small`}>
								<div className="label">
									<label>Building Name</label>
								</div>
								<input
									type='text'
									value={buildingName}
									onChange={(e) => {
										if (buildingName === e.target.value) {
											return;
										}

										this.setState({
											buildingName: e.target.value,
										});
									}}
								/>
							</div>
							<div className={`drop-down`}>
								<div className="label">
									<label>System Group</label>
								</div>
								<Select
									defaultValue={defaultValue}
									options={this.systemGroups}
									value={selectedSystemGroup}
									onChange={(selectedOption) => {
										this.setState({
											selectedSystemGroup: selectedOption,
										});
									}}
								/>
							</div>
						</div>
					</div>
					<div className={style.bottom}>
						{secondButton}
						<button
							className={saveButtonClassName}
							onClick={(e) => {
								e.preventDefault();

								this.saveChanges();
							}}
						>
							<CustomIcon icon='save' />Save
						</button>
					</div>
				</div>
			</>
		);
	}
}

export default BuildingsModal;