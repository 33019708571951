import React from 'react';

import style from './EntertainmentView.module.css';

import {
	fetchComponentEntertainmentCategories,
} from 'Vulture/Entertainment';

class EntertainmentView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			categories:       {},
			isImageModalOpen: false,
			imageModalURL:    '',
		};
	}

	componentDidMount() {
		const {
			componentUUID,
		} = this.props;

		fetchComponentEntertainmentCategories(componentUUID)
			.then((categories) => {
				this.setState({
					categories,
				});
			});
	}

	componentDidUpdate(prevProps) {
		const {
			componentUUID,
		} = this.props;

		if (prevProps.componentUUID !== componentUUID) {
			fetchComponentEntertainmentCategories(componentUUID)
				.then((categories) => {
					this.setState({
						categories,
					});
				});
		}
	}

	openImageModal (thumbnail_url) {
		this.setState({
			isImageModalOpen: true,
			imageModalURL:    thumbnail_url,
		});
	}

	render() {
		const {
			componentMap,
			componentSettingMap,
			componentUUID,
			app,
		} = this.props;

		const {
			categories,
		} = this.state;

		if (!componentMap[componentUUID]?.settings?.categories) {
			return null;
		}

		const selectedCategories = JSON.parse(componentMap[componentUUID].settings.categories);
		const allSelected = selectedCategories.every((category) => {
			return category.active;
		});

		return (
			<>
				<div
					className={style.selectAll}
					data-ts-id={`entertainmentSelectAll`}
				>
					<input
						className='on-off on-off-label'
						type='checkbox'
						checked={allSelected}
						data-ts-id={`entertainmentSelectorAll`}
						onChange={() => {
							selectedCategories.forEach((category) => {
								category.active = !allSelected;
							});

							app.setValue('entertainment', `component.${componentUUID}.setting.${componentSettingMap.categories.uuid}.string`, JSON.stringify(selectedCategories), () => {
								app.saveData('entertainment', () => {
									app.fetchComponent(componentUUID, true);
								});
							});
						}}
					/>
					Select All
				</div>
				<div className={style.entertainmentContent} data-ts-id='entertainmentContent'>

					{Object.entries(categories)
						.filter(([ uuid, ]) => {
							return selectedCategories.some((category) => {
								return category.value === uuid;
							});
						})
						.sort(([ , a, ], [ , b, ]) => {
							return a.category.toLowerCase() > b.category.toLowerCase() ? 1 : -1;
						})
						.map(([ uuid, data, ], i) => {
							const selectedCategoryIndex = selectedCategories.findIndex((category) => {
								return category.value === uuid;
							});

							return (
								<div
									className={style.feedContainer}
									key={uuid}
									data-ts-id={`entertainmentFeedContainer-${uuid}`}
									data-ts-index={i}
								>
									<label className={style.titleLine} data-ts-id={`entertainmentFeedTitle-${uuid}`}>
										<div>{data.category}</div>
									</label>
									<img
										src={data.thumbnail_url}
										alt=''
										data-ts-id={`entertainmentFeedImage-${uuid}`}
										onClick={() => {
											this.openImageModal(data.thumbnail_url);
										}}
									/>
									<div
										className={style.description}
										data-ts-id={`entertainmentFeedDescription-${uuid}`}
									>
										{data.description}
									</div>
									<input
										className='on-off on-off-label'
										type='checkbox'
										checked={selectedCategories[selectedCategoryIndex].active}
										data-ts-id={`entertainmentFeedActive-${uuid}`}
										onChange={() => {
											selectedCategories[selectedCategoryIndex].active = !selectedCategories[selectedCategoryIndex].active;

											app.setValue('entertainment', `component.${componentUUID}.setting.${componentSettingMap.categories.uuid}.string`, JSON.stringify(selectedCategories), () => {
												app.saveData('entertainment', () => {
													app.fetchComponent(componentUUID, true);
												});
											});
										}}
									/>
								</div>
							);
						})
					}

					{this.state.isImageModalOpen && (
						<div className={style.imageModal} data-ts-id="entertainmentImageModal">
							<div className={style.imageContainer} data-ts-id="entertainmentImageContainer">
								<img src={this.state.imageModalURL} alt='' data-ts-id="entertainmentImageModalImage" />
							</div>
							<button
								className={style.closeButton}
								data-ts-id="entertainmentImageModalCloseButton"
								onClick={() => {
									this.setState({
										isImageModalOpen: false,
									});
								}}
							>
								X
							</button>
						</div>
					)}
				</div>
			</>
		);
	}
}

export default EntertainmentView;