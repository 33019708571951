import axios from 'axios';
import Cookie from 'js-cookie';

import {
	broncoURL,
} from 'Vulture/ENV.js';

import {
	dlSessionStartPush,
} from 'Vulture/DataLayer';

export const clearSecret = () => {
	document.cookie = 'secret=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
};

export const hasSession = function () {
	let sessionKeyCookie = Cookie.get('session');
	if (sessionKeyCookie) {
		return true;
	}

	return false;
};

export const getSecret = () => {
	if (Cookie.get('secret') === 'undefined') {
		return;
	}

	return Cookie.get('secret');
};

export const getEmail = () => {
	return Cookie.get('email');
};

export const getSession = function () {
	if (!hasSession()) {
		return false;
	}

	let pg = '';

	switch (Cookie.get('pg')) {
	case 'bccf8794-d545-4bb4-8343-1929cc85b7f5':
		pg = 'ia';
		break;
	case 'cacbcbc2-e8ea-40ac-98e4-7c0f30b3f083':
	case 'd8d5ef60-e16f-4dda-8921-8720276d635e':
		pg = 'iu';
		break;
	case 'd97ec83e-d489-441e-ad83-c360382f10a6':
	case '871b40fb-ec5c-483a-8814-2a5e035bbb99':
		pg = 'ea';
		break;
	case '14c4898e-7489-4893-a50c-a4a3fdbc8c7a':
		pg = 'pm';
		break;
	default:
		break;
	}

	refreshSession();

	return {
		session: Cookie.get('session'),
		user:    Cookie.get('user'),
		email:   Cookie.get('email'),
		pg,
	};
};

export const setSecret = (secret) => {
	const parsedExpiresAt = new Date();
	parsedExpiresAt.setTime(parsedExpiresAt.getTime() + 5 * 60 * 1000);
	const stringExpiresAt = parsedExpiresAt.toUTCString();

	document.cookie = `secret=${secret}; expires=${stringExpiresAt}; path=/`;
};

export const setEmail = (email) => {
	const parsedExpiresAt = new Date();
	parsedExpiresAt.setTime(parsedExpiresAt.getTime() + 5 * 60 * 1000);
	const stringExpiresAt = parsedExpiresAt.toUTCString();

	document.cookie = `email=${email}; expires=${stringExpiresAt}; path=/`;
};

export const setSession = (session) => {
	let sessionKey = session.session_key;
	let userUUID = session.uuid;
	let expiresAt = session.expires_at;
	let pg = session.pg;

	let parsedExpiresAt = new Date(expiresAt);
	let stringExpiresAt = parsedExpiresAt.toUTCString();

	document.cookie = `session=${sessionKey}; expires=${stringExpiresAt}; path=/ ;SameSite=Lax`;
	document.cookie = `email=${session.email}; expires=${stringExpiresAt}; path=/ ;SameSite=Lax`;
	document.cookie = `pg=${pg}; expires=${stringExpiresAt}; path=/ ;SameSite=Lax`;
	document.cookie = `user=${userUUID}; expires=${stringExpiresAt}; path=/ ;SameSite=Lax`;

	dlSessionStartPush({
		userType: getSession().pg,
		userUUID,
		internal: session.email.includes('touchsource.com'),
	});
};

export const refreshSession = () => {
	let sessionKey = Cookie.get('session');
	let email = Cookie.get('email');
	let pg = Cookie.get('pg');
	let userUUID = Cookie.get('user');

	const parsedExpiresAt = new Date();
	parsedExpiresAt.setTime(parsedExpiresAt.getTime() + 24 * 60 * 60 * 1000);
	const stringExpiresAt = parsedExpiresAt.toUTCString();

	document.cookie = `session=${sessionKey}; expires=${stringExpiresAt}; path=/ ;SameSite=Lax`;
	document.cookie = `email=${email}; expires=${stringExpiresAt}; path=/ ;SameSite=Lax`;
	document.cookie = `pg=${pg}; expires=${stringExpiresAt}; path=/ ;SameSite=Lax`;
	document.cookie = `user=${userUUID}; expires=${stringExpiresAt}; path=/ ;SameSite=Lax`;
};

export const delSession = function() {
	sessionStorage.clear();

	Cookie.remove('session', {
		path: '/',
	});
	Cookie.remove('email', {
		path: '/',
	});
	Cookie.remove('pg', {
		path: '/',
	});
	Cookie.remove('user', {
		path: '/',
	});
};

export const login2FA = async function (email, pass) {
	if (hasSession()) {
		return;
	}

	let url = `${broncoURL}/login`;
	let body = {
		email,
		pass,
	};

	try {
		const res = await axios.post(url, body, {});

		return {
			res,
			data: res.data,
		};
	} catch (err) {
		if (axios.isAxiosError(err)) {
			return {
				err:  true,
				res:  err?.response || {},
				data: err?.response?.data || {},
			};
		}

		return {
			err:  true,
			res:  {},
			data: {},
		};
	}
};

export const verify2FA = async function (email, code) {
	let url = `${broncoURL}/verify_2fa`;
	let body = {
		email,
		code,
	};

	try {
		let res = await axios.post(url, body, {	});

		return {
			res,
			data: res.data,
		};
	} catch (err) {
		if (axios.isAxiosError(err)) {
			return {
				err:  true,
				res:  err?.response || {},
				data: err?.response?.data || {},
			};
		}

		return {
			err:  true,
			res:  {},
			data: {},
		};
	}
};

export const logout = async function () {
	if (!hasSession()) {
		return;
	}

	let url = `${broncoURL}/logout`;
	let body = {
		'session_key': getSession().session,
	};

	try {
		let res = await axios.post(url, body, {});

		return res.data;
	} catch (err) {
		console.error(err);
		// no error handler needed
	}
};