export const arrayNames = [
	'custom_classes',
	'details_data_exclusion',
	'filter',
	'modifier_classes',
	'custom_classes_1',
	'custom_classes_2',
	'custom_classes_3',
	'modifier_classes_1',
	'modifier_classes_2',
	'modifier_classes_3',
	'line_custom_classes',
	'line_modifier_classes',
	'box_custom_classes',
	'container_classes',
	'container_custom_classes',
	'content_classes',
	'content_custom_classes',
	'condor_layout_classes',
];

export const exclusions = [
	'default_list_shown',
];

export const componentTypeExclusions = {
	'brood': [
		'transition_in',
		'transition_out',
	],
	'monarch': [
		'transition_in',
		'transition_out',
	],
	'peacock': [
		'transition_in',
		'transition_out',
	],
	'pelican': [
		'transition_in',
		'transition_out',
	],
};

export const unusedSettingsGeneric = [
	'condor_theme_variation',
	'condor_render_name',
	'zip_code',
	'venue_id',
	'monarch_location',
	'static_map_latitude',
	'static_map_longitude',
	'google_tag_manager_id',
	'traffic_pin_yah_letter',
	'traffic_pin_yah_color',
];

export const jsonTypes = {
	raven_days: '[]',
};

export const unusedSettingsSpecific = {
	animated_scroller: [
		'animation_level',
	],
	info_box: [
		'custom_classes', 'header', 'header_block', 'img', 'info_box_text',
	],
	info_box_group: [
		'header_block_display', 'header_display', 'line_seperator',
	],
	listing_collection: [
		'vertical_line_style', 'vertical_line_width',
	],
	listing_group: [
		'column_line_width', 'id_name', 'labels', 'layout', 'listing_static_labels', 'show_column_line_first', 'show_column_line_last', 'show_column_lines', 'show_listing_background', 'style_parent_header',
	],
	news: [
		'format',
	],
	weather: [
		'line', 'refresh_time_mins', 'title',
	],
	mobile_url: [
		'custom_classes', 'custom_content_sidebar_label', 'custom_portal_label', 'is_locked', 'layout_order',
	],
	dynamic_map_amenities: [
		'dynamic_map_popup_pin_url', 'dynamic_map_yah_pin_url',
	],
	peacock: [
		'file_size_limit',
	],
};

export const readOnlySettingsSpecific = {
	top_level_condor: [
		'condor_component_group', 'condor_configuration', 'condor_theme',
	],
	raven_display: [
		'calendar_id',
	],
};

export const readOnlySystemGroupSettingsSpecific = {
	weather: [
		'zip_code',
	],
	static_map: [
		'static_map_latitude', 'static_map_longitude',
	],
	dynamic_map_traffic: [
		'static_map_latitude', 'static_map_longitude',
	],
	monarch_feed_community_calendar: [
		'monarch_location',
	],
	monarch_feed_traffic_graphics: [
		'monarch_location',
	],
	peacock: [
		'project_number',
	],
};

export const listingGroupDropdownOptions = {
	text_align: [
		{
			value: 0,
			label: 'Left',
		},
		{
			value: 1,
			label: 'Center',
		},
		{
			value: 2,
			label: 'Right',
		},
	],
	mobile_text_align: [
		{
			value: 0,
			label: 'Left',
		},
		{
			value: 1,
			label: 'Center',
		},
		{
			value: 2,
			label: 'Right',
		},
	],
};

export const listingGroupDropdownValues =  {
	text_align: {
		0: {
			label: 'Left',
		},
		1: {
			label: 'Center',
		},
		2: {
			label: 'Right',
		},
	},
	mobile_text_align: {
		0: {
			label: 'Left',
		},
		1: {
			label: 'Center',
		},
		2: {
			label: 'Right',
		},
	},
};

export const multipleSelectionValues = {
	monarch_feed_news_in_pictures_3_pak: {
		monarch__category: [
			{
				label: 'News',
				value: 'news',
			},
			{
				label: 'Sports',
				value: 'sports',
			},
			{
				label: 'Celebrity',
				value: 'celebrity',
			},
		],
	},
	monarch_feed_sports_central: {
		monarch_league: [
			{
				label: 'Major League Baseball',
				value: 'MLB',
			},
			{
				label: 'National Basketball Association',
				value: 'NBA',
			},
			{
				label: 'National Football League',
				value: 'NFL',
			},
			{
				label: 'National Hockey League',
				value: 'NHL',
			},
			{
				label: 'Major League Soccer',
				value: 'MLS',
			},
			{
				label: 'NCAA Mens Football',
				value: 'NCAAMensFootball',
			},
			{
				label: 'NCAA Mens Football 1AA',
				value: 'NCAAMensFootball1aa',
			},
			{
				label: 'NCAA Mens Basketball',
				value: 'NCAAMensBasketball',
			},
			{
				label: 'Professional Golfers\' Association',
				value: 'PGA',
			},
		],
	},
	dynamic_map_amenities: {
		dynamic_map_categories: [
			{
				value: 'Restaurant',
				label: 'Restaurant',
			},
			{
				value: 'Coffee Shop',
				label: 'Coffee Shop',
			},
			{
				value: 'Hotel',
				label: 'Hotel',
			},
			{
				value: 'Bank',
				label: 'Bank',
			},
			{
				value: 'ATM',
				label: 'ATM',
			},
			{
				value: 'Gas Station',
				label: 'Gas Station',
			},
			{
				value: 'Pharmacy',
				label: 'Pharmacy',
			},
			{
				value: 'Train Station',
				label: 'Train Station',
			},
			{
				value: 'Bar or Pub',
				label: 'Bar or Pub',
			},
		],
	},
	amenities_map: {
		amenity_categories: [
			{
				value: '13065',
				label: 'Restaurant',
			},
			{
				value: '13032',
				label: 'Café',
			},
			{
				value: '19009',
				label: 'Lodging',
			},
			{
				value: '11045',
				label: 'Bank',
			},
			{
				value: '19007',
				label: 'Gas Station',
			},
			{
				value: '17029',
				label: 'Convenience Store',
			},
			{
				value: '11044',
				label: 'ATM',
			},
			{
				value: '17069',
				label: 'Grocery',
			},
			{
				value: '17035',
				label: 'Pharmacy',
			},
			{
				value: '17114',
				label: 'Shopping Mall',
			},
			{
				value: '18021',
				label: 'Gym',
			},
			{
				value: '11061',
				label: 'Health & Beauty',
			},
			{
				value: '11073',
				label: 'Spa',
			},
			{
				value: '17056',
				label: 'Florist',
			},
			{
				value: '17089',
				label: 'Gifts',
			},
			{
				value: '15014',
				label: 'Hospital',
			},
			{
				value: '12075',
				label: 'Post Office',
			},
			{
				value: '19047',
				label: 'Rail Station',
			},
			{
				value: '11011',
				label: 'Car Wash',
			},
			{
				value: '12098',
				label: 'Spiritual Center',
			},
			{
				value: '12080',
				label: 'Library',
			},
			{
				value: '16032',
				label: 'Park',
			},
			{
				value: '10027',
				label: 'Museum',
			},
			{
				value: '10024',
				label: 'Movie Theater',
			},
			{
				value: '13003',
				label: 'Bar',
			},
			{
				value: '10032',
				label: 'Night Club',
			},
			{
				value: '17076',
				label: 'Liquor Store',
			},
			{
				value: '13002',
				label: 'Bakery',
			},
			{
				value: '17018',
				label: 'Bookstore',
			},
			{
				value: '11066',
				label: 'Dry Cleaner',
			},
			{
				value: '11069',
				label: 'Laundry',
			},
			{
				value: '17043',
				label: 'Clothing Store',
			},
			{
				value: '17023',
				label: 'Electronics Store',
			},
			{
				value: '17090',
				label: 'Hardware Store',
			},
		].sort((a, b) => {
			if (a.label > b.label) {
				return 1;
			}
			return -1;
		}),
	},
};

export const numberRanges = {
	broadsign_feed: {
		playlist_duration: {
			max: 1440,
			min: 1,
		},
		polling_rate: {
			max: 1440,
			min: 1,
		},
	},
	monarch: {
		limit: {
			max: 10,
			min: 1,
		},
	},
	monarch_feed_reuters_business_news: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_reuters_business_news_videos: {
		monarch_limit: {
			max: 10,
			min: 1,
		},
	},
	monarch_feed_flight_info_arrivals: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_flight_info_departures: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_traffic_graphics: {
		monarch_limit: {
			max: 4,
			min: 4,
		},
	},
	monarch_feed_ap_celebrity_extra: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	'monarch_feed_ap_entertainment_&_lifestyle_news_videos': {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_ap_showbiz_and_newsminute_dailies: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_global_entertainment_news_videos: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_reuters_entertainment_news: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_the_list_images: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_the_list_videos: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_community_calendar: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_creature_features: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_destinations: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_inspirations: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_pop_quiz_images: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_pop_quiz_videos: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_window_to_our_world: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_health_news_2_pak: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_healthy_bites_images: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_healthy_bites_videos: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_knowmore_living: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_local_health_conditions: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_missing_persons: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_community_events: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_ap_top_news_videos: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_digital_broadcaster_package: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_global_top_news_videos: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_news_in_pictures_3_pak: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_reuters_german_news_videos: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_reuters_national_news: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_reuters_odd_news: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_reuters_world_news: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_the_canadian_press: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_video_news_bites: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_action_sports: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_reuters_sports_news: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_sports_central: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_spectrum_weather: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_weather_forecast_videos: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
	monarch_feed_weather_graphics: {
		monarch_limit: {
			max: 100,
			min: 1,
		},
	},
};

export const dropdownSettings = {
	animated_scroller: [
		'scroll_type',
	],
	broadsign_feed: [
		'ad_provider',
	],
	brood: [
		'sort', 'transition_in', 'transition_out',
	],
	clock: [
		'time_format',
	],
	date_display: [
		'date_format',
	],
	dynamic_message: [
		'animation_type',
	],
	emergency_message: [
		'format', 'type',
	],
	entertainment: [
		'resolution'
	],
	info_box: [
		'reverse_order', 'text_align',
	],
	info_box_group: [
		'config',
	],
	listing_collection: [
		'vert_line_style',
	],
	listing_group: [
		'icon_child', 'icon_parent', 'vert_line_style',
	],
	news: [
		'animation_type',
	],
	navigation_button: [
		'icon_family', 'icon_type',
	],
	qr_code: [
		'format',
	],
	monarch: [
		'sort', 'transition_in', 'transition_out',
	],
	pelican: [
		'sort', 'transition_in', 'transition_out',
	],
	peacock: [
		'sort', 'transition_in', 'transition_out', 'peacock_size', 'peacock_media_type', 'dmn_operator',
	],
	stocks: [
		'format',
	],
	ts_logo: [
		'ts_logo_color',
	],
	text_list: [
		'decoration',
	],
	weather: [
		'day_config', 'item_format',
	],
	monarch_feed_financial_graphics: [
		 'monarch_theme', 'monarch_sort', 'monarch_size',
	],
	monarch_feed_reuters_business_news: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_reuters_business_news_videos: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_flight_info_arrivals: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_flight_info_departures: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_traffic_graphics: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_ap_celebrity_extra: [
		 'monarch_size', 'monarch_sort',
	],
	'monarch_feed_ap_entertainment_&_lifestyle_news_videos': [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_ap_showbiz_and_newsminute_dailies: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_global_entertainment_news_videos: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_reuters_entertainment_news: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_the_list_images: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_the_list_videos: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_community_calendar: [
		 'monarch_theme', 'monarch_sort', 'monarch_size',
	],
	monarch_feed_creature_features: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_destinations: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_innovations: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_inspirations: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_pop_quiz_images: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_pop_quiz_videos: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_window_to_our_world: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_health_news_2_pak: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_healthy_bites_images: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_healthy_bites_videos: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_knowmore_living: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_local_health_conditions: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_missing_persons: [
		'monarch_size', 'monarch_sort',
	],
	monarch_feed_community_events: [
		'monarch_size', 'monarch_sort',
	],
	monarch_feed_ap_top_news_videos: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_digital_broadcaster_package: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_global_top_news_videos: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_news_in_pictures_3_pak: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_reuters_german_news_videos: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_reuters_national_news: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_reuters_odd_news: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_reuters_world_news: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_the_canadian_press: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_video_news_bites: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_todays_top_tweets: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_action_sports: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_reuters_sports_news: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_sports_central: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_spectrum_weather: [
		 'monarch_sort', 'monarch_size',
	],
	monarch_feed_weather_forecast_videos: [
		 'monarch_size', 'monarch_sort',
	],
	monarch_feed_weather_graphics: [
		 'monarch_sort', 'monarch_size',
	],
	listings_scroller: [
		'scroll_buttons', 'default_keyboard',
	],
	raven_display: [
		'time_zone',
		'raven_time_format',
	],
};

export const dropdownValues = {
	animated_scroller: {
		scroll_type: [
			{
				value: 0,
				label: 'Vertical',
			},
			{
				value: 1,
				label: 'Horizontal',
			},
		],
	},
	broadsign_feed: {
		ad_provider: [
			{
				value: 'pearl_media',
				label: 'Pearl Media',
			},
			{
				value: 'taubman',
				label: 'Taubman',
			},
		],
	},
	brood: {
		sort: [
			{
				value: 'sequential',
				label: 'Sequential',
			},
			{
				value: 'random',
				label: 'Random',
			},
		],
		transition_in: [
			{
				value: 'fadeIn',
				label: 'Fade In',
			},
			{
				value: 'none',
				label: 'None',
			},
		],
		transition_out: [
			{
				value: 'fadeOut',
				label: 'Fade Out',
			},
			{
				value: 'none',
				label: 'None',
			},
		],
	},
	clock: {
		time_format: [
			{
				value: '12_hour',
				label: 'Standard',
			},
			{
				value: 'military',
				label: 'Military',
			},
		],
	},
	date_display: {
		date_format: [
			{
				value: 1,
				label: 'Monday, February 24',
			},
			{
				value: 2,
				label: 'February 24',
			},
			{
				value: 3,
				label: 'Mon, Feb 24',
			},
			{
				value: 4,
				label: 'Feb 24',
			},
			{
				value: 5,
				label: '2/24',
			},
		],
	},
	dynamic_message: {
		animation_type: [
			{
				value: 0,
				label: 'None',
			},
			{
				value: 1,
				label: 'Fade',
			},
			{
				value: 2,
				label: 'Roll',
			},
			{
				value: 3,
				label: 'Slide',
			},
			{
				value: 4,
				label: 'Reveal',
			},
		],
	},
	emergency_message: {
		format: [
			{
				value: 'sidebar',
				label: 'Sidebar',
			},
			{
				value: 'simple',
				label: 'Simple',
			},
			{
				value: 'border',
				label: 'Border',
			},
		],
		type: [
			{
				value: 'emergency',
				label: 'Emergency',
			},
			{
				value: 'urgent',
				label: 'Urgent',
			},
			{
				value: 'warning',
				label: 'Warning',
			},
		],
	},
	entertainment: {
		resolution: [
			{
				value: '3840x2160',
				label: '3840x2160',
			},
			{
				value: '2160x3840',
				label: '2160x3840',
			},
			{
				value: '1920x1080',
				label: '1920x1080',
			},
			{
				value: '1080x1920',
				label: '1080x1920',
			},
			{
				value: '1280x720',
				label: '1280x720',
			},
			{
				value: '720x1280',
				label: '720x1280',
			},
		]
	},
	info_box: {
		reverse_order: [
			{
				value: 0,
				label: 'False',
			},
			{
				value: 1,
				label: 'True',
			},
		],
		text_align: [
			{
				value: 1,
				label: 'Left',
			},
			{
				value: 2,
				label: 'Center',
			},
			{
				value: 3,
				label: 'Right',
			},
		],
	},
	info_box_group: {
		config: [
			{
				value: 0,
				label: 'Horizontal',
			},
			{
				value: 1,
				label: 'Vertical',
			},
		],
	},
	listing_collection: {
		vert_line_style: [
			{
				value: 0,
				label: 'Gradient',
			},
			{
				value: 1,
				label: 'Solid',
			},
			{
				value: 2,
				label: 'Broken',
			},
		],
	},
	listing_group: {
		icon_child: [
			{
				value: 0,
				label: 'None',
			},
			{
				value: 1,
				label: 'fa-circle',
			},
			{
				value: 2,
				label: 'fa-circle',
			},
			{
				value: 3,
				label: 'fa-square',
			},
			{
				value: 4,
				label: 'fa-square',
			},
			{
				value: 5,
				label: 'fa-user',
			},
			{
				value: 6,
				label: 'user-friends',
			},
			{
				value: 7,
				label: 'fa-user',
			},
			{
				value: 8,
				label: 'user-circle',
			},
			{
				value: 9,
				label: 'user-circle',
			},
			{
				value: 10,
				label: 'fa-building',
			},
			{
				value: 11,
				label: 'fa-building',
			},
		],
		icon_parent: [
			{
				value: 0,
				label: 'None',
			},
			{
				value: 1,
				label: 'fa-circle',
			},
			{
				value: 2,
				label: 'fa-circle',
			},
			{
				value: 3,
				label: 'fa-square',
			},
			{
				value: 4,
				label: 'fa-square',
			},
			{
				value: 5,
				label: 'fa-user',
			},
			{
				value: 6,
				label: 'user-friends',
			},
			{
				value: 7,
				label: 'fa-user',
			},
			{
				value: 8,
				label: 'user-circle',
			},
			{
				value: 9,
				label: 'user-circle',
			},
			{
				value: 10,
				label: 'fa-building',
			},
			{
				value: 11,
				label: 'fa-building',
			},
		],
		vert_line_style: [
			{
				value: 0,
				label: 'Gradient',
			},
			{
				value: 1,
				label: 'Solid',
			},
			{
				value: 2,
				label: 'Broken',
			},
		],
	},
	news: {
		animation_type: [
			{
				value: 1,
				label: 'Pop',
			},
			{
				value: 2,
				label: 'Fade',
			},
			{
				value: 3,
				label: 'Roll',
			},
			{
				value: 4,
				label: 'Slide',
			},
		],
	},

	navigation_button: {
		icon_family: [
			{
				value:     0,
				label:     'Default',
				icon_type: [
					{
						value: 0,
						label: 'None',
					},
					{
						value: 1,
						label: 'Announcements',
					},
					{
						value: 2,
						label: 'Amenities Area',
					},
					{
						value: 3,
						label: 'Amenities Building',
					},
					{
						value: 4,
						label: 'Document',
					},
					{
						value: 5,
						label: 'Floor Plan',
					},
					{
						value: 6,
						label: 'Home',
					},
					{
						value: 7,
						label: 'Individuals',
					},
					{
						value: 8,
						label: 'Info',
					},
					{
						value: 9,
						label: 'Info 2',
					},
					{
						value: 10,
						label: 'Search',
					},
					{
						value: 11,
						label: 'Space Available',
					},
					{
						value: 12,
						label: 'Tenants',
					},
					{
						value: 13,
						label: 'Traffic',
					},
					{
						value: 14,
						label: 'Transit',
					},
					{
						value: 15,
						label: 'URL',
					},
					{
						value: 16,
						label: 'Video',
					},
					{
						value: 25,
						label: 'Airplane',
					},
					{
						value: 26,
						label: 'Anchor',
					},
					{
						value: 27,
						label: 'Announcements 2',
					},
					{
						value: 28,
						label: 'Brochure',
					},
					{
						value: 29,
						label: 'Conference Room',
					},
					{
						value: 30,
						label: 'Directory Hierarchy',
					},
					{
						value: 31,
						label: 'Directory Person',
					},
					{
						value: 32,
						label: 'Doctor',
					},
					{
						value: 33,
						label: 'Events Calendar',
					},
					{
						value: 34,
						label: 'FAQ Question Mark',
					},
					{
						value: 35,
						label: 'Floor Plan 3',
					},
					{
						value: 36,
						label: 'Gym',
					},
					{
						value: 37,
						label: 'Home Plate',
					},
					{
						value: 38,
						label: 'Hospital Cross',
					},
					{
						value: 39,
						label: 'Info 3',
					},
					{
						value: 40,
						label: 'Light Bulb',
					},
					{
						value: 41,
						label: 'Map Marker',
					},
					{
						value: 42,
						label: 'Restaurant',
					},
					{
						value: 43,
						label: 'Shield',
					},
					{
						value: 44,
						label: 'Url Globe 2',
					},
				].sort((a, b) => {
					// setting None as the first option always
					if (a.label === 'None') {
						return -1;
					}
					if (b.label === 'None') {
						return 1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return -1;
				}),
			},
			{
				value:     1,
				label:     'Retail',
				icon_type: [
					{
						value: 0,
						label: 'None',
					},
					{
						value: 17,
						label: 'Dining',
					},
					{
						value: 18,
						label: 'Calendar',
					},
					{
						value: 19,
						label: 'Fun',
					},
					{
						value: 20,
						label: 'Home',
					},
					{
						value: 21,
						label: 'Map',
					},
					{
						value: 22,
						label: 'Services',
					},
					{
						value: 23,
						label: 'Shopping',
					},
					{
						value: 24,
						label: 'Search',
					},
				].sort((a, b) => {
					// setting None as the first option always
					if (a.label === 'None') {
						return -1;
					}
					if (b.label === 'None') {
						return 1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return -1;
				}),
			},
		],
	},
	qr_code: {
		format: [
			{
				value: 'textTop',
				label: 'Text Top',
			},
			{
				value: 'textRight',
				label: 'Text Right',
			},
			{
				value: 'textBottom',
				label: 'Text Bottom',
			},
			{
				value: 'textLeft',
				label: 'Text Left',
			},
		],
	},
	monarch: {
		sort: [
			{
				value: 'random',
				label: 'Random',
			},
			{
				value: 'sequential',
				label: 'Sequential',
			},
		],
		transition_in: [
			{
				value: 'fadeIn',
				label: 'Fade In',
			},
			{
				value: 'fadeOut',
				label: 'Fade Out',
			},
		],
		transition_out: [
			{
				value: 'fadeIn',
				label: 'Fade In',
			},
			{
				value: 'fadeOut',
				label: 'Fade Out',
			},
		],
	},
	pelican: {
		sort: [
			{
				value: 'random',
				label: 'Random',
			},
			{
				value: 'sequential',
				label: 'Sequential',
			},
		],
		transition_in: [
			{
				value: 'fadeIn',
				label: 'Fade In',
			},
			{
				value: 'fadeOut',
				label: 'Fade Out',
			},
			{
				value: 'none',
				label: 'None',
			},
		],
		transition_out: [
			{
				value: 'fadeIn',
				label: 'Fade In',
			},
			{
				value: 'fadeOut',
				label: 'Fade Out',
			},
			{
				value: 'none',
				label: 'None',
			},
		],
	},
	peacock: {
		sort: [
			{
				value: 'random',
				label: 'Random',
			},
			{
				value: 'sequential',
				label: 'Sequential',
			},
		],
		transition_in: [
			{
				value: 'fadeIn',
				label: 'Fade In',
			},
			{
				value: 'fadeOut',
				label: 'Fade Out',
			},
			{
				value: 'none',
				label: 'None',
			},
		],
		transition_out: [
			{
				value: 'fadeIn',
				label: 'Fade In',
			},
			{
				value: 'fadeOut',
				label: 'Fade Out',
			},
			{
				value: 'none',
				label: 'None',
			},
		],
		peacock_size: [
			{
				value: '1920x1080',
				label: '1920x1080',
			},
			{
				value: '1080x1920',
				label: '1080x1920',
			},
			{
				value: '1280x960',
				label: '1280x960',
			},
			{
				value: '1400x400',
				label: '1400x400',
			},
			{
				value: '840x400',
				label: '840x400',
			},
		],
		peacock_media_type: [
			{
				value: 'image/jpeg,image/png,video/mp4',
				label: 'Image and Video',
			},
			{
				value: 'image/jpeg,image/png',
				label: 'Image',
			},
			{
				value: 'video/mp4',
				label: 'Video',
			},
		],
		dmn_operator: [
			{
				value: 'mg_malls',
				label: 'MG Malls',
			},
			{
				value: 'screenverse',
				label: 'Screenverse',
			},
		],
	},
	stocks: {
		format: [
			{
				value: 'animated',
				label: 'Animated',
			},
			{
				value: 'horizontal1',
				label: 'Horizontal 1',
			},
			{
				value: 'horizontal2',
				label: 'Horizontal 2',
			},
			{
				value: 'vertical1',
				label: 'Vertical 1',
			},
			{
				value: 'vertical2',
				label: 'Vertical 2',
			},
			{
				value: 'vertical3',
				label: 'Vertical 3',
			},
		],
	},
	ts_logo: {
		ts_logo_color: [
			{
				value: 'White',
				label: 'White',
			},
			{
				value: 'WhiteBlue',
				label: 'White & Blue',
			},
			{
				value: 'Black',
				label: 'Black',
			},
			{
				value: 'BlackBlue',
				label: 'Black & Blue',
			},
		],
	},
	text_list: {
		decoration: [
			{
				value: 'disc',
				label: 'Disc',
			},
			{
				value: 'circle',
				label: 'Circle',
			},
			{
				value: 'none',
				label: 'None',
			},
			{
				value: 'square',
				label: 'Square',
			},
		],
	},
	weather: {
		day_config: [
			{
				value: 0,
				label: 'Standard',
			},
			{
				value: 1,
				label: 'Line Separated',
			},
			{
				value: 2,
				label: 'Colored Squares',
			},
		],
		item_format: [
			{
				value: 'format1',
				label: 'Format 1',
			},
			{
				value: 'format2',
				label: 'Format 2',
			},
		],
	},
	monarch_feed_financial_graphics: {
		monarch_theme: [
			{
				label: 'Money-Green',
				value: 'money-green',
			}, {
				label: 'Metro-Smooth',
				value: 'metro-smooth',
			}, {
				label: 'Metro-Dark',
				value: 'metro-dark',
			}, {
				label: 'Metro-Light',
				value: 'metro-light',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_reuters_business_news: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_reuters_business_news_videos: {
		monarch_size: [
			{
				label: '1280x720',
				value: '1280x720',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_flight_info_arrivals: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_flight_info_departures: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_traffic_graphics: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_ap_celebrity_extra: {
		monarch_size: [
			{
				label: '853x480',
				value: '853x480',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	'monarch_feed_ap_entertainment_&_lifestyle_news_videos': {
		monarch_size: [
			{
				label: '853x480',
				value: '853x480',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_ap_showbiz_and_newsminute_dailies: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_global_entertainment_news_videos: {
		monarch_size: [
			{
				label: '16x9 standard',
				value: '1920x1080',
			}, {
				label: '16x9 smaller',
				value: '1280x720',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_reuters_entertainment_news: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_the_list_images: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_the_list_videos: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_community_calendar: {
		monarch_theme: [
			{
				label: 'Clean Modern',
				value: 'clean-modern',
			}, {
				label: 'Cool Breeze',
				value: 'cool-breeze',
			}, {
				label: 'Gray',
				value: 'gray',
			}, {
				label: 'Light And Airy',
				value: 'light-airy',
			}, {
				label: 'Warmup',
				value: 'warmup',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_creature_features: {
		monarch_size: [
			{
				label: '16x9',
				value: '1280x720',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_destinations: {
		monarch_size: [
			{
				label: '1280x720',
				value: '1280x720',
			}, {
				label: '768x432',
				value: '768x432',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_innovations: {
		monarch_size: [
			{
				label: '1280x720',
				value: '1280x720',
			}, {
				label: '768x432',
				value: '768x432',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_inspirations: {
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_pop_quiz_images: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_pop_quiz_videos: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_window_to_our_world: {
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_health_news_2_pak: {
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_healthy_bites_images: {
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_healthy_bites_videos: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_knowmore_living: {
		monarch_size: [
			{
				label: '16x9',
				value: '1280x720',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_local_health_conditions: {
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_missing_persons: {
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_community_events: {
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_ap_top_news_videos: {
		monarch_size: [
			{
				label: '853x480',
				value: '853x480',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_digital_broadcaster_package: {
		monarch_size: [
			{
				label: '853x480',
				value: '853x480',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_global_top_news_videos: {
		monarch_size: [
			{
				label: '1920x1080',
				value: '1920x1080',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_news_in_pictures_3_pak: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_reuters_german_news_videos: {
		monarch_size: [
			{
				label: '1920x1080',
				value: '1920x1080',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_reuters_national_news: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_reuters_odd_news: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_reuters_world_news: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_the_canadian_press: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_video_news_bites: {
		monarch_size: [
			{
				label: '16x9 standard',
				value: '1280x720',
			}, {
				label: '16x9 smaller',
				value: '853x480',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_todays_top_tweets: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_action_sports: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_reuters_sports_news: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_sports_central: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_spectrum_weather: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	monarch_feed_weather_forecast_videos: {
		monarch_size: [
			{
				label: '1280x720',
				value: '1280x720',
			},
		],
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
	},
	monarch_feed_weather_graphics: {
		monarch_sort: [
			{
				label: 'Sequential',
				value: 'sequential',
			}, {
				label: 'Random',
				value: 'random',
			},
		],
		monarch_size: [
			{
				label: '16x9',
				value: '1920x1080',
			}, {
				label: '9x16',
				value: '1080x1920',
			}, {
				label: '4x3',
				value: '1024x768',
			},
		],
	},
	listings_scroller: {
		scroll_buttons: [
			{
				label: 'None',
				value: 0,
			},
			{
				label: 'Scroll Click and Hold',
				value: 1,
			},
			{
				label: 'Scroll Single Click',
				value: 2,
			},
		],
		default_keyboard: [
			{
				value: 'letters',
				label: 'QWERTY Letters',
			},
			{
				value: 'numbers',
				label: 'Numbers and Punctuation',
			},
			{
				value: 'specialChars',
				label: 'Special Characters',
			},
		],
	},
	raven_display: {
		time_zone: [
			{
				label: 'Coordinated Universal Time (UTC)',
				value: 'Coordinated Universal Time',
			},
			{
				label: 'Eastern Standard Time (EST)',
				value: 'Eastern Standard Time',
			},
			{
				label: 'Central Standard Time (CST)',
				value: 'Central Standard Time',
			},
			{
				label: 'Mountain Standard Time (MST)',
				value: 'Mountain Standard Time',
			},
			{
				label: 'Pacific Standard Time (PST)',
				value: 'Pacific Standard Time',
			},
			{
				label: 'Alaska Standard Time (AKST)',
				value: 'Alaska Standard Time',
			},
			{
				label: 'Hawaii-Aleutian Standard Time (HAST)',
				value: 'Hawaii-Aleutian Standard Time',
			},
		],
		raven_time_format: [
			{
				label: '12-hour',
				value: '12_hour',
			},
			{
				label: '24-hour',
				value: '24_hour',
			},
		],
	},
};

const settingsRegex = {
	'82597dc8-828c-11eb-ae1a-0d442d14e026': {
		regex: (val) => {
			return (/^https:\/\//).test(val.string);
		},
		errorMsg: 'You must input a https secure URL.',
	},
};

export const updatesAllowed = (pendingUpdates, systemMap, settingMap) => {
	let updatesAreAllowed = true;
	const errorMsgs = [];

	let flatData = {};
	for (let tlcFlatData of Object.values(systemMap)) {
		flatData = Object.assign({}, flatData, tlcFlatData);
	}

	for (let [ m1Key, m1Data, ] of Object.entries(pendingUpdates)) {
		if (m1Key.startsWith('template_skeleton.')) {
			if (m1Key.endsWith('.data')) {
				flatData = Object.assign({}, flatData, JSON.parse(m1Data));
			}
		}
	}

	const broodToPelicanMap = {};
	const settingM1Key = `setting.${settingMap['custom_content_sidebar_label'].uuid}`;

	for (let [ m1Key, m1Data, ] of Object.entries(flatData)) {
		if (m1Data['type'] === 'pelican') {
			const broodM1Key = m1Data['parent'];

			if (!broodToPelicanMap[broodM1Key]) {
				broodToPelicanMap[broodM1Key] = [];
			}

			const pelicanLabel = flatData[m1Key][settingM1Key]?.string || '';
			// pending deletions should not be blocked
			let pendingDeletion = false;
			for (let [ key, val, ] of Object.entries(pendingUpdates)) {
				if (key === `${broodM1Key}.${m1Key}` && val === false) {
					pendingDeletion = true;

					break;
				}
			}
			if (pendingDeletion) {
				continue;
			}

			broodToPelicanMap[broodM1Key].push(pelicanLabel);
		}
	}

	let duplicatePelicanLabels = false;
	for (let pelicanLabels of Object.values(broodToPelicanMap)) {
		if ((new Set(pelicanLabels)).size !== pelicanLabels.length) {
			duplicatePelicanLabels = true;

			break;
		}
	}

	if (duplicatePelicanLabels) {
		errorMsgs.push('Duplicate pelican labels are not allowed.');
		updatesAreAllowed = false;
	}

	Object.entries(pendingUpdates).forEach(([ key, val, ]) => {
		const splitKey = key.split('.');

		if (splitKey.length === 4) {
			const m1 = splitKey[0], m2 = splitKey[2];

			if (m1 === 'component' && m2 === 'setting') {
				const m2UUID = splitKey[3];
				if (Object.keys(settingsRegex).includes(m2UUID)) {
					if ((!settingsRegex[m2UUID].regex(val) && val !== false && val.string !== '')) {
						errorMsgs.push(settingsRegex[m2UUID].errorMsg);
						updatesAreAllowed = false;
					}
				}
			}
		}
	});

	const compositeErrorMsg = errorMsgs.reduce((accum, curr) => {
		return accum + '\n- ' + curr;
	}, '');

	return [ updatesAreAllowed, compositeErrorMsg, ];
};

export const dropdownStyles = {
	control: (provided, state) => {
		return {
			...provided,
			background:  '#fff',
			borderColor: '#9e9e9e',
			minHeight:   '30px',
			height:      '30px',
			boxShadow:   state.isFocused ? null : null,
		};
	},

	valueContainer: (provided) => {
		return {
			...provided,
			height:  '30px',
			padding: '0 6px',
		};
	},

	input: (provided, _) => {
		return {
			...provided,
			margin: '0px',
		};
	},
	indicatorsContainer: (provided, _) => {
		return {
			...provided,
			height: '30px',
		};
	},
};