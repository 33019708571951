import React from 'react';

function TextSmall(props) {
	const {
		classNameList,
		label,
		value,
		defaultValue,
		setValue = () => {},
		onBlurCB = () => {},
		dataTestingInfo,
		isDisabled,
		field = {},
	} = props;

	const {
		attr = {},
	} = field;

	let classNames = `text-small ${(classNameList || []).join(' ')}`;
	return (
		<div className={classNames}>
			{label &&
				<div className="label">
					<label>{label}</label>
				</div>
			}

			<input
				type='text'
				value={value}
				defaultValue={defaultValue}
				disabled={isDisabled}
				onChange={(e) => {
					if (value === e.target.value) {
						return;
					}

					setValue(e.target.value);
				}}
				onBlur={(e) => {
					setValue(e.target.value.trim());
					onBlurCB(e.target.value.trim());
				}}
				{...attr.htmlattr || {}}
				data-testing-info={dataTestingInfo ? dataTestingInfo : ''}
			/>
		</div >
	);
}

export default TextSmall;