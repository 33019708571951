import axios from 'axios';
import kali from 'kali';

import {
	getSession,
} from 'Vulture/Session.js';

import {
	broncoURL,
} from 'Vulture/ENV.js';

export const registerListingIntegration = async (accountUUID, listingConfigUUID, provider, integrationList, email = '', authCode = '', mallID = '') => {
	const session = getSession();

	let url = `${broncoURL}/listing_config/register_integration`;
	let body = {
		account_uuid:        accountUUID,
		listing_config_uuid: listingConfigUUID,
		provider,
		email,
		auth_code:           authCode,
		integration_list:    integrationList,
		mall_id:             mallID,
	};

	const headers = {
		headers: {
			'X-Auth-Session': session.session,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}
		alert('Credentials successfully set.');

		return res.data;
	} catch (err) {
		console.error(err);
		alert('Could not set credentials.');
	}
};

export const crawlListingIntegration = async (accountUUID, listingConfigUUID, provider) => {
	const session = getSession();

	let url = `${broncoURL}/integrations/crawl_listing`;
	let body = {
		account_uuid:        accountUUID,
		listing_config_uuid: listingConfigUUID,
		provider,
	};

	const headers = {
		headers: {
			'X-Auth-Session': session.session,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		throw new Error({
			error:   true,
			message: err.message,
		});
	}
};

export const fetchListingColumnTypes = async () => {
	const url = `${broncoURL}/r/list/listing_column_type`;
	let sessionKey = getSession().session;

	let retVal;

	await new kali({
		body: {
			'skip_auth_filter': true,
		},
		headers: {
			'content_type':   'application/json',
			'X-Auth-Session': sessionKey,
		},
		method: 'POST',
	}).post(url, {
		success: (_kali, res, contents) => {
			retVal = true;
			if (contents && contents.data) {
				retVal = contents;
			}
		},

		failure: (_kali, res, err) => {
			console.error(err);
		},
	});

	return retVal;
};

export const fetchPlacewiseMalls = async (email, authCode) => {
	const url = `${broncoURL}/integrations/malls/placewise`;
	let sessionKey = getSession().session;

	let malls;

	try {
		const res = await fetch(url, {
			body: JSON.stringify({
				email: email,
				auth_code: authCode,
			}),
			headers: {
				'Content-Type':   'application/json',
				'X-Auth-Session': sessionKey,
			},
			method: 'POST',
		});
	
		if (res.status !== 200) {
			throw new Error('Could not fetch malls.');
		}
		
		malls = await res.json();
	} catch (err) {
		console.error(err);
		alert('Could not fetch malls.');
	}

	return malls;
};