import React from 'react';

class CrowFieldCustomVultureElementsSlack extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			slackNameValue: props.value || '',
			isValidSlackName: true,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	handleChange(e) {
		const { value } = e.target;

		this.setState({
			slackNameValue: value,
		});
	}

	handleBlur() {
		const { app, entity } = this.props;
		const isValid = this.validateSlackName(this.state.slackNameValue);
		this.setState({ isValidSlackName: isValid });

		if (!this.state.slackNameValue) {
			console.log('Slack name is empty, setting value to null.');
			app.setValue('todo', entity.name, null);
		} else if (isValid) {
			console.log('Valid Slack name, setting value.');
			app.setValue('todo', entity.name, this.state.slackNameValue);
		} else {
			console.error('Invalid Slack name:', this.state.slackNameValue);
		}
	}

	validateSlackName(slackName) {
		const regex = /^[a-zA-Z0-9._-]+$/;
		return regex.test(slackName);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				slackNameValue: this.props.value,
			});
		}
	}

	render() {
		const { attr } = this.props;
		const { isValidSlackName } = this.state;

		return (
			<div className={`text-small ${(this.props.classNameList || []).join(' ')}`}>
				<div className="label">
					<label>{attr.label} Please use the person's Slack tag (e.g., @username).</label>
				</div>
				<div className={`phoneContainer`}>
					<input
						type="text"
						className="username"
						value={this.state.slackNameValue}
						placeholder="username"
						onChange={this.handleChange}
						onBlur={this.handleBlur}
						{...attr.htmlattr || {}}
					/>
					{!isValidSlackName && <div className="error">Invalid Slack username</div>}
				</div>
			</div>
		);
	}
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['custom.vulture.elements.slack'] = CrowFieldCustomVultureElementsSlack;
export default CrowFieldCustomVultureElementsSlack;
