/* eslint-disable no-console */
import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import ViewWindow from '../Common/ViewWindow/ViewWindow.js';
import integerIconOptions from 'Vulture/IconOptions';
import CustomCheckbox from './Elements/Checkbox';
import ListingsTable from './ListingsTable';
import style from './Listings.module.css';
import kali from 'kali';
import showdown from 'showdown';

import {
	getSession,
} from 'Vulture/Session';

import {
	getFDValue,
} from 'Vulture/Helpers';

import {
	getIntegerIconLabel,
} from 'Vulture/IconOptions';

import {
	deleteListings,
	fetchListingDataByListingConfig,
	generateConcatenatedColumns,
} from 'Vulture/Content';

const getParentM1Keys = function (fd, childM2Key) {
	let parentM1Keys = [];
	for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
		for (let m2Key of Object.keys(m1Data)) {
			if (m2Key === childM2Key && m2Key !== m1Key) {
				parentM1Keys.push(m1Key);
			}
		}
	}

	return parentM1Keys;
};

class ListingsView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			listings:                     {},
			tableListings:                [],
			listingConfigTableSettings:   {},
			uniqueSettingsListForAdd:     {},
			keyValMap:                    {},
			listingComponentTypeID:       '',
			listingGroups:                [],
			sortBy:                       '',
			showTagsWindow:               false,
			bundleOnLoad:                 {},
			showViewWindow:               false,
			columnsToHide:                [ 'columnToHide', ],
			needsFetch:                   false,
			showTagSearchWindow:          false,
			tagsInSearch:                 [],
			confirmModal:                 false,
			confirmModalContent:          '',
			clearFilters:                 false,
			parentsPrimaryColumnData:     {},
			listingConfigToListingsMap:   {},
			listingWidthCols:             {},
			listingsContentGroupColWidth: '',
			selectedListings:             [],
			activeAccountUUID:            '',
			allRowsSelected:              false,
			typeAheadFilter:              '',
			listingFD:                    props.fd,
			showUnassigned:	              true,
			assignedListings:             [],
			filteredTableListings:        [],
		};

		this.closeView = this.closeView.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.hideUnhideAllColumns = this.hideUnhideAllColumns.bind(this);
		this.toggleShowUnassigned = this.toggleShowUnassigned.bind(this);
		this.findRootListings = this.findRootListings.bind(this);
		this.deleteSelectedListings = this.deleteSelectedListings.bind(this);
		this.getParentComponentName = this.getParentComponentName.bind(this);
		this.getParentListingData = this.getParentListingData.bind(this);
		this.listingCollectionHasListingGroupRelation = this.listingCollectionHasListingGroupRelation.bind(this);
		this.getChildRelations = this.getChildRelations.bind(this);
		this.exportCSV = this.exportCSV.bind(this);
		this.templateCSV = this.templateCSV.bind(this);
		this.openView = this.openView.bind(this);
		this.toggleGroup = this.toggleGroup.bind(this);
		this.toggleSelectAllRows = this.toggleSelectAllRows.bind(this);
		this.toggleSelectSingleRow = this.toggleSelectSingleRow.bind(this);
		this.fetchListingDataByListingConfig = this.fetchListingDataByListingConfig.bind(this);
	}

	componentDidMount() {
		this.fetchListingDataByListingConfig();
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			listingConfigUUID,
			pendingChanges,
		} = this.props;

		const {
			tableListings,
			selectedListings,
			showUnassigned,
			listingFD,
		} = this.state;

		const prevListingConfigUUID = prevProps.listingConfigUUID;
		const prevRootListingConfigUUID = this.getRootListingConfig(prevListingConfigUUID);
		const rootListingConfigUUID = this.getRootListingConfig(listingConfigUUID);

		if (JSON.stringify(prevState.listingFD) !== JSON.stringify(listingFD)) {
			this.buildAssignedListings();
		}

		if (JSON.stringify(prevState.listingFD) !== JSON.stringify(listingFD) ||
			JSON.stringify(prevState.tableListings) !== JSON.stringify(tableListings) ||
			prevState.selectedListings !== selectedListings ||
			prevState.showUnassigned !== showUnassigned) {
			this.setState({
				filteredTableListings: tableListings
					.filter(this.unassignedListingsFilter.bind(this)),
			});
		}

		if (Object.keys(prevProps.pendingChanges).length > 0 && Object.keys(pendingChanges).length === 0) {
			// saving or discarding pending changes
			let hasFetched = false;

			for (const pendingKey of Object.keys(prevProps.pendingChanges)) {
				if (pendingKey.startsWith('listing.')) {
					if (pendingKey.startsWith('listing._')) {
						generateConcatenatedColumns(listingConfigUUID)
							.finally(() => {
								this.fetchListingDataByListingConfig(false);
							});
					} else {
						generateConcatenatedColumns(listingConfigUUID, pendingKey.split('.')[1])
							.finally(() => {
								this.fetchListingDataByListingConfig(false);
							});
					}
					hasFetched = true;
					break;
				}
			}

			if (!hasFetched) {
				this.fetchListingDataByListingConfig(false);
			}

		} else if (prevRootListingConfigUUID !== rootListingConfigUUID) {
			// selecting a different listing config hierarchy
			this.fetchListingDataByListingConfig();
		} else if (prevListingConfigUUID !== listingConfigUUID) {
			// selecting a different listing config tier

			this.makeListings(() => {
				this.setVariables();
			});
		}

		if (prevProps.activeSystem !== this.props.activeSystem) {
			this.setState({
				selectedListings: [],
			});
		}
	}

	getRootListingConfig(listingConfigUUID) {
		const {
			listingConfigMap,
		} = this.props;

		let rootConfig;
		let uuid = listingConfigUUID;

		while (!rootConfig) {
			const listingConfig = listingConfigMap[uuid];

			if (!listingConfig) {
				break;
			}

			if (listingConfig.root) {
				rootConfig = uuid;
				break;
			}

			uuid = listingConfig.parentUUID;
		}

		return rootConfig;
	}

	async fetchListingDataByListingConfig(remakeMaps = true) {
		const {
			accountUUID,
			systemGroupUUID,
			listingConfigUUID,
			fd,
		} = this.props;

		const rootConfig = this.getRootListingConfig(listingConfigUUID);

		const response = await fetchListingDataByListingConfig(accountUUID, systemGroupUUID, rootConfig);

		const newListingFD = response ? {
			...fd,
			...response,
		} : fd;

		Object.keys(newListingFD).forEach((key) => {
			if (key.startsWith('listing._')) {
				Reflect.deleteProperty(newListingFD, key);
			}
		});

		this.setState({
			listingFD: newListingFD,
		}, () => {
			if (remakeMaps) {
				return this.makeMaps();
			}
			return this.updateMaps();
		});
	}

	removeListingsFromFD(listingUUIDs) {
		const {
			listingFD,
			listingConfigToListingsMap,
			listingToListingCollectionsMap,
			listingToParentListingsMap,
			listingToSystemsMap,
		} = this.state;

		for (const listingUUID of listingUUIDs) {
			Reflect.deleteProperty(listingFD, `listing.${listingUUID}`);
			Reflect.deleteProperty(listingToSystemsMap, `listing.${listingUUID}`);
			Reflect.deleteProperty(listingToListingCollectionsMap, `listing.${listingUUID}`);
			Reflect.deleteProperty(listingToParentListingsMap, `listing.${listingUUID}`);

			for (const listingConfigUUID of Object.keys(listingConfigToListingsMap)) {
				if (listingConfigToListingsMap?.[listingConfigUUID]) {
					Reflect.deleteProperty(listingConfigToListingsMap[listingConfigUUID], `listing.${listingUUID}`);
				}
			}

			for (const parentListingUUID of Object.keys(listingToParentListingsMap)) {
				if (listingToParentListingsMap?.[parentListingUUID]) {
					Reflect.deleteProperty(listingToParentListingsMap[parentListingUUID], `listing.${listingUUID}`);
				}
			}
		}

		this.setState({
			listingFD,
			listingConfigToListingsMap,
			listingToListingCollectionsMap,
			listingToParentListingsMap,
			listingToSystemsMap,
		}, () => {
			this.makeListings();
		});
	}

	buildListingConfigToListingConfigTierMap(parentUUID, listingConfigToListingConfigTierMap = {}) {
		const {
			fd,
		} = this.props;

		const parentObj = fd[parentUUID] || {};

		for (let m2Key of Object.keys(parentObj)) {
			if (m2Key.startsWith('listing_config.')) {
				listingConfigToListingConfigTierMap[m2Key] = parentUUID;
				listingConfigToListingConfigTierMap = this.buildListingConfigToListingConfigTierMap(m2Key, listingConfigToListingConfigTierMap);
			}
		}

		return listingConfigToListingConfigTierMap;
	}

	makeMaps() {
		console.time('makeMaps');
		const {
			accountUUID,
			app,
		} = this.props;

		const {
			listingFD,
		} = this.state;

		app.setState({
			loading: true,
		});

		const systemToListingCollectionsMap = {};

		const listingToParentListingsMap = {};
		const listingToListingCollectionsMap = {};
		const listingToSystemsMap = {};

		const listingConfigToListingConfigTierMap = this.buildListingConfigToListingConfigTierMap(`account.${accountUUID}`, {});
		const listingCollectionToListingConfigTierMap = {};
		const listingCollectionToSystemsMap = {};

		const listingConfigToListingCollectionsMap = {};
		const listingConfigToListingsColumnsMap = {};
		const listingConfigToListingsMap = {};

		const getListingCollectionToSystemsMap = (systemM1Key, currentM1Key) => {
			if (typeof listingFD[currentM1Key] === 'object' && listingFD[currentM1Key] !== null) {
				for (let m2Key of Object.keys(listingFD[currentM1Key])) {
					if (m2Key.startsWith('component.') && m2Key !== currentM1Key) {
						getListingCollectionToSystemsMap(systemM1Key, m2Key);
					} else if (m2Key.startsWith('listing_collection.')) {
						listingCollectionToSystemsMap[m2Key] = listingCollectionToSystemsMap[m2Key] || [];
						listingCollectionToSystemsMap[m2Key].push(systemM1Key);
						systemToListingCollectionsMap[systemM1Key] = systemToListingCollectionsMap[systemM1Key] || [];
						systemToListingCollectionsMap[systemM1Key].push(m2Key);
					}
				}
			}
		};

		for (const m1Key of Object.keys(listingFD)) {
			if (m1Key.startsWith('system.')) {
				getListingCollectionToSystemsMap(m1Key, m1Key);
			}

			if (m1Key.startsWith('listing_config.')) {
				listingConfigToListingsMap[m1Key] = listingConfigToListingsMap[m1Key] || {};
			}
		}

		for (const [ m1Key, m1Data, ] of Object.entries(listingFD)) {
			if (m1Key.startsWith('listing.')) {
				for (const m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('listing.')) {
						if (!listingToParentListingsMap[m2Key]) {
							listingToParentListingsMap[m2Key] = [];
						}

						listingToParentListingsMap[m2Key].push(m1Key);
					}
				}
			}

			if (m1Key.startsWith('listing_collection.')) {
				if (!listingCollectionToListingConfigTierMap[m1Key]) {
					listingCollectionToListingConfigTierMap[m1Key] = {};
				}

				for (const m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('listing_config.')) {
						let m2Data = listingFD[m2Key];

						// T1 listing_config
						listingCollectionToListingConfigTierMap[m1Key][0] = m2Key;

						if (!listingConfigToListingCollectionsMap[m2Key]) {
							listingConfigToListingCollectionsMap[m2Key] = [];
						}
						listingConfigToListingCollectionsMap[m2Key].push(m1Key);

						// T(n) listing_config
						let i = 0;
						while (~Object.keys(m2Data).join('').indexOf('listing_config.')) {
							i++;
							let childListingConfigM1Key = Object.keys(m2Data).filter((k) => {
								return k.startsWith('listing_config.');
							})[0];

							m2Data = listingFD[childListingConfigM1Key];
							listingCollectionToListingConfigTierMap[m1Key][i] = childListingConfigM1Key;

							if (!listingConfigToListingCollectionsMap[childListingConfigM1Key]) {
								listingConfigToListingCollectionsMap[childListingConfigM1Key] = [];
							}
							listingConfigToListingCollectionsMap[childListingConfigM1Key].push(m1Key);
						}
					}
				}
			}

			if (m1Key.startsWith('listing_config.')) {
				if (!listingConfigToListingsColumnsMap[m1Key]) {
					listingConfigToListingsColumnsMap[m1Key] = {};
				}

				for (const [ m2Key, m2Data, ] of Object.entries(m1Data)) {
					if (m2Key.startsWith('listing_column.')) {
						listingConfigToListingsColumnsMap[m1Key][m2Key] = m2Data;
						listingConfigToListingsColumnsMap[m1Key][m2Key].name = listingFD[m2Key].display_name;

						let listingColumnTypeM1Key = Object.keys(listingFD[m2Key]).filter((k) => {
							return k.startsWith('listing_column_type.');
						})[0];

						listingConfigToListingsColumnsMap[m1Key][m2Key].type = listingFD[listingColumnTypeM1Key].type;
						listingConfigToListingsColumnsMap[m1Key][m2Key].fieldType = listingFD[listingColumnTypeM1Key].display_name;
					}
				}
			}
		}

		const systemToComponentGroupMap = {};
		for (const m1Key of Object.keys(listingFD)) {
			if (m1Key.startsWith('component_group.')) {
				const group = listingFD[m1Key];
				if (group.group_label !== this.props.activeMenu) {
					continue;
				}

				for (const m2Key of Object.keys(listingFD[m1Key])) {
					if (m2Key.startsWith('system.')) {
						for (const [ , systemM1Keys, ] of Object.entries(listingCollectionToSystemsMap)) {
							if (~systemM1Keys.indexOf(m2Key)) {
								systemToComponentGroupMap[m2Key] = group;
							}
						}
					}
				}
			}
		}

		for (const listingM1Key of Object.keys(listingFD)) {
			if (!listingM1Key.startsWith('listing.')) {
				continue;
			}

			let parents = getParentM1Keys(listingFD, listingM1Key);

			while (!~parents.join('').indexOf('listing_collection.')) {
				parents = getParentM1Keys(listingFD, parents[0]);

				if (parents.length === 0) {
					break;
				}
			}

			if (!listingToListingCollectionsMap[listingM1Key]) {
				listingToListingCollectionsMap[listingM1Key] = [];
			}

			if (!listingToSystemsMap[listingM1Key]) {
				listingToSystemsMap[listingM1Key] = [];
			}

			for (const parentM1Key of parents) {
				if (parentM1Key.startsWith('listing_collection.')) {
					let listingCollectionM1Key = parentM1Key;
					listingToListingCollectionsMap[listingM1Key].push(listingCollectionM1Key);

					let systemM1Keys = listingCollectionToSystemsMap[listingCollectionM1Key] || [];
					for (const systemM1Key of systemM1Keys) {
						listingToSystemsMap[listingM1Key].push(systemM1Key);
					}
				}
			}
		}

		for (const listingM1Key of Object.keys(listingFD)) {
			if (!listingM1Key.startsWith('listing.')) {
				continue;
			}

			const listingConfigM1Key = Object.keys(listingFD[listingM1Key]).find((m2Key) => {
				return m2Key.startsWith('listing_config.');
			});

			if (!listingConfigM1Key) {
				continue;
			}

			let listing = {
				vals:    {},
				systems: new Set(listingToSystemsMap[listingM1Key]),
			};

			const listingCollectionM1Keys = listingToListingCollectionsMap[listingM1Key];
			for (const listingCollectionM1Key of listingCollectionM1Keys) {
				const systemM1Keys = listingCollectionToSystemsMap[listingCollectionM1Key];

				if (!systemM1Keys) {
					continue;
				}

				if (systemToComponentGroupMap[systemM1Keys[0]]) {
					const group = systemToComponentGroupMap[systemM1Keys[0]];
					for (const m1Key of Object.keys(group)) {
						if (m1Key.startsWith('system.')) {
							listing.systems.add(m1Key);
						}
					}
				}
			}
			listing.systems = Array.from(listing.systems);

			if (listingToParentListingsMap[listingM1Key]) {
				listing.parent_listings = listingToParentListingsMap[listingM1Key];
			}

			let cols = listingConfigToListingsColumnsMap[listingConfigM1Key];

			if (!cols) {
				continue;
			}

			listing.cols = cols;

			for (let [ colM1Key, col, ] of Object.entries(cols)) {
				if (typeof listingFD[listingM1Key][colM1Key] === 'undefined') {
					listing.vals[col.name] = null;
				} else {
					let type = col.type;

					if (col.fieldType === 'Array') {
						const arrayOptions = listingFD[`account.${accountUUID}`].array_options;

						if (arrayOptions) {
							type = JSON.parse(arrayOptions)[col.name].type;
						}
					}

					listing.vals[col.name] = listingFD[listingM1Key][colM1Key][type];
				}
			}

			listingConfigToListingsMap[listingConfigM1Key][listingM1Key] = listing;
		}

		this.setState({
			listingCollectionToSystemsMap,
			systemToListingCollectionsMap,
			listingConfigToListingConfigTierMap,
			listingConfigToListingCollectionsMap,
			listingConfigToListingsMap,
			listingToListingCollectionsMap,
			listingToParentListingsMap,
			listingToSystemsMap,
			listingConfigToListingsColumnsMap,
		}, () => {
			this.makeListings(() => {
				this.getListingGroups();
				this.setVariables();

				app.setState({
					loading: false,
				});
			});
		});
		console.timeEnd('makeMaps');
	}

	updateMaps() {
		console.time('updateMaps');
		const {
			accountUUID,
			app,
		} = this.props;

		app.setState({
			loading: true,
		});

		const {
			listingFD,
			listingCollectionToSystemsMap,
			listingConfigToListingsColumnsMap,
		} = this.state;

		const listingToParentListingsMap = {};
		const listingToListingCollectionsMap = {};
		const listingToSystemsMap = {};
		const listingConfigToListingsMap = {};

		const listingUUIDs = [];

		for (const m1Key of Object.keys(listingFD)) {
			if (m1Key.startsWith('listing_config.')) {
				listingConfigToListingsMap[m1Key] = listingConfigToListingsMap[m1Key] || {};
			}

			if (m1Key.startsWith('listing.')) {
				listingUUIDs.push(m1Key);
			}
		}

		for (const m1Key of listingUUIDs) {
			for (const m2Key of Object.keys(listingFD[m1Key])) {
				if (m2Key.startsWith('listing.')) {
					listingToParentListingsMap[m2Key] = listingToParentListingsMap[m2Key] || [];

					listingToParentListingsMap[m2Key].push(m1Key);
				}
			}
		}

		const systemToComponentGroupMap = {};
		for (const m1Key of Object.keys(listingFD)) {
			if (m1Key.startsWith('component_group.')) {
				const group = listingFD[m1Key];
				if (group.group_label !== this.props.activeMenu) {
					continue;
				}

				for (const m2Key of Object.keys(listingFD[m1Key])) {
					if (m2Key.startsWith('system.')) {
						for (const [ , systemM1Keys, ] of Object.entries(listingCollectionToSystemsMap)) {
							if (~systemM1Keys.indexOf(m2Key)) {
								systemToComponentGroupMap[m2Key] = group;
							}
						}
					}
				}
			}
		}

		for (const listingM1Key of listingUUIDs) {
			let parents = getParentM1Keys(listingFD, listingM1Key);

			while (!~parents.join('').indexOf('listing_collection.')) {
				parents = getParentM1Keys(listingFD, parents[0]);

				if (parents.length === 0) {
					break;
				}
			}

			if (!listingToListingCollectionsMap[listingM1Key]) {
				listingToListingCollectionsMap[listingM1Key] = [];
			}

			if (!listingToSystemsMap[listingM1Key]) {
				listingToSystemsMap[listingM1Key] = [];
			}

			for (const parentM1Key of parents) {
				if (parentM1Key.startsWith('listing_collection.')) {
					let listingCollectionM1Key = parentM1Key;
					listingToListingCollectionsMap[listingM1Key].push(listingCollectionM1Key);

					let systemM1Keys = listingCollectionToSystemsMap[listingCollectionM1Key] || [];
					for (const systemM1Key of systemM1Keys) {
						listingToSystemsMap[listingM1Key].push(systemM1Key);
					}
				}
			}
		}

		for (const listingM1Key of listingUUIDs) {
			const listingConfigM1Key = Object.keys(listingFD[listingM1Key]).find((m2Key) => {
				return m2Key.startsWith('listing_config.');
			});

			if (!listingConfigM1Key) {
				continue;
			}

			let listing = {
				vals:    {},
				systems: new Set(listingToSystemsMap[listingM1Key]),
			};

			const listingCollectionM1Keys = listingToListingCollectionsMap[listingM1Key];
			for (const listingCollectionM1Key of listingCollectionM1Keys) {
				const systemM1Keys = listingCollectionToSystemsMap[listingCollectionM1Key];

				if (!systemM1Keys) {
					continue;
				}

				if (systemToComponentGroupMap[systemM1Keys[0]]) {
					const group = systemToComponentGroupMap[systemM1Keys[0]];
					for (const m1Key of Object.keys(group)) {
						if (m1Key.startsWith('system.')) {
							listing.systems.add(m1Key);
						}
					}
				}
			}
			listing.systems = Array.from(listing.systems);

			if (listingToParentListingsMap[listingM1Key]) {
				listing.parent_listings = listingToParentListingsMap[listingM1Key];
			}

			let cols = listingConfigToListingsColumnsMap[listingConfigM1Key];

			if (!cols) {
				continue;
			}

			listing.cols = cols;

			for (let [ colM1Key, col, ] of Object.entries(cols)) {
				if (typeof listingFD[listingM1Key][colM1Key] === 'undefined') {
					listing.vals[col.name] = null;
				} else {
					let type = col.type;

					if (col.fieldType === 'Array') {
						const arrayOptions = listingFD[`account.${accountUUID}`].array_options;

						if (arrayOptions) {
							type = JSON.parse(arrayOptions)[col.name].type;
						}
					}

					listing.vals[col.name] = listingFD[listingM1Key][colM1Key][type];
				}
			}

			listingConfigToListingsMap[listingConfigM1Key][listingM1Key] = listing;
		}

		this.setState({
			listingConfigToListingsMap,
			listingToListingCollectionsMap,
			listingToParentListingsMap,
			listingToSystemsMap,
		}, () => {
			this.makeListings(() => {
				app.setState({
					loading: false,
				});
			});
		});
		console.timeEnd('updateMaps');
	}

	injectColumnElm(listing) {
		const {
			buildingsDataArray,
		} = this.props;

		let selectedListings = this.state.selectedListings;

		listing.isChecked = false;

		for (let [ listingColumnM1Key, col, ] of Object.entries(listing.cols)) {
			let val = listing.vals[col.name];

			let elm;
			switch (col.fieldType) {
			case 'Checkbox':
				if (val) {
					elm = <CustomIcon icon='check-circle' color='#00baff'></CustomIcon>;
				} else {
					elm = <CustomIcon icon='circle' color='#00baff'></CustomIcon>;
				}
				break;

			case 'Image':
				if (val) {
					elm = <CustomIcon icon='image' color='#00baff'></CustomIcon>;
				} else {
					elm = null;
				}
				break;

			case 'PDF':
				if (val) {
					elm = <CustomIcon icon='file-pdf' color='#00baff'></CustomIcon>;
				} else {
					elm = null;
				}
				break;
			case 'Video':
				if (val) {
					elm = <CustomIcon icon='video' color='#00baff'></CustomIcon>;
				} else {
					elm = null;
				}
				break;
			case 'Directional Arrows':
				elm = getIntegerIconLabel(col.fieldType, val) || null;
				break;
			case 'Phone':
			case 'Primary Phone':
				if (val) {
					try {
						let jsonCellData = JSON.parse(val);
						elm = '+' + jsonCellData.cc + ' ' + jsonCellData.area + '-' + jsonCellData.n;
					} catch (error) {
						console.error('Failed to parse JSON:', error);
						elm = null;
					}
				} else {
					elm = null;
				}
				break;
			case 'SMS Message':
				if (val) {
					try {
						let jsonCellData = JSON.parse(val);
						elm = '+' + jsonCellData.cc + ' ' + jsonCellData.area + '-' + jsonCellData.n;
					} catch (error) {
						console.error('Failed to parse JSON:', error);
						elm = null;
					}
				} else {
					elm = null;
				}
				break;
			case 'Email Message':
				if (val) {
					try {
						let jsonCellData = JSON.parse(val);
						elm = jsonCellData.email + '@' + jsonCellData.domain;
					} catch (error) {
						elm = null;
					}
				} else {
					elm = null;
				}
				break;
			case 'Teams':
				if (val) {
					try {
						let jsonCellData = JSON.parse(val);
						elm = jsonCellData.email + '@' + jsonCellData.domain;
					} catch (error) {
						elm = null;
					}
				} else {
					elm = null;
				}
				break;
			case 'Slack':
				if (val) {
					elm = val;
				} else {
					elm = null;
				}
				break;
			case 'Logo':
			case 'Photo':
				if (val) {
					elm = <CustomIcon icon='check' color='#00baff'></CustomIcon>;
				} else {
					elm = null;
				}
				break;
			case 'Text Large':
				(() => {
					const converter = new showdown.Converter();
					const convertedtext = converter.makeHtml(val);
					const html = (convertedtext ? convertedtext.substring(0, 25) + '...' : '');
					if (val) {
						elm = <div dangerouslySetInnerHTML={{
							__html: html,
						}}></div>;
					} else {
						elm = null;
					}
				})();
				break;

			default:
				elm = val;
			}

			let listingColumnUUID = listingColumnM1Key.split('.')[1];
			listing.vals[listingColumnUUID] = elm;
		}

		if (selectedListings.length > 0) {
			if (selectedListings.includes(listing.uuid)) {
				listing.isChecked = true;
			}
		}

		return listing;
	}

	// find the attribute and entity key for the tier 2+ parent's column with the order of 1
	getParentsPrimaryColumnData() {
		const {
			listingConfigMap,
			listingConfigUUID,
		} = this.props;

		const activeListingConfig = listingConfigMap[listingConfigUUID];

		let parentPrimaryColumnInfo = {
			parentsFirstColumnEntityKey: '',
			parentsFirstColumnAttr:      '',
		};

		let validConfig = false;
		for (const m1Key of (Object.keys(listingConfigMap[listingConfigUUID] || {}))) {
			if (m1Key.startsWith('listing_column')) {
				validConfig = true;
			}
		}

		if (validConfig) {
			const parentListingConfig = listingConfigMap[activeListingConfig.parentUUID];
			for (const [ m1Key, m1Data, ] of Object.entries(parentListingConfig)) {
				if (m1Key.startsWith('listing_column.') && m1Data.order === 1) {
					parentPrimaryColumnInfo.parentsFirstColumnEntityKey = m1Key;

					for (const [ m2Key, m2Data, ] of Object.entries(m1Data)) {
						if (m2Key.startsWith('listing_column_type.')) {
							parentPrimaryColumnInfo.parentsFirstColumnAttr = m2Data.type;

							return parentPrimaryColumnInfo;
						}
					}
				}
			}
		}

		return parentPrimaryColumnInfo;
	}

	getListingCollectionInfo(listingConfigUUID) {
		const {
			accountUUID,
			activeMenu,
		} = this.props;

		const {
			listingCollectionToSystemsMap,
			systemToListingCollectionsMap,
			listingConfigToListingCollectionsMap,
			listingFD,
		} = this.state;

		let info = {};
		let bundles = [];
		let systems = [];

		let componentGroupMap = {};

		let accountM1Key = `account.${accountUUID}`;
		for (const m1Key of Object.keys(listingFD[accountM1Key])) {
			if (m1Key.startsWith('component_group.')) {
				let componentGroup = listingFD[m1Key];

				// TODO: check by component type as well
				if (componentGroup.group_label === activeMenu) {
					componentGroupMap[m1Key] = listingFD[m1Key];
				}
			}
		}

		let listingConfigM1Key = `listing_config.${listingConfigUUID}`;
		let currentListingConfigListingCollectionM1Keys = listingConfigToListingCollectionsMap[listingConfigM1Key] || [];

		let listingCollectionObjs = {};
		let groupedSystemM1Keys = [];

		currentListingConfigListingCollectionM1Keys
			.filter((listingCollectionM1Key) => {
				return listingCollectionToSystemsMap[listingCollectionM1Key];
			})
			.forEach((listingCollectionM1Key) => {
				const systemM1Keys = listingCollectionToSystemsMap[listingCollectionM1Key];

				let listingCollectionM1Keys = new Set();
				for (const systemM1Key of systemM1Keys) {
					for (const m1Key of systemToListingCollectionsMap[systemM1Key]) {
						listingCollectionM1Keys.add(m1Key);
					}
				}
				listingCollectionM1Keys = Array.from(listingCollectionM1Keys)
					.filter((m1Key) => {
						if (~currentListingConfigListingCollectionM1Keys.indexOf(m1Key)) {
							return true;
						}

						return false;
					});

				let grouped = false;

				let systemOrGroupName;
				let systemOrGroupUUID;
				let nonExclusive; // can't delete if non exclusive, related to system group view
				for (const m1Key of Object.keys(listingFD)) {
					if (m1Key.startsWith('component_group.')) {
						let group = listingFD[m1Key];
						if (group.group_label !== this.props.activeMenu) {
							continue;
						}

						nonExclusive = listingFD[m1Key].non_exclusive;

						for (const m2Key of Object.keys(listingFD[m1Key])) {
							if (m2Key.startsWith('system.')) {
								if (~systemM1Keys.indexOf(m2Key)) {
									grouped = true;
									systemOrGroupName = group.display_name;
									systemOrGroupUUID = m1Key.split('.')[1];
									break;
								}
							}
						}
					}
				}

				if (grouped) {
					if (groupedSystemM1Keys.length > 0 && ~groupedSystemM1Keys.indexOf(systemM1Keys[0])) {
						groupedSystemM1Keys = [ ...groupedSystemM1Keys, ...systemM1Keys, ];
					}

					listingCollectionObjs[systemOrGroupUUID] = {
						grouped,
						name:         systemOrGroupName,
						uuid:         systemOrGroupUUID,
						listingCollectionM1Keys,
						systemM1Keys,
						nonExclusive: !!nonExclusive,
					};
				} else {
					for (let systemM1Key of systemM1Keys) {
						if (!listingFD[systemM1Key]) {
							continue;
						}
						let systemOrGroupName = listingFD[systemM1Key].display_name;
						let systemOrGroupUUID = systemM1Key.split('.')[1];

						listingCollectionObjs[systemOrGroupUUID] = {
							grouped,
							name: systemOrGroupName,
							uuid: systemOrGroupUUID,
							listingCollectionM1Keys,
							systemM1Keys,
						};
					}
				}
			});

		for (let listingCollectionObj of Object.values(listingCollectionObjs)) {
			if (listingCollectionObj.grouped) {
				bundles.push(listingCollectionObj);
			}

			if (!listingCollectionObj.grouped) {
				systems.push(listingCollectionObj);
			}
		}

		info.bundles = bundles;
		info.systems = systems;

		return info;
	}

	// gather all Listing Groups by name and ID and sort them by name
	getListingGroups() {
		const {
			listingConfigUUID,
		} = this.props;

		let info = this.getListingCollectionInfo(listingConfigUUID);

		info.bundles.sort((a, b) => {
			if (b.name > a.name) {
				return -1;
			}
			if (b.name < a.name) {
				return 1;
			}
			return 0;
		});

		info.systems.sort((a, b) => {
			if (b.name > a.name) {
				return -1;
			}
			if (b.name < a.name) {
				return 1;
			}
			return 0;
		});

		let listingGroups = [];
		listingGroups.push(...info.bundles);
		listingGroups.push(...info.systems);

		this.setState({
			listingGroups,
		});
	}

	makeListings(cb = () => { }) {
		console.time('makeListings');
		const {
			listingConfigMap,
			listingConfigUUID,
		} = this.props;

		const {
			listingFD,
		} = this.state;

		const {
			listingConfigToListingsMap,
		} = this.state;

		if (!listingConfigUUID) {
			return;
		}

		let listingConfigEntityKey = `listing_config.${listingConfigUUID}`;
		if (!listingConfigToListingsMap[listingConfigEntityKey]) {
			return;
		}

		let listingObjects = [];
		for (const [ k, v, ] of Object.entries(listingConfigToListingsMap[listingConfigEntityKey])) {
			v.uuid = k.split('.')[1];
			listingObjects.push(v);
		}

		let tableSettings = this.getListingsConfigComponent();
		let listingConfigTableSettings = tableSettings.listingTableSettings;

		let sortBy = this.state.sortBy;
		if (tableSettings.sortBy) {
			sortBy = tableSettings.sortBy;
		}
		if (~sortBy.indexOf('.')) {
			// sort by UUID of listing_column.UUID
			sortBy = sortBy.split('.')[1];
		}

		// let listingComponentTypeID = this.props.form.state?.vulture?.componentTypeToUUID?.['listing'];
		let listingComponentTypeID = '';

		if (listingObjects) {
			const listings = {};
			let tableListings = [];
			let uniqueSettingsListForAdd = {};

			Object.values(listingObjects).forEach((listing) => {
				tableListings.push(this.injectColumnElm(listing));
			});

			if (sortBy !== '') {
				tableListings.sort((a, b) => {
					if (a.vals[sortBy] && b.vals[sortBy]) {
						let listingA = String(a.vals[sortBy]).toLowerCase();
						let listingB = String(b.vals[sortBy]).toLowerCase();

						if (listingA > listingB) {
							return 1;
						}

						if (listingA < listingB) {
							return -1;
						}

						return 0;
					}
				});
			}

			let parentsPrimaryColumnData = {};
			if (!listingConfigMap[listingConfigUUID].root) {
				parentsPrimaryColumnData = this.getParentsPrimaryColumnData();
			}

			const tableListingsWithSchedules = tableListings
				.map((tableListing) => {
					const retval = {
						...tableListing,
					};

					retval.schedule = false;
					retval.scheduleForSearch = [ 'false', ];

					const schedulingJSONString = listingFD?.[`listing.${tableListing.uuid}`]?.listing_scheduling;

					if (schedulingJSONString === undefined) {
						return retval;
					}

					const schedulingJSON = JSON.parse(schedulingJSONString);

					if (schedulingJSON.scheduling) {
						retval.schedule = true;
						retval.scheduleForSearch = [ 'true', ];
					}

					return retval;
				});

			this.setState({
				listings,
				tableListings: tableListingsWithSchedules
					.filter((listing) => {
						return !listing.uuid.startsWith('_');
					}),
				listingComponentTypeID,
				uniqueSettingsListForAdd,
				listingConfigTableSettings,
				parentsPrimaryColumnData,
			}, () => {
				if (typeof cb === 'function') {
					return cb();
				}
			});

			if (Object.keys(this.props.pendingChanges).length > 0) {
				this.resetPendingChangesForUI();
			}
			console.timeEnd('makeListings');
		}
	}

	sortTable(sortBy) {
		let tableListings = this.state.tableListings;

		switch (typeof tableListings[0][sortBy]) {
		case 'string':
			tableListings.sort((a, b) => {
				if (a[sortBy] && b[sortBy]) {
					let listingA = a[sortBy].toLowerCase();
					let listingB = b[sortBy].toLowerCase();
					if (listingB > listingA) {
						return -1;
					}
					if (listingB < listingA) {
						return 1;
					}
					return 0;
				}
			});
			break;

		case 'number':
			tableListings.sort((a, b) => {
				if (a[sortBy] && b[sortBy]) {
					return b[sortBy] - a[sortBy];
				}
			});
			break;

		case 'bool':
			tableListings.sort((a, b) => {
				if (a[sortBy]) {
					return 1;
				}
				if (b[sortBy]) {
					return -1;
				}
			});
			break;

		case 'undefined':
			tableListings.sort((a, b) => {
				return this.listingCollectionHasListingGroupRelation(sortBy, b) - this.listingCollectionHasListingGroupRelation(sortBy, a);
			});
			break;

		default:
			break;
		}

		this.setState({
			sortBy,
			tableListings,
		});
	}

	// create a key[value] map of data[displayName] (or accessor[header] for react table)
	getListingsConfigComponent() {
		const {
			listingConfigUUID,
			listingConfigMap,
		} = this.props;

		let listingTableSettings = {};
		let tableSettings = {};
		let candidateColumns = [];
		let activeListingConfig = listingConfigMap[listingConfigUUID];
		let disableColumn = false;
		for (const [ m1Key, m1Data, ] of Object.entries(activeListingConfig)) {
			if (m1Key.startsWith('listing_column.')) {
				/* for (let [m2Key, m2Data,] of Object.entries(m1Data)) {
					if (m2Key.startsWith('listing_column_type.')) {
						if (m2Data.display_name === 'Text Large') {
							disableColumn = true;
						}
					}
				} */
				if (m1Data.order === 1) {
					tableSettings.sortBy = m1Key;
				}
				if (!disableColumn) {
					candidateColumns.push({
						uuid:  m1Key.split('.')[1],
						name:  m1Data.display_name,
						order: m1Data.order,
					});
				}
			}
		}

		candidateColumns.sort((a, b) => {
			if (a.order < b.order) {
				return -1;
			}
			if (a.order > b.order) {
				return 1;
			}
			return 0;
		});

		candidateColumns.forEach((info) => {
			listingTableSettings[info.uuid] = info.name;
		});

		tableSettings.listingTableSettings = listingTableSettings;

		return tableSettings;
	}

	isPositiveInteger(str) {
		if (typeof str !== 'string') {
			return false;
		}

		const num = Number(str);

		if (Number.isInteger(num) && num > 0) {
			return true;
		}

		return false;
	}

	getParentListingData(listing) {
		if ((listing.parent_listings || []).length === 0) {
			return '';
		}

		const {
			listingFD,
		} = this.state;

		let {
			parentsFirstColumnEntityKey,
			parentsFirstColumnAttr,
		} = this.state.parentsPrimaryColumnData;

		let parentData = [];
		for (let parentM1Key of listing.parent_listings) {
			if (listingFD?.[parentM1Key]?.[parentsFirstColumnEntityKey]?.[parentsFirstColumnAttr]) {
				parentData.push(listingFD[parentM1Key][parentsFirstColumnEntityKey][parentsFirstColumnAttr]);
			}
		}

		parentData.sort((a, b) => {
			a = a.toLowerCase();
			b = b.toLowerCase();

			return (a > b) ? 1 : -1;
		});

		return parentData.join(', ');
	}

	// Using the order of the parent's columns, determine what name to display
	getParentComponentName(listingConfigEntityKey, parentsFirstColumnEntityKey, parentsFirstColumnAttr) {
		const {
			listingFD,
		} = this.state;

		return getFDValue(listingFD, `${listingConfigEntityKey}.${parentsFirstColumnEntityKey}.${parentsFirstColumnAttr}`);
	}

	deleteSelectedListings() {
		const {
			app,
			systemGroupUUID,
		} = this.props;

		const {
			selectedListings,
			listingToParentListingsMap,
			listingToListingCollectionsMap,
		} = this.state;

		if (systemGroupUUID) {
			const keys = [];
			const vals = [];

			selectedListings.forEach((listingUUID) => {
				(listingToParentListingsMap['listing.' + listingUUID] ||  []).forEach((listingParentUUID) => {
					keys.push(`${listingParentUUID}.listing.${listingUUID}`);
					vals.push(false);
				});
				(listingToListingCollectionsMap['listing.' + listingUUID] || []).forEach((listingParentUUID) => {
					keys.push(`${listingParentUUID}.listing.${listingUUID}`);
					vals.push(false);
				});
			});

			app.setValue('listings', keys, vals, () => {
				app.saveData('listings', () => {
					this.setState({
						selectedListings: [],
					});
					this.fetchListingDataByListingConfig(false);
				});
			});
		} else {
			app.load(async () => {
				return deleteListings(selectedListings)
					.then(() => {
						this.removeListingsFromFD(selectedListings);
					});
			});
		}
	}

	unassignedListingsFilter(listing) {
		const {
			showUnassigned,
			assignedListings,
		} = this.state;

		if (showUnassigned) {
			return true;
		}

		return assignedListings.includes(`listing.${listing.uuid}`);
	}

	toggleSelectAllRows(areAllRowsChecked) {
		let newTableListings = this.state.tableListings;

		let newSelectedListings = [];
		newTableListings
			.filter(this.unassignedListingsFilter.bind(this))
			.forEach((element) => {
				element.isChecked = !this.state.allRowsSelected;
				if (!areAllRowsChecked) {
					newSelectedListings.push(element.uuid);
				}
			});

		this.setState({
			allRowsSelected:  !this.state.allRowsSelected,
			tableListings:    newTableListings,
			selectedListings: newSelectedListings,
		});
	}

	toggleSelectSingleRow(listingUUID, index) {
		const newTableListings = this.state.tableListings;
		const newSelectedListings = this.state.selectedListings;
		if (~newSelectedListings.indexOf(listingUUID)) {
			newSelectedListings.splice(newSelectedListings.indexOf(listingUUID), 1);
		} else {
			newSelectedListings.push(listingUUID);
		}
		newTableListings[index].isChecked = !newTableListings[index].isChecked;

		this.setState({
			tableListings:    [ ...newTableListings, ],
			selectedListings: [ ...newSelectedListings, ],
		});


	}

	submitChanges() {
		let formSubmit = document.getElementById('crow-form-submit-btn');
		formSubmit.click();
	}

	deleteComponent(IDToDelete, final) {
		let idsToCheck = [];
		idsToCheck.push(IDToDelete);

		let sessionKey = getSession().session;

		let opts = {
			method:  'POST',
			headers: {
				'content_type':   'application/json',
				'X-Auth-Session': sessionKey,
			},
			body: {},
		};

		new kali(opts).post(window._getEnv('BRONCO_URL') + `/w/delete/listing/${IDToDelete}`, {
			success: (_kali, res, data) => {
				console.log(data);
				if (final) {
					this.fetchListings(true);
				}
			},

			failure: (_kali, res, err) => {
				console.error(err);

				if (res.status === 503) {
					this.props.form.props.on503(true);
				}
			},
		});
	}

	listingCollectionHasListingGroupRelation(listingCollection, listing) {
		if (listingCollection.grouped && listing.systems.includes(listingCollection.systemM1Keys[0])) {
			return true;
		}

		return listing.systems && listing.systems.includes(`system.${listingCollection.uuid}`);
	}

	getChildRelations(listingUUID) {
		const {
			listingFD,
		} = this.state;

		const componentRelations = [];

		for (const [ m1Key, m1Data, ] of Object.entries(listingFD)) {
			if (m1Key === `listing.${listingUUID}`) {
				for (const m2Key of Object.keys(m1Data)) {
					if (m2Key.includes('.')) {
						componentRelations.push(`${m1Key}${m2Key}`);
					}
				}
			}
		}

		return componentRelations;
	}

	exportCSV(tableListings = this.state.tableListings) {
		const {
			listingConfigMap,
			listingConfigUUID,
			accountUUID,
		} = this.props;

		const {
			listingConfigTableSettings,
			selectedListings,
			listingToParentListingsMap,
			listingToSystemsMap,
			listingFD,
		} = this.state;

		const activeListingConfig = listingConfigMap[listingConfigUUID];

		const isTier1 = !(activeListingConfig.parentUUID in listingConfigMap);
		const isTier2 = !isTier1 && !(listingConfigMap[activeListingConfig.parentUUID].parentUUID in listingConfigMap);

		let parentNameColumn = '';

		const accountName = listingFD[`account.${accountUUID}`].display_name;
		const accountNameWithUnderscores = accountName.split(' ').join('_');

		// logic for getting the uuid to keep ordering state of the columns
		const headers = Object.values(listingConfigTableSettings);

		const columnHeaders = [];

		headers.forEach((header) => {
			const [ , columnName, ] = Object.entries(listingConfigTableSettings).find(([ , val, ]) => {
				return header === val;
			});
			columnHeaders.push(`"${columnName}"`);
		});

		if (isTier1) {
			columnHeaders.push(`"Systems"`);
		} else {
			columnHeaders.push(`"Parent Listings"`);
			if (isTier2) {
				columnHeaders.push(`"Systems"`);
			}
			Object.entries(listingFD[`listing_config.${activeListingConfig.parentUUID}`]).forEach(([ key, val, ]) => {
				if (key.startsWith('listing_column.')) {
					if (val.order === 1) {
						parentNameColumn = key;
					}
				}
			});
		}

		const dataToCollect = Object.keys(listingConfigTableSettings);

		const bodyRows = [];

		tableListings
			.filter(this.unassignedListingsFilter.bind(this))
			.filter((listing) => {
				return selectedListings.length === 0 || selectedListings.includes(listing.uuid);
			})
			.forEach((listing) => {
				const listingM1Key = `listing.${listing.uuid}`;

				const row = [];
				dataToCollect.forEach((listingColumnM1UUID) => {
					const listingColumnM1Key = `listing_column.${listingColumnM1UUID}`;

					const listingColumnType = Object.keys(listingFD[listingColumnM1Key])
						.find((key) => {
							return key.startsWith('listing_column_type.');
						});

					const {
						type,
					} = listingFD[listingColumnType];


					const hasValue = !!listingFD[listingM1Key][listingColumnM1Key];
					if (hasValue) {
						let value = listingFD[listingM1Key][listingColumnM1Key][type];
						if (value === undefined || value === null) {
							row.push(`"null"`);
							return;
						}

						if (type === 'json' || listingFD[listingColumnType].display_name === 'SMS Message') {
							const {
								area,
								cc,
								country_name,
								n,
							} = JSON.parse(value);
							value = `${country_name};${cc};${area};${n}`;
						} else if (listingFD[listingColumnType].display_name === 'Email Message') {
							const {
								email,
								domain,
							} = JSON.parse(value);
							value = `${email}@${domain}`;
						}

						try {
							value = value.toString().replace(/\n/g, '\\n');
							row.push(`"${value.replace(/"/g, '""')}"`);
						} catch (err) {
							console.warn(`Skipping this value: ${value};`, err);
							row.push(`"null"`);
						}
					} else {
						row.push(`"null"`);
					}
				});

				if (isTier1) {
					let systems;
					if (listingM1Key in listingToSystemsMap) {
						systems = '"';
						systems += listingToSystemsMap[listingM1Key]
							.filter((systemM1Key, index, self) => {
								return self.indexOf(systemM1Key) === index;
							})
							.map((systemM1Key) => {
								const retVal = listingFD[systemM1Key].display_name.replace(/"/g, '""');
								return retVal;
							}).join(';');
						systems += '"';
					} else {
						systems = '""';
					}

					row.push(systems);
				} else {
					let parentsText;
					if (listingM1Key in listingToParentListingsMap) {
						parentsText = '"';
						parentsText += listingToParentListingsMap[listingM1Key]
							.filter((parentM1Key, index, self) => {
								return self.indexOf(parentM1Key) === index;
							})
							.map((parentM1Key) => {
								const retVal = listingFD[parentM1Key][parentNameColumn].string.replace(/"/g, '""');
								return retVal;
							}).join(';');
						parentsText += '"';
					} else {
						parentsText = '""';
					}

					row.push(parentsText);

					if (isTier2) {
						let systemsText = '"';

						const grandParents = {};
						(listingToParentListingsMap[listingM1Key] || []).forEach((parentM1Key) => {
							if (parentM1Key in listingToSystemsMap) {
								listingToSystemsMap[parentM1Key].forEach((systemM1Key) => {
									grandParents[systemM1Key] = true;
								});
							}
						});

						systemsText += Object.keys(grandParents).map((key) => {
							const retVal = listingFD[key].display_name.replace(/"/g, '""');
							return retVal;
						}).join(';');

						systemsText += '"';

						row.push(systemsText);
					}
				}

				bodyRows.push(row);
			});

		for (let shift = 0; shift <= bodyRows.length; shift += 5000) {
			let csvContent = 'data:text/csv;charset=utf-8,';

			const rows = [];

			rows.push(columnHeaders);

			rows.push(...bodyRows.slice(0 + shift, 5000 + shift));

			rows.forEach((rowArray) => {
				let row = rowArray.join(',');
				csvContent += row + '\r\n';
			});

			let encodedUri = encodeURI(csvContent);
			encodedUri = encodedUri.replaceAll('#', '%23');
			let link = document.createElement('a');
			link.setAttribute('href', encodedUri);

			link.setAttribute('download', `${accountNameWithUnderscores}_${activeListingConfig.display_name}_${(shift / 5000) + 1}.csv`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	templateCSV() {
		this.exportCSV([]);
	}

	openView() {
		this.setState({
			showViewWindow: true,
		});
	}

	closeView() {
		this.setState({
			showViewWindow: false,
		});
	}

	hideUnhideAllColumns(selectAllFlag) {
		const {
			columnsToHide,
			listingGroups,
		} = this.state;

		let groupUUIDs = Object.values(listingGroups);

		while (columnsToHide.length > 0) {
			columnsToHide.pop();
		}
		columnsToHide.push('columnToHide');

		if (!selectAllFlag) {
			groupUUIDs.forEach((group) => {
				columnsToHide.push('groups.' + group.uuid);
			});
		}

		this.setState({
			columnsToHide,
		}, () => {
			this.setVariables();
		});
	}

	hideUnhideColumn(id, checkedCount, parentWidth = 0) {
		const {
			listingConfigUUID,
			fd,
		} = this.props;

		const {
			listingFD,
			columnsToHide,
		} = this.state;

		let additionalColumnWidths = 0;
		if (!columnsToHide.includes(id)) {
			if (checkedCount === 1) {
				return;
			}
			columnsToHide.push(id);
			if (id.startsWith('parents.')) {
				additionalColumnWidths += parentWidth;
			} else if (!id.startsWith('groups.')) {
				additionalColumnWidths += getFDValue(fd, `listing_config.${listingConfigUUID}.listing_column.${id}.width`) ||
					getFDValue(listingFD, `listing_config.${listingConfigUUID}.listing_column.${id}.width`);
			}
		} else if (columnsToHide.includes(id)) {
			let indexToRemove = columnsToHide.indexOf(id);
			columnsToHide.splice(indexToRemove, 1);
		}

		this.setState({
			columnsToHide,
		}, () => {
			this.setVariables(additionalColumnWidths);
		});
	}

	decimal_or_1(value) {
		let num = parseInt(value, 10);
		if (num === 100) {
			return 1;
		}
		return num / 100;
	}

	// setVariables takes an optional param called additionalColumnWidths and determines
	// the size (in vw) for each column to display
	setVariables(additionalColumnWidths = 0) {
		console.time('setVariables');
		const {
			listingConfigMap,
			listingConfigUUID,
			listingConfigHasScheduling,
			fd,
		} = this.props;

		const {
			listingFD,
			columnsToHide,
			listingConfigTableSettings,
		} = this.state;

		let activeListingConfig = listingConfigMap[listingConfigUUID];

		let listingWidthCols = {};
		const preComputedWidth = getComputedStyle(document.body).getPropertyValue('--sideNavWidth');
		let sideNavWidth = parseInt(preComputedWidth, 10);
		if (!sideNavWidth) {
			sideNavWidth = 21;
		}

		let listingsWidth = 100 - sideNavWidth;

		let listingConfigEntityKey = `listing_config.${listingConfigUUID}`;
		let listingConfigWidth = getFDValue(fd, `${listingConfigEntityKey}.data_width`) ||
			getFDValue(listingFD, `${listingConfigEntityKey}.data_width`);
		let listingConfigWidthPercentage = listingConfigWidth / 100;
		let listingConfigContentGroupCount = getFDValue(fd, `${listingConfigEntityKey}.content_group_count`) ||
			getFDValue(listingFD, `${listingConfigEntityKey}.content_group_count`);
		let listingColumnUUIDs = Object.keys(listingConfigTableSettings);

		let listingDataWidth = listingsWidth * listingConfigWidthPercentage;
		let numGroupColsShown = listingConfigContentGroupCount;

		let uncheckedCount = 0;
		listingColumnUUIDs.forEach((listingColumnUUID) => {
			if (columnsToHide.includes(listingColumnUUID)) {
				return;
			}
			uncheckedCount++;
		});

		let checkboxCol = 5;
		let columnTotal = checkboxCol;
		if (listingConfigHasScheduling) {
			columnTotal += 5;
		}
		let currentTotalWidth = 0;
		let firstColumnUUID = '';

		// divide the additionalColumnWidths (total width of all hidden columns) by the
		// uncheckedCount to determine how much additional width to give to visible columns
		let additionalWidthForColumn = additionalColumnWidths / uncheckedCount;

		listingColumnUUIDs.forEach((listingColumnUUID) => {
			if (columnsToHide.includes(listingColumnUUID)) {
				return;
			}

			// track the first column for rounding reasons
			if (firstColumnUUID === '') {
				firstColumnUUID = listingColumnUUID;
			}

			let columnWidth = getFDValue(fd, `${listingConfigEntityKey}.listing_column.${listingColumnUUID}.width`) ||
				getFDValue(listingFD, `${listingConfigEntityKey}.listing_column.${listingColumnUUID}.width`);
			let columnPercent = this.decimal_or_1(columnWidth);
			let listingColumnWidth = Math.floor(columnPercent * listingDataWidth);

			if (columnWidth === 0 && !columnsToHide.includes(listingColumnUUID)) {
				columnsToHide.push(listingColumnUUID);
			}

			// if there is an additionalWidthForColumn, convert this to a percent and add
			// the value to the current columnPercent and multiply it by the listingDataWidth
			// to determine it's current width
			if (additionalWidthForColumn > 0) {
				let additionalPercent = this.decimal_or_1(additionalWidthForColumn);
				listingColumnWidth = Math.floor((columnPercent + additionalPercent) * listingDataWidth);
			}

			listingWidthCols[listingColumnUUID] = {
				width: listingColumnWidth,
				left:  columnTotal,
			};
			columnTotal += listingColumnWidth;
			currentTotalWidth += listingColumnWidth;
		});

		// check to see if a tier 1 listing does not have a currentTotalWidth that equals
		// the expected total width, or listingDataWidth. If it does not, then find the rounding
		// difference and add it to the first column. This keeps the columns the correct total.
		let roundingDifference = 0;
		if (listingWidthCols[firstColumnUUID] && activeListingConfig.root && listingDataWidth !== currentTotalWidth) {
			roundingDifference = listingDataWidth - currentTotalWidth;
			listingWidthCols[firstColumnUUID].width = listingWidthCols[firstColumnUUID].width + roundingDifference;
		}

		// for each column that is visible and not the first column the 'left' property needs to
		// be adjusted to account for the roundingDifference.
		listingColumnUUIDs.forEach((listingColumnUUID) => {
			if (columnsToHide.includes(listingColumnUUID)) {
				return;
			}
			if (listingColumnUUID !== firstColumnUUID) {
				listingWidthCols[listingColumnUUID].left = listingWidthCols[listingColumnUUID].left + roundingDifference;
			}
		});

		let listingsContentGroupsTotalWidth = (listingsWidth * (1 - listingConfigWidthPercentage)) - checkboxCol;
		let listingsContentGroupColWidth = listingsContentGroupsTotalWidth / numGroupColsShown;

		this.setState({
			listingWidthCols,
			listingsContentGroupColWidth,
		});
		console.timeEnd('setVariables');
	}

	toggleShowUnassigned() {
		const {
			showUnassigned,
		} = this.state;

		this.setState({
			showUnassigned: !showUnassigned,
		});
	}

	getColumns(showAdvanced, filterTerms = '') {
		const {
			app,
			listingConfigMap,
			listingConfigUUID,
			fd,
		} = this.props;

		const {
			columnsToHide,
			listingFD,
		} = this.state;

		let activeListingConfig = listingConfigMap[listingConfigUUID];

		let data = [];
		let contentGroups = [];
		let groupNames = [];
		let listingColumnUUIDs = Object.keys(this.state.listingConfigTableSettings);
		let listingConfigEntityKey = `listing_config.${listingConfigUUID}`;
		let listingConfigWidth = getFDValue(listingFD, `${listingConfigEntityKey}.data_width`);
		let listingConfigContentGroupCount = getFDValue(listingFD, `${listingConfigEntityKey}.content_group_count`);
		let checkedDataColumnCount = 0;
		let totalColumnWidth = 0;

		let parentEntityKey = `listing_collection.${activeListingConfig.parentUUID}`;
		if (!activeListingConfig.root) {
			parentEntityKey = `listing.${activeListingConfig.parentUUID}`;
		}

		listingColumnUUIDs.forEach((listingColumnUUID, i) => {
			let columnWidth = 0;
			if (showAdvanced) {
				columnWidth = getFDValue(fd, `${listingConfigEntityKey}.listing_column.${listingColumnUUID}.width`) ||
					getFDValue(listingFD, `${listingConfigEntityKey}.listing_column.${listingColumnUUID}.width`);
				totalColumnWidth += columnWidth;
			}

			let checked = !columnsToHide.includes(listingColumnUUIDs[i]);
			if (checked) {
				checkedDataColumnCount++;
			}

			let individualWidthInput = (
				<div className={style.devRow}>
					<div className={`${style.columnLabel} ${style.inputLabel}`}>
						{this.state.listingConfigTableSettings[listingColumnUUID]}
					</div>
					<input className={`${style.input} ${style.inputSmall}`}
						id={`${listingColumnUUID}HeaderWidth`}
						defaultValue={columnWidth}
						onBlur={(e) => {
							const orgValue = getFDValue(listingFD, `${listingConfigEntityKey}.listing_column.${listingColumnUUID}.width`);
							const newValue = parseInt(e.target.value, 10);
							if (orgValue !== newValue) {
								let keys = [ `${listingConfigEntityKey}.listing_column.${listingColumnUUID}.width`, ];

								let values = [ newValue, ];

								app.setValue('listings', keys, values);

								if (!activeListingConfig.root) {
									// update the parentWidthInput accordingly
									let parentWidthInput = document.getElementById(`${parentEntityKey}HeaderWidth`);
									let parentWidthValue = (100 - totalColumnWidth) + (orgValue - newValue);
									parentWidthInput.value = parentWidthValue;
								}
							}
						}}
					></input>
					<div>%</div>
				</div>
			);

			let elm = (
				<div className={style.tagElement} key={`${listingColumnUUID}_${i}_${i}`}>
					<div onClick={(e) => {
						e.preventDefault();
						this.hideUnhideColumn(listingColumnUUID, checkedDataColumnCount);
					}}>
						<CustomCheckbox checked={checked} />
					</div>
					{!showAdvanced ? this.state.listingConfigTableSettings[listingColumnUUID] : individualWidthInput}
				</div>
			);
			data.push(elm);
		});

		if (!activeListingConfig.root) {
			let checked = !columnsToHide.includes(`parents.${parentEntityKey}`);
			if (checked) {
				checkedDataColumnCount++;
			}

			let individualWidthInput = (
				<div className={style.devRow}>
					<div className={`${style.columnLabel} ${style.inputLabel}`}>
						{getFDValue(listingFD, `${parentEntityKey}.display_name`)}
					</div>
					<input className={`${style.input} ${style.inputSmall}`}
						id={`${parentEntityKey}HeaderWidth`}
						defaultValue={100 - totalColumnWidth}
						disabled={true}
					>
					</input>
					<div>%</div>
				</div>
			);

			let elm = (
				<div className={style.tagElement} key={`${parentEntityKey}`}>
					<div onClick={(e) => {
						e.preventDefault();
						this.hideUnhideColumn(`parents.${parentEntityKey}`, checkedDataColumnCount, (100 - totalColumnWidth));
					}}>
						<CustomCheckbox checked={checked} />
					</div>
					{!showAdvanced &&
						getFDValue(listingFD, `${parentEntityKey}.display_name`)
					}
					{showAdvanced &&
						individualWidthInput
					}
				</div>
			);
			data.push(elm);
		}

		let saveButton = null;
		let widthInput = null;
		if (activeListingConfig.root) {
			let groupsInput = (
				<>
					<div className={style.sectionHeaderText}>Content Groups</div>
				</>
			);
			if (showAdvanced) {
				let directions = (
					<div
						key={'showAdvanced'}
						className={totalColumnWidth === 100 ? style.colDirectionsCont : `${style.colDirectionsContError} ${style.colDirectionsCont}`}
					>
						Columns must = 100%
					</div>
				);
				data.push(directions);

				groupsInput = (
					<React.Fragment>
						<div className={style.sectionHeader}>
							<div className={style.sectionHeaderText}>Content Groups</div>
							<div className={style.contentGroupSettings}>
								<div className={style.contentGroupWidth}>
									<input className={`${style.input} ${style.inputSmall}`}
										id='groupColumnWidth'
										size='2'
										maxLength='2'
										defaultValue={listingConfigContentGroupCount}
										onBlur={(e) => {
											const orgValue = getFDValue(listingFD, `${listingConfigEntityKey}.content_group_count`);
											const newValue = parseInt(e.target.value, 10);
											if (orgValue !== newValue) {
												app.setValue('listings', `${listingConfigEntityKey}.content_group_count`, newValue);
											}
										}}
									></input>
								</div>
							</div>
						</div>
					</React.Fragment>
				);
				widthInput = (
					<div className={style.dataColumnWidth}>
						<input className={`${style.input} ${style.inputSmall}`}
							id='dataColumnWidth'
							size='2'
							maxLength='2'
							defaultValue={listingConfigWidth}
							onBlur={(e) => {
								const orgValue = getFDValue(listingFD, `${listingConfigEntityKey}.data_width`);
								const newValue = parseInt(e.target.value, 10);
								if (orgValue !== newValue) {
									app.setValue('listings', `${listingConfigEntityKey}.data_width`, newValue);
								}
							}}
						></input>
						<p>%</p>
					</div>
				);
			}

			contentGroups.push(groupsInput);
			this.state.listingGroups
				.sort((groupA, groupB) => {
					if ((groupA?.name || '').toLowerCase() > (groupB?.name || '').toLowerCase()) {
						return 1;
					}

					return -1;
				}).forEach((group, i) => {
					if (group.name && group.name.toLowerCase().includes(filterTerms.toLowerCase())) {
						let groupName = group.name;
						if (groupNames.includes(groupName)) {
							return;
						}
						groupNames.push(groupName);

						let idToUse = group.uuid;
						let groupID = `groups.${idToUse}`;
						let checked = !columnsToHide.includes(groupID);
						let elm = (
							<div className={style.tagElement} key={`${groupID}_${i}-${i}`}>
								<div key={`${groupID}_${i}`} onClick={(e) => {
									e.preventDefault();
									this.hideUnhideColumn(groupID);
								}}>
									<CustomCheckbox checked={checked} />
								</div>
								{groupName}
							</div>
						);
						contentGroups.push(elm);
					}
				});
		}

		if (showAdvanced) {
			saveButton = (
				<button
					className='button'
					onClick={(e) => {
						e.preventDefault();
						app.saveData('', this.setVariables.bind(this));
					}}
				>
					<CustomIcon icon='save' /> Save
				</button>
			);
		}

		return (
			<div className={style.viewWindowContent}>
				<div className={style.dataColumn}>
					<div className={style.sectionHeader}>
						<div className={style.sectionHeaderText}>Data</div>
						{showAdvanced ? widthInput : null}
					</div>
					{data}
				</div>
				<div className={style.contentGroupsColumn}>
					{contentGroups}
				</div>
				<div className={style.saveSettingsButton}>
					{saveButton}
				</div>
			</div>
		);
	}

	findRootConfig(configMap, startingConfig) {
		let currentConfig = startingConfig;
		if (configMap) {
			while (configMap[currentConfig] && !configMap[currentConfig].startsWith('account.')) {
				currentConfig = configMap[currentConfig];
			}
		}
		return currentConfig.split('.')[1];
	}

	findRootListings(targetListing) {
		const {
			listingToParentListingsMap,
		} = this.state;
		const visited = new Set();
		const roots = [];

		function dfs(currentListing) {
			// visited this node before, return
			if (visited.has(currentListing)) {
				return;
			}
			visited.add(currentListing);

			const parents = listingToParentListingsMap[currentListing] || [];

			// If the listing has no parents, it is a root
			if (parents.length === 0) {
				roots.push(currentListing);
				return;
			}

			// Otherwise, recurse on all parents
			for (const parent of parents) {
				dfs(parent);
			}
		}

		dfs(targetListing);

		return roots;
	}

	resetPendingChangesForUI() {
		const {
			pendingChanges,
		} = this.props;

		let entityKeys = Object.keys(pendingChanges);
		let tableListings = this.state.tableListings;

		entityKeys.forEach((entityKey) => {
			const [ m1, m1ID, m2, m2ID, ] = entityKey.split('.');

			tableListings.forEach((tableListing) => {
				if (tableListing.componentID === m2ID) {
					let index = tableListing.parentUUIDs.indexOf(m1ID);

					let addOrRemove = pendingChanges[entityKey];
					if (addOrRemove && index === -1) {
						tableListing.parentUUIDs.push(m1ID);
					}
					if (!addOrRemove && index > -1) {
						tableListing.parentUUIDs.splice(index, 1);
					}
				}
			});
		});

		this.setState({
			tableListings,
		});
	}

	toggleGroup(group, ...listingIndexes) {
		const {
			app,
		} = this.props;

		const {
			tableListings,
			listingGroups,
		} = this.state;

		let nonExclusiveSystemPresent = false;
		let addOrRemove = true;

		const nonExclusiveSystemUUIDs = listingGroups
			.filter((listingGroup) => {
				return listingGroup.nonExclusive;
			})
			.flatMap((listingGroup) => {
				return listingGroup.systemM1Keys;
			});

		group.systemM1Keys.forEach((systemM1Key) => {
			if (nonExclusiveSystemUUIDs.includes(systemM1Key)) {
				nonExclusiveSystemPresent = true;
			}
		});

		let obj = {};

		const shiftedListingIndexes = [];
		let listingIndexOffset = 0;
		for (let i = 0; i <= Math.max(...listingIndexes) + listingIndexOffset; i++) {
			while (
				i + listingIndexOffset < tableListings.length &&
				!this.unassignedListingsFilter(tableListings[i + listingIndexOffset])
			) {
				listingIndexOffset++;
			}

			if (listingIndexes.includes(i)) {
				shiftedListingIndexes.push(i + listingIndexOffset);
			}
		}

		// Perform the state update immutably
		this.setState((prevState) => {
			const newTableListings = prevState.tableListings.map((listing, index) => {
				if (!shiftedListingIndexes.includes(index)) {
					return listing;
				}

				const updatedListing = {
					...listing,
				};

				if (!updatedListing.componentGroups) {
					updatedListing.componentGroups = [];
				}

				let systemIndex = updatedListing.systems.indexOf(`system.${group.uuid}`);
				if (group.grouped) {
					const systemM1ToCheck =
						group.systemM1Keys.length === 1 ?
							group.systemM1Keys[0] :
							group.systemM1Keys[1];
					systemIndex = updatedListing.systems.indexOf(systemM1ToCheck);
				}

				if (systemIndex !== -1) {
					addOrRemove = false;
					if (group.grouped) {
						updatedListing.systems = updatedListing.systems.filter(
							(system) => {
								return !group.systemM1Keys.includes(system);
							}
						);
					} else {
						updatedListing.systems = updatedListing.systems.filter(
							(system, idx) => {
								return idx !== systemIndex;
							}
						);
					}

					group.listingCollectionM1Keys.forEach((listingCollectionM1Key) => {
						obj[`${listingCollectionM1Key}.listing.${listing.uuid}`] = false;
					});
				}

				if (systemIndex === -1) {
					if (group.grouped) {
						updatedListing.systems = [
							...updatedListing.systems,
							...group.systemM1Keys,
						];
					} else {
						updatedListing.systems = [
							...updatedListing.systems,
							`system.${group.uuid}`,
						];
					}

					group.listingCollectionM1Keys.forEach((listingCollectionM1Key) => {
						obj[`${listingCollectionM1Key}.listing.${listing.uuid}`] = true;
					});
				}

				return updatedListing;
			});

			if (nonExclusiveSystemPresent) {
				return {
					confirmModal:        true,
					confirmModalContent: (
						<>
							<p>{`You are ${addOrRemove ? 'assigning this listing to' : 'unassigning this listing from'} a content group that is shared by other system groups.
							Please be aware that this action will affect other systems.`}</p>
							<div className="confirm-modal-buttons">
								<button
									className="button"
									onClick={(e) => {
										e.preventDefault();
										this.setState(
											{
												confirmModal:        false,
												confirmModalContent: '',
												tableListings:       newTableListings,
											},
											() => {
												app.setValue(
													'listings',
													Object.keys(obj),
													Object.values(obj)
												);
											}
										);
									}}
								>
									Yes
								</button>
								<button
									className="button"
									onClick={(e) => {
										e.preventDefault();
										this.setState({
											confirmModal:        false,
											confirmModalContent: '',
										});
									}}
								>
									No
								</button>
							</div>
						</>
					),
					tableListings: prevState.tableListings, // Keep tableListings unchanged until confirmation
				};
			}
			return {
				tableListings: newTableListings,
			};

		}, () => {
			if (!nonExclusiveSystemPresent) {
				app.setValue('listings', Object.keys(obj), Object.values(obj));
			}
		});
	}

	buildAssignedListings() {
		const {
			listingFD,
		} = this.state;

		const assignedListings = [];

		const searchChildTiers = (listingM1) => {
			if (!listingFD[listingM1]) {
				return;
			}

			for (const [ m2Key, m2Data, ] of Object.entries(listingFD[listingM1])) {
				if (m2Key.startsWith('listing.') && m2Data) {
					assignedListings.push(m2Key);
					searchChildTiers(m2Key);
				}
			}
		};

		for (const [ m1Key, m1Data, ] of Object.entries(listingFD)) {
			if (m1Key.startsWith('listing_collection.') && m1Data) {
				for (const [ m2Key, m2Data, ] of Object.entries(m1Data)) {
					if (m2Key.startsWith('listing.') && m2Data && !listingFD[m2Key]?.readonly) {
						assignedListings.push(m2Key);
						searchChildTiers(m2Key);
					}
				}
			}
		}

		this.setState({
			assignedListings,
		});
	}

	render() {
		const {
			app,
			accountUUID,
			systemGroupUUID,
			buildingsDataArray,
			listingConfigUUID,
			listingConfigMap,
			pendingChanges,
			listingConfigHasScheduling,
			listingConfigIsIntegration,
		} = this.props;

		const {
			allRowsSelected,
			listingToListingCollectionsMap,
			listingToSystemsMap,
			listingConfigsWithSystems,
			listingConfigToListingsMap,
			listingFD,
			tableListings,
			filteredTableListings,
			selectedListings,
			showUnassigned,
			columnsToHide,
		} = this.state;

		let activeListingConfig = listingConfigMap[listingConfigUUID];
		if (!activeListingConfig) {
			return '';
		}

		if (Object.keys(listingConfigToListingsMap).length === 0) {
			return '';
		}

		let confirmModal = '';
		if (this.state.confirmModal === true) {
			confirmModal = (
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.confirmModalContent}
					</div>
				</div>
			);
		}

		let originalColumns = this.getColumns(false);

		return (
			<>
				{confirmModal}
				<>
					<div
						className={style.unassignedListingsHeader}
						onClick={() => {
							this.toggleShowUnassigned();
						}}
						data-testing-info={'input--checkbox--show-unassigned'}
					>
						<CustomCheckbox checked={showUnassigned} />
						{showUnassigned ? 'Showing unassigned' : 'Hiding unassigned'}
						<i>
							{!showUnassigned && tableListings.length !== filteredTableListings.length && '(There are unassigned items in this list.)'}
						</i>
					</div>
					<ViewWindow
						app={this.props.app}
						show={this.state.showViewWindow}
						showViewAllButtons={this.state.parentsPrimaryColumnData}
						customWidth={'50vw'}
						originalColumns={originalColumns}
						close={this.closeView}
						getColumns={this.getColumns}
						toggleContentGroups={this.hideUnhideAllColumns}
					/>

					<ListingsTable
						app={app}
						accountUUID={accountUUID}
						systemGroupUUID={systemGroupUUID}
						buildingsDataArray={buildingsDataArray}
						listingFD={listingFD}
						listingConfigUUID={listingConfigUUID}
						pendingChanges={pendingChanges}
						selectedListings={selectedListings}

						listingToSystemsMap={listingToSystemsMap || {}}
						listingConfigMap={listingConfigMap}
						listingConfigHasScheduling={listingConfigHasScheduling}
						listingConfigIsIntegration={listingConfigIsIntegration}
						listingToListingCollectionsMap={listingToListingCollectionsMap || {}}
						listingConfigsWithSystems={listingConfigsWithSystems}
						allRowsSelected={allRowsSelected || false}
						showUnassigned={showUnassigned}

						listingView={this}
						findRootListings={this.findRootListings}
						deleteSelectedListings={this.deleteSelectedListings}
						getParentComponentName={this.getParentComponentName}
						getParentListingData={this.getParentListingData}
						listingCollectionHasListingGroupRelation={this.listingCollectionHasListingGroupRelation}
						getChildRelations={this.getChildRelations}
						exportCSV={this.exportCSV}
						templateCSV={this.templateCSV}
						openView={this.openView}
						toggleGroup={this.toggleGroup}
						toggleSelectAllRows={this.toggleSelectAllRows}
						toggleSelectSingleRow={this.toggleSelectSingleRow}
						fetchListingDataByListingConfig={this.fetchListingDataByListingConfig}
						toggleShowUnassigned={this.toggleShowUnassigned}

						array={filteredTableListings}
						tableSettings={this.state.listingConfigTableSettings}
						uniqueSettingsListForAdd={this.state.uniqueSettingsListForAdd}
						listingComponentTypeID={this.state.listingComponentTypeID}
						activeListingConfig={activeListingConfig}
						parentsPrimaryColumnData={this.state.parentsPrimaryColumnData}
						listingGroups={this.state.listingGroups}
						setGroupValue={this.props.setGroupValue}
						columnsToHide={columnsToHide}
						listingConfigToListingsMap={listingConfigToListingsMap}
						listingWidthCols={this.state.listingWidthCols}
						listingsContentGroupColWidth={this.state.listingsContentGroupColWidth}
					/>
				</>
			</>
		);
	}
}

export default ListingsView;