import React from 'react';

class CrowFieldNumber extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};

		this.debounceTimeout = null;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	componentWillUnmount() {
		clearTimeout(this.debounceTimeout);
	}

	debouncedSetValue = (newValue) => {
		clearTimeout(this.debounceTimeout);
		this.debounceTimeout = setTimeout(() => {
			const { app, entity } = this.props;
			app.setValue('system', entity.name, newValue);
		}, 300);
	};

	handleInputChange = (e) => {
		const newValue = parseInt(e.target.value, 10);

		if (!isNaN(newValue)) {
			this.setState({ value: newValue }, () => {
				this.debouncedSetValue(this.state.value);
			});
		}
	};

	render() {
		const {
			entity,
		} = this.props;

		let {
			attr = {},
		} = entity || {};

		let {
			value,
		} = this.state;

		if (typeof value === 'undefined') {
			value = 0;
		}

		return (
			<div className="numberPicker">
				<div className="label">
					<label>{attr.label}</label>
				</div>
				<button
					onClick={(e) => {
						e.preventDefault();
						this.setState((prevState) => 
							{
								if (prevState.value === 0) {
									return null;
								}

								return { value: prevState.value - 1 }
							},
							() => { this.debouncedSetValue(this.state.value) }
						);
					}}
				>-</button>

				<input
					type="number"
					value={value}
					onChange={this.handleInputChange}
					min="0"
					step="1"
				/>

				<button
					onClick={(e) => {
						e.preventDefault();
						this.setState((prevState) => 
							({ value: prevState.value + 1 }),
							() => { this.debouncedSetValue(this.state.value) }
						);
					}}
				>+</button>
			</div>
		);
	}
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.elements.number'] = CrowFieldNumber;
export default CrowFieldNumber;