export const providerListToColumnMap = {
	Placewise: {
		// Should be ordered in the order that it should be displayed
		Events: {
			title:       'Text Small',
			description: 'Text Large',
			date_text:   'Text Medium',
			short_text:  'Text Small',
			image:       'Image',
			location:    'Text Small',
		},
		Stores: {
			title:           'Name',
			description:     'Text Large',
			tenant_location: 'Text Small',
			landmark_id:     'Location (apt/suite)',
			phone:           'Text Small',
			logo:            'Image',
			image:           'Image',
			mall_store_url:  'Text Small',
			hours:           'Text Large',
		},
		Categories: {
			name: 'Name',
		},
	},
	USCourts: {
		// Should be ordered in the order that it should be displayed
		Courts: {
			FULLDATETIME:        'Text Small',
			FULLDATETIMEDISPLAY: 'Text Small',
			TIMEONLY:            'Text Small',
			CASELOCATION:        'Text Small',
			JUDGEFULLNAME:       'Text Small',
			CASENUMBER:          'Text Small',
			CASETITLE:           'Text Small',
			CASETYPE:            'Text Small',
			Map:                 'Image',
			FULLDATETIMEUNIX:    'Text Small',
		},
	},
	Pylot: {
		// Should be ordered in the order that it should be displayed
		Stores:     {
			title:          'Name',
			store_logo:     'Image',
			phone:          'Text Small',
			description:    'Text Large',
			location:       'Text Small',
			landmark_id:    'Location (apt/suite)',
			mall_store_url: 'Text Small',
			hours:          'Text Large',
		},
		Events:     {
			title:       'Name',
			excerpt:     'Text Large',
			content:     'Text Large',
			location:    'Text Small',
			event_image: 'Image',
			date:        'Text Medium',
			date_time:   'Text Small',
		},
		Categories: {
			name:        'Name',
			description: 'Text Large',
		},
	},
};

export const providerListToModalContent = {
	placewise: {
		label:  'Placewise',
		fields: {
			email:    'User ID',
			authCode: 'Auth Code',
			mallID:   'Mall ID',
		},
	},
	uscourts: {
		label:  'US Courts',
		fields: {
			authCode: 'Court Feed URL',
		},
	},
	pylot: {
		label:  'Pylot',
		fields: {
			authCode: 'Mall Website URL',
		},
	},
};