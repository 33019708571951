import React, {} from 'react';

// Import styles
import style from './SystemsHierarchyTable.module.css';
import SystemsTable from './SystemsTable';

const SystemsHierarchyTable = (props) => {
	const {
		array,
		openSystemsDetailModal,
		defaultExpanded,
		expandAllRows,
		collapseAllRows,
	} = props;

	return (
		<>
			<div className={style.secondaryNav}>
				<div className='button' onClick={(e) => {
					e.preventDefault();
					expandAllRows();
				}}>
					Expand All
				</div>
				<div className='button' onClick={(e) => {
					e.preventDefault();
					collapseAllRows();
				}}>
					Collapse All
				</div>
			</div>
			<SystemsTable
				style={style}
			    array={array}
			    openSystemsDetailModal={openSystemsDetailModal}
			    defaultExpanded={defaultExpanded}
			/>
		</>
	);
};

export default SystemsHierarchyTable;