import React from 'react';
import Select from 'react-select';
import {
	getFDValue,
} from 'Vulture/Helpers';

class CrowFieldCustomVultureResolutionDropDown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			resolutionValue:    getFDValue(props.fd, props.entity.name),
			orientationValue:   getFDValue(props.fd, props.entity.altName + '.orientation'),
			orientationOptions: [],
		};
	}

	componentDidMount() {
		const orientationOptions = this.setOrientationValues();
		this.setState({
			orientationOptions,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const currentResolution = getFDValue(this.props.fd, this.props.entity.name);
		if (currentResolution !== prevState.resolutionValue) {
			const orientationOptions = this.setOrientationValues(true);
			this.setState({
				resolutionValue: currentResolution,
				orientationOptions,
			});
		}
	}

	setOrientationValues(isNewValue = false) {
		const {
			fd, entity, app,
		} = this.props;
		let {
			orientationOptions = [],
		} = entity || {};

		let originalOrientationValue = getFDValue(fd, entity.altName + '.orientation');
		let resolution = getFDValue(fd, entity.name);

		let currentOrientationValue = originalOrientationValue;

		if (resolution) {
			let XY = resolution.split('x');
			let X = parseInt(XY[0], 10);
			let Y = parseInt(XY[1], 10);

			if (Y > X) {
				orientationOptions = [
					{
						value: 'portraitleft',
						label: 'Portrait Left',
					},
					{
						value: 'portraitright',
						label: 'Portrait Right',
					},
				];
			} else {
				orientationOptions = [
					{
						value: 'landscape',
						label: 'Landscape',
					},
					{
						value: 'landscapeflipped',
						label: 'Landscape (flipped)',
					},
				];
			}

			if (isNewValue || currentOrientationValue === undefined || currentOrientationValue === null) {
				currentOrientationValue = orientationOptions[0]?.value || 'landscape';
			} else {
				const isValidOption = orientationOptions.some((opt) => {
					return opt.value === currentOrientationValue;
				});
				if (!isValidOption) {
					currentOrientationValue = orientationOptions[0]?.value || 'landscape';
				}
			}
		} else if (isNewValue || currentOrientationValue === undefined || currentOrientationValue === null) {
			currentOrientationValue = 'landscape';
		}

		if (currentOrientationValue !== originalOrientationValue) {
			app.setValue('system', entity.altName + '.orientation', currentOrientationValue);
		}

		this.setState({
			orientationValue: currentOrientationValue,
		});

		return orientationOptions;
	}

	render() {
		const {
			fd, entity, app,
		} = this.props;
		const {
			orientationValue, orientationOptions,
		} = this.state;

		let {
			attr = {}, options = [],
		} = entity || {};
		let defaultOption = null;
		options.forEach((option, i) => {
			if (option.value === getFDValue(fd, entity.name)) {
				defaultOption = i;
			}
		});

		let orientationIndex = 0;
		orientationOptions.forEach((option, i) => {
			if (option.value === orientationValue) {
				orientationIndex = i;
			}
		});

		return (
			<div className={`drop-down`}>
				<div className="label">
					<label>{attr.label}</label>
				</div>
				<Select
					className={entity.style}
					options={options}
					defaultValue={options[defaultOption]}
					onChange={(value) => {
						this.setState({
							value,
							resolutionValue: value.value,
						}, () => {
							this.setOrientationValues(true);
							app.setValue('system', entity.name, value.value);
						});
					}}
				/>
				<div className="text-small">
					<div className="label">
						<label>Orientation</label>
					</div>
					<Select
						className={entity.style}
						options={orientationOptions}
						value={orientationOptions[orientationIndex]}
						onChange={(orientation) => {
							this.setState({
								orientationValue: orientation.value,
							});
							app.setValue('system', entity.altName + '.orientation', orientation.value);
						}}
					/>
				</div>
			</div>
		);
	}
}


if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.elements.resolution.drop.down'] = CrowFieldCustomVultureResolutionDropDown;
export default CrowFieldCustomVultureResolutionDropDown;