import React from 'react';
import Cookie from 'js-cookie';
import CustomIcon from '../Common/CustomIcon/CustomIcon.js';
import kali from 'kali';
import md5 from 'md5';
import Select from 'react-select';
import TemplateSkeleton from './TemplateSkeleton.js';
import {
	v4 as uuidv4,
} from 'uuid';

import style from './TemplateBuilder.module.css';
import {
	getSession,
} from 'Vulture/Session.js';

import {
	broncoURL,
	vultureENV,
} from 'Vulture/ENV.js';

class TemplateBuilder extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTemplate: {},
			category:       {},
			config:         {},
			theme:          {},
			templateName:   '',
			bucketName:     'ts-theme-editor',
		};

		this.categoryRef = React.createRef();
		this.configRef = React.createRef();
		this.themeRef = React.createRef();
	}

	componentDidUpdate(prevProps) {
		const {
			accountUUID,
			step,
		} = this.props;

		if (prevProps.accountUUID !== accountUUID) {
			this.clearTemplateData();
		}

		if (prevProps.step !== step) {
			this.clearTemplateData();
		}
	}

	clearTemplateData() {
		// refs are not rendered during step 2 & 3.
		if (this.categoryRef.current && this.configRef.current && this.themeRef.current) {
			this.categoryRef.current.select.clearValue();
			this.configRef.current.select.clearValue();
			this.themeRef.current.select.clearValue();
		}

		this.setState({
			activeTemplate: {},
			category:       {},
			config:         {},
			templateName:   '',
			theme:          {},
		});

		this.props.setSkeletonHasUpdated();
	}

	// TODO: Do we need this?
	copyTemplate() { }

	deleteTemplate() {
		const {
			activeTemplate,
		} = this.state;

		let inUse = false;
		window.form.getMatchingKeys(`system.*.template.${activeTemplate.uuid}`).forEach((entityKey) => {
			if (window.form.getValue(entityKey)) {
				inUse = true;
			}
		});

		if (inUse) {
			alert(`Please unassign ${activeTemplate.display_name} from all systems before deleting.`);
			return;
		}

		if (window.confirm(`Are you sure you want to delete ${activeTemplate.display_name}?`)) {
			const {
				accountUUID, stateCallback,
			} = this.props;

			let pendingUpdates = {
				[`account.${accountUUID}.template.${activeTemplate.uuid}`]:                            false,
				[`template.${activeTemplate.uuid}.template_category.${activeTemplate.category.uuid}`]: false,
				[`template.${activeTemplate.uuid}.template_config.${activeTemplate.config.uuid}`]:     false,
				[`template.${activeTemplate.uuid}.template_theme.${activeTemplate.theme.uuid}`]:       false,
			};

			// Trigger Save button in SystemProgramming
			stateCallback('pendingUpdates', pendingUpdates);

			// Add to form.state.newValue
			window.form.setValue(Object.keys(pendingUpdates), Object.values(pendingUpdates));

			this.clearTemplateData();
		}
	}

	stateCallback(key, val) {
		if (typeof key !== undefined && typeof val !== undefined) {
			this.setState({
				[key]: val,
			});
		}

		// Update category, config, and theme if activeTemplate was changed through modal.
		if (key === 'activeTemplate') {
			this.handleActiveTemplateChange(val);
		}
	}

	handleActiveTemplateChange(activeTemplate, isClone) {
		const {
			globalTemplateData,
			openModal,
			pendingUpdates,
			assignmentMap,
		} = this.props;

		// Ask user if they want to save changes when switching active templates.
		// Ignore when we're cloning a custom configuration.
		if (Object.keys(pendingUpdates).length > 0 && !isClone) {
			openModal('activeTemplate', activeTemplate, this.stateCallback.bind(this));
			return;
		} else if (Object.keys(assignmentMap).length > 0 && !isClone) {
			openModal('activeTemplate', activeTemplate, this.stateCallback.bind(this));
			return;
		}

		let category = {};
		let config = {};
		let theme = {};

		globalTemplateData.forEach((template_category) => {
			if (template_category.uuid === activeTemplate?.category?.uuid) {
				category = template_category;

				template_category.template_config_list.forEach((categoryToConfig) => {
					let cloneParentUUID;
					// If the selected config isn't global, find the parent it was cloned from.
					if (activeTemplate.config.is_global === false) {
						cloneParentUUID = window.form.getValue(`template_config.${activeTemplate.config.uuid}.clone_parent_uuid`);
					}

					// If the config owned by a category matches the cloneParentUUID:
					// Modify the config object to use the proper skeleton and preserve the parent themes.
					if (categoryToConfig.template_config.uuid === cloneParentUUID) {
						let templateSkeletonUUID;
						window.form.getMatchingKeys(`template_config.${activeTemplate.config.uuid}.template_skeleton.*`).forEach((entityKey) => {
							const {
								m2ID,
							} = window.form.parseEntityName(entityKey);
							templateSkeletonUUID = m2ID;
						});

						config = categoryToConfig.template_config;
						config = {
							...config,
							clone_parent_uuid:      cloneParentUUID,
							is_global:              false,
							uuid:                   activeTemplate.config.uuid,
							template_skeleton_list: {
								template_config_uuid: activeTemplate.config.uuid,
								template_skeleton:    {
									data: window.form.getValue(`template_skeleton.${templateSkeletonUUID}.data`),
									hash: window.form.getValue(`template_skeleton.${templateSkeletonUUID}.hash`),
									uuid: templateSkeletonUUID,
								},
								template_skeleton_uuid: templateSkeletonUUID,
							},
						};

						config.template_theme_list.forEach((configToTheme) => {
							if (configToTheme.template_theme.uuid === activeTemplate?.theme?.uuid) {
								theme = configToTheme.template_theme;
							}
						});

						return;
					}

					if (categoryToConfig.template_config.uuid === activeTemplate?.config?.uuid) {
						config = categoryToConfig.template_config;

						categoryToConfig.template_config.template_theme_list.forEach((configToTheme) => {
							if (configToTheme.template_theme.uuid === activeTemplate?.theme?.uuid) {
								theme = configToTheme.template_theme;
							}
						});
					}
				});
			}
		});

		this.setState({
			activeTemplate,
			category,
			config,
			theme,
			templateName: activeTemplate.display_name,
		});
	}

	updatePendingTemplateChanges() {
		const {
			activeTemplate, category, config, theme, templateName,
		} = this.state;
		const {
			accountUUID,
			stateCallback,
		} = this.props;

		if (category.uuid && config.uuid && theme.uuid && templateName !== '') {
			const {
				uuid = uuidv4(),
			} = activeTemplate;

			let pendingUpdates = {
				[`template.${uuid}.display_name`]:                       templateName,
				[`account.${accountUUID}.template.${uuid}`]:             true,
				[`template.${uuid}.template_category.${category.uuid}`]: true,
				[`template.${uuid}.template_config.${config.uuid}`]:     true,
				[`template.${uuid}.template_theme.${theme.uuid}`]:       true,
			};

			// Remove previous relations if a difference is found.
			if (activeTemplate.uuid) {
				if (activeTemplate.category.uuid !== category.uuid) {
					pendingUpdates = {
						...pendingUpdates,
						[`template.${uuid}.template_category.${activeTemplate.category.uuid}`]: false,
					};
				}
				if (activeTemplate.config.uuid !== config.uuid) {
					pendingUpdates = {
						...pendingUpdates,
						[`template.${uuid}.template_config.${activeTemplate.config.uuid}`]: false,
					};
				}
				if (activeTemplate.theme.uuid !== theme.uuid) {
					pendingUpdates = {
						...pendingUpdates,
						[`template.${uuid}.template_theme.${activeTemplate.theme.uuid}`]: false,
					};
				}
			}

			// Trigger Save button in SystemProgramming
			stateCallback('pendingUpdates', pendingUpdates);

			// Add to form.state.newValue
			window.form.setValue(Object.keys(pendingUpdates), Object.values(pendingUpdates));

			this.clearTemplateData();

			return;
		}

		alert('Please fill out all fields.');
	}

	cloneConfig(parentPendingUpdates) {
		const {
			activeTemplate, config,
		} = this.state;

		const url = `${broncoURL}/w/clone/template_config`;
		const sessionKey = getSession().session;

		return new Promise((resolve, reject) => {
			new kali({
				body: {
					'data': [
						config.uuid,
					],
					'exclusions': [
						'setting',
						'setting_type',
					],
				},
				headers: {
					'content_type':   'application/json',
					'X-Auth-Session': sessionKey,
				},
				method: 'POST',
			}).post(url, {
				success: (_kali, res, contents) => {
					if (contents && contents.data && contents.keys) {
						// Find the cloned template_config UUID.
						Object.values(contents.keys).forEach((cloneKeyArr) => {
							if (Array.isArray(cloneKeyArr)) {
								const cloneConfigKey = cloneKeyArr[0];
								const cloneConfigUUID = cloneConfigKey.split('.')[1];

								let cloneSkeletonUUID = '';

								// Find the cloned template_skeleton UUID to update its data and hash.
								Object.keys(contents.data).forEach((entityKey) => {
									if (entityKey.startsWith('template_skeleton.')) {
										cloneSkeletonUUID = entityKey.split('.')[1];
									}
								});

								const {
									newSkeletonData,
								} = parentPendingUpdates;

								const pendingUpdates = {
									// Set the cloned template_config.is_global to false.
									[`template_config.${cloneConfigUUID}.is_global`]: false,

									// Satisfy props.value and avoid needing a refresh.
									[`template_config.${cloneConfigUUID}.display_name`]:                           config.display_name,
									[`template_config.${cloneConfigUUID}.clone_parent_uuid`]:                      config.uuid,
									[`template_config.${cloneConfigUUID}.template_skeleton.${cloneSkeletonUUID}`]: true,

									// Update template_to_template_config_map.
									[`template.${activeTemplate.uuid}.template_config.${cloneConfigUUID}`]: true,
									[`template.${activeTemplate.uuid}.template_config.${config.uuid}`]:     false,

									// Update template_skeleton data and hash.
									[`template_skeleton.${cloneSkeletonUUID}.data`]: JSON.stringify(newSkeletonData),
									[`template_skeleton.${cloneSkeletonUUID}.hash`]: md5(JSON.stringify(newSkeletonData)),
								};

								// Add to form.state.newValue
								window.form.setValue(Object.keys(pendingUpdates), Object.values(pendingUpdates));

								this.props.makeMaps();

								// Update the activeTemplate
								this.handleActiveTemplateChange({
									...activeTemplate,
									config: {
										...activeTemplate.config,
										is_global: false,
										uuid:      cloneConfigUUID,
									},
								}, true);

								resolve(pendingUpdates);
							}

							reject('Invalid response.keys from /w/clone: ', contents);
						});
					}

					reject('Invalid response from /w/clone: ', contents);
				},

				failure: (_kali, err) => {
					reject(err);
				},
			});
		});
	}

	updateSkeletonData(newSkeletonData) {
		const {
			stateCallback,
		} = this.props;

		const {
			activeTemplate,
			config,
		} = this.state;

		const {
			data: skeletonData,
			uuid: skeletonUUID,
		} = config.template_skeleton_list.template_skeleton;

		// If newSkeletonData doesn't match the active config's skeleton data, save the changes.
		if (JSON.stringify(newSkeletonData) !== skeletonData) {

			// If the config is global and has been modified, we need to clone the config for the template.
			if (activeTemplate.config.is_global) {
				stateCallback('cb', this.cloneConfig.bind(this));
				stateCallback('pendingUpdates', {
					newSkeletonData,
				});

				return;
			}

			let pendingUpdates = {
				[`template_skeleton.${skeletonUUID}.data`]: JSON.stringify(newSkeletonData),
				[`template_skeleton.${skeletonUUID}.hash`]: md5(JSON.stringify(newSkeletonData)),
			};

			// Trigger Save button in SystemProgramming
			stateCallback('pendingUpdates', pendingUpdates);

			// Add to form.state.newValue
			window.form.setValue(Object.keys(pendingUpdates), Object.values(pendingUpdates));

			return;
		}

		// Clear out newValues
		window.form.newVals = {};

		// Disable Save button in SystemProgramming
		stateCallback('pendingUpdates', {});
	}

	findDisplayName(m1UUID) {
		const {
			globalTemplateData,
		} = this.props;

		// for...of will stop looping when return is hit.
		for (const category of globalTemplateData) {
			if (category.uuid === m1UUID) {
				return category.display_name;
			}

			if (category.template_config_list) {
				for (const configMapModel of category.template_config_list) {
					const {
						template_config = {},
					} = configMapModel;
					if (template_config.uuid === m1UUID) {
						return template_config.display_name;
					}

					if (template_config.template_theme_list) {
						for (const themeMapModel of template_config.template_theme_list) {
							const {
								template_theme = {},
							} = themeMapModel;
							if (template_theme.uuid === m1UUID) {
								return template_theme.display_name;
							}
						}
					}
				}
			}
		}
	}

	renderTemplates() {
		const {
			accountUUID, form, value,
		} = this.props;
		const {
			activeTemplate,
		} = this.state;

		let templateRenderMap = {};
		window.form.getMatchingKeys(`account.${accountUUID}.template.*`).forEach((entityKey) => {
			const {
				m2ID,
			} = window.form.parseEntityName(entityKey);

			// Skip templates that are pending delete.
			if (window.form.getValue(`account.${accountUUID}.template.${m2ID}`) === false) {
				return;
			}

			const display_name = window.form.getValue(`template.${m2ID}.display_name`);

			// Skip templates that were never saved.
			if (!display_name) {
				return;
			}

			templateRenderMap[m2ID] = {
				display_name,
			};
		});

		Object.keys(templateRenderMap).forEach((templateUUID) => {
			// OTO
			window.form.getMatchingKeys(`template.${templateUUID}.template_category.*`).forEach((entityKey) => {
				const {
					m2ID: categoryUUID,
				} = window.form.parseEntityName(entityKey);
				let display_name = window.form.getValue(`template_category.${categoryUUID}.display_name`);

				if (!display_name) {
					display_name = this.findDisplayName(categoryUUID);
				}

				// Add data to the render map.
				templateRenderMap[templateUUID] = {
					...templateRenderMap[templateUUID],
					category: {
						display_name,
						uuid: categoryUUID,
					},
				};
			});

			// OTO
			window.form.getMatchingKeys(`template.${templateUUID}.template_config.*`).forEach((entityKey) => {
				const {
					m2ID: configUUID,
				} = window.form.parseEntityName(entityKey);
				let display_name = window.form.getValue(`template_config.${configUUID}.display_name`);
				const is_global = window.form.getValue(`template_config.${configUUID}.is_global`);

				if (!display_name) {
					display_name = this.findDisplayName(configUUID);
				}

				templateRenderMap[templateUUID] = {
					...templateRenderMap[templateUUID],
					config: {
						display_name,
						is_global,
						uuid: configUUID,
					},
				};
			});

			// OTO
			window.form.getMatchingKeys(`template.${templateUUID}.template_theme.*`).forEach((entityKey) => {
				const {
					m2ID: themeUUID,
				} = window.form.parseEntityName(entityKey);
				let display_name = window.form.getValue(`template_theme.${themeUUID}.display_name`);

				if (!display_name) {
					display_name = this.findDisplayName(themeUUID);
				}

				templateRenderMap[templateUUID] = {
					...templateRenderMap[templateUUID],
					theme: {
						display_name,
						uuid: themeUUID,
					},
				};
			});
		});

		return Object.entries(templateRenderMap)
			.sort(([ , templateDataA, ], [ , templateDataB, ]) => {
				if ((templateDataA?.display_name || '').toLowerCase() > (templateDataB?.display_name || '').toLowerCase()) {
					return 1;
				}
				return -1;
			})
			.map(([ templateUUID, templateData, ], i) => {
				let active = '';
				if (activeTemplate.uuid === templateUUID) {
					active = style.templateActive;
				}

				let customIcon = null;
				if (templateData?.config?.is_global === false) {
					customIcon = <CustomIcon icon='code' color='#C93A6A' />;
				}

				return (
					<div
						className={`${style.template} ${active}`}
						key={i}
						onClick={(e) => {
							e.preventDefault();
							const activeTemplate = {
								...templateData,
								uuid: templateUUID,
							};
							this.handleActiveTemplateChange(activeTemplate);
						}}
					>
						<div className={style.templateName}>{templateData.display_name}</div>
						<div className={style.templateData}>
							<div className={style.templateDataKey}>Category:</div>
							<div>{templateData.category.display_name}</div>
						</div>
						<div className={style.templateData}>
							<div className={style.templateDataKey}>Configuration:</div>
							<div>{customIcon} {templateData?.config?.display_name || 'BAD_CONFIG'}</div>
						</div>
						<div className={style.templateData}>
							<div className={style.templateDataKey}>Theme:</div>
							<div>{templateData.theme.display_name}</div>
						</div>
					</div>
				);
			});
	}

	renderStepOne() {
		const {
			activeTemplate, category, config, theme,
		} = this.state;
		const {
			globalTemplateData,
		} = this.props;

		// Get values for react-select in case we're editing.
		let categoryValue;
		if (category.uuid) {
			categoryValue = {
				label: category.display_name,
				value: category,
			};
		}

		let configValue;
		if (config.uuid) {
			configValue = {
				label: config.display_name,
				value: config,
			};
		}

		let themeValue;
		if (theme.uuid) {
			themeValue = {
				label: theme.display_name,
				value: theme,
			};
		}

		// Create options for react-select.
		const categoryList = globalTemplateData.map((template_category) => {
			return {
				label: template_category.display_name,
				value: template_category,
			};
		}).sort((a, b) => {
			const labelA = a.label.toLowerCase();
			const labelB = b.label.toLowerCase();

			if (labelA > labelB) {
				return 1;
			}

			if (labelA < labelB) {
				return -1;
			}

			return 0;
		});

		let configList = [];
		if (category.template_config_list) {
			category.template_config_list.forEach((mapModel) => {
				if (mapModel.template_config) {
					configList.push({
						label: mapModel.template_config.display_name,
						value: mapModel.template_config,
					});
				}
			});
		}
		configList.sort((a, b) => {
			const labelA = a.label.toLowerCase();
			const labelB = b.label.toLowerCase();

			if (labelA > labelB) {
				return 1;
			}

			if (labelA < labelB) {
				return -1;
			}

			return 0;
		});

		let themeList = [];
		if (config.template_theme_list) {
			config.template_theme_list.forEach((mapModel) => {
				if (mapModel.template_theme) {
					themeList.push({
						label: mapModel.template_theme.display_name,
						value: mapModel.template_theme,
					});
				}
			});
		}
		themeList.sort((a, b) => {
			const labelA = a.label.toLowerCase();
			const labelB = b.label.toLowerCase();

			if (labelA > labelB) {
				return 1;
			}

			if (labelA < labelB) {
				return -1;
			}

			return 0;
		});

		let title = 'NEW DESIGN';
		if (activeTemplate.uuid) {
			title = 'EDIT DESIGN';
		}

		return (
			<React.Fragment>
				<div className={style.header}>
					<div className={style.headerLabel}>{title}</div>
					<div className={style.headerBtnContainer}>
						<button
							className='button'
							onClick={(e) => {
								e.preventDefault();
								this.updatePendingTemplateChanges();
							}}
						>
							<CustomIcon icon='check' />
						</button>
						<button
							className='button'
							onClick={(e) => {
								e.preventDefault();
								this.clearTemplateData();
							}}
						>
							<CustomIcon icon='ban' />
						</button>
					</div>
				</div>

				<div className={style.stepOneData}>
					<div className={style.label}>Name</div>
					<input
						className={style.input}
						onChange={(e) => {
							this.setState({
								templateName: e.target.value,
							});
						}}
						value={this.state.templateName}
					/>

					<div className={style.label}>Category</div>
					<Select
						isClearable
						ref={this.categoryRef}
						options={categoryList}
						onChange={(e) => {
							this.setState({
								category: e?.value || {},
								config:   {},
								theme:    {},
							});
							this.configRef.current.select.clearValue();
							this.themeRef.current.select.clearValue();
						}}
						{...categoryValue ? {
							value: categoryValue,
						} : null}
					/>

					<div className={style.label}>Configuration</div>
					<Select
						isClearable
						{...category.uuid ? null : {
							isDisabled: true,
							value:      null,
						}}
						ref={this.configRef}
						options={configList}
						onChange={(e) => {
							this.setState({
								config: e?.value || {},
								theme:  {},
							});
							this.themeRef.current.select.clearValue();
						}}
						{...configValue ? {
							value: configValue,
						} : null}
					/>

					<div className={style.label}>Theme</div>
					<Select
						isClearable
						{...config.uuid ? null : {
							isDisabled: true,
							value:      null,
						}}
						ref={this.themeRef}
						options={themeList}
						onChange={(e) => {
							this.setState({
								theme: e?.value || {},
							});
						}}
						{...themeValue ? {
							value: themeValue,
						} : null}
					/>
				</div>
			</React.Fragment>
		);
	}

	renderStepTwo() {
		const {
			activeTemplate, config,
		} = this.state;

		// Don't show the skeleton unless a template is active
		if (Object.keys(activeTemplate).length === 0) {
			return '';
		}

		let existingFlatData = null;
		if (config?.template_skeleton_list?.template_skeleton) {
			try {
				let data = config.template_skeleton_list.template_skeleton.data;
				existingFlatData = JSON.parse(data);
			} catch (err) {
				console.error('Error parsing skeleton data: ', err);
			}
		}

		// If template_config.is_global is false, customConfig is true. Fallback to false if not found.
		let customConfig = !config.is_global || false;

		return (
			<TemplateSkeleton
				app={this.props.app}
				listingConfigMap={this.props.listingConfigMap}
				accountUUID={this.props.accountUUID}
				customConfig={customConfig}
				existingFlatData={existingFlatData}
				stateCallback={this.props.stateCallback}
				pendingUpdates={this.props.pendingUpdates}
				form={this.props.form}
				config={this.state.config}
				category={this.state.category}
				theme={this.state.theme}
				updateSkeletonData={this.updateSkeletonData.bind(this)}
				settingMap={this.props.settingMap}
				fd={this.props.fd}
			/>
		);
	}

	modifySkeletonUUIDs(skeletonData) {
		const {
			form,
		} = this.props;

		Object.keys(skeletonData).forEach((m1Key) => {
			const {
				m1ID,
			} = window.form.parseEntityName(m1Key);

			// Find invalid m1UUIDs and replace m1Key with a new UUID.
			if (m1ID.length !== 36) {
				const newM1ID = uuidv4();
				const newM1Key = m1Key.replace(m1ID, newM1ID);

				skeletonData[newM1Key] = skeletonData[m1Key];
				delete skeletonData[m1Key];

				// Loop over skeletonData to find if the m1 was used as an m2 anywhere.
				// Replace the m2ID the same new UUID from above if so.
				Object.entries(skeletonData).forEach(([ entityKey, m2Obj, ]) => {
					for (const m2Key of Object.keys(m2Obj)) {
						const {
							m1ID: childUUID,
						} = window.form.parseEntityName(m2Key);

						if (childUUID === m1ID) {
							const newM2Key = m2Key.replace(childUUID, newM1ID);

							skeletonData[entityKey][newM2Key] = skeletonData[entityKey][m2Key];
							delete skeletonData[entityKey][m2Key];

							break;
						}
					}
				});
			}
		});

		return skeletonData;
	}

	handleSystemAssignment(systemUUID, status, systemName) {
		const {
			stateCallback,
		} = this.props;

		const {
			activeTemplate,
		} = this.state;

		if (!activeTemplate.uuid) {
			return;
		}

		let {
			assignmentMap,
		} = this.props;

		// Remove any previously existing assignments for this system.
		let prevUpdates = false;
		Object.keys(assignmentMap).forEach((key) => {
			if (key.startsWith(`system.${systemUUID}.`)) {
				prevUpdates = true;


				Reflect.deleteProperty(assignmentMap, `system.${systemUUID}.template.${activeTemplate.uuid}`);
			}
		});

		if (prevUpdates) {
			// Trigger Save button in SystemProgramming
			stateCallback('assignmentMap', assignmentMap);

			// Remove the system from the deleteAllMap
			return;
		}

		// Prompt the user if they're removing a template from a system.
		if (status === 'enabled') {
			if (!window.confirm(`Are you sure you want to remove ${activeTemplate.display_name} from ${systemName}?`)) {
				return;
			}

			assignmentMap[`system.${systemUUID}.template.${activeTemplate.uuid}`] = false;

			// Trigger Save button in SystemProgramming
			stateCallback('assignmentMap', assignmentMap);

			return;
		}

		// Prompt the user if they're updating a system that has a different template.
		if (status === 'disabled') {
			if (!window.confirm(`Are you sure you want to update ${systemName} to use ${activeTemplate.display_name}?`)) {
				return;
			}
		}

		assignmentMap[`system.${systemUUID}.template.${activeTemplate.uuid}`] = true;

		// Trigger Save button in SystemProgramming
		stateCallback('assignmentMap', assignmentMap);
	}

	// Create a render map of all system_groups under the selected account, that own one or more systems.
	buildRenderMap(m1, m1UUID, renderMap = {}) {
		window.form.getMatchingKeys(`${m1}.${m1UUID}.system_group.*`).forEach((entityKey) => {
			const {
				m2ID: systemGroupUUID,
			} = window.form.parseEntityName(entityKey);

			// Recursively loop to find nested systems.
			this.buildRenderMap('system_group', systemGroupUUID, renderMap);

			// Find all systems owned by the system group.
			window.form.getMatchingKeys(`system_group.${systemGroupUUID}.system.*`).forEach((entityKey) => {
				const {
					m2ID: systemUUID,
				} = window.form.parseEntityName(entityKey);

				const systemGroupName = window.form.getValue(`system_group.${systemGroupUUID}.display_name`);
				const systemName = window.form.getValue(`system.${systemUUID}.display_name`) || window.form.orgVals[`system.${systemUUID}`]['display_name'];
				const resolution = window.form.getValue(`system.${systemUUID}.resolution`) || window.form.orgVals[`system.${systemUUID}`]['resolution'];

				let templateName = '';
				let templateUUID = '';

				// Find the system_to_template relation and grab the template.display_name. OTO
				window.form.getMatchingKeys(`system.${systemUUID}.template.*`).forEach((entityKey) => {
					const {
						m2ID: _templateUUID,
					} = window.form.parseEntityName(entityKey);

					// Check for pending system.*.template.* deletions before assigning the template name.
					if (window.form.getValue(`system.${systemUUID}.template.${_templateUUID}`)) {
						templateName = window.form.getValue(`template.${_templateUUID}.display_name`);
						templateUUID = _templateUUID;
					}

					// Check for previously stored values
					if (window.form.orgVals[`template.${_templateUUID}`]) {
						templateName = window.form.orgVals[`template.${_templateUUID}`]['display_name'];
						templateUUID = _templateUUID;
					}

					// Check to see if there relation is about to be removed
					if (window.form.newVals[`system.${systemUUID}`] && window.form.newVals[`system.${systemUUID}`][`template.${_templateUUID}`] === false) {
						templateName = '';
						templateUUID = '';
					}
				});

				renderMap[systemGroupName] = {
					...renderMap[systemGroupName],
					[systemUUID]: {
						systemName,
						resolution,
						templateName,
						templateUUID,
					},
				};
			});
		});

		return renderMap;
	}

	renderStepThree() {
		const {
			assignmentMap,
			accountUUID,
			fd,
		} = this.props;

		const {
			activeTemplate,
		} = this.state;

		let groupedSystems = [];
		for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key.startsWith('component_group.')) {
				for (const m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('system.')) {
						groupedSystems.push(m2Key);
					}
				}
			}
		}
		groupedSystems = [ ...new Set(groupedSystems), ];

		const renderMap = this.buildRenderMap('account', accountUUID);
		let sortedEntries = Object.entries(renderMap).sort((a, b) => {
			const labelA = a[0].toLowerCase();
			const labelB = b[0].toLowerCase();
			if (labelA > labelB) {
				return 1;
			}
			return -1;
		});
		return sortedEntries.map(([ systemGroupName, systemObj, ], i) => {
			let sortedSystems = Object.entries(systemObj).sort((a, b) => {
				if (a[1].systemName > b[1].systemName) {
					return 1;
				}
				return -1;
			});
			return (
				<div key={i}>
					<div>{systemGroupName}</div>
					{sortedSystems.map(([ systemUUID, systemVals, ]) => {
						const {
							systemName,
							resolution,
							templateName,
							templateUUID,
						} = systemVals;

						let templateNameDisplay = templateName;

						let orientationIcon;
						let highResIcon;
						let isActive;
						let isGrouped = groupedSystems.includes(`system.${systemUUID}`);

						if (resolution) {
							const [ width, height, ] = resolution.split('x');

							if (width > height) {
								orientationIcon = <CustomIcon icon={'horizontal'} />;
							}

							if (height > width) {
								orientationIcon = <CustomIcon icon={'vertical'} />;
							}

							// TODO: Expand this for 5k, 10k, etc. future systems.
							if (height > 3000 || width > 3000) {
								highResIcon = <span className={style.resolutionIcon}><CustomIcon icon='4k' /></span>;
							}
						}

						let activeIcon = <CustomIcon icon='ban' color='#C7567C' />;
						let containerStyle = style.systemDisabled;
						let status = 'disabled';
						let pendingChangeStyle;

						// If there is no active template selected or if there is no template tied to the system, use the unassigned style.
						if (!activeTemplate.uuid || !templateUUID) {
							isActive = false;
							activeIcon = <CustomIcon icon='circle' color='#DBDBDB' />;
							containerStyle = style.systemEmpty;
							status = 'empty';
						}

						// If there is a template name and the display_names match, set the system to active.
						// Else the names do not match, use the inUse style.
						if (templateUUID && templateUUID === activeTemplate.uuid) {
							isActive = true;
							activeIcon = <CustomIcon icon='check-circle' color='#0CBAFF' />;
							containerStyle = style.systemEnabled;
							status = 'enabled';
						}

						for (const [ key, val, ] of Object.entries(assignmentMap)) {
							if (key.startsWith(`system.${systemUUID}`)) {
								pendingChangeStyle = style.systemPendingChange;

								if (key === `system.${systemUUID}.template.${activeTemplate.uuid}`) {
									if (val) {
										templateNameDisplay = activeTemplate.display_name;
										isActive = true;
										activeIcon = <CustomIcon icon='check-circle' color='#0CBAFF' />;
										containerStyle = style.systemEnabled;
										status = 'enabled';
										break;
									} else {
										templateNameDisplay = '';
										isActive = false;
										activeIcon = <CustomIcon icon='circle' color='#DBDBDB' />;
										containerStyle = style.systemEmpty;
										status = 'empty';
									}
								}
							}
						}

						return (
							<div
								className={`${style.systemContainer} ${containerStyle} ${pendingChangeStyle}`}
								key={systemUUID}
								onClick={(e) => {
									if (isActive && isGrouped) {
										this.props.app.openModal({
											modalSize:  1,
											showModal:  true,
											modalProps: {
												omitClose: true,
												title:     'Warning',
												jsx:       (
													<AssignmentWarning
													/>
												),
											},
										});
									} else {
										e.preventDefault();
										this.handleSystemAssignment(systemUUID, status, systemName);
									}
								}}
							>
								<div className={style.systemIcon}>{activeIcon}</div>
								<div className={style.systemIcon}>{orientationIcon}</div>
								<div>{systemName}</div>
								<div className={style.resolutionContainer}>
									{resolution} {highResIcon}
								</div>
								<div>{templateNameDisplay}</div>
							</div>
						);
					})}
				</div>
			);
		});
	}

	renderData() {
		const {
			step,
		} = this.props;

		switch (step) {
		case 1:
			return this.renderStepOne();
		case 2:
			return this.renderStepTwo();
		case 3:
			return this.renderStepThree();
		default:
			return '';
		}
	}

	openImageModal(modalTitle, m1, photosValue, settingList) {
		let { app,  } = this.props;
		let photoUrl = '';
		settingList.forEach((model) => {
			if (photosValue === model.setting.name) {
				photoUrl = `https://${this.state.bucketName}.s3.amazonaws.com/${vultureENV}${model.text}`;
			}
		})
		let title = m1.split('_')[1];
		title = title[0].toUpperCase() + title.substring(1);

		app.openModal({
			modalSize: 3,
			showModal: true,
			modalProps: {
				title: `${modalTitle}`,
				jsx: (
					<div className={style.modalContainer}>
						<img className={style.modalImage} src={photoUrl} alt="" />
					</div>
				)
			}
		})
	}

	renderThemeDetails() {
		if (Object.keys(this.state.theme).length > 0) {
			let notes = [];
			let template_theme = this.state.theme;
			let resolutionsArray = [
				{
					label: "Horizontal",
					notesValue: "horizontal_notes",
					photosValue: "horizontal_image",
					settingValue: "horizontal"
				},
				{
					label: "Vertical",
					notesValue: "portrait_notes",
					photosValue: "portrait_image",
					settingValue: "vertical"
				},
				{
					label: "4K Horizontal",
					notesValue: "4k_horizontal_notes",
					photosValue: "4k_horizontal_image",
					settingValue: "horizontal_4k"
				},
				{
					label: "4K Vertical",
					notesValue: "4k_portrait_notes",
					photosValue: "4k_portrait_image",
					settingValue: "vertical_4k"
				}
			];
			if (template_theme.setting_list) {
				template_theme.setting_list.forEach((settingMapModel) => {
					let settingOptionSelected = [];
					resolutionsArray.forEach((option, i) => {
						if (settingMapModel.setting.name === option.settingValue) {
							settingOptionSelected = resolutionsArray[i];
						}
					});

					let val = '';
					let photo = '';
					if (settingOptionSelected.length !== 0) {
						template_theme.setting_list.forEach((model) => {
							if (settingOptionSelected.notesValue === model.setting.name) {
								val = model.text;
							}
							if (settingOptionSelected.photosValue === model.setting.name) {
								photo = model.text;
							}
						})

						let photoUrl = `https://${this.state.bucketName}.s3.amazonaws.com/${vultureENV}${photo}`;
						let note = (
							<div className={style.noteContainer}>
								<div>
									<p className={style.noteResolutionHeader}>{settingOptionSelected.label}</p>
									<p className={style.noteResolution}>{val ? val : 'There are no notes for this resolution'}</p>
								</div>
								<div className={style.imageContainer}>
									{photo ? <img src={photoUrl}
										className={style.noteImage}
										onClick={(e) => {
											e.preventDefault();
											//this is needed because otherwise it will always open the latest URL in the array. I don't know why.
											this.openImageModal(settingOptionSelected.label, 'template_category', settingOptionSelected.photosValue, template_theme.setting_list); 
										}}
									></img> :
										<div className={style.iconContainer}><CustomIcon icon='image' /></div>}
								</div>
							</div>
						);

						notes.push(note);
					}

				});
			}

			return (
				<div>
					<p className={style.notesTitle}>THEME</p>
					<div className={style.notesContainer}>
						{notes}
					</div>
				</div>
			);
		}
	}

	render() {
		const {
			activeTemplate,
		} = this.state;
		let buttonClass = 'button button-disabled';
		if (Object.keys(activeTemplate).length > 0) {
			buttonClass = 'button';
		}

		const {
			step,
		} = this.props;
		let dataContainerClass = style.dataContainerSmall;
		if (step > 1) {
			dataContainerClass = style.dataContainerLarge;
		}

		return (
			<React.Fragment>
				<div className={style.mainContainer}>
					<div className={style.templateContainer}>
						<div className={style.header}>
							<div className={style.headerLabel}>DESIGNS</div>
							<div className={style.headerBtnContainer}>
								<button
									className={buttonClass}
									onClick={(e) => {
										e.preventDefault();
										this.copyTemplate();
									}}
								>
									<CustomIcon icon='copy' />
								</button>
								<button
									className={buttonClass}
									onClick={(e) => {
										e.preventDefault();
										this.deleteTemplate();
									}}
								>
									<CustomIcon icon='trash' />
								</button>
							</div>
						</div>

						{this.renderTemplates()}
					</div>

					<div className={dataContainerClass}>
						{this.renderData()}
					</div>
					{this.renderThemeDetails()}
				</div>
			</React.Fragment>
		);
	}
}

class AssignmentWarning extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};
	}
	render() {
		return (
			<React.Fragment>
				<div className={style.listingWarningModalContainer}>
					<p>You must remove this system from its Group, before unassigning the Template.</p>
					<div className={style.warningModalButton}>
						<button className="button"	onClick={(e) => {
							e.preventDefault();
							this.props.app.closeModal();
						}}><CustomIcon icon='undo' />Go Back</button>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default TemplateBuilder;