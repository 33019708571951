import React from 'react';
import style from './TemplateSkeleton.module.css';

import {
	arrayNames,
} from './systemRules.js';

import {
	toCapitalCase,
} from '../../../../../Crow/Common/Crow';

class ListingColumnInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};
	}

	updateFlatData(value) {
		const {
			attr,
			flatData,
			listingColumnGroupKey,
			listingColumnKey,
			stateCallback,
			type,
		} = this.props;

		if (this.pendingUpdatesTimeout) {
			clearTimeout(this.pendingUpdatesTimeout);
		}

		// Add a delay to wait for the user to finish typing before we do a deep compare.
		this.pendingUpdatesTimeout = setTimeout(() => {
			if (type === 'number') {
				value = parseInt(value, 10);
			}

			if (!listingColumnKey) {
				flatData[listingColumnGroupKey][attr] = value;
				stateCallback('flatData', flatData);

				return;
			}

			flatData[listingColumnGroupKey][listingColumnKey][attr] = value;
			stateCallback('flatData', flatData);
		}, 500);
	}

	render() {
		const {
			attr,
			label,
			type,
			flatData,
			updateSettingValue,
			listingColumnGroupKey,
			listingColumnKey,
		} = this.props;

		if (arrayNames.includes(attr)) {
			let key = listingColumnGroupKey + ',' + listingColumnKey;
			let value = flatData[listingColumnGroupKey][listingColumnKey][attr] || '[]';
			let tmpVal = [];

			try {
				tmpVal = JSON.parse(value);
			} catch (err) {
				console.error(err);
			}

			const stringVal = tmpVal.join(', ');
			return (
				<div className={style.settingContainer} key={key}>
					<div className={style.settingLabel} title={attr}>
						{attr}
					</div>
					<input
						onChange={(e) => {
							e.preventDefault();
							let arrayVal = [];
							if (e.target.value.length > 0) {
								arrayVal = e.target.value.split(', ');
							}

							updateSettingValue(key, JSON.stringify(arrayVal), attr);
						}}
						value={stringVal}
					/>
				</div>
			);
		}

		return (
			<div className={style.settingContainer}>
				<div className={style.settingLabel}>{label || toCapitalCase(attr)}</div>
				<input
					type={type}
					onChange={(e) => {
						e.preventDefault();
						this.setState({
							value: e.target.value,
						});

						this.updateFlatData(e.target.value);
					}}
					value={this.state.value}
				/>
			</div>
		);
	}
}

export default ListingColumnInput;
