import React, {
	useCallback,
	useState,
	useEffect,
} from 'react';

import CustomIcon from '../Common/CustomIcon/CustomIcon';
import VultureTable from './VultureTable';

// Import styles
import style from './ListingsTable.module.css';

import BuildingsModal from './BuildingsModal';

import {
	deleteBuildings,
} from 'Vulture/Buildings';

const BuildingsTable = (props) => {
	const {
		app,
		systemGroupUUID,
		array,
		columnsToHide,
		tableSettings,
		fd,
		closeModal,
		toggleSelectAllRows,
	} = props;

	const rowsPerPage = 50;
	const [ page, setPage, ] = useState(1);
	const [ pageNavigationBlocked, setPageNavigationBlocked, ] = useState(false);
	const [ filteredRowsCount, setFilteredRowsCount, ] = useState(array.length);

	useEffect(() => {
		if (filteredRowsCount >= rowsPerPage && pageNavigationBlocked === true) {
			setPageNavigationBlocked(false);
		} else if (filteredRowsCount < rowsPerPage && pageNavigationBlocked === false) {
			setPageNavigationBlocked(true);
		}
	}, [ filteredRowsCount, pageNavigationBlocked, page, ]);

	const onFilter = useCallback((_, preFilteredRows) => {
		setFilteredRowsCount(preFilteredRows.length);
	}, []);

	const previousPageAvailable = (page > 1) && !pageNavigationBlocked;
	const nextPageAvailable = (page * rowsPerPage < filteredRowsCount) && !pageNavigationBlocked;

	let checkIcon = <div className={style.iconWrapper}><CustomIcon icon="check"/></div>;

	let disabledSquare = (
		<div className={style.disabledSquare}>
			<div className={style.checkboxIcon}>
				<CustomIcon icon="check"/>
			</div>
		</div>
	);

	let activeCheckbox = (
		<div className={style.activeCheckbox}>
			<div className={style.checkboxIcon}>
				<CustomIcon icon="check"/>
			</div>
		</div>
	);

	let buildingColumns = [
		{
			id:       'checkbox',
			Header:   checkIcon,
			accessor: (d) => {
				return d.isChecked ? activeCheckbox : disabledSquare;
			},
			disableFilters: true,
		},
		{
			id:       'buildingName',
			Header:   'Building Name',
			accessor: (d) => {
				return d.vals.buildingName;
			},
			disableFilters: true,
		},
	];


	Object.entries(tableSettings).forEach(([ id, Header, ]) => {
		buildingColumns.push(
			{
				id,
				Header,
				accessor: (d) => {
					if (d.system_group === id.split('.')[1]) {
						return activeCheckbox;
					}
					return disabledSquare;
				},
				sortType: (a, b) => {
					return 1;
				},
				disableFilters: true,
			}
		);
	});

	const columns = React.useMemo(
		() => {
			return [
				{
					Header:  'BuildingsTable',
					columns: buildingColumns,
				},
			];
		},
		[ buildingColumns, ]
	);

	const data = React.useMemo(() => {
		return array;
	}, [ array, ]
	);

	let disabledUnlessChecked = `button ${style.button} button-disabled`;
	array.map((obj) => {
		if (obj.isChecked) {
			disabledUnlessChecked = 'button';
		}
	});

	let secondaryNavButtons = [
		{
			onClick: (e) => {
				e.preventDefault();
				const {
					buildingsView,
				} = props;

				const selectedBuildings = buildingsView.state.buildingsDataArray.filter((building) => {
					return building.isChecked;
				});

				if (selectedBuildings.length === 0) {
					return;
				}

				buildingsView.setState({
				    confirmModal:        true,
				    confirmModalContent: (
				        <>
				            <p>{`Delete ${selectedBuildings.length} building(s)?`}</p>
				            <div className="confirm-modal-buttons">
								<button className="button" onClick={(e) => {
								    e.preventDefault();
								    deleteBuildings(selectedBuildings)
										.finally(() => {
											app.fetchAccountBuildings();
										});
								    buildingsView.setState({
								        confirmModal:        false,
								        confirmModalContent: '',
								    });
								}}>Yes</button>
								<button className="button" onClick={(e) => {
								    e.preventDefault();
								    buildingsView.setState({
								        confirmModal:        false,
								        confirmModalContent: '',
				                    });
								}}>No</button>
				            </div>
				        </>
				    ),
				});
			},
			className: disabledUnlessChecked,
			icon:      'trash',
			text:      'Delete',
		},
		{
			onClick: (e) => {
				e.preventDefault();
				app.openModal({
					modalSize:  2,
					showModal:  true,
					modalProps: {
						title:          'New Building',
						pendingChanges: false,
						jsx:            (
							<BuildingsModal
								app={app}
								systemGroupUUID={systemGroupUUID}
								tableSettings={tableSettings}
								closeModal={closeModal}
							/>
						),
					},
				});
			},
			className: 'button',
			icon:      'plus',
			text:      'Add New',
		},
		{
			className: 'button' + (previousPageAvailable ? '' : ' button-disabled'),
			onClick:   () => {
				if (previousPageAvailable) {
					setPage(page - 1);
				}
			},
			text: '< Previous',
		},
		{
			className: 'button' + (nextPageAvailable ? '' : ' button-disabled'),
			onClick:   () => {
				if (nextPageAvailable) {
					setPage(page + 1);
				}
			},
			text: 'Next >',
		},
	];

	const tertiaryNavButtons = [
		{
			className: 'button' + (previousPageAvailable ? '' : ' button-disabled'),
			onClick:   () => {
				if (previousPageAvailable) {
					setPage(page - 1);
				}
			},
			text: '< Previous',
		},
		{
			className: 'button' + (nextPageAvailable ? '' : ' button-disabled'),
			onClick:   () => {
				if (nextPageAvailable) {
					setPage(page + 1);
				}
			},
			text: 'Next >',
		},
	];

	const headerStyles = (column) => {
		let colStyles = {
			width: `5vw`,
		};

		if (column.id === 'checkbox') {
			colStyles = {
				width:    `5vw`,
				left:     `0vw`,
				position: 'sticky',
				zIndex:   5,
			};
		} else if (column.id === 'buildingName') {
			colStyles = {
				width: `15vw`,
			};
		}

		return colStyles;
	};

	const bodyStyles = (cell) => {
		let colStyles = {
			width: `5vw`,
		};

		if (cell.column.id === 'checkbox') {
			colStyles = {
				width:    `5vw`,
				left:     `0vw`,
				position: 'sticky',
				zIndex:   5,
			};
		} else if (cell.column.id === 'buildingName') {
			colStyles = {
				width: `15vw`,
			};
		}

		return colStyles;
	};

	const checkboxClick = (cell) => {
		const {
			buildingsView,
		} = props;

		const buildingsDataArray = array;
		buildingsDataArray[cell.row.index].isChecked = !buildingsDataArray[cell.row.index].isChecked;
		buildingsView.setState({
			buildingsDataArray,
		});
	};

	const cellOnClick = (cell, row) => {
		const {
			toggleGroup,
		} = props;
		return (e) => {
			e.preventDefault();
			if (cell.column.id === 'checkbox') {
				checkboxClick(cell);
			} else if (cell.column.id.startsWith('system_group.')) {
				toggleGroup(cell, row.index);
			} else {
				app.openModal({
					modalSize:  2,
					showModal:  true,
					modalProps: {
						title:          'Edit Building',
						pendingChanges: false,
						jsx:            (
							<BuildingsModal
								app={app}
								buildingName={cell.row.original.vals.buildingName}
								buildingM1={cell.row.original.m1Key}
								originalSystemGroupUUID={row.original.system_group}
								tableSettings={tableSettings}
								isEdit={true}
								closeModal={closeModal}
								buildingsView={props.buildingsView}
							/>
						),
					},
				});
			}
		};
	};

	const allRowsSelected = data.reduce((previous, current) => {
		return previous && current.isChecked;
	}, true);

	return (
		<VultureTable
			style={style}
			columns={columns}
			data={data}
			fd={fd}
			columnsToHide={columnsToHide}
			secondaryNavButtons={secondaryNavButtons}
			tertiaryNavButtons={tertiaryNavButtons}
			cellOnClick={cellOnClick}
			theadColStyles={headerStyles}
			filterRowColStyles={headerStyles}
			tbodyColStyles={bodyStyles}
			allRowsSelected={allRowsSelected}
			toggleSelectAllRows={toggleSelectAllRows}
			page={page}
			rowsPerPage={rowsPerPage}
			onFilter={onFilter}
		/>
	);
};

export default BuildingsTable;