import React from 'react';
import Select from 'react-select';
import CustomIcon from '../Common/CustomIcon/CustomIcon.js';
import style from './MessagingOptions.module.css';
import {
	broncoURL,
} from 'Vulture/ENV.js';
import {
	getSession,
} from 'Vulture/Session';

class MessagingOptions extends React.Component {
	constructor(props) {
		super(props);

		const {
			fd,
		} = props;

		this.state = {
			activeSystem:        null,
			selectedLabel:       null,
			isCreatingNewLabel:  false,
			messagingSystemData: this.getMessagingSystemData(fd),
			labels:              [],
			messages:            [],
			messageValue:        '',
			messageButtonLabel:  '',
			teamsWebhook:        '',
			slackWebhook:        '',
			currentMessageUUID:  null,
			newLabelInput:       '',
		};
	}

	componentDidMount() {
		this.fetchLabels();
		this.fetchMessagesBySystemUUIDs();
	}

	getMessagingSystemData = (fd) => {
		return Object.keys(fd)
			.filter((key) => {
				return key.startsWith('system.');
			})
			.map((key) => {
				return {
					uuid:         key.split('system.')[1],
					display_name: fd[key].display_name,
				};
			});
	};

	// Fetch existing labels
	fetchLabels = () => {
		const url = `${broncoURL}/messaging/labels/${this.props.accountUUID}`;
		let sessionKey = getSession().session;

		fetch(url, {
			method:  'GET',
			headers: {
				'Content-Type':   'application/json',
				'X-Auth-Session': sessionKey,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((labels) => {
				const formattedLabels = labels.map((label) => {
					return {
						id:    label.ID,
						value: label.UUID,
						label: label.Label,
					};
				});

				this.setState({
					labels: formattedLabels,
				}, () => {
					if (this.props.onMessageLabelsUpdate) {
						this.props.onMessageLabelsUpdate(formattedLabels);
					}
				});
			})
			.catch((err) => {
				console.error('Error fetching labels:', err);
			});
	};


	handleLabelChange = (selectedOption) => {
		const isNewLabel = selectedOption.value === 'newLabel';
		const selectedLabel = isNewLabel ?
			null :
			this.state.labels.find((label) => {
				return label.id === selectedOption.value;
			});

		this.setState(
			{
				isCreatingNewLabel: isNewLabel,
				selectedLabel,
				activeSystem:       null,
				messageValue:       '',
				messageButtonLabel: '',
				teamsWebhook:       '',
				slackWebhook:       '',
				currentMessageUUID: null,
			},
			() => {
				if (!isNewLabel) this.populateMessageFields();
			}
		);
	};

	handleNewLabelChange = (e) => {
		this.setState({
			newLabelInput: e.target.value,
		});
	};

	handleNewLabelKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.createLabel(this.state.newLabelInput);
		}
	};

	handleNewLabelBlur = () => {
		if (this.state.newLabelInput.trim()) {
			this.createLabel(this.state.newLabelInput);
		}
	};

	populateMessageFields = () => {
		const {
			activeSystem, selectedLabel, messages,
		} = this.state;

		const systemUUID = activeSystem ? activeSystem.uuid : null;

		const existingMessage = messages.find((message) => {
			return message.label_id === selectedLabel?.id &&
			(message.system_uuid === systemUUID || (!systemUUID && !message.system_uuid));
		}
		);

		if (existingMessage) {
			this.setState({
				messageValue:       existingMessage.message_value,
				messageButtonLabel: existingMessage.message_button_label,
				teamsWebhook:       existingMessage.teams_webhook,
				slackWebhook:       existingMessage.slack_webhook,
				currentMessageUUID: existingMessage.UUID,
			});
		} else {
			this.setState({
				messageValue:       '',
				messageButtonLabel: '',
				teamsWebhook:       '',
				slackWebhook:       '',
				currentMessageUUID: null,
			});
		}
	};

	handleSystemChange = (selectedOption) => {
		const selectedUuid = selectedOption ? selectedOption.value : null;

		const activeSystem = selectedUuid ? this.state.messagingSystemData.find(
			(system) => {
				return system.uuid === selectedUuid;
			}
		) : null;

		this.setState({
			activeSystem,
		}, this.populateMessageFields);
	};

	handleInputChange = (e) => {
		this.setState({
			[e.target.id]: e.target.value,
		});
	};

	createLabel = (labelName, callback) => {
		const url = `${broncoURL}/messaging/labels`;
		const data = {
			label:        labelName,
			account_uuid: this.props.accountUUID,
		};

		let sessionKey = getSession().session;

		fetch(url, {
			body:    JSON.stringify(data),
			headers: {
				'Content-Type':   'application/json',
				'X-Auth-Session': sessionKey,
			},
			method: 'POST',
		})
			.then((response) => {
				return response.json();
			})
			.then((newLabel) => {
				const formattedLabel = {
					id:    newLabel.ID,
					value: newLabel.UUID,
					label: newLabel.Label,
				};

				this.setState(
					(prevState) => {
						const updatedLabels = [ ...prevState.labels, formattedLabel, ];
						return {
							labels:             updatedLabels,
							isCreatingNewLabel: false,
							selectedLabel:      formattedLabel,
							newLabelInput:      '',
						};
					},
					() => {
						if (callback) callback(formattedLabel);

						if (this.props.onMessageLabelsUpdate) {
							this.props.onMessageLabelsUpdate(this.state.labels);
						}
					}
				);
			})
			.catch((err) => {
				console.error('Error creating label:', err);
			});
	};


	createOrUpdateMessagingOption = (data, isUpdate = false) => {
		const url = isUpdate ?
			`${broncoURL}/messaging/${data.UUID}` :
			`${broncoURL}/messaging`;
		const method = isUpdate ? 'PUT' : 'POST';

		let sessionKey = getSession().session;

		fetch(url, {
			method,
			body:    JSON.stringify(data),
			headers: {
				'Content-Type':   'application/json',
				'X-Auth-Session': sessionKey,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((responseData) => {
				this.setState((prevState) => {
					const updatedMessages = isUpdate ?
						prevState.messages.map((msg) => {
							return msg.UUID === responseData.UUID ? responseData : msg;
						}
						  ) :
						[ ...prevState.messages, responseData, ];

					return {
						messages:           updatedMessages,
						currentMessageUUID: responseData.UUID,
					};
				});
			})
			.catch((err) => {
				console.error(`Error on ${method} /messaging response, err`);
			});
	};


	fetchMessagesBySystemUUIDs = () => {
		const url = `${broncoURL}/messaging/systems`;
		const {
			messagingSystemData,
		} = this.state;

		const requestPayload = {
			system_uuids: [ ...messagingSystemData.map((data) => {
				return data.uuid;
			}), null, ],
			account_uuid: this.props.accountUUID,
		};

		fetch(url, {
			body:    JSON.stringify(requestPayload),
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'POST',
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					messages: data && data.length > 0 ? data : [], // Ensure messages is an empty array if data is empty
				});
			})
			.catch((err) => {
				console.error('Error on /messages/by-system-uuids response', err);
			});
	};


	handleDelete = () => {
		const {
			currentMessageUUID, activeSystem,
		} = this.state;

		if (!currentMessageUUID || !activeSystem) return; // Ensure both are present

		const url = `${broncoURL}/messaging/${currentMessageUUID}`;
		let sessionKey = getSession().session;

		fetch(url, {
			method:  'DELETE',
			headers: {
				'Content-Type':   'application/json',
				'X-Auth-Session': sessionKey,
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Failed to delete message');
				}
				return response.json();
			})
			.then(() => {
				this.setState((prevState) => {
					const updatedMessages = prevState.messages.filter(
						(msg) => {
							return msg.UUID !== currentMessageUUID;
						}
					);

					const updatedLabels = prevState.labels.filter(
						(label) => {
							return label.id !== currentMessageUUID;
						}
					);

					return {
						messages:           updatedMessages,
						labels:             updatedLabels,
						messageValue:       '',
						messageButtonLabel: '',
						currentMessageUUID: null,
					};
				}, () => {
					if (this.props.onMessageLabelsUpdate) {
						this.props.onMessageLabelsUpdate(this.state.labels);
					}
				});
			})
			.catch((err) => {
				console.error('Error deleting message:', err);
			});
	};


	handleSave = (e) => {
		e.preventDefault();
		const {
			activeSystem,
			selectedLabel,
			newLabelInput,
			isCreatingNewLabel,
		} = this.state;

		// If creating a new label, handle that first
		if (isCreatingNewLabel) {
			this.createLabel(newLabelInput, (newLabel) => {
				this.saveMessage(newLabel, null);
			});
		} else {
			this.saveMessage(selectedLabel, activeSystem ? activeSystem.uuid : null);
		}
	};

	saveMessage = (label, systemUUID) => {
		const {
			messageValue, messageButtonLabel, messages, teamsWebhook, slackWebhook,
		} = this.state;

		const existingMessage = messages.find((msg) => {
			return (
			  msg.label_id === label.id &&
			  ((systemUUID === null || systemUUID === '') ?
			  	(msg.system_uuid === null || msg.system_uuid === '') :
			  	msg.system_uuid === systemUUID)
			);
		  });


		console.log('Existing Message Found:', existingMessage);

		const data = {
			UUID:                 existingMessage ? existingMessage.UUID : null,
			system_uuid:          systemUUID,
			label_id:             label.id,
			account_uuid:         this.props.accountUUID,
			message_value:        messageValue,
			message_button_label: messageButtonLabel,
			teams_webhook:        teamsWebhook,
			slack_webhook:        slackWebhook,
		};

		this.createOrUpdateMessagingOption(data, !!existingMessage);
	};


	render() {
		const {
			activeSystem,
			selectedLabel,
			labels,
			messagingSystemData,
			messageValue,
			messageButtonLabel,
			isCreatingNewLabel,
			newLabelInput,
			currentMessageUUID,
		} = this.state;

		const labelOptions = labels?.length ?
			labels.map((label) => {
				return {
					value: label.id,
					label: label.label,
				};
			}) :
			[];
		labelOptions.push({
			value: 'newLabel',
			label: 'Create New Label',
		});

		const systemOptions = [
			{
				value: null,  // This represents no system selected
				label: 'No System Selected',
			},
			...messagingSystemData.map((system) => {
				return {
					value: system.uuid,
					label: system.display_name,
				};
			}),
		];

		return (
			<div className={style.messagingOptionsContainer}>
				<h1>Messaging Options</h1>

				{/* Message Label Dropdown */}
				<div className={style.listingContainer}>
					<div className={style.fieldGroup}>
						<label className={style.label}>Select a message label:</label>
						<Select
							id="labelSelect"
							options={labelOptions}
							onChange={this.handleLabelChange}
							value={labelOptions.find((option) => {
								return option.value === this.state.selectedLabel?.id;
							})}
							className={style.select}
						/>
					</div>

					{/* New Label Input (appears when creating a new label) */}
					{isCreatingNewLabel && (
						<div className={style.fieldGroup}>
							<label className={style.label}>New Label:</label>
							<input
								type="text"
								id="newLabelInput"
								value={newLabelInput}
								onChange={this.handleNewLabelChange}
								onBlur={this.handleNewLabelBlur} // Trigger label creation on blur
								onKeyPress={this.handleNewLabelKeyPress} // Trigger label creation on Enter key
								className={style.input}
							/>
						</div>
					)}

					{/* System Dropdown (optional, appears after label selection) */}
					{selectedLabel && (
						<div className={style.fieldGroup}>
							<label className={style.label}>Select a system (optional):</label>
							<Select
								id="systemSelect"
								options={systemOptions}
								onChange={this.handleSystemChange}
								value={systemOptions.find(
									(option) => {
										return option.value === (activeSystem ? activeSystem.uuid : null);
									}
								)}
								className={style.select}
							/>
						</div>
					)}

					{/* Message Fields (appear after label selection) */}
					{selectedLabel && (
						<div className={style.selectedSystemContainer}>
							<div className={style.fieldGroup}>
								<label className={style.label}>Message Value:</label>
								<textarea
									id="messageValue"
									value={messageValue}
									onChange={this.handleInputChange}
									className={style.textarea}
									rows="4"
								/>
							</div>

							<div className={style.fieldGroup}>
								<label className={style.label}>Message Button Label:</label>
								<input
									type="text"
									id="messageButtonLabel"
									value={messageButtonLabel}
									onChange={this.handleInputChange}
									className={style.input}
								/>
							</div>
						</div>
					)}

					{/* Teams Webhook Field */}
					{selectedLabel && (
						<div className={style.fieldGroup}>
							<label className={style.label}>Teams Webhook:</label>
							<input
								type="text"
								id="teamsWebhook"
								value={this.state.teamsWebhook}
								onChange={this.handleInputChange}
								className={style.input}
							/>
						</div>
					)}
					{selectedLabel && (
						<div className={style.fieldGroup}>
							<label className={style.label}>Slack Webhook:</label>
							<input
								type="text"
								id="slackWebhook"
								value={this.state.slackWebhook}
								onChange={this.handleInputChange}
								className={style.input}
							/>
						</div>
					)}

					{/* Save Button */}
					{selectedLabel && (
						<div className={style.buttonGroup}>
							<button
								className={`${style.button} button`}
								onClick={this.handleSave}
							>
								<CustomIcon icon="plus" />{' '}
								{currentMessageUUID ? 'Update Option' : 'Create Option'}
							</button>
							{currentMessageUUID && activeSystem && (
								<button
									className={`${style.button} button ${style.deleteButton}`}
									onClick={this.handleDelete}
								>
									<CustomIcon icon="trash" /> Delete
								</button>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default MessagingOptions;