import React from 'react';

import ImageUploaderComponent from 'Crow/Form/Field/Custom/Vulture/Elements/Uploader.js';
import DynamicMessageComponent from 'Crow/Form/Field/Custom/Vulture/DynamicMessage.js';
import QrCodeComponent from 'Crow/Form/Field/Custom/Vulture/QrCode.js';

import EmergencyMessageComp from 'Crow/Form/Field/Custom/Vulture/EmergencyMessage';
import TextSmall from 'Vulture/Field/TextSmall.js';
import style from './LayoutGroup.module.css';

import {
	fetchProviderURL,
	fetchProviderAuth,
	fetchProviderCalendars,
	fetchProviderCalendarEvents,
	fetchProviderClearAuth,
} from 'Vulture/Raven.js';
import JSONField from './JSONField';

class RavenDisplayComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			auth:              false,
			provider:          '',
			calendars:         [],
			currentCalendarID: this.props.component.settings.calendar_id || `Select a calendar`,
			events:            [],
		};
	}

	componentDidMount() {
		const {
			component,
		} = this.props;

		let provider = component.settings.raven_provider;
		if (!provider || sessionStorage.content_auth_provider) {
			provider = sessionStorage.content_auth_provider;
		}

		let authCode = sessionStorage.content_auth_code;

		if (provider) {
			this.fetchProviderAuth(provider, authCode, (res) => {
				if (res?.auth) {
					this.setState({
						auth: true,
						provider,
					}, () => {
						Reflect.deleteProperty(sessionStorage, 'content_auth_provider');
						Reflect.deleteProperty(sessionStorage, 'content_auth_code');

						this.fetchProviderCalendars(provider, (res) => {
							if (res.calendars) {
								this.setState({
									calendars: res.calendars,
								});
							}
						});
					});
				}
			});
		}
	}

	componentDidUpdate(prevProps) {
		const {
			component,
		} = this.props;

		if (prevProps.component.settings.raven_provider !== component.settings.raven_provider) {
			this.setState({
				provider: component.settings.raven_provider,
			});
		}
	}

	async _fetchProviderURL(provider, componentUUID, cb = () => { }) {
		let res = await fetchProviderURL(provider, componentUUID);

		return cb(res);
	}

	fetchProviderURL(provider, cb = () => { }) {
		const {
			app,
			component,
		} = this.props;

		app.load(
			this._fetchProviderURL.bind(this, provider, component.uuid, cb)
		);
	}

	async _fetchProviderAuth(provider, componentUUID, authCode, cb = () => {}) {
		let res = await fetchProviderAuth(provider, componentUUID, authCode);

		return cb(res);
	}

	async _fetchClearProvider(componentUUID, cb = () => { }) {
		let res = await fetchProviderClearAuth(componentUUID);

		return cb(res);
	}

	fetchClearProvider(cb = () => { }) {
		const {
			app,
			component,
		} = this.props;

		app.load(
			this._fetchClearProvider.bind(this, component.uuid, cb)
		);
	}

	fetchProviderAuth(provider, authCode, cb = () => { }) {
		const {
			app,
			component,
		} = this.props;

		app.load(
			this._fetchProviderAuth.bind(this, provider, component.uuid, authCode, cb)
		);
	}

	async _fetchProviderCalendars(provider, componentUUID, cb = () => { }) {
		let res = await fetchProviderCalendars(provider, componentUUID);

		return cb(res);
	}

	fetchProviderCalendars(provider, cb = () => { }) {
		const {
			app,
			component,
		} = this.props;

		app.load(
			this._fetchProviderCalendars.bind(this, provider, component.uuid, cb)
		);
	}

	async _fetchProviderCalendarEvents(provider, componentUUID, calendarID, cb = () => { }) {
		let res = await fetchProviderCalendarEvents(provider, componentUUID, calendarID);

		return cb(res);
	}

	fetchProviderCalendarEvents(provider, calendarID, cb = () => { }) {
		const {
			app,
			component,
		} = this.props;

		app.load(
			this._fetchProviderCalendarEvents.bind(this, provider, component.uuid, calendarID, cb)
		);
	}

	handleProviderClick(e) {
		const {
			calendars,
			provider,
		} = this.state;

		let currProvider = provider || ''; // || component.settings.raven_provider;
		let nextProvider = e.target.innerText.toLowerCase();

		let currProviderStr = provider;
		if (provider.length > 0) {
			currProviderStr = (currProvider[0].toUpperCase() + currProvider.substring(1));
		}
		let nextProviderStr = e.target.innerText;

		this.fetchProviderAuth(nextProvider, null, (res) => {
			if (!res.auth && provider) {
				let deauthorize = confirm(`You are currently connected to "${currProviderStr}".\n\nIf you want to fully disconnect from "${currProviderStr}", please login to "${currProviderStr}" and deauthorize this application.\n\nConnect to the new "${nextProviderStr}" without fully deauthorizing "${currProviderStr}" first?`);

				if (deauthorize) {
					this.fetchProviderURL(nextProvider, (res) => {
						if ('url' in res) {
							window.location.href = res.url;
						}
					});
				}
			}

			if (!res.auth && !provider) {
				this.fetchProviderURL(nextProvider, (res) => {
					if ('url' in res) {
						window.location.href = res.url;
					}
				});
			}

			if (res.auth && currProviderStr.toLowerCase() === nextProvider.toLowerCase()) {
				let deauthorize = confirm(`You are currently connected to "${currProviderStr}".\n\nIf you want to fully disconnect from "${currProviderStr}", please login to "${currProviderStr}" and deauthorize this application.\n\nDo you want to delete your local data for "${currProviderStr}"?`);
				if (deauthorize) {
					this.fetchClearProvider(() => {
						window.location.reload();
					});
				}
			} else if (res.auth) {
				if (calendars.length === 0) {
					this.fetchProviderURL(nextProvider, (res) => {
						if ('url' in res) {
							window.location.href = res.url;
						}
					});
				}
			}
		});
	}

	handleChange(event) {
		this.setState({
			currentCalendarID: event.target.value,
		});
	}

	renderProviderContent() {
		const {
			component,
			componentSettingMap,
			app,
		} = this.props;

		const {
			provider,
		} = this.state;

		if (provider !== 'third party') {
			return this.renderProviderCalendars();
		}

		return (
			<div>
				<p>Endpoint</p>
				<TextSmall
					value={component.settings.raven_integration_endpoint}
					setValue={(e) => {
						app.setValue('component', `component.${component.uuid}.setting.${componentSettingMap.raven_integration_endpoint.uuid}.string`, e);
					}}
				/>
				<p>Conversion Map</p>
				<JSONField
					value={component.settings.raven_conversion_map}
					handleChange={(e) => {
						if (e !== component.settings.raven_conversion_map) {
							app.setValue('component', `component.${component.uuid}.setting.${componentSettingMap.raven_conversion_map.uuid}.json`, e);
						}
					}}
				/>
			</div>
		);
	}

	renderProviderCalendars() {
		const {
			provider,
			calendars,
			currentCalendarID,
		} = this.state;

		if (calendars.length === 0) {
			return 'No calendars loaded.';
		}

		return (
			<select
				value={currentCalendarID}
				onChange={(e) => {
					if (e.target.selectedIndex > 0) {
						let calendarID = Object.keys(calendars).sort()[(e.target.selectedIndex - 1)];
						this.handleChange(e);
						this.props.setValue(calendarID);
					}
				}}
			>
				<option value="" selected>Select a {provider} calendar</option>
				{Object.keys(calendars).sort().map((calendarID) => {
					let calendarName = calendars[calendarID];

					return (
						<option
							key={calendarID}
							value={calendarID}
						>
							{calendarName}
						</option>
					);
				})}
			</select>
		);
	}

	renderProviderCalendarEvents() {
		const {
			events,
		} = this.state;

		return (
			<div>
				<ul>
					{events.map((e) => {
						return (
							<li
								key={e.id}
							>{e.subject}</li>
						);
					})}
				</ul>
			</div>
		);
	}

	render() {
		const {
			component,
		} = this.props;

		const {
			auth,
			provider,
		} = this.state;

		let providers = [ 'google', 'office365', 'third party', ];

		let providerColor = {};
		for (let p of providers) {
			providerColor[p] = (p === provider) ? 'green' : 'red';
		}

		return (
			<div>
				<p
					onClick={(e) => {

					}}
				>{component.uuid}</p>

				{providers.map((p) => {
					return (
						<button
							key={p}
							style={{
								backgroundColor: providerColor[p],
								color:           '#fff',
							}}
							onClick={this.handleProviderClick.bind(this)}
						>
							{p.split(' ').map((_p) => {
								return _p[0].toUpperCase() + _p.substring(1);
							}).join(' ')}</button>
					);
				})}

				{auth &&
					<div>
						{this.renderProviderContent()}
						{this.renderProviderCalendarEvents()}
					</div>
				}
			</div>
		);
	}
}

function LayoutGroupComponent(props) {
	const {
		app,
		component,
		componentMap,
		componentTypeMap,
		componentSettingMap,
	} = props;

	if (Object.keys(componentMap).length === 0) {
		debugger;
	}

	let children = component.components;

	if (component.type === 'layout_group') {
		children = children.filter((childComponentUUID) => {
			if (componentMap[childComponentUUID].type === 'layout_group') {
				return false;
			}

			return true;
		});
	}

	children.sort((aUUID, bUUID) => {
		let aComponent = componentMap[aUUID];
		let bComponent = componentMap[bUUID];

		let aOrder = aComponent.settings['layout_order'];
		let bOrder = bComponent.settings['layout_order'];

		return aOrder > bOrder ? 1 : -1;
	});

	const childMap = children.map((childComponentUUID) => {
		let childComponent = componentMap[childComponentUUID];
		let renderName = childComponent.settings.condor_render_name;

		let m1Key = `component.${childComponentUUID}`;
		let entityName, entitySetting, m2Key, settingName;

		let Component;
		let componentProps = {
			app,
			componentMap,
			componentTypeMap,
			componentSettingMap,
			component: childComponent,
			label:     childComponent.settings['custom_portal_label'] || childComponent.settings['condor_render_name'] || childComponent.type,
			...childComponent.settings,
		};

		switch (renderName) {
		case 'LayoutGroup':
			Component = LayoutGroupComponent;
			break;

		case 'ImageComponent':
		case 'InfoBoxImg':
			settingName = 'source';
			if (renderName === 'InfoBoxImg') {
				settingName = 'image_url';
			}

			componentProps = {
				classList:       [ `${style.infoBoxUploader}`, ],
				bucketName:      'ts-condor-assets',
				parentDirectory: 'ts-layout-group',
				...componentProps,
			};
			Component = ImageUploaderComponent;
			break;

		case 'PdfComponent':
			settingName = 'source';

			componentProps = {
				classList:       [ `${style.infoBoxUploader}`, ],
				bucketName:      'ts-condor-assets',
				parentDirectory: 'ts-layout-group',
				type:            'PDF',
				...componentProps,
			};
			Component = ImageUploaderComponent;
			break;

		case 'TextBox':
			settingName = 'text';

			componentProps = {
				classList: [],
				...componentProps,
			};
			Component = TextSmall;
			break;

		case 'InfoBox':
			settingName = 'text';

			componentProps = {
				classList: [],
				...componentProps,
			};
			Component = LayoutGroupComponent;
			break;

		case 'InfoBoxText':
			settingName = 'text';

			componentProps = {
				classList: [],
				...componentProps,
			};
			Component = TextSmall;
			break;

		case 'InfoBoxHeader':
			settingName = 'text';

			componentProps = {
				classList: [],
				...componentProps,
			};
			Component = TextSmall;
			break;

		case 'DynamicMessage':
			settingName = 'message_list';

			componentProps = {
				classList: [],
				...componentProps,
			};
			Component = DynamicMessageComponent;
			break;

		case 'EmergencyMessage':
			/* settingName = 'message'; */

			componentProps = {
				classList: [],
				...componentProps,
			};
			Component = EmergencyMessageComp;
			break;
		case 'QrCode':
			settingName = 'url';

			componentProps = {
				classList: [],
				...componentProps,
			};
			Component = QrCodeComponent;
			break;


		case 'RavenDisplay':
			settingName = 'calendar_id';
			componentProps = {
				componentSettingMap,
				classList: [],
				...componentProps,
			};
			Component = RavenDisplayComponent;
			break;

		default:
			Component = function () {
				return (
					<div>Unknow Component: {renderName}</div>
				);
			};
		}

		if (settingName) {
			entitySetting = props.componentSettingMap[settingName];
			m2Key = `setting.${entitySetting.uuid}`;
			entityName = `${m1Key}.${m2Key}.${entitySetting.type}`;
		}

		return (
			<React.Fragment
				key={childComponentUUID}
			>
				<Component
					value={componentMap[childComponentUUID].settings[settingName]}
					setValue={app.setValue.bind(app, 'content', entityName)}
					{...componentProps}
				/>
			</React.Fragment>
		);
	});

	if (component.type === 'info_box_group') {
		return childMap;
	}
	return (
		<div className={style.infoBox}>
			<div className={style.content}>
				<div className={style.formHeader}>
					<h3>{component.label || 'Content'}</h3>
				</div>
				<div className={style.form}>
					{childMap}
				</div>
			</div>
		</div>
	);
}

export default LayoutGroupComponent;