import React, {
	useRef,
	useMemo,
} from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';

import {
	getSession,
} from 'Vulture/Session';

import {
	useRowSelect,
	useSortBy,
	useTable,
	useFilters,
} from 'react-table';

const paginateRows = (rows, page, rowsPerPage) => {
	let sliceStart = (page - 1) * rowsPerPage;
	let sliceEnd = page * rowsPerPage;

	while (rows.length < sliceStart) {
		sliceStart -= rowsPerPage;
		sliceEnd -= rowsPerPage;
	}

	return rows.slice(sliceStart, sliceEnd);
};

const formatTime = (time) => {
	const timeArray = time.split(':');
	let hour = parseInt(timeArray[0], 10);
	const minute = timeArray[1];
	let period = 'AM';

	if (hour === 0) {
		hour = 12;
	} else if (hour === 12) {
		period = 'PM';
	} else if (hour > 12) {
		hour -= 12;
		period = 'PM';
	}

	// Pad minute with leading zeros if needed
	const paddedMinute = minute.padStart(2, '0');

	return `${hour}:${paddedMinute} ${period}`;
};

const formatDate = (date) => {
	const newDate = new Date(date);
	const options = {
		month: '2-digit',
		day:   '2-digit',
		year:  'numeric',
	};
	const formattedDate = newDate.toLocaleDateString('en-US', options);

	return formattedDate;
};

const pg = getSession().pg;

const VultureTable = (props) => {
	const {
		columns,
		data,
		columnsToHide,
		secondaryNavButtons,
		tertiaryNavButtons,
		theadColStyles = () => {},
		filterRowColStyles = () => {},
		tbodyColStyles = () => {},
		cellOnClick = () => {},
		allRowsSelected,
		toggleSelectAllRows,
		listingTable,
		listingConfigUUID,
		listingConfigsWithSystems,
		style = {},
		page,
		rowsPerPage,
		onFilter = () => {},
	} = props;

	const tableScrollerRef = useRef();
	const tableTopScrollerRef = useRef();
	const tableTopScrollerChildRef = useRef();
	const theadRef = useRef();

	if (theadRef.current && tableTopScrollerChildRef.current) {
		tableTopScrollerChildRef.current.style.width = `${theadRef.current.clientWidth}px`;
	}

	const defaultColumn = useMemo(() => {
		return {
			Filter: function DefaultColumnFilter({
				column: {
					filterValue, preFilteredRows, setFilter,
				},
			}) {
				const count = preFilteredRows.length;

				const handleChange = (e) => {
					const value = e.target.value || undefined;
					setFilter(value);
					onFilter(value, preFilteredRows);
				};

				if (count === 0) {
					return <></>;
				}

				return (<input
					value={filterValue || ''}
					onChange={handleChange}
					placeholder={`Search ${count} records...`}
				/>);
			},
		};
	}, [ onFilter, ]);

	const memoizedColumns = useMemo(() => {
		return columns;
	}, [ columns, ]);

	const memoizedData = useMemo(() => {
		return data;
	}, [ data, ]);

	const memoizedColumnsToHide = useMemo(() => {
		return columnsToHide;
	}, [ columnsToHide, ]);

	const memoizedSecondaryNavButtons = useMemo(() => {
		return secondaryNavButtons || [];
	}, [ secondaryNavButtons, ]);

	const memoizedTertiaryNavButtons = useMemo(() => {
		return tertiaryNavButtons || [];
	}, [ tertiaryNavButtons, ]);

	const tableInstance = useTable(
		{
			columns:          memoizedColumns,
			data:             memoizedData,
			disableMultiSort: true,
			initialState:     {
				hiddenColumns: memoizedColumnsToHide,
			},
			autoResetFilters: false,
			autoResetSortBy:  false,
			defaultColumn,
		},
		useFilters,
		useSortBy,
		useRowSelect
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = tableInstance;

	const showWarning = pg === 'pm' && listingTable && listingConfigsWithSystems && !listingConfigsWithSystems.includes(listingConfigUUID);

	let paginatedRows = rows;

	if (page && rowsPerPage) {
		paginatedRows = paginateRows(rows, page, rowsPerPage);
	}

	return (
		<>
			{(showWarning) && (
				<div className='noPermissionsWarning'>
				*You do not have permissions to edit these listings.
				</div>
			)}
			<div className='secondaryNav'>
				{memoizedSecondaryNavButtons.map((button, i) => {
					return (
						<div
							key={`button_${i}`}
							className={button.className}
							onClick={button.onClick}
						>
							{button.icon ? <CustomIcon icon={button.icon}/> : ''}{button.text}
						</div>
					);
				})}
			</div>

			<div
				className={style.tableTopScroller}
				ref={tableTopScrollerRef}
				onScroll={(e) => {
					const newPosition = e.target.scrollLeft;
					tableScrollerRef.current.scrollLeft = newPosition;
				}}
			>
				<div
					className={style.tableTopScrollerChild}
					ref={tableTopScrollerChildRef}
				/>
			</div>
			<div
				id="table-scroll"
				className={style.tableScroll}
				ref={tableScrollerRef}
				onScroll={(e) => {
					const newPosition = e.target.scrollLeft;
					tableTopScrollerRef.current.scrollLeft = newPosition;
				}}
			>
				<div className={style.table} {...getTableProps()}>
					<div className={style.thead} ref={theadRef}>
						{headerGroups.map((headerGroup, i) => {
							return (
								<div
									key={`${i}`}
									className={style.tr}
									{...headerGroup.getHeaderGroupProps()}
								>
									{headerGroup.headers.map((column, j) => {
										let columnSortIcon = '';
										if (column.isSorted) {
											columnSortIcon = <CustomIcon icon="caret-up"/>;
										}

										if (column.isSortedDesc) {
											columnSortIcon = <CustomIcon icon="caret-down"/>;
										}

										return (
											<div
												id={column.render('id')}
												key={`${j}`}
												className={`${style.th} ${style[column.render('id')]}`}
												{...column.getHeaderProps()}
												{...column.getSortByToggleProps()}
												style={theadColStyles(column)}
											>
												<div className={style.thContainer}>
													<div className={style.nameContainer}>
														{column.render('Header')}
													</div>
													<div className={style.iconContainer}>
														{columnSortIcon}
													</div>
												</div>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>

					<div className={style.filterRow}>
						{headerGroups.map((headerGroup, i) => {
							return (
								<div
									key={`${i}`}
									className={style.tr}
									{...headerGroup.getHeaderGroupProps()}
								>
									{headerGroup.headers.map((column, j) => {
										let columnFilter = null;

										if (column.canFilter) {
											columnFilter = <div className={style.thFilter}>{column.render('Filter')}</div>;
										} else if (column.render('id').startsWith('checkbox')) {
											columnFilter = <div className={allRowsSelected ? style.activeCheckbox : style.disabledSquare} onClick={(e) => {
												e.preventDefault();
												toggleSelectAllRows(allRowsSelected);
											}}>
												<div className={style.checkboxIcon}>
													<CustomIcon icon="check"/>
												</div>
											</div>;
										}

										return (
											<div
												id={column.render('id')}
												key={`${j}`}
												className={`${style.th} ${style[`${column.render('id')}`]}`}
												{...column.getHeaderProps()}
												style={filterRowColStyles(column)}
											>
												<div className={style.thContainer}>
													{columnFilter}
												</div>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
					<div className={style.tbody} {...getTableBodyProps()}>
						{paginatedRows.map((row, i) => {
							prepareRow(row);
							return (
								<div
									key={`row_${i}`}
									className={style.tr}
									role='row'
								>
									{row.cells.map((cell, j) => {
										let cellColumnId = cell.render('id');
										let cellRendered = cell.render('Cell');
										let listingColumn = props.fd[`listing_column.${cellColumnId}`];
										let columnId = '';

										if (listingColumn) {
											for (let [ m1Key, ] of Object.entries(listingColumn)) {
												if (m1Key.startsWith('listing_column_type.')) {
													columnId = m1Key.split('.')[1];
												}
											}
										}

										let listing_column_type = props.fd[`listing_column_type.${columnId}`];

										if (cellColumnId === 'resolution') {
											return <div
												key={`${cellColumnId}_${j}`}
												className={`${style.td} ${style[`${cellColumnId}`]}`}
												{...cell.getCellProps()}
											>
												<div className={style.tdContainer}>
													{cellRendered.props.value.replace(/px/g, '')}
												</div>
											</div>;
										}

										if (listing_column_type?.type === 'datetime' && cellRendered.props.value) {
											// Date start and Date end
											return <div
												key={`${cellColumnId}_${j}`}
												onClick={cellOnClick(cell, row)}
												className={`${style.td} ${style[`${cellColumnId}`]}`}
												{...cell.getCellProps()}
												style={tbodyColStyles(cell)}
											>
												<div className={style.tdContainer}>
													{formatDate(cellRendered.props.value)}
												</div>
											</div>;
										}
										if (listing_column_type?.type === 'time') {
											// Time
											let time = cell.value;

											return <div
												key={`${cellColumnId}_${j}`}
												onClick={cellOnClick(cell, row)}
												className={`${style.td} ${style[`${cellColumnId}`]}`}
												{...cell.getCellProps()}
												style={tbodyColStyles(cell)}
											>
												<div className={style.tdContainer}>
													{time && formatTime(time)}
												</div>
											</div>;
										}
										return <div
											key={`${cellColumnId}_${j}`}
											onClick={cellOnClick(cell, row)}
											className={`${style.td} ${style[`${cellColumnId}`]}`}
											{...cell.getCellProps()}
											style={tbodyColStyles(cell)}
										>
											<div className={style.tdContainer}>
												{cellRendered}
											</div>
										</div>;
									})}
								</div>
							);
						})}
					</div>
				</div>
			</div>

			<div className='secondaryNav'>
				{memoizedTertiaryNavButtons.map((button, i) => {
					return (
						<div
							key={`button_${i}`}
							className={button.className}
							onClick={button.onClick}
						>
							{button.icon ? <CustomIcon icon={button.icon}/> : ''}{button.text}
						</div>
					);
				})}
			</div>
		</>
	);
};

export default VultureTable;