import React from 'react';

import CustomIcon from 'Crow/Form/Field/Custom/Common/CustomIcon/CustomIcon.js';

import {
	getSession,
	logout,
} from 'Vulture/Session.js';

import {
	dlAccountSelectPush,
	dlLogoutPush,
	dlNavigationPush,
	dlToolsMenu,
	dlUserMenu,
} from 'Vulture/DataLayer.js';

import style from './Header.module.css';

import hierarchyDropdownStyle from './SystemsHierarchyDropdown.module.css';

import {
	cardinalHost,
} from 'Vulture/ENV';

import SystemsTable from './SystemsTable';

class HeaderView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			accountDropdownClass:   style.accountDropdownHidden,
			hierarchyDropdownClass: style.hierarchyDropdownHidden,
			userDropdownClass:      style.userDropdownHidden,
			toolsDropdownClass:     style.toolsHidden,
			encryptedKey:           null,
		};
	}

	async logout() {
		const {
			app,
		} = this.props;

		try {
			await logout();
		} catch (err) {
			console.error(err);
		}

		app.logout();
	}

	componentDidUpdate(prevProps) {
		const {
			accountUUID,
		} = this.props;

		if (prevProps.accountUUID === accountUUID) {
			return;
		}

		const {
			session = '',
		} = getSession();

		let key = accountUUID.substring(0, 32);

		const env = window._getEnv('COMMITHASH');

		let iv = env;
		while (iv.length < 12) {
			iv += env;
		}
		iv = iv.substring(0, 12);

		const encoder = new TextEncoder();
		const keyBuffer = encoder.encode(key);
		const ivBuffer = encoder.encode(iv);
		const tokenBuffer = encoder.encode(session);

		// Import the key
		crypto.subtle.importKey('raw', keyBuffer, {
			name: 'AES-GCM',
		}, false, [ 'encrypt', 'decrypt', ])
			.then((cryptoKey) => {
				return crypto.subtle.encrypt({
					name: 'AES-GCM',
					iv:   ivBuffer,
				}, cryptoKey, tokenBuffer);
			})
			.then((encryptedKey) => {
				let bin = '';
				const bytes = new Uint8Array(encryptedKey);
				for (const byte of bytes) {
					bin += String.fromCharCode(byte);
				}

				this.setState({
					encryptedKey: window.btoa(bin),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	renderHierarchyDropdown() {
		const {
			app,
			accountUUID,
			accountList,
			fd,
			systemGroupUUID,
			mode,
		} = this.props;

		let shouldShowSystemGroups = false;

		switch (mode) {
			case 'content':
			case 'systems':
				shouldShowSystemGroups = true;
				break;
			default:
				break;
		}

		const buildSystemGroups = (m1Key) => {
			return Object
				.keys(fd[m1Key] || {})
				.filter((m2Key) => {
					return m2Key.startsWith('system_group.');
				})
				.sort((a, b) => {
					return fd[a]?.display_name > fd[b]?.display_name ? 1 : -1;
				})
				.map((m2Key) => {
					const isSelected = m2Key === 'system_group.' + systemGroupUUID;
					const projectNumber = fd[m2Key]['project_number'];
					const name = (
						<div
							className={style.oneLine}
						>
							<div className={`${style.displayName} ${isSelected ? hierarchyDropdownStyle.selectedSystemGroupDisplayName : ''}`}>{fd[m2Key].display_name}</div>
						</div>
					);

					return {
						id:           m2Key.split('.')[1],
						name,
						m1:           'system_group',
						projectNumber,
						status:       '',
						subRows:      buildSystemGroups(m2Key),
						components:   [],
						path:         '',
						searchValues: {
							name:            fd[m2Key].display_name,
							projectNumber:   '',
							resolution:      '',
							status:          '',
							macAddress:      '',
							orientation:     '',
							directoryNumber: '',
						},
					};
				});
		};

		const systemGroups = buildSystemGroups(`account.${accountUUID}`);

		return (
			<div className={`${style.hierarchyMenu} ${this.state.hierarchyDropdownClass} ${accountList.length === 0 && style.buttonNoAccounts}`}>
				<button className={style.button}
					onClick={(e) => {
						dlNavigationPush({
							destination: 'All Accounts',
						});

						this.setState({
							hierarchyDropdownClass: style.hierarchyDropdownHidden,
						}, () => {
							app.redirect({
								to: `/accounts`,
							});
						});
					}}
				>All Accounts</button>
				{accountList
					.sort((a, b) => {
						const aM1UUID = Object.keys(a)[0];
						const aM1Name = Object.values(a)[0];
						const bM1UUID = Object.keys(b)[0];
						const bM1Name = Object.values(b)[0];

						if (aM1UUID === accountUUID) {
							return -1;
						}

						if (bM1UUID === accountUUID) {
							return 1;
						}

						return aM1Name > bM1Name ? 1 : -1;
					})
					.map((accountObj, i) => {
					const m1UUID = Object.keys(accountObj)[0];
					const m1Name = Object.values(accountObj)[0];

					return (
						<>
							<button className={style.button}
								onClick={(e) => {
									e.preventDefault();

									dlAccountSelectPush({
										accountName: m1Name,
									});

									this.setState({
										hierarchyDropdownClass: style.hierarchyDropdownHidden,
									}, () => {
										app.setAccountUUID(m1UUID);
										if (systemGroupUUID) {
											app.redirect({
												to: `/systems/${accountUUID}`,
											});
										}
									});
								}}
							>{m1Name}</button>
							{shouldShowSystemGroups && m1UUID === accountUUID && <SystemsTable
								style={hierarchyDropdownStyle}
								array={systemGroups}
								systemGroupUUID={systemGroupUUID}
								defaultExpanded={systemGroups.map(() => { return {index: true}; })}
								systemsTableRowOnclick={(_systemGroupUUID) => {
									this.setState({
										hierarchyDropdownClass: style.hierarchyDropdownHidden,
									}, () => {
										app.setSystemGroupUUID(systemGroupUUID === _systemGroupUUID ? '' : _systemGroupUUID);
										app.redirect({
											to: `/systems/${accountUUID}`,
										});
									});
								}}
							/>}
						</>
					);
				})}
			</div>
		);
	}

	renderUserDropdown() {
		return (
			<div className={`${style.userMenu} ${this.state.userDropdownClass}`} data-test-id="userDropdown">
				<button
					data-test-id="logoutButton"
					className={style.button}
					onClick={async (e) => {
						dlLogoutPush();
						await this.logout();
					}}
				>Logout</button>
			</div>
		);
	}

	renderSupport() {
		const {
			app,
			accountUUID,
		} = this.props;

		// TODO: fix SF Web2Case form
		return '';

		return (
			<a href='' onClick={(e) => {
				e.preventDefault();

				let to = '/support';
				if (accountUUID) {
					to = `/support/${accountUUID}`;
				}

				app.redirect({
					to,
				});
			}}
			className={`${style.link} ${style.userSupport}`}
			>
				<CustomIcon icon='headset' />&nbsp;
				<div className={`${style.pullDownName} ${style.pullDownAccountName}`}>Need Assistance?</div>
			</a>
		);
	}

	renderToolsDropdown() {
		const {
			app,
			accountUUID,
		} = this.props;

		const {
			encryptedKey,
		} = this.state;

		const {
			pg = '',
		} = getSession();

		let themeMenu = (
			<li>
				<a href='' onClick={(e) => {
					e.preventDefault();

					dlNavigationPush({
						destination: 'Theme Editor',
					});

					let to = '/theme_editor';
					if (accountUUID) {
						to = `/theme_editor/${accountUUID}`;
					}

					app.redirect({
						to,
					});

					this.setState({
						toolsDropdownClass: style.toolsHidden,
					});
				}}><CustomIcon icon="swatchbook"/>Theme Editor</a>
			</li>
		);

		let screenshotView = (
			<li>
				<a href='' onClick={(e) => {
					e.preventDefault();

					dlNavigationPush({
						destination: 'Screenshot',
					});

					let to = '/screenshots';
					if (accountUUID) {
						to = `/screenshots/${accountUUID}`;
					}

					app.redirect({
						to,
					});

					this.setState({
						toolsDropdownClass: style.toolsHidden,
					});
				}}><CustomIcon icon="book"/>Screenshots</a>
			</li>
		);

		const systemAssignment = (
			<li>
				<a href='' onClick={(e) => {
					e.preventDefault();

					dlNavigationPush({
						destination: 'System Assignment',
					});

					let to = '/system_assignment';

					app.redirect({
						to,
					});

					this.setState({
						toolsDropdownClass: style.toolsHidden,
					});
				}}><CustomIcon icon="book"/>System Assignment</a>
			</li>
		);

		const entertainment = (
			<li>
				<a href='' onClick={(e) => {
					e.preventDefault();

					dlNavigationPush({
						destination: 'Entertainment',
					});

					let to = '/entertainment';
					if (accountUUID) {
						to = `/entertainment/${accountUUID}`;
					}

					app.redirect({
						to,
					});

					this.setState({
						toolsDropdownClass: style.toolsHidden,
					});
				}}><CustomIcon icon="book"/>Entertainment</a>
			</li>
		);

		const dataPresetsView = (
			<li>
				<a href='' onClick={(e) => {
					e.preventDefault();

					dlNavigationPush({
						destination: 'DataPresets',
					});

					let to = '/data_presets';
					if (accountUUID) {
						to = `/data_presets/${accountUUID}`;
					}

					app.redirect({
						to,
					});

					this.setState({
						toolsDropdownClass: style.toolsHidden,
					});
				}}><CustomIcon icon="laptop-code"/>Data Presets</a>
			</li>
		);

		const cardinalLink = (
			<li>
				<a
					href={`${cardinalHost}/?cardinal_account=${accountUUID}&s=${encodeURIComponent(encryptedKey)}`}
					target='_blank'
					rel='noreferrer'
					onClick={() => {
						dlNavigationPush({
							destination: 'Cardinal',
						});
					}}
				>
					<CustomIcon icon="fa-location-arrow"/>Wayfinding
				</a>
			</li>
		);

		if (pg === 'iu' || pg === 'ia') {
			return (
				<div className={`${style.toolsMenu} ${this.state.toolsDropdownClass}`}>
					<ul className={style.toolsList}>
						<li>
							<a href='' onClick={(e) => {
								e.preventDefault();

								dlNavigationPush({
									destination: 'Admin Users',
								});

								let to = '/users';
								if (accountUUID) {
									to = `/users/${accountUUID}`;
								}

								app.redirect({
									to,
								});

								this.setState({
									toolsDropdownClass: style.toolsHidden,
								});
							}}><CustomIcon icon="users"/>Admin Users</a>
						</li>
						{pg === 'ia' ? themeMenu : ''}
						{pg === 'ia' ? dataPresetsView : ''}
						{pg === 'ia' ? screenshotView : ''}
						{accountUUID && (pg === 'ia' || pg === 'iu') ? cardinalLink : ''}
						{pg === 'ia' ? systemAssignment : ''}
						{pg === 'ia' ? entertainment : ''}
					</ul>
				</div>
			);
		}
	}

	render() {
		const {
			accountList,
			accountName,
			fd,
			systemGroupUUID,
			mode,
		} = this.props;

		const {
			email = '',
			pg = '',
		} = getSession();

		const selectedAccountName = accountName || 'Search Accounts';

		let dropdownText = selectedAccountName;

		switch (mode) {
			case 'content':
			case 'systems':
				dropdownText = fd['system_group.' + systemGroupUUID]?.display_name || selectedAccountName;;
				break;
			default:
				break;
		}

		let toolsDropdown = null;
		if (pg === 'iu' || pg === 'ia') {
			toolsDropdown = (
				<div
					className={`${style.link} ${this.state.toolsDropdownClass}`}
					onClick={(e) => {
						e.preventDefault();

						dlToolsMenu();

						if (this.state.toolsDropdownClass === style.toolsHidden) {
							this.setState({
								toolsDropdownClass: style.toolsVisible,
							});
							return;
						}
						this.setState({
							toolsDropdownClass: style.toolsHidden,
						});
					}}
					data-onclick="Tools Navigation"
				>
					<div className={`${style.pullDownName} ${style.toolsTextBox}`}>TOOLS</div>
				</div>
			);
		}

		return (
			<React.Fragment>
				<div className={`${style.popoverBackground} ${this.state.hierarchyDropdownClass} ${this.state.userDropdownClass} ${this.state.toolsDropdownClass}`}
					onMouseOver={(e) => {
						e.preventDefault();
						this.setState({
							hierarchyDropdownClass: style.hierarchyDropdownHidden,
							userDropdownClass:      style.userDropdownHidden,
							toolsDropdownClass:     style.toolsHidden,
						});
					}}/>
				<nav
					className={`${style.header}`}
				>
					<div className={style.hierarchy}>
						<div className={style.logo} data-onclick="The Touchsource Logo in header">
							<a href='https://touchsource.com/' target='_blank' rel="noopener noreferrer">
								<img src='https://ts-public-images.s3.amazonaws.com/TouchSourceSPARK_logo_FINAL_TS_whiteblue.png' alt="The Touchsource Spark PX logo" />
							</a>
						</div>
						<div className={style.hierarchyDropdown}>
							<div className={style.hierarchyDropdown}>
								<div className={style.vertLine}></div>
								<div
									className={`${style.link} ${this.state.hierarchyDropdownClass}`}
									onClick={(e) => {
										e.preventDefault();
										if (this.state.hierarchyDropdownClass === style.hierarchyDropdownHidden) {
											this.setState({
												hierarchyDropdownClass: style.hierarchyDropdownVisible,
											});

											return;
										}

										this.setState({
											hierarchyDropdownClass: style.hierarchyDropdownHidden,
										});
									}}
									data-onclick="Project Navigation"
								>
									<div className={style.pullDownName}>{dropdownText}</div>
									{(pg === 'iu' || pg === 'ia') || accountList.length > 1 ? <CustomIcon icon='caret-down'/> : null}
								</div>
							</div>
						</div>
					</div>
					<div className={style.userAccountContainer}>
						<div className={style.user}>
							<div className={style.userDropdown}>
								{this.renderSupport()}
								{toolsDropdown}
								<div
									className={`${style.link} ${this.state.userDropdownClass}`}
									onClick={(e) => {
										e.preventDefault();
										dlUserMenu();
										if (this.state.userDropdownClass === style.userDropdownHidden) {
											this.setState({
												userDropdownClass: style.userDropwdownVisible,
											});

											return;
										}
										this.setState({
											userDropdownClass: style.userDropdownHidden,
										});
									}}
									data-onclick="User Navigation"
									data-test-id="userNavigation"
								>
									<CustomIcon icon='user'/>&nbsp;
									<div className={`${style.pullDownName} ${style.pullDownAccountName}`}>{email}</div> <CustomIcon className='caret' icon='caret-down'/>
								</div>
							</div>
						</div>
					</div>
				</nav>
				{(pg === 'iu' || pg === 'ia') || (accountList || []).length > 1 ? this.renderHierarchyDropdown() : null}
				{this.renderUserDropdown()}
				{(pg === 'iu' || pg === 'ia') ? this.renderToolsDropdown() : null}
			</React.Fragment>
		);
	}
}

export default HeaderView;