import React from 'react';
import Select from 'react-select';
import { getFDValue } from 'Vulture/Helpers';
import { integerIconOptions } from 'Vulture/IconOptions';

class CrowFieldCustomVultureIcon extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	render() {
		const { fd, entity, app } = this.props;
		const { attr = {} } = entity || {};
		const { value } = this.state;

		/**
		 * Use the "directionalArrows" array from our options object.
		 * If in the future you want a different category, you can switch keys here.
		 */
		const directionalArrowsOptions = integerIconOptions[entity.type];

		const currentFDValue = getFDValue(fd, entity.name);
		// Find the matching option for what's stored in FD
		const matchedOption = directionalArrowsOptions.find((opt) => opt.value === currentFDValue);

		return (
			<div className={`drop-down ${(this.props.classNameList || []).join(' ')}`}>
				<div className="label">
					<label>{attr.label}</label>
				</div>
				<Select
					className={entity.style}
					options={directionalArrowsOptions}
					defaultValue={matchedOption || null}
					/**
					 * For a controlled component, you might do:
					 * value={directionalArrowsOptions.find(opt => opt.value === value) || null}
					 */
					onChange={(selectedOption) => {
						// Update local state
						if (this.state.value === selectedOption.value) {
							return;
						}
						this.setState({ value: selectedOption.value });
                        app.setValue('system', entity.name, this.state.value);
					}}
					onBlur={() => {
						// Only persist if changed
						if (this.state.value === currentFDValue) {
							return;
						}
						app.setValue('system', entity.name, this.state.value);
					}}
				/>
			</div>
		);
	}
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.elements.icon'] = CrowFieldCustomVultureIcon;
export default CrowFieldCustomVultureIcon;
