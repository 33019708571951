export const touchActionNone = 0;
export const touchActionTrayA = 1;
export const touchActionTrayB = 2;
export const touchActionTrayC = 3;
export const touchActionDetailsPageA = 4;
export const touchActionDetailsPageB = 5;
export const touchActionDetailsPageC = 6;
export const touchActionWayfindingA = 7;
export const touchActionWayfindingB = 8;
export const touchActionWayfindingC = 9;
export const touchActionIframe = 11;
export const touchActionPDF = 12;