import React from 'react';

import Select from 'react-select';

import CustomIcon from '../Common/CustomIcon/CustomIcon';

import style from './EntertainmentEditor.module.css';
import ImageUploaderComponent from './Elements/Uploader';

export class EntertainmentCategoryModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			category: props.category || {
				display_name:  '',
				description:   '',
				thumbnail_url: '',
				active:        true,
			},
		};
	}

	componentDidMount() { }

	render() {
		const {
			app,
			isEdit,
			onSave,
		} = this.props;

		const {
			category,
		} = this.state;

		return (
			<div className={style.modalContainer} data-ts-id="entertainmentCategoryModalContainer">
				<div className="text-small" data-ts-id="entertainmentCategoryModalName">
					<div className="label">
						<label>Name</label>
					</div>
					<input
						type="text"
						value={category.display_name}
						data-ts-id="entertainmentCategoryModalNameInput"
						maxLength={255}
						onChange={(e) => {
							this.setState({
								category: {
									...category,
									display_name: e.target.value,
								},
							});
						}}
					/>
				</div>
				<div className="text-small" data-ts-id="entertainmentCategoryModalDescription">
					<div className="label">
						<label>Description</label>
					</div>
					<input
						type="text"
						value={category.description}
						data-ts-id="entertainmentCategoryModalDescriptionInput"
						maxLength={255}
						onChange={(e) => {
							this.setState({
								category: {
									...category,
									description: e.target.value,
								},
							});
						}}
					/>
				</div>
				<ImageUploaderComponent
					app={app}
					component={{
						settings: {
							entityName: 'entertainmentCategory',
						},
					}}
					label='Thumbnail'
					value={category.thumbnail_url}
					parentDirectory='category-thumbnails'
					// bucketName='ts-public-images'
					bucketName='ts-condor-assets'
					type='Image'
					setValue={(value) => {
						this.setState({
							category: {
								...category,
								thumbnail_url: value,
							},
						});
					}}
				/>
				<div className="text-small" data-ts-id="entertainmentCategoryModalActive">
					<div className="label">
						<label>Active</label>
					</div>
					<input
						className={`on-off on-off-label ${style.activeToggle}`}
						type='checkbox'
						checked={category.active}
						onChange={(e) => {
							this.setState({
								category: {
									...category,
									active: !category.active,
								},
							});
						}}
						data-ts-id="entertainmentCategoryModalActiveCheckbox"
					/>
				</div>
				<div className={style.bottom} data-ts-id="entertainmentCategoryModalButtons">
					<button
						className='button-outlined'
						data-ts-id="entertainmentCategoryModalCancelButton"
						onClick={() => {
							app.closeModal();
						}}
					>
						Cancel
					</button>
					{isEdit && <button className='button-outlined' data-ts-id="entertainmentCategoryModalDeleteButton">
						<CustomIcon icon='trash' /> Delete
					</button>}
					<button
						className='button'
						data-ts-id="entertainmentCategoryModalSaveButton"
						onClick={() => {
							onSave(category);
						}}
					>
						<CustomIcon icon='save' /> Save
					</button>
				</div>
			</div>
		);
	}
}

export class EntertainmentFeedModal extends React.Component {
	constructor(props) {
		super(props);

		let initialActive = false;

		let initialResolution = {
			uuid:       '',
			resolution: '',
		};

		let initialFeed = {
			uuid:         '',
			display_name: '',
		};

		let initialProvider = {
			uuid:         '',
			display_name: '',
		};

		if (props.isEdit) {
			const categoryResolution = props.categoryResolutions.find((_categoryResolution) => {
				return _categoryResolution.entertainment_category_id === props.category?.id;
			});

			initialActive = categoryResolution?.active || false;

			const resolution = props.resolutions.find((_resolution) => {
				return _resolution.id === categoryResolution?.entertainment_provider_feed_resolution_id;
			});

			initialResolution = resolution;

			const feed = props.feeds.find((_feed) => {
				return _feed.id === resolution?.entertainment_provider_feed_id;
			});

			initialFeed = feed;

			const provider = props.providers.find((_provider) => {
				return _provider.id === feed?.entertainment_provider_id;
			});

			initialProvider = provider;

		}

		initialResolution.value = initialResolution.uuid;
		initialResolution.label = initialResolution.resolution;

		initialFeed.value = initialFeed.uuid;
		initialFeed.label = initialFeed.display_name;

		initialProvider.value = initialProvider.uuid;
		initialProvider.label = initialProvider.display_name;

		this.state = {
			provider:   initialProvider,
			feed:       initialFeed,
			resolution: initialResolution,
			active:     initialActive,
		};
	}

	componentDidMount() {
	}

	render() {
		const {
			app,
			category,
			providers,
			feeds,
			resolutions,
			categoryResolutions,
			onSave,
		} = this.props;

		const {
			provider,
			feed,
			resolution,
			active,
		} = this.state;

		return (
			<div className={style.modalContainer} data-ts-id="entertainmentFeedModalContainer">
				<div className="text-small" data-ts-id="entertainmentFeedModalProvider">
					<div className="label">
						<label>Feed Provider</label>
					</div>
					<Select
						value={provider}
						options={providers
							.sort((a, b) => {
								return a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1;
							})
							.map((_provider) => {
								return {
									..._provider,
									value: _provider.uuid,
									label: _provider.display_name,
								};
							})}
						onChange={(selected) => {
							this.setState({
								provider:   selected,
								resolution: {
									uuid:       '',
									resolution: '',
								},
							});
						}}
						data-ts-id="entertainmentFeedModalProviderSelect"
					/>
				</div>
				<div className="text-small" data-ts-id="entertainmentFeedModalFeed">
					<div className="label">
						<label>Feed</label>
					</div>
					<Select
						value={feed}
						options={feeds
							.filter((_feed) => {
								return _feed.entertainment_provider_id === provider?.id;
							})
							.sort((a, b) => {
								return a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1;
							})
							.map((_feed) => {
								return {
									..._feed,
									value: _feed.uuid,
									label: _feed.display_name,
								};
							})
						}
						onChange={(selected) => {
							this.setState({
								feed:       selected,
								resolution: {
									uuid:       '',
									resolution: '',
								},
							});
						}}
						data-ts-id="entertainmentFeedModalFeedSelect"
					/>
				</div>
				<div className="text-small" data-ts-id="entertainmentFeedModalResolution">
					<div className="label">
						<label>Resolution</label>
					</div>
					<Select
						value={resolution}
						options={resolutions
							.filter((_resolution) => {
								return _resolution.entertainment_provider_feed_id === feed?.id;
							})
							.sort((a, b) => {
								return a.resolution.toLowerCase() > b.resolution.toLowerCase() ? 1 : -1;
							})
							.map((_resolution) => {
								return {
									..._resolution,
									value: _resolution.uuid,
									label: _resolution.resolution,
								};
							})
						}
						onChange={(selected) => {
							this.setState({
								resolution: selected,
							});
						}}
						data-ts-id="entertainmentFeedModalResolutionSelect"
					/>
				</div>
				<div className="text-small" data-ts-id="entertainmentFeedModalActive">
					<div className="label">
						<label>Active</label>
					</div>
					<input
						className={`on-off on-off-label ${style.activeToggle}`}
						type='checkbox'
						checked={active}
						onChange={(e) => {
							this.setState({
								active: !active,
							});
						}}
						data-ts-id="entertainmentFeedModalActiveCheckbox"
					/>
				</div>
				<div className={style.bottom} data-ts-id="entertainmentFeedModalButtons">
					<button
						className='button-outlined'
						data-ts-id="entertainmentFeedModalCancelButton"
						onClick={() => {
							app.closeModal();
						}}
					>
						Cancel
					</button>
					<button
						className='button-outlined'
						data-ts-id="entertainmentFeedModalDeleteButton"
					>
						<CustomIcon icon='trash' /> Delete
					</button>
					<button
						className={resolution.id ? 'button' : 'button button-disabled'}
						data-ts-id="entertainmentFeedModalSaveButton"
						onClick={() => {
							if (!resolution.id) {
								return;
							}

							const categoryResolution = categoryResolutions.find((_categoryResolution) => {
								return _categoryResolution.entertainment_category_id === category?.id;
							});

							onSave({
								uuid:                                      categoryResolution?.uuid,
								entertainment_category_id:                 category?.id,
								entertainment_provider_feed_resolution_id: resolution.id,
								active,
							});
						}}
					>
						<CustomIcon icon='save' /> Save
					</button>
				</div>
			</div>
		);
	}
}