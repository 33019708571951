import React, {
	useEffect, useRef, useState,
} from 'react';
import CustomIcon from '../../Common/CustomIcon/CustomIcon';
import md5 from 'md5';
import style from '../Library.module.css';
import {
	vultureENV,
} from 'Vulture/ENV.js';

export const BaseUploader = (props) => {
	const buttonRef = useRef();

	const {
		handleImageUpload,
		deleteContent,
		openLibraryModal,
		label,
		imgSrc,
		s3Key,
		entityName,
		fileSizeLimit,
		fileSizeUnit,
		uploadedImgXResolution,
		uploadedImgYResolution,
		uploadedImgUnit,
		file,
		type,
	} = props;

	// Clicks the actual image upload button
	const clickImageUpload = () => {
		let imageUploadButton = buttonRef.current;
		imageUploadButton.click();
	};

	let accepted = '.jpg, .jpeg, .png';

	if (type === 'PDF') {
		accepted = '.pdf';
	} else if (type === 'Video') {
		accepted = '.mp4, .mov, .webm';
	}
	let fileFormat = `File format: ${accepted.toUpperCase()}`;

	let deleteButton = 'button';
	if (imgSrc.includes('ts-library') || s3Key === '') {
		deleteButton = 'button button-disabled no-click';
	}

	return (
		<div className="uploaderWrapper">
			<div className="label">
				<label>{label || ''}</label>
			</div>
			<div className="uploader">
				<div onClick={clickImageUpload} className="uploader-image">
					{type === 'PDF' && (s3Key ? <CustomIcon icon='file-pdf' color='#00baff' size='5x'></CustomIcon> : <CustomIcon icon="file" size='5x' />)}
					{type === 'Image' && (s3Key ? <img src={file.previewSrc} ></img> : <CustomIcon icon="image" />)}
					{type === 'Video' && (s3Key ? <CustomIcon icon='video' color='#00baff' size='5x'></CustomIcon> : <CustomIcon icon="video" size='5x' />)}
				</div>
				<div className="uploader-buttons">
					<button
						onClick={clickImageUpload}
						className="button"
					>
						<CustomIcon icon='cloud-upload-alt' />Upload File
					</button>
					<button
						onClick={(e) => {
							e.preventDefault();
							deleteContent(file);
						}}
						className={deleteButton}
					>
						<CustomIcon icon='trash' />Delete
					</button>
					<button
						onClick={(e) => {
							e.preventDefault();
							openLibraryModal();
						}}
						className="button"
					>
						<CustomIcon icon='book' />Library
					</button>
					<input
						id={`${entityName}Button`}
						onChange={(e) => {
							handleImageUpload(e);
						}}
						accept={accepted}
						ref={buttonRef}
						type='file'
					/>
				</div>
				<div className="uploader-restrictions">
					<span className="important">Save the form to submit your changes.</span><br />
					{fileFormat} (Max: {fileSizeLimit}{fileSizeUnit})<br />
					Max Resolution: {uploadedImgXResolution}{uploadedImgUnit} x {uploadedImgYResolution}{uploadedImgUnit}
				</div>
			</div>
		</div>
	);
};

const ImageUploaderComponent = (props) => {
	const {
		app,
		component: {
			settings,
		},
		label,
		value,
		attr,
		parentDirectory,
		bucketName,
	} = props;

	const {
		// source: imgSrc = '',
		file_size_limit: fileSizeLimit,
		file_size_unit: fileSizeUnit,
		uploaded_img_x_resolution: uploadedImgXResolution,
		uploaded_img_y_resolution: uploadedImgYResolution,
		uploaded_img_unit: uploadedImgUnit,
		entityName,
	} = settings;

	let {
		type,
	} = props;

	if (!type) {
		switch (label) {
		case 'ImageComponent':
			type = 'Image';
			break;
		case 'PdfComponent':
			type = 'PDF';
			break;
		case 'VideoComponent':
			type = 'Video';
			break;
		case undefined:
		case null:
			type = 'Unknown';
		default:
			type = 'Unknown';
		}
	}

	const imgSrc = value || '';

	const [ file, setFile, ] = useState({});
	const [ s3Key, setS3Key, ] = useState(imgSrc);

	useEffect(() => {
		let parsedSrc = imgSrc;
		// Get initial AWS Key from URL
		let split = imgSrc.split('.com/');
		if (split[1]) {
			parsedSrc = split[1];
			parsedSrc = decodeURI(parsedSrc);
		}
		setS3Key(parsedSrc);
		setFile({
			previewSrc: imgSrc,
		});
		app.getStockAndDynamicPhotos();
	}, []);

	const handleImageUpload = (e) => {
		e.preventDefault();
		if (e.target && e.target.files && e.target.files.length > 0) {
			let file = e.target.files[0];
			let fileSize = file.size;

			if (fileSizeLimit && unit) {
				filedSizeUnit = filedSizeUnit.toLowerCase();
				if (filedSizeUnit === 'kb') {
					fileSize = fileSize / 1024;
				}
				if (filedSizeUnit === 'mb') {
					fileSize = fileSize / 1024 / 1024;
				}
				if (filedSizeUnit === 'gb') {
					fileSize = fileSize / 1024 / 1024 / 1024;
				}

				if (fileSize > fileSizeLimit) {
					// TODO: Use modal instead of alert?
					alert(`File size: ${Math.floor(fileSize)} ${filedSizeUnit} is too large.\nMax file size is: ${fileSizeLimit} ${filedSizeUnit}.`);
					return;
				}
			}

			let fileType = file.type;
			let fileArray = fileType.split('/');
			let columnType = fileArray[0] === 'application' ? fileArray[1] : fileArray[0];

			let previewSrc = null;
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (e) => {
				previewSrc = e.target.result;
				setFile({
					file,
					fileName: file.name,
					fileType: columnType,
					previewSrc,
				});
			};

			reader.onerror = () => {
				alert(`Error occurred while loading ${columnType}`);
				return;
			};
			uploadContent(file);
		}
	};

	const uploadContent = (file) => {
		let fileExtension = file.type.split('/')[1];
		let hashedFilename = md5(`${new Date().getTime()}_${file.name}`);
		let uuid = app.getHashedActiveAccountName();
		const subDir = parentDirectory || settings.parentDirectory;
		let objKey = `${vultureENV}/${subDir}/${uuid}/${hashedFilename}.${fileExtension}`;
		app.uploadPhotosToBucket(file, objKey, bucketName);
		setFile({
			file,
			fileName:   file.name,
			fileType:   fileExtension,
			previewSrc: objKey,
		});
		let fullImgSrc = 'https://' + bucketName + '.s3.amazonaws.com/' + objKey;
		setValue(fullImgSrc, false);
	};

	const deleteContent = (file) => {
		app.deletePhotosFromBucket(file, s3Key);
		setS3Key('');
		setValue('', false);
	};

	const setValue = (val) => {
		setS3Key(val);
		setFile({
			previewSrc: val,
		});
		props.setValue(val);
		app.closeLibraryModal();
	};

	const openLibraryModal = () => {
		app.openModal({
			showModal:  true,
			modalSize:  0,
			library:    true,
			modalProps: {
				title:       'Library',
				customClass: 'test',
				type,
				jsx: (
					<LibraryModal
						setFile={setFile}
						setValue={setValue}
					/>
				),
			},
		});
	};

	return (
		<BaseUploader
			handleImageUpload={handleImageUpload}
			deleteContent={deleteContent}
			openLibraryModal={openLibraryModal}
			label={label || attr.label || ''}
			type={type}
			imgSrc={imgSrc}
			s3Key={s3Key}
			entityName={entityName}
			fileSizeLimit={fileSizeLimit}
			fileSizeUnit={fileSizeUnit}
			uploadedImgXResolution={uploadedImgXResolution}
			uploadedImgYResolution={uploadedImgYResolution}
			uploadedImgUnit={uploadedImgUnit}
			file={file}
		/>
	);
};

class LibraryModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: 'My Images',
		};
	}

	closeModal() {
		const {
			app,
		} = this.props;
		app.closeModal();
	}

	render() {
		const {
			setFile,
			app,
			setValue,
			type,
		} = this.props;

		let tabContent = <p>There are no photos</p>;
		if (app.state.photos) {
			tabContent = (
				<React.Fragment>
					{app.state.photos.map((obj, i) => {
						if (!obj.imgURL.endsWith('.pdf') && type === 'PDF') {
							return;
						}
						return (
							<div className={style.card} key={i}>
								<div className={style.cardContainer}>
									<div className={style.image} onClick={(e) => {
										setFile({
											previewSrc: obj.imgURL,
										});
										setValue(obj.imgURL);
									}}>
										{obj.imgElm}
									</div>
									<div className={style.dimensions}>{obj.size}</div>
								</div>
							</div>
						);
					})}
				</React.Fragment>
			);
			if (this.state.activeTab === 'Stock Images') {
				tabContent = (
					<React.Fragment>
						{(app.state.stockPhotos || []).map((obj, i) => {
							//Add pdf library support??
							if (!obj.imgURL.endsWith('.pdf') && type === 'PDF') {
								return;
							}
							return (
								<div className={style.card} key={i}>
									<div className={style.cardContainer}>
										<div className={style.image} onClick={(e) => {
											setFile({
												previewSrc: obj.imgURL,
											});
											setValue(obj.imgURL);
										}}>
											{obj.imgElm}
										</div>
										<div className={style.dimensions}>{obj.size}</div>
									</div>
								</div>
							);
						})}
					</React.Fragment>
				);
			}
		}

		return (
			<React.Fragment>
				<ul className={style.modalTabs}>
					<li className={this.state.activeTab === 'My Images' ? style.active : ''} onClick={(e) => {
						this.setState({
							activeTab: 'My Images',
						});
					}}>My Images</li>
					<li className={this.state.activeTab === 'Stock Images' ? style.active : ''} onClick={(e) => {
						this.setState({
							activeTab: 'Stock Images',
						});
					}}>Stock Images</li>
				</ul>
				<div className={style.modalGallery}>
					<p>Your collection of reusable images for logos and property info.</p>
					{tabContent}
				</div>
			</React.Fragment>
		);
	}
}

export default ImageUploaderComponent;
