import React from 'react';
import Select from 'react-select';

import style from './TemplateSkeleton.module.css';

import {
	fetchEntertainment,
} from 'Vulture/Entertainment';

import {
	dropdownStyles,
} from './systemRules';

export default class EntertainmentCategoryPicker extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		    options: [],
		};
	}

	componentDidMount() {
		const {
			activeEntityKey,
			flatData,
			settingKey,
		} = this.props;

		const stringVal = flatData[activeEntityKey]?.[settingKey]?.string;

		fetchEntertainment()
			.then((entertainment) => {
				this.setState({
					entertainment: {
						categories:          entertainment.categories,
						providers:           entertainment.providers,
						feeds:               entertainment.feeds,
						resolutions:         entertainment.resolutions,
						categoryResolutions: entertainment.category_resolutions,
					},
				}, () => {
					this.setOptions(stringVal === '');
				});
			});
	}

	componentDidUpdate(prevProps) {
		const {
			settingMap,
			activeEntityKey,
			flatData,
		} = this.props;

		const {
			flatData: oldFlatData,
		} = prevProps;

		const resolutionSettingUUID = settingMap['resolution']?.uuid;

		const selectedResolution = flatData[activeEntityKey]?.['setting.' + resolutionSettingUUID]?.string;
		const oldSelectedResolution = oldFlatData[activeEntityKey]?.['setting.' + resolutionSettingUUID]?.string;

		if (selectedResolution !== oldSelectedResolution) {
			this.setOptions(true);
			return;
		}
	}

	async setOptions(reset = false) {
		const {
			settingKey,
			settingMap,
			activeEntityKey,
			flatData,
			updateSettingValue,
		} = this.props;

		const {
			entertainment,
		} = this.state;

		const resolutionSettingUUID = settingMap['resolution']?.uuid;

		const selectedResolution = flatData[activeEntityKey]?.['setting.' + resolutionSettingUUID]?.string;

		const matchingResolutions = entertainment.resolutions.filter((resolution) => {
			return resolution.resolution === selectedResolution;
		});

		const availableCategoryResolutions = entertainment.categoryResolutions.filter((categoryResolution) => {
			return matchingResolutions.find((matchingResolution) => {
				return matchingResolution.id === categoryResolution.entertainment_provider_feed_resolution_id;
			});
		});

		const options = entertainment.categories
			.filter((category) => {
				return availableCategoryResolutions.find((categoryResolution) => {
					return categoryResolution.active && category.id === categoryResolution.entertainment_category_id;
				});
			})
			.map((category) => {
				return {
					value:  category.uuid,
					label:  category.display_name,
					active: true,
				};
			});

		options.sort((a, b) => {
			return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
		});

		this.setState({
			options,
		}, () => {
			if (reset) {
				updateSettingValue(settingKey, JSON.stringify(options), 'string');
			}
		});
	}

	render() {
		const {
			activeEntityKey,
			flatData,
			settingKey,
			updateSettingValue,
		} = this.props;

		const {
			options,
		} = this.state;

		const categoryMap = options.reduce((acc, option) => {
			acc[option.value] = option.label;
			return acc;
		}, {});

		const stringVal = flatData[activeEntityKey]?.[settingKey]?.string;
		const value = JSON.parse(stringVal || '[]');

		for (const selectedOption of value) {
			selectedOption.label = categoryMap[selectedOption.value];
		}

		return (
			<div className={style.settingContainer}>
				<div className={style.settingLabel}>
					Categories
				</div>
				<Select
					isMulti={true}
					allowSelectAll={true}
					closeMenuOnSelect={false}
					hideSelectedOptions={false}
					className={style.buttonsDropdown + ' ' + style.autoWidthSelect}
					styles={dropdownStyles}
					options={options}
					value={value
						.filter((selected) => {
							return selected.label && selected.label !== '';
						})
						.sort((a, b) => {
							return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
						})}
					onChange={(selected) => {
						updateSettingValue(settingKey, JSON.stringify(selected), 'string');
					}}
				/>
			</div>
		);
	}
}