import React, {
	useEffect,
} from 'react';

import CustomIcon from '../Common/CustomIcon/CustomIcon';

// React Table
import {
	useTable, useExpanded,
} from 'react-table';

import {
	buildTestData,
} from 'Vulture/TestData';

// Import styles
import {
	dlSystemDetailsPush,
} from 'Vulture/DataLayer';

import {
	getSession,
} from 'Vulture/Session';

const expanderCaret = 'expander-caret';

const Table = ({
	columns, data, props,
})  => {
	// Use the state and functions returned from useTable to build your UI
	let defaultExpandedRows = props.defaultExpanded;

	const [ controlledPageIndex, setControlledPage, ] = React.useState(0);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		toggleAllRowsExpanded,
		state: {
			expanded,
		},
	} = useTable(
		{
			columns,
			data,
			props,
			autoResetExpanded: false,
			initialState:      {
				expanded: defaultExpandedRows,
			},
			useControlledState: (state) => {
				return React.useMemo(() => {
					return {
						...state,
						pageIndex: controlledPageIndex,
					};
				}, [ state, ]);
			},
		},
		useExpanded
	);

	useEffect(() => {
		toggleAllRowsExpanded(defaultExpandedRows.length > 0);
	}, [ defaultExpandedRows, ]);

	const {
		openSystemsDetailModal = () => {},
		style,
		systemsTableRowOnclick = () => {},
	} = props;

	// Render the UI for your table
	return (
		<div className={style.table} {...getTableProps()}>
			<div className={style.tbody} {...getTableBodyProps()}>
				{rows.map((row, i) => {
					prepareRow(row);
					let rowDiv = '';
					const userType = getSession().pg;

					if (row.original.m1 === 'system_group') {
						rowDiv = <div data-test-id="systemsTableRow" className={`${style.systemGroup} ${style[`goupLevel${row.depth}`]} ${style.show} ${userType === 'ea' && style.systemWithoutHover}`} {...row.getToggleRowExpandedProps({
							title: undefined,
						})} onClick={(e) => {
							const className = typeof e.target.className === 'string' ? e.target.className : '';
							const nodeName = e.target.nodeName.toLowerCase();
							if (!className.includes(expanderCaret) && !(nodeName === 'svg' || nodeName === 'path')) {
								e.stopPropagation();
								systemsTableRowOnclick(row.original.id);
							}
							e.preventDefault();
						}}>
							{row.depth === 1 ? <div className={style.test}></div> : ''}
							<div key={`${i}`} className={style.tr} {...row.getRowProps()}>
								{row.cells.map((cell, j) => {
									return <div key={`${j}`} className={style.td} {...cell.getCellProps()}>{cell.render('Cell')}</div>;
								})}
							</div>
						</div>;
					} else {
						rowDiv = <div data-test-id="systemsTableRow" className={`${style.system} ${style.show} ${userType === 'ea' && style.systemWithoutHover}`} {...row.getToggleRowExpandedProps({
							title: 'Edit system',
						})} onClick={(e) => {
							e.preventDefault();
							if (typeof e.target.className === 'string' && e.target.className.indexOf('System') !== -1) {
								if (userType === 'iu' || userType === 'ia') {
									openSystemsDetailModal(row.original.id, row.original.macAddress);
								}
							}

						}}>
							<div key={`${i}`} className={style.tr} {...row.getRowProps()}>
								{row.cells.map((cell, j) => {
									return <div key={`${j}`} className={style.td} {...cell.getCellProps()}>{cell.render('Cell')}</div>;
								})}
							</div>
						</div>;
					}
					let firstDiv0 = 'empty';
					let firstDiv1 = 'empty';
					let firstDiv2 = 'empty';
					let firstChild = 'empty';
					let lastDiv0 = 'empty';
					let lastDiv1 = 'empty';
					let lastDiv2 = 'empty';
					let tier1 = 'empty';
					let tier2 = 'empty';
					let tier1Spacer = '';
					let tier2Spacer = '';

					// ====== bottom lines ======
					if (i === rows.length - 1) { // last listing only
						lastDiv0 = 'lastDiv0a';
						if (row.depth === 2) {
							lastDiv1 = 'lastDiv1a';
							tier1Spacer = <div className={style.tier1Spacer}></div>; // needed for last row
						}
						if (row.depth === 3) {
							lastDiv1 = 'lastDiv1a';
							lastDiv2 = 'lastDiv2a';
							tier1Spacer = <div className={style.tier1Spacer}></div>; // needed for last row
							tier2Spacer = <div className={style.tier2Spacer}></div>;
						}
					} else if (i + 1 < rows.length && (parseInt(rows[i].id) !== parseInt(rows[i + 1].id))) { // last group lines
						lastDiv0 = 'lastDiv0b';
						if (row.depth === 2) {
							lastDiv1 = 'lastDiv1b';
							tier1Spacer = <div className={style.tier1Spacer}></div>; // needed for last row in a group
						}
						if (row.depth === 3) {
							lastDiv1 = 'lastDiv1b';
							lastDiv2 = 'lastDiv2b';
							tier1Spacer = <div className={style.tier1Spacer}></div>; // needed for last row in a group
							tier2Spacer = <div className={style.tier2Spacer}></div>;
						}
					}

					// ===== closed system groups - bottom lines =====
					if (!row.isExpanded && row.original.m1 === 'system_group') {
						if (row.depth === 0) {
							lastDiv0 = 'lastDiv0';
						}
						if (row.depth === 1) {
							lastDiv1 = 'lastDiv1';
						}
						if (row.depth === 2) {
							lastDiv2 = 'lastDiv2';
						}
					}

					// ===== open system groups - debug only =====
					let visible_children = 0;
					if (row.original.m1 === 'system_group' && row.isExpanded) {
						visible_children = row.subRows.length;
					}

					// ===== last row in a group =====
					if (i + 1 < rows.length && (parseInt(rows[i].id) === parseInt(rows[i + 1].id))) {
						let rowId = row.id.split('.');
						let rowIdAfter = rows[i + 1].id.split('.');

						if (rowId.length > rowIdAfter.length) {
							if (row.depth === 2) {
								lastDiv1 = 'lastDiv1c';
								tier1Spacer = <div className={style.tier1Spacer}></div>; // needed when open and not last row in a group
							}
							if (row.depth === 3) {
								lastDiv2 = 'lastDiv2c';
								tier2Spacer = <div className={style.tier2Spacer}></div>; // needed when open and not last row in a group
							}
						}
					}

					// ===== bottom spacer for closed groups =====
					if (row.depth === 1 && row.original.m1 === 'system_group' && !row.isExpanded) {
						tier1Spacer = <div className={style.tier1Spacer}></div>; // needed for groups that aren't open
					}
					if (row.depth === 2 && row.original.m1 === 'system_group' && !row.isExpanded) {
						tier2Spacer = <div className={style.tier2Spacer}></div>;
					}

					// ===== top lines =====
					if (i === 0) {
						firstDiv0 = 'firstDiv0';
					} else if ((i - 1) >= 0) {
						if (parseInt(rows[i].id) !== parseInt(rows[i - 1].id) || row.original.m1 === 'system_group') {
							firstDiv0 = 'firstDiv0';
							firstDiv1 = 'firstDiv1';
							firstDiv2 = 'firstDiv2';
							firstChild = 'firstChild';
						}
					}

					// ===== sides =====
					if ((row.original.m1 === 'system_group' && row.depth === 1) || row.depth === 2) {
						tier1 = 'tier1';
					}
					if ((row.original.m1 === 'system_group' && row.depth === 2) || row.depth === 3) {
						tier1 = 'tier1';
						tier2 = 'tier2';
					}

					let rowDivWapped = '';
					switch (row.depth) {
					case 0:
						rowDivWapped = <div key={i} className={`${style.tier0} ${style[firstDiv0]} ${style[lastDiv0]}`}
							data-testing-info={buildTestData(`tableItem--${row.original.id}`)}>
							<div className={style.data}>{row.depth}, {row.id}, {visible_children}</div>
							{rowDiv}
						</div>;
						break;
					case 1:
						rowDivWapped = <div key={i} className={`${style.tier0} ${style[lastDiv0]} ${style[firstChild]}`}>
							<div className={style.data}>{row.depth}, {row.id}, {visible_children}</div>
							<div className={`${style[tier1]} ${style[firstDiv1]} ${style[lastDiv1]}`}>
								{rowDiv}
							</div>
							{tier1Spacer}
						</div>;
						break;
					case 2:
						rowDivWapped = <div key={i} className={`${style.tier0} ${style[lastDiv0]}`}>
							<div className={style.data}>{row.depth}, {row.id}, {visible_children}</div>
							<div className={`${style[tier1]} ${style[lastDiv1]} ${style[firstChild]}`}>
								<div className={`${style[tier2]} ${style[firstDiv2]} ${style[lastDiv2]}`}>
									{rowDiv}
								</div>
								{tier2Spacer}
							</div>
							{tier1Spacer}
						</div>;
						break;
					case 3:
						rowDivWapped = <div key={i} className={`${style.tier0} ${style[lastDiv0]}`}>
							<div className={style.data}>{row.depth}, {row.id}, {visible_children}</div>
							<div className={`${style[tier1]} ${style[lastDiv1]}`}>
								<div className={`${style[tier2]} ${style[lastDiv2]}`}>
									{rowDiv}
								</div>
								{tier2Spacer}
							</div>
							{tier1Spacer}
						</div>;
						break;
					default:
						rowDivWapped = rowDiv;
						break;
					}
					return rowDivWapped;

				})}
			</div>
		</div>
	);
};

const SystemsTable = (props) => {
	const {
		style,
	} = props;

	const systemsColumns = [
		{
			Header:   'System Name',
			accessor: 'name',
		},
		{
			Header:   'Status',
			accessor: 'status',
		},
		{
			Header:   'Resolution',
			accessor: 'resolution',
		},
		{
			Header:   'Orientation',
			accessor: 'orientationValue',
		},
		{
			Header:   'MAC Address',
			accessor: 'macAddress',
		},
		{
			Header:   'Directory Number',
			accessor: 'directoryNumber',
		},
	];

	const columns = React.useMemo(
		() => {
			return [
				{
					id:     'expander',
					Header: ({
						getToggleAllRowsExpandedProps, isAllRowsExpanded,
					}) => {
						return (
							<span className={style.expander} {...getToggleAllRowsExpandedProps()}>
								{isAllRowsExpanded ? <CustomIcon icon='caret-down' color='#00BAFF'/> : <CustomIcon icon='caret-right' color='#00BAFF'/>}
							</span>
						);
					},
					Cell: ({
						row,
					}) => {
						let testID = `systemTableExpander_${row.original.searchValues.name}`;
						return row.canExpand ? (
							<div
								data-test-id={testID}
								className={`${expanderCaret} ${style.expander}`}
								{...row.getToggleRowExpandedProps({
									title: 'Expand group',
									style: { },
								})}
								onMouseDown={(e) => {
									/* toggleRowExpanded(row.id, true); */
									dlSystemDetailsPush({
										expansion: row.isExpanded ? 'Collapsed' : 'Expanded',
									});
								}}
							>
								{row.isExpanded ? <CustomIcon icon='caret-down' color='#00BAFF' /> : <CustomIcon icon='caret-right' color='#00BAFF'/>}
							</div>
						) : (
							<div
								className={style.noExpander}
								{...row.getToggleRowExpandedProps({
									title: undefined,
									style: { },
								})}
							></div>
						);
					},
				},
				{
					Header:  'SystemsHeader',
					columns: systemsColumns,
				},
			];
		},
		[
			{
				Header:  'SystemsHeader',
				columns: systemsColumns,
			},
		]
	);

	const data = React.useMemo(() => {
		return props.array;
	}, [ props.array, ]);

	const sortFunction = (a, b) => {
		const nameA = a.searchValues.name.toLowerCase();
		const nameB = b.searchValues.name.toLowerCase();

		if (nameA > nameB) {
			return 1;
		}

		return -1;
	};

	const sortSubrows = (item) => {
		item.subRows.sort(sortFunction);
		item.subRows.forEach(sortSubrows);
	};

	data.forEach(sortSubrows);	

	return (
		<Table
			columns={columns}
			data={data}
			props={props}
			defaultExpanded={props.defaultExpanded}
		/>
	);
};

export default SystemsTable;