import kali from 'kali';

import {
	getSession,
} from 'Vulture/Session.js';

import {
	broncoURL,
} from './ENV';

export const fetchTemplateData = async (form) => {
	const url = `${broncoURL}/template_category`;
	const sessionKey = getSession().session;

	let retVal;

	await new kali({
		headers: {
			'content_type':   'application/json',
			'X-Auth-Session': sessionKey,
		},
		method: 'POST',
	}).post(url, {
		success: (_kali, res, data) => {
			if (data) {
				retVal = data;
			}
		},

		failure: (_kali, res, err) => {
			console.error(err);

			if (res.status === 503) {
				form.props.on503(true);
			}
		},
	});

	return retVal;
};

export const fetchSettingsForTemplateTheme = async (form) => {
	const url = `${broncoURL}/r/list/setting`;
	const sessionKey = getSession().session;

	let retVal;

	await new kali({
		body: {
			'skip_auth_filter': true,
			'filters':          [
				[
					{
						'key':       'name',
						'condition': 'IN',
						'val':       'horizontal',
						'operator':  'OR',
					},
					{
						'key':       'name',
						'condition': 'IN',
						'val':       'vertical',
						'operator':  'OR',
					},
					{
						'key':       'name',
						'condition': 'IN',
						'val':       'horizontal_4k',
						'operator':  'OR',
					},
					{
						'key':       'name',
						'condition': 'IN',
						'val':       'vertical_4k',
						'operator':  'OR',
					},
					{
						'key':       'name',
						'condition': 'IN',
						'val':       'is_custom',
						'operator':  'OR',
					},
					{
						'key':       'name',
						'condition': 'IN',
						'val':       'notes',
						'operator':  'AND',
					},
				],
			],
		},
		headers: {
			'content_type':   'application/json',
			'X-Auth-Session': sessionKey,
		},
		method: 'POST',
	}).post(url, {
		success: (_kali, res, contents) => {
			if (contents?.data) {
				retVal = contents.data;
			}
		},

		failure: (_kali, res, err) => {
			console.error(err);

			if (res.status === 503) {
				form.props.on503(true);
			}
		},
	});

	return retVal;
};

export const submitPendingUpdates = async (pendingUpdates, form) => {
	const url = `${broncoURL}/w/edit`;
	const sessionKey = getSession().session;

	let body = {
		data:        pendingUpdates,
		data_format: 'DD',
	};

	let retVal = false;

	await new kali({
		body,
		headers: {
			'content_type':   'application/json',
			'X-Auth-Session': sessionKey,
		},
		method: 'POST',
	}).post(url, {
		success: (_kali, res, contents) => {
			if (contents && contents.data) {
				retVal = true;
			}
		},

		failure: (_kali, res, err) => {
			console.error(err);
			alert(`Error Saving: ${err}`);

			if (res.status === 503) {
				form.props.on503(true);
			}
		},
	});

	return retVal;
};

export const handleSubmit = async (data, form) => {
	const url = `${broncoURL}/w/edit`;
	const sessionKey = getSession().session;

	let body = {
		data,
		data_format: 'DD',
	};

	await new kali({
		body,
		headers: {
			'content_type':   'application/json',
			'X-Auth-Session': sessionKey,
		},
		method: 'POST',
	}).post(url, {
		success: (_kali, res, contents) => {},

		failure: (_kali, res, err) => {
			console.error(err);
			alert(`Error Saving: ${err}`);

			if (res.status === 503) {
				form.props.on503(true);
			}
		},
	});
};

export const handleDelete = async (body, form) => {
	const url = `${broncoURL}/delete/template_entity`;
	const sessionKey = getSession().session;

	let retVal;

	await new kali({
		body,
		headers: {
			'content_type':   'application/json',
			'X-Auth-Session': sessionKey,
		},
		method: 'POST',
	}).post(url, {
		success: (_kali, res, contents) => {
			// kali doesn't display messages from server errors
			retVal = contents;
		},

		failure: (_kali, res, err) => {
			console.error(err);
			alert(`Error Deleting: ${err}`);

			if (res.status === 503) {
				form.props.on503(true);
			}
		},
	});

	return retVal;
};