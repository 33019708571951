import React, {
	useCallback,
	useState,
	useEffect,
	useMemo,
} from 'react';
import {
	v4 as uuidv4,
} from 'uuid';

import ListingsModal from './ListingsModal';
import ListingsUploadModal from './ListingsUploadModal';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import VultureTable from './VultureTable';

import {
	dlListingsExportAll,
	dlListingsImport,
	dlViewListingsSettings,
	dlListingsTemplate,
} from 'Vulture/DataLayer';

// Import styles
import style from './ListingsTable.module.css';
import {
	getSession,
} from 'Vulture/Session';

function filterByProp(rows, id, filterValue) {
	return rows.filter((row) => {
		if (row.original.vals[id]) {
			const rowValue = row.original.vals[id].toLowerCase();
			return rowValue.includes(filterValue.toLowerCase());
		}
	});
}

let canUserImport = false;

const checkIcon = <div className={style.iconWrapper}><CustomIcon icon="check"/></div>;

const disabledSquare = (
	<div className={style.disabledSquare}>
		<div className={style.checkboxIcon}>
			<CustomIcon icon="check"/>
		</div>
	</div>
);

const activeCheckbox = (
	<div className={style.activeCheckbox}>
		<div className={style.checkboxIcon}>
			<CustomIcon icon="check"/>
		</div>
	</div>
);

const rowsPerPage = 50;

const ListingsTable = (props) => {
	const {
		app,
		accountUUID,
		systemGroupUUID,
		buildingsDataArray,
		listingFD,
		array,
		columnsToHide,
		tableSettings,
		activeListingConfig,
		listingGroups,
		listingCollectionHasListingGroupRelation,
		getParentListingData,
		listingToSystemsMap,
		listingConfigMap,
		toggleSelectAllRows,
		toggleSelectSingleRow,
		listingConfigUUID,
		listingConfigToListingsMap,
		parentsPrimaryColumnData,
		setGroupValue,
		getChildRelations,
		findRootListings,
		getParentComponentName,
		uniqueSettingsListForAdd,
		pendingChanges,
		listingToListingCollectionsMap,
		updateListing,
		toggleGroup,
		listingWidthCols,
		openView,
		deleteSelectedListings,
		listingsContentGroupColWidth,
		exportCSV,
		templateCSV,
		listingView,
		allRowsSelected,
		listingConfigHasScheduling,
		listingConfigIsIntegration,
		fetchListingDataByListingConfig,
		selectedListings,
		listingConfigsWithSystems,
	} = props;

	const [ page, setPage, ] = useState(1);
	const [ pageNavigationBlocked, setPageNavigationBlocked, ] = useState(false);
	const [ filteredRowsCount, setFilteredRowsCount, ] = useState(array.length);

	useEffect(() => {
		const pg = getSession().pg;

		if (pg === 'iu' || pg === 'ia') {
			canUserImport = true;
		}
	}, []);

	useEffect(() => {
		if (array && array.length > 0) {
		  setFilteredRowsCount(array.length);
		}
	  }, [ array, ]);

	useEffect(() => {
		if (filteredRowsCount >= rowsPerPage && pageNavigationBlocked === true) {
			setPageNavigationBlocked(false);
		} else if (filteredRowsCount < rowsPerPage && pageNavigationBlocked === false) {
			setPageNavigationBlocked(true);
		}
	}, [ filteredRowsCount, pageNavigationBlocked, page, ]);

	const onFilter = useCallback((_, preFilteredRows) => {
		setFilteredRowsCount(preFilteredRows.length);
	}, []);

	const previousPageAvailable = (page > 1) && !pageNavigationBlocked;
	const nextPageAvailable = (page * rowsPerPage < filteredRowsCount) && !pageNavigationBlocked;

	const listingStaticColumns = (() => {
		const retval = [
			{
				id:       'checkbox',
				Header:   checkIcon,
				accessor: (d) => {
					return d.isChecked ? activeCheckbox : disabledSquare;
				},
				disableFilters: true,
			},
		];

		if (listingConfigHasScheduling) {
			retval.push({
				id:       'scheduling',
				Header:   'Scheduling',
				accessor: (d) => {
					return d.schedule ? activeCheckbox : disabledSquare;
				},
				disableFilters: true,
			});
		}

		let columnKeys = Object.keys(tableSettings);
		columnKeys.forEach((columnKey, i) => {
			let header = tableSettings[columnKey];

			if (tableSettings[columnKey].includes('\n')) {
				header = tableSettings[columnKey].split('\n')[0];
			}

			if (i === 0) {
				retval.push(
					{
						id:       `${columnKey}`,
						Header:   header,
						accessor: (d) => {
							return d.vals[columnKey];
						},
						Cell: function Cell(row) {
							return (
								<div className={style.name} key={`${i}`}>
									{row.row.original.vals[columnKey]}
									{row.row.original.tags}
								</div>
							);
						},
						filter: filterByProp,
					},
					{
						id:       'columnToHide',
						Header:   'Hidden column for filtering',
						accessor: columnKey,
					}
				);
			}
			if (i > 0) {
				retval.push(
					{
						id:       `${columnKey}`,
						Header:   header,
						accessor: (d) => {
							return d.vals[columnKey];
						},
					}
				);
			}
		});

		if (activeListingConfig?.root) {
			listingGroups.forEach((listingCollection, i) => {
				let listingCollectionName = listingCollection.name || listingCollection.uuid;

				if (listingCollection.grouped) {
					let elms = [];
					for (let systemM1Key of listingCollection.systemM1Keys) {
						if (listingFD[systemM1Key]) {
							elms.push(<div key={systemM1Key}>{listingFD[systemM1Key].display_name}</div>);
						}
					}

					let rolloverConfigData =
					<>
						<div className={style.rolloverHeader}>
							<CustomIcon icon="info-circle"/> {listingCollection.name}
						</div>
						<div className={style.rolloverContent}>
							<p>This Content Group will display selected<br/> items on the following systems:</p>
							<br/>
							{elms}
						</div>
					</>;

					listingCollectionName = (
						<div id={`${listingCollection.uuid}_${i}`} className={style.configInfo}>
							<div className={style.configInfoHeader}>
								<div className={style.nameContainer}>
									{listingCollection.name}
								</div>
								<div className={style.iconContainer}>
									<CustomIcon icon="info-circle"/>
								</div>
							</div>
							<div className={style.rolloverBox}>{rolloverConfigData}</div>
						</div>
					);
				}

				const SelectAllCheckboxFilter = ({
					column: {
						id,
						preFilteredRows,
					},
				}) => {
					const checkboxRef = React.useRef();
					const rowCount = preFilteredRows.length;
					let checkedRowCount = preFilteredRows.reduce((count, row) => {
						return count + (listingCollectionHasListingGroupRelation(listingCollection, row.original) ? 1 : 0);
					}, 0);

					const [ checked, setChecked, ] = useState(checkedRowCount === rowCount);

					const onChange = (e) => {
						let targetChecked = e.target.checked;
						let toggleRows = [];
						let groupID = id.split('.')[1];

						preFilteredRows.forEach((row) => {
							let hasGreenCheck = listingCollectionHasListingGroupRelation(listingCollection, row.original);
							if ((targetChecked && !hasGreenCheck) || (!targetChecked && hasGreenCheck)) {
								toggleRows.push(row.index);
							}
						});

						if (toggleRows.length > 0) {
							let group = listingGroups.find((listingGroup) => {
								return listingGroup.uuid === groupID;
							});
							toggleGroup(group, ...toggleRows);
						}

						setChecked(targetChecked);
					};

					React.useEffect(() => {
					  if (checkboxRef.current) {
							checkboxRef.current.indeterminate = checkedRowCount > 0 && checkedRowCount < rowCount;
					  }
					}, [ checkedRowCount, rowCount, ]);

					return (
					  <input
							type="checkbox"
							ref={checkboxRef}
							checked={checked}
							onChange={onChange}
							onClick={(e) => {
								return e.stopPropagation();
							}}
							title={`Select ${(checked) ? 'None' : 'All'}`}
					  />
					);
				};

				retval.push(
					{
						id:       `groups.${listingCollection.uuid}`,
						Header:   listingCollectionName,
						Filter:   SelectAllCheckboxFilter,
						accessor: (d) => {
							return listingCollectionHasListingGroupRelation(listingCollection, d) ? activeCheckbox : disabledSquare;
						},
						sortType: (a, b) => {
							return listingCollectionHasListingGroupRelation(listingCollection, b.original) - listingCollectionHasListingGroupRelation(listingCollection, a.original);
						},
						// disableFilters: true,
					}
				);
			});
		} else if (activeListingConfig && !activeListingConfig.root) {
			let parentUUID = activeListingConfig.parentUUID;
			retval.push(
				{
					id:       `parents.${parentUUID}`,
					Header:   listingFD[`listing_config.${parentUUID}`].display_name,
					accessor: (data) => {
						return getParentListingData(data);
					},
				}
			);
		}

		return retval;
	})();

	const columns = useMemo(() => {
		return [
			{
				Header:  'ListingsTable',
				columns: listingStaticColumns,
			},
		];
	}, [ listingStaticColumns, ]);

	const data = useMemo(() => {
		return array;
	}, [ array, ]);

	let validConfig = false;
	for (const m1Key of (Object.keys(listingConfigMap[listingConfigUUID] || {}))) {
		if (m1Key.startsWith('listing_column')) {
			validConfig = true;
		}
	}

	let disabledUnlessChecked = `button ${style.button} button-disabled`;
	array.map((obj) => {
		if (obj.isChecked) {
			disabledUnlessChecked = 'button';
		}
	});

	let totalWidth = 0;

	// write as for of
	for (let [ listingColumnUUID, ] of Object.entries(listingFD)) {
		totalWidth += listingFD[`listing_config.${listingConfigUUID}`]?.[`listing_column.${listingColumnUUID}`]?.width || 0;
	}

	let parentsWidth = 100 - totalWidth;

	const handleDelete = useCallback(() => {
		let count = 0;
		let listingsHaveChildren = false;
		let nonExclusiveSystemPresent = false;
		let numberOfChildren = 0;

		const nonExclusiveSystemUUIDs = listingGroups
			.filter((listingGroup) => {
				return listingGroup.nonExclusive;
			})
			.reduce((_nonExclusiveSystemUUIDs, listingGroup) => {
				return [ ..._nonExclusiveSystemUUIDs, ...listingGroup.systemM1Keys, ];
			}, []);

		array.map((obj) => {
			if (obj.isChecked) {
				count++;

				for (const systemUUID of obj.systems) {
					if (nonExclusiveSystemUUIDs.includes(systemUUID)) {
						nonExclusiveSystemPresent = true;
					}
				}

				const childUUIDs = [];

				for (let [ m1Key, m1Data, ] of Object.entries(listingFD)) {
					if (m1Key === `listing.${obj.uuid}`) {
						for (let m2Key of Object.keys(m1Data)) {
							const sections = m2Key.split('.').length;
							const entityKey = `${m1Key}.${m2Key}`;
							if (m2Key.startsWith('listing.') && sections === 2) {
								childUUIDs.push(entityKey);
							}
						}
					}
				}

				numberOfChildren = childUUIDs.length;
				if (childUUIDs.length > 0) {
					listingsHaveChildren = true;
				}
			}
		});
		if (count === 0) {
			return;
		}

		if (nonExclusiveSystemPresent) {
			listingView.setState({
				confirmModal:        true,
				confirmModalContent: (
					<>
						<p>Cannot delete listings that are assigned to systems outside this group.</p>
						<div className="confirm-modal-buttons">
							<button className="button" onClick={(e) => {
								e.preventDefault();
								listingView.setState({
									confirmModal:        false,
									confirmModalContent: '',
								});
							}}>Okay</button>
						</div>
					</>
				),
			});
			return;
		} else if (count > 1 && listingsHaveChildren) {
			listingView.setState({
				confirmModal:        true,
				confirmModalContent: (
					<>
						<p>Cannot delete listings with children.</p>
						<div className="confirm-modal-buttons">
							<button className="button" onClick={(e) => {
								e.preventDefault();
								listingView.setState({
									confirmModal:        false,
									confirmModalContent: '',
								});
							}}>Okay</button>
						</div>
					</>
				),
			});
			return;
		}

		if (listingsHaveChildren) {
			let message = `This listing currently has ${numberOfChildren} record(s) associated to it. Please delete the associated record(s) first.`;
			listingView.setState({
				confirmModal:        true,
				confirmModalContent: (
					<>
						<p>{message}</p>
						<div className="confirm-modal-buttons">
							<button className="button" onClick={(e) => {
								e.preventDefault();
								listingView.setState({
									confirmModal:        false,
									confirmModalContent: '',
								});
							}}>Okay</button>
						</div>
					</>
				),
			});
			return;
		}
		listingView.setState({
			confirmModal:        true,
			confirmModalContent: (
				<>
					<p>{`Delete ${count} listing${count > 1 ? 's' : ''}?`}</p>
					<div className="confirm-modal-buttons">
						<button className="button" onClick={(e) => {
							e.preventDefault();
							deleteSelectedListings();
							listingView.setState({
								confirmModal:        false,
								confirmModalContent: '',
							});
						}}>Yes</button>
						<button className="button" onClick={(e) => {
							e.preventDefault();
							listingView.setState({
								confirmModal:        false,
								confirmModalContent: '',
							});
						}}>No</button>
					</div>
				</>
			),
		});
	}, [ array, deleteSelectedListings, listingFD, listingGroups, listingView, ]);

	const secondaryNavButtons = useMemo(() => {
		if (listingConfigIsIntegration) {
			const retVal = [
				{
					onClick: (e) => {
						e.preventDefault();
						dlViewListingsSettings();
						openView();
					},
					className: 'button',
					icon:      'eye',
					text:      'View',
				},
				{
					onClick: (e) => {
						e.preventDefault();
						handleDelete();
					},
					className: disabledUnlessChecked,
					icon:      'trash',
					text:      'Delete',
				},
				{
					onClick: (e) => {
						e.preventDefault();
						dlListingsExportAll();
						exportCSV();
					},
					className: 'button',
					icon:      'file-download',
					text:      'Export' + (selectedListings.length > 0 ? ' Selected' : ' All'),
				},
				{
					className: 'button' + (previousPageAvailable ? '' : ' button-disabled'),
					onClick:   () => {
						if (previousPageAvailable) {
							setPage(page - 1);
						}
					},
					text: '< Previous',
				},
				{
					className: 'button' + (nextPageAvailable ? '' : ' button-disabled'),
					onClick:   () => {
						if (nextPageAvailable) {
							setPage(page + 1);
						}
					},
					text: 'Next >',
				},
			];

			return retVal;
		}

		let retVal = [
			{
				onClick: (e) => {
					e.preventDefault();
					dlViewListingsSettings();
					openView();
				},
				className: 'button',
				icon:      'eye',
				text:      'View',
			},
			{
				onClick: (e) => {
					e.preventDefault();
					handleDelete();
				},

				className: disabledUnlessChecked,
				icon:      'trash',
				text:      'Delete',
			},
			{
				onClick: (e) => {
					e.preventDefault();

					const newListingUUID = `_${uuidv4()}`;

					app.openModal({
						modalSize:  2,
						showModal:  true,
						modalProps: {
							title:          'Add Listings',
							customClass:    'add-listings-modal',
							pendingChanges: false,
							jsx:            (
								<ListingsModal
									isEdit={false}
									listingFD={listingFD}
									pendingChanges={pendingChanges}
									listing={{
										uuid:            newListingUUID,
										parent_listings: [],
									}}
									listingToListingCollectionsMap={listingToListingCollectionsMap}
									listingToSystemsMap={listingToSystemsMap}
									listingConfigMap={listingConfigMap}
									findRootListings={findRootListings}
									updateListing={updateListing}
									accountUUID={accountUUID}
									buildingsDataArray={buildingsDataArray}

									listingConfigHasScheduling={listingConfigHasScheduling}
									activeListingConfig={activeListingConfig}
									listingGroups={listingGroups}
									setGroupValue={setGroupValue}
									getChildRelations={getChildRelations}
									getParentComponentName={getParentComponentName}
									uniqueSettingsListForAdd={uniqueSettingsListForAdd}
									listingConfigUUID={listingConfigUUID}
									parentsPrimaryColumnData={parentsPrimaryColumnData}
									listingConfigToListingsMap={listingConfigToListingsMap}
									fetchListingDataByListingConfig={fetchListingDataByListingConfig}
								/>
							),
						},
					});
				},
				className: 'button',
				icon:      'plus',
				text:      'Add New',
			},
			{
				onClick: (e) => {
					e.preventDefault();
					if (!canUserImport) {
						return;
					}
					dlListingsImport();
					app.openModal({
						modalSize:  2,
						showModal:  true,
						modalProps: {
							title:          'Import Listings',
							customClass:    'test',
							pendingChanges: false,
							jsx:            (
								<ListingsUploadModal
									accountUUID={accountUUID}
									listingConfigUUID={listingConfigUUID}
									tableSettings={tableSettings}
									listingConfigMap={listingConfigMap}
									fetchListingDataByListingConfig={fetchListingDataByListingConfig}
								/>
							),
						},
					});
				},
				className: 'button'  + (canUserImport ? '' : ' button-disabled'),
				icon:      'cloud-upload-alt',
				text:      'Import',
			},
			{
				onClick: (e) => {
					e.preventDefault();
					dlListingsExportAll();
					exportCSV();
				},
				className: 'button',
				icon:      'file-download',
				text:      'Export' + (selectedListings.length > 0 ? ' Selected' : ' All'),
			},
			{
				onClick: (e) => {
					e.preventDefault();
					dlListingsTemplate();
					templateCSV();
				},
				className: 'button',
				icon:      'file-download',
				text:      'Get Template',
			},
			{
				className: 'button' + (previousPageAvailable ? '' : ' button-disabled'),
				onClick:   () => {
					if (previousPageAvailable) {
						setPage(page - 1);
					}
				},
				text: '< Previous',
			},
			{
				className: 'button' + (nextPageAvailable ? '' : ' button-disabled'),
				onClick:   () => {
					if (nextPageAvailable) {
						setPage(page + 1);
					}
				},
				text: 'Next >',
			},
		];

		return retVal;
	}, [ accountUUID, activeListingConfig, app, buildingsDataArray, disabledUnlessChecked, exportCSV, fetchListingDataByListingConfig, findRootListings, getChildRelations, getParentComponentName, handleDelete, listingConfigHasScheduling, listingConfigIsIntegration, listingConfigMap, listingConfigToListingsMap, listingConfigUUID, listingFD, listingGroups, listingToListingCollectionsMap, listingToSystemsMap, selectedListings, nextPageAvailable, openView, page, parentsPrimaryColumnData, pendingChanges, previousPageAvailable, setGroupValue, tableSettings, templateCSV, uniqueSettingsListForAdd, updateListing, ]);

	const tertiaryNavButtons = useMemo(() => {
		return [
			{
				className: 'button' + (previousPageAvailable ? '' : ' button-disabled'),
				onClick:   () => {
					if (previousPageAvailable) {
						setPage(page - 1);
					}
				},
				text: '< Previous',
			},
			{
				className: 'button' + (nextPageAvailable ? '' : ' button-disabled'),
				onClick:   () => {
					if (nextPageAvailable) {
						setPage(page + 1);
					}
				},
				text: 'Next >',
			},
		];
	}, [ nextPageAvailable, page, previousPageAvailable, ]);

	const theadColStyles = useCallback((column) => {
		let colStyles = {};
		if (listingWidthCols[column.id]) {
			let headerInfo = listingWidthCols[column.id];
			if (activeListingConfig.root) {
				colStyles = {
					width:    `${headerInfo.width}vw`,
					left:     `${headerInfo.left}vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `${headerInfo.width}vw`,
				};
			}
		} else if (column.id === 'checkbox') {
			if (activeListingConfig.root) {
				colStyles = {
					width:    `5vw`,
					left:     `0vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `5vw`,
				};
			}
		} else if (column.id === 'scheduling') {
			if (props.activeListingConfig.root) {
				colStyles = {
					width:    `5vw`,
					left:     `5vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `5vw`,
				};
			}
		} else if (column.id.startsWith('groups.')) {
			colStyles = {
				width: `${listingsContentGroupColWidth}vw`,
			};
		} else if (column.id.startsWith('parents.')) {
			colStyles = {
				width: `${parentsWidth}vw`,
			};
		}

		return colStyles;
	}, [ activeListingConfig.root, listingWidthCols, listingsContentGroupColWidth, parentsWidth, props.activeListingConfig.root, ]);

	const filterRowColStyles = useCallback((column) => {
		let colStyles = {};
		if (listingWidthCols[column.id]) {
			let headerInfo = listingWidthCols[column.id];
			if (activeListingConfig.root) {
				colStyles = {
					width:    `${headerInfo.width}vw`,
					left:     `${headerInfo.left}vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `${headerInfo.width}vw`,
				};
			}
		} else if (column.id === 'checkbox') {
			if (activeListingConfig.root) {
				colStyles = {
					width:    `5vw`,
					left:     `0vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `5vw`,
				};
			}
		}  else if (column.id === 'scheduling') {
			if (props.activeListingConfig.root) {
				colStyles = {
					width:    `5vw`,
					left:     `5vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `5vw`,
				};
			}
		} else if (column.id.startsWith('groups.')) {
			colStyles = {
				width: `${listingsContentGroupColWidth}vw`,
			};
		} else if (column.id.startsWith('parents.')) {
			colStyles = {
				width: `${parentsWidth}vw`,
			};
		}

		return colStyles;
	}, [ activeListingConfig.root, listingWidthCols, listingsContentGroupColWidth, parentsWidth, props.activeListingConfig.root, ]);

	const tbodyColStyles = useCallback((cell) => {
		let colStyles = {};
		if (listingWidthCols[cell.column.id]) {
			let cellInfo = listingWidthCols[cell.column.id];
			if (activeListingConfig.root) {
				colStyles = {
					width:    `${cellInfo.width}vw`,
					left:     `${cellInfo.left}vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `${cellInfo.width}vw`,
				};
			}
		} else if (cell.column.id === 'checkbox') {
			if (activeListingConfig.root) {
				colStyles = {
					width:    `5vw`,
					left:     `0vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `5vw`,
				};
			}
		} else if (cell.column.id === 'scheduling') {
			if (props.activeListingConfig.root) {
				colStyles = {
					width:    `5vw`,
					left:     `5vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `5vw`,
				};
			}
		} else if (cell.column.id.startsWith('groups.')) {
			colStyles = {
				width: `${listingsContentGroupColWidth}vw`,
			};
		} else if (cell.column.id.startsWith('parents.')) {
			colStyles = {
				width: `${parentsWidth}vw`,
			};
		}

		return colStyles;
	}, [ activeListingConfig.root, listingWidthCols, listingsContentGroupColWidth, parentsWidth, props.activeListingConfig.root, ]);

	const cellOnClick = useCallback((cell, row) => {
		return (e) => {
			e.preventDefault();
			if (cell.column.id === 'checkbox') {
				toggleSelectSingleRow(cell.row.original.uuid, cell.row.index);
			} else if (cell.column.id.startsWith('groups.')) {
				let groupID = cell.column.id.split('.')[1];
				let group = listingGroups.filter((listingGroup) => {
					return (listingGroup.uuid === groupID);
				})[0];

				toggleGroup(group, row.index);
			} else {
				// set scroll position manually, not via function because of a race condition
				let scrollX = document.getElementById('table-scroll').scrollLeft;
				let scrollY = document.getElementById('table-scroll').scrollTop;

				let title = 'Edit Listings';

				if (listingConfigIsIntegration) {
					title = 'Listings Details';
				}

				app.openModal({
					scrollX,
					scrollY,
					modalSize:  2,
					showModal:  true,
					modalProps: {
						title,
						customClass:    'test',
						pendingChanges: false,
						jsx:            (
							<ListingsModal
								isEdit={true}
								listingConfigIsIntegration={listingConfigIsIntegration}
								listingFD={listingFD}
								pendingChanges={pendingChanges}
								listing={array[cell.row.index]}
								listingToListingCollectionsMap={listingToListingCollectionsMap}
								listingToSystemsMap={listingToSystemsMap}
								listingConfigMap={listingConfigMap}
								findRootListings={findRootListings}
								updateListing={updateListing}
								accountUUID={accountUUID}
								systemGroupUUID={systemGroupUUID}
								buildingsDataArray={buildingsDataArray}

								listingConfigHasScheduling={listingConfigHasScheduling}
								activeListingConfig={activeListingConfig}
								setGroupValue={setGroupValue}
								listingGroups={listingGroups}
								getChildRelations={getChildRelations}
								getParentComponentName={getParentComponentName}
								listingCollectionHasListingGroupRelation={listingCollectionHasListingGroupRelation}
								listingConfigUUID={listingConfigUUID}
								parentsPrimaryColumnData={parentsPrimaryColumnData}
								listingConfigToListingsMap={listingConfigToListingsMap}
								fetchListingDataByListingConfig={fetchListingDataByListingConfig}
							/>
						),
					},
				});
			}
		};
	}, [ accountUUID, activeListingConfig, app, array, buildingsDataArray, fetchListingDataByListingConfig, findRootListings, getChildRelations, getParentComponentName, listingCollectionHasListingGroupRelation, listingConfigHasScheduling, listingConfigIsIntegration, listingConfigMap, listingConfigToListingsMap, listingConfigUUID, listingFD, listingGroups, listingToListingCollectionsMap, listingToSystemsMap, parentsPrimaryColumnData, pendingChanges, setGroupValue, systemGroupUUID, toggleGroup, toggleSelectSingleRow, updateListing, ]);

	let areAllRowsChecked = allRowsSelected;

	array.forEach((obj) => {
		if (!obj.isChecked) {
			areAllRowsChecked = false;
		}
	});

	if (validConfig) {
		return (
			<VultureTable
				style={style}
				columns={columns}
				data={data}
				fd={listingFD}
				columnsToHide={columnsToHide}
				secondaryNavButtons={secondaryNavButtons}
				tertiaryNavButtons={tertiaryNavButtons}
				cellOnClick={cellOnClick}
				page={page}
				rowsPerPage={rowsPerPage}
				onFilter={onFilter}

				theadColStyles={theadColStyles}
				filterRowColStyles={filterRowColStyles}
				tbodyColStyles={tbodyColStyles}

				listingTable={true}
				listingConfigUUID={listingConfigUUID}
				listingConfigsWithSystems={listingConfigsWithSystems}
				allRowsSelected={areAllRowsChecked}
				toggleSelectAllRows={toggleSelectAllRows}
			/>
		);
	}

	return (
		<div>
			This is not a valid Listing Tier. Please return to Account Settings and add at least one data type to this Tier.
		</div>
	);
};

export default ListingsTable;