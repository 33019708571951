import React from 'react';
import kali from 'kali';
import CustomIcon from '../Common/CustomIcon/CustomIcon';

import style from './ContentGroups.module.css';

import {
	v4 as uuidv4,
} from 'uuid';

import {
	fetchComponentGroupConfigs,
	fetchComponentGroupConfig,
	fetchComponentTreeInfo,
	fetchComponentTreeDiff,
	updateComponentGroup,
} from 'Vulture/ContentGroup';
import {
	vultureENV,
} from 'Vulture/ENV';

import { s3 } from 'S3';

const broncoURL = window._getEnv('BRONCO_URL');
const bucket = 'ts-pelican';
const resizedBucket = `${bucket}-resized`;
class CrowFieldCustomVultureContentGroups extends React.Component {
	constructor(props) {
		super(props);


		this.state = {
			activeComponentLabel:        '',
			activeComponentType:         '',
			selectedComponentGroupM1Key: '',

			componentGroupMap: {},
			componentMap:      {},
			componentTypeMap:  {},
			systemConfigMap:   {},
			systemMap:         {},
			configMap:         {},
			configHashMap:     {},

			activeSystemConfig:    '',
			selectedSystemM1Key:   '',
			configMapShowSettings: false,

			bundlesToDelete:     [],
			confirmModal:        false,
			confirmModalContent: '',
			pendingUpdates:      {},
		};
	}

	componentDidMount() {
		console.log('MOUNT: content_groups');

		const {
			step,
			stateCallback,
		} = this.props;

		stateCallback('contentGroupsSubmitForm', this.handleSaveButtonClick.bind(this));

		if (step === 4) {
			this.initConfigs();
		}
	}

	componentDidUpdate(prevProps) {
		const {
			fd,
			step,
		} = this.props;

		const {
			systemConfigMap,
		} = this.state;

		if (JSON.stringify(prevProps.fd) !== JSON.stringify(fd)) {
			if (step === 4) {
				let componentGroupMap = this.makeComponentGroupMap(systemConfigMap);

				this.setState({
					componentGroupMap,
				});
			}
		}
	}

	componentWillUnmount() {
		const {
			systemProgramming,
		} = this.props;

		systemProgramming.setState({
			pendingUpdates: {},
		});
	}

	async fetchComponentGroupConfigs(cb = () => {}) {
		const {
			accountUUID,
		} = this.props;

		let req = {
			account_uuid: accountUUID,
		};

		const res = await fetchComponentGroupConfigs(req);
		if (typeof cb === 'function') {
			return cb(res);
		}
	}

	async fetchComponentGroupConfig(componentTypeM1Key, component, cb = () => {}) {
		const {
			accountUUID,
		} = this.props;

		let req = {
			account_uuid:        accountUUID,
			component_type_uuid: componentTypeM1Key.split('.')[1],
			group_label:         component,
		};

		const res = await fetchComponentGroupConfig(req);
		if (typeof cb === 'function') {
			return cb(res);
		}
	}

	async updateComponentGroup(cb = () => {}) {
		const {
			accountUUID,
		} = this.props;

		const {
			pendingUpdates,
		} = this.state;

		let req = {
			account_uuid: accountUUID,
			group:        {},
		};

		for (let m1Key of Object.keys(pendingUpdates)) {
			if (m1Key.startsWith('component_group.')) {
				let componentGroup = this.getComponentGroupByM1Key(m1Key);

				let rootSystemUUID;
				for (let [ systemM1Key, systemInfo, ] of Object.entries(componentGroup)) {
					if (systemM1Key.startsWith('system.')) {
						if (systemInfo.root) {
							let systemUUID = systemM1Key.split('.')[1];
							rootSystemUUID = systemUUID;
						}
					}
				}

				let systems = {};
				for (let [ systemM1Key, systemInfo, ] of Object.entries(pendingUpdates[m1Key].systems)) {
					let systemUUID = systemM1Key.split('.')[1];
					if (systemInfo.root) {
						rootSystemUUID = systemUUID;
					}

					if (systemInfo.add === true) {
						systems[systemUUID] = true;
					}
					if (systemInfo.del === true) {
						systems[systemUUID] = false;
					}
				}

				let group = {
					group_label:      componentGroup.group_label,
					root_system_uuid: rootSystemUUID,
					systems,
				};

				let componentGroupUUID = m1Key.split('.')[1];
				req.group[componentGroupUUID] = group;
			}
		}

		const res = await updateComponentGroup(req);
		if (typeof cb === 'function') {
			return cb(res);
		}
	}

	initConfigs() {
		let {
			activeComponentType,
			activeComponentLabel,
		} = this.state;

		this.fetchComponentGroupConfigs((configsData) => {
			let componentMap = Object.assign({}, configsData.group_labels);
			let componentTypeMap = Object.assign({}, configsData.component_types);
			let systemMap = Object.assign({}, configsData.system_names);

			if (!activeComponentType) {
				let vals = Object.values(componentTypeMap).map((val) => {
					return val.toLowerCase();
				});
				vals.sort();

				let firstVal = vals[0];

				let componentTypeM1Key;
				for (let [ m1Key, v, ] of Object.entries(componentTypeMap)) {
					if (firstVal === v.toLowerCase()) {
						componentTypeM1Key = m1Key;
						break;
					}
				}

				activeComponentType = componentTypeM1Key;
			}

			if (!activeComponentLabel) {
				if ((componentMap[activeComponentType] || []).length > 0) {
					activeComponentLabel = componentMap[activeComponentType][0];
				}
			}

			this.setState({
				activeComponentLabel,
				activeComponentType,
				componentMap,
				componentTypeMap,
				systemMap,
			}, () => {
				this.initConfig();
			});
		});
	}

	initConfig() {
		const {
			systemProgramming,
		} = this.props;

		const {
			activeComponentLabel,
			activeComponentType,
			pendingUpdates,
		} = this.state;

		if (Object.keys(pendingUpdates).length > 0) {
			systemProgramming.setState({
				pendingUpdates,
			});
		}

		this.fetchComponentGroupConfig(activeComponentType, activeComponentLabel, (configData) => {
			let {
				configMap,
				configHashMap,
				systemConfigMap,
			} = this.makeConfigMaps(configData);

			let componentGroupMap = this.makeComponentGroupMap(systemConfigMap);

			this.setState({
				componentGroupMap,
				configHashMap,
				configMap,
				systemConfigMap,
			});
		});
	}

	makeComponentGroupMap(systemConfigMap) {
		const {
			fd,
		} = this.props;

		const {
			pendingUpdates,
		} = this.state;

		let vals = fd || Object.assign({}, window.form.orgVals, window.form.newVals);

		let componentGroupMap = {};
		for (let [ m1Key, m1Data, ] of Object.entries(vals)) {
			if (m1Key.startsWith('account.')) {
				for (let m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('component_group.')) {
						let componentGroup = JSON.parse(JSON.stringify(vals[m2Key]));

						let componentTypeM1Key = Object.keys(componentGroup)
							.filter((key) => {
								return key.startsWith('component_type.');
							})[0];

						if (!componentGroupMap[componentTypeM1Key]) {
							componentGroupMap[componentTypeM1Key] = {};
						}

						if (!componentGroupMap[componentTypeM1Key][componentGroup.group_label]) {
							componentGroupMap[componentTypeM1Key][componentGroup.group_label] = {};
						}

						for (let [ componentGroupM1Key, updates, ] of Object.entries(pendingUpdates)) {
							if (m2Key === componentGroupM1Key) {
								for (let [ update, value, ] of Object.entries(updates)) {
									if (update === 'configs') {
										componentGroup.configs = value;
									}

									if (update === 'systems') {
										for (let [ systemM1Key, systemInfo, ] of Object.entries(value)) {
											if (systemInfo.add) {
												componentGroup[systemM1Key] = {};
											}

											if (systemInfo.del) {
												Reflect.deleteProperty(componentGroup, systemM1Key);
											}
										}
									}
								}
							}
						}

						let systemM1Keys = [];
						for (let m1Key of Object.keys(componentGroup)) {
							if (m1Key.startsWith('system.')) {
								systemM1Keys.push(m1Key);
							}
						}

						if (systemM1Keys.length > 0) {
							let systemUUID = systemM1Keys[0].split('.')[1];
							componentGroup.configs = systemConfigMap[systemUUID];
						}

						componentGroup.m2_list = Object.keys(componentGroup).filter((key) => {
							return key.startsWith('system.');
						});

						if (componentGroup.m2_list.length === 0) {
							componentGroup.configs = [];
						}

						componentGroup.m1Key = m2Key;
						componentGroupMap[componentTypeM1Key][componentGroup.group_label][componentGroup.display_name] = componentGroup;
					}
				}
			}
		}

		return componentGroupMap;
	}

	makeConfigMaps(data) {
		let configHashMap = {};
		let configMap = {};

		for (let [ configStr, components, ] of Object.entries(data.component_configs)) {
			let componentUUID = components[0].component_uuid;

			let hash = data.component_tree_hashes[componentUUID];
			configHashMap[hash] = configStr;

			let componentTree = data.component_trees[componentUUID];
			configMap[configStr] = componentTree;
		}

		let systemConfigUniqueMap = {};
    	for (let [key, value] of Object.entries(data.system_configs)) {
        	if (Array.isArray(value)) {
           		systemConfigUniqueMap[key] = [...new Set(value)];
        	} else {
           		systemConfigUniqueMap[key] = value;
       		}
    	}

		return {
			configHashMap,
			configMap,
			systemConfigMap: systemConfigUniqueMap,
		};
	}

	getActiveComponentGroupMap() {
		const {
			activeComponentLabel,
			activeComponentType,
			componentGroupMap,
		} = this.state;

		return (componentGroupMap[activeComponentType] || {})[activeComponentLabel] || {};
	}

	getActiveComponentMap() {
		const {
			activeComponentType,
			componentMap,
		} = this.state;

		return (componentMap[activeComponentType] || []);
	}

	getComponentGroupByM1Key(m1Key) {
		let activeComponentLabelGroupMap = this.getActiveComponentGroupMap();

		for (let componentGroup of Object.values(activeComponentLabelGroupMap)) {
			if (componentGroup.m1Key === m1Key) {
				return componentGroup;
			}
		}

		return;
	}

	getComponentGroupBySystemM1Key(systemM1Key) {
		let activeComponentLabelGroupMap = this.getActiveComponentGroupMap();

		for (let componentGroup of Object.values(activeComponentLabelGroupMap)) {
			for (let m1Key of Object.keys(componentGroup)) {
				if (systemM1Key === m1Key) {
					return componentGroup;
				}
			}
		}

		return;
	}

	renderActiveCell(system, pending = false, groupRoot = false) {
		let iconStyle;
		let groupStyle;

		if (pending) {
			iconStyle = style.iconPending;
			groupStyle = style.groupPending;

			if (groupRoot) {
				iconStyle = style.iconRoot;
				groupStyle = style.groupRoot;
			}
		}

		if (!pending) {
			iconStyle = style.iconChecked;
			groupStyle = style.selectedGroup;
		}

		return (
			<div className={style.selectBox} key={`active_${system.uuid}`}>
				<div className={`${style.selectBoxInner} ${groupStyle}`}>
					<div className={iconStyle}><CustomIcon icon='check-circle' />A</div>
				</div>
			</div>
		);

		// // disabled?
		// (
		// 	<div className={style.selectBox} key={`disabled_bundle_${i}`}>
		// 		<div className={`${style.lockedSelectBoxInner}`}></div>
		// 	</div>
		// );

		// // disabled?
		// (
		// 	<div className={style.selectBox} key={`disabled_system_${system.uuid}`}>
		// 		<div className={`${style.lockedSelectBoxInner}`}></div>
		// 	</div>
		// );

		// // locked
		// (
		// 	<div className={style.selectBox} key={'locked_icon'}>
		// 		<div className={`${style.lockedSelectBoxInner} ${style.lockedSelectGroup}`}>
		// 			<div className={style.iconLocked}><CustomIcon icon='lock' /></div>
		// 		</div>
		// 	</div>
		// );
	}

	renderActiveComponentGroupCell(componentGroupM1Key, systemM1Key, pending, root) {
		let iconStyle = style.iconChecked;
		let groupStyle = style.selectedGroup;

		if (pending) {
			iconStyle = style.iconPending;
			groupStyle = style.groupPending;

			if (root) {
				iconStyle = style.iconMaster;
				groupStyle = style.groupMaster;
			}
		}

		if (!pending) {
			iconStyle = style.iconChecked;
			groupStyle = style.selectedGroup;

			if (root) {
				iconStyle = style.iconMaster;
				groupStyle = style.groupMaster;
			}
		}

		return (
			<div
				key={`active_cell_${componentGroupM1Key}_${systemM1Key}`}
				className={style.selectBox}
			>
				<div className={`${style.selectBoxInner} ${groupStyle}`}>
					<div className={iconStyle}><CustomIcon icon='check-circle' />
						{/* active_component_group */}
					</div>
				</div>
			</div>
		);
	}

	renderInactiveComponentLabelGroupCell(groupName, systemM1Key) {
		const {
			systemConfigMap,
		} = this.state;

		let activeComponentGroupMap = this.getActiveComponentGroupMap();
		let componentGroup = activeComponentGroupMap[groupName];

		let configsMatch = true;
		let configs = componentGroup.configs || [];

		if (configs.length > 0) {
			let systemUUID = systemM1Key.split('.')[1];
			let systemConfigs = systemConfigMap[systemUUID] || [];

			if (JSON.stringify(configs) !== JSON.stringify(systemConfigs)) {
				configsMatch = false;
			}
		}

		return (
			<div
				key={`inactive_cell_${componentGroup.m1Key}_${systemM1Key}`}
				className={style.selectBox}
				onClick={(e) => {
					if (!configsMatch) {
						return;
					}

					this.addSystemToComponentGroup(componentGroup.m1Key, systemM1Key);
				}}
			>
				{configsMatch &&
					<div className={`${style.selectBoxInner} ${style.notSelectedGroup}`}>
						{/* inactive_component_group */}
					</div>
				}
				{!configsMatch &&
					<div className={`${style.lockedSelectBoxInner} ${style.lockedSelectGroup}`}
						style={{
							cursor: 'help',
						}}
					>
						<div className={style.iconLocked}><CustomIcon icon='lock' /></div>
					</div>
				}
			</div>
		);
	}

	renderActiveSystemCell(systemM1Key, pending) {
		let iconStyle = style.iconChecked;
		let groupStyle = style.selectedGroup;

		if (pending) {
			iconStyle = style.iconPending;
			groupStyle = style.groupPending;
		}

		return (
			<div
				key={`active_cell_${systemM1Key}`}
				className={style.selectBox}
			>
				<div className={`${style.selectBoxInner} ${groupStyle}`}>
					<div className={iconStyle}><CustomIcon icon='check-circle' />
						{/* active_system_default */}
					</div>
				</div>
			</div>
		);
	}

	renderInactiveSystemCell(systemM1Key) {
		return (
			<div
				key={`inactive_cell_${systemM1Key}`}
				className={style.selectBox}
				onClick={(e) => {
					this.delSystemFromComponentGroup(systemM1Key);
				}}
			>
				<div className={`${style.selectBoxInner} ${style.notSelectedGroup}`}>
					{/* inactive_system_default */}
				</div>
			</div>
		);
	}

	renderSystemGridRow(system) {
		const {
			pendingUpdates,
			systemConfigMap,
		} = this.state;

		let row = [];

		let systemM1Keys = Object.keys(systemConfigMap).map((systemUUID) => {
			return `system.${systemUUID}`;
		});

		let activeComponentLabelGroupMap = this.getActiveComponentGroupMap();
		let activeComponentLabelMap = this.getActiveComponentMap();

		if (activeComponentLabelMap) {
			let componentGroupNames = Object.keys(activeComponentLabelGroupMap);
			componentGroupNames.sort();

			let systemIsGrouped = false;
			componentGroupNames.forEach((groupName, i) => {
				let componentGroup = activeComponentLabelGroupMap[groupName];

				let elm = this.renderInactiveComponentLabelGroupCell(groupName, system.uuid);

				systemM1Keys.forEach((systemM1Key) => {
					let m2List = activeComponentLabelGroupMap[groupName].m2_list || [];
					if (systemM1Key === system.uuid && ~m2List.indexOf(systemM1Key)) {
						systemIsGrouped = true;

						let pending = false;
						let groupRoot = false;
						if (pendingUpdates[componentGroup.m1Key]) {
							let pendingSystems = pendingUpdates[componentGroup.m1Key].systems || {};

							if (pendingSystems[systemM1Key]) {
								pending = true;
								groupRoot = pendingSystems[systemM1Key].root || false;
							}
						}
						
						if (!pendingUpdates[componentGroup.m1Key]) {
							groupRoot = activeComponentLabelGroupMap[groupName][systemM1Key].root
						}

						elm = this.renderActiveComponentGroupCell(componentGroup.m1Key, systemM1Key, pending, groupRoot);
					}
				});

				row.push(elm);
			});

			let elm = this.renderActiveSystemCell(system.uuid);
			if (systemIsGrouped) {
				elm = this.renderInactiveSystemCell(system.uuid);
			}

			row.push(elm);
		}

		return row;
	}

	handleNewComponentGroup(groupName) {
		const {
			accountUUID,
			app,
		} = this.props;

		const {
			activeComponentLabel,
			activeComponentType,
		} = this.state;

		let accountM1Key = `account.${accountUUID}`;
		let componentGroupM1Key = `component_group.${uuidv4()}`;
		let componentTypeM1Key = activeComponentType;

		let obj = {
			[`${componentGroupM1Key}.display_name`]:          groupName,
			[`${componentGroupM1Key}.group_label`]:           activeComponentLabel,
			[`${componentGroupM1Key}.${componentTypeM1Key}`]: true,
			[`${accountM1Key}.${componentGroupM1Key}`]:       true,
		};

		app.setValue(null, Object.keys(obj), Object.values(obj), () => {
			app.saveDataAndCloseModal('', () => {
				app.fetchAccountTemplates(accountUUID);
			});
		});
	}

	handleRenameComponentGroup(newGroupName) {
		const {
			app,
			accountUUID,
		} = this.props;

		const {
			selectedComponentGroupM1Key,
		} = this.state;

		let componentGroupM1Key = selectedComponentGroupM1Key;

		let obj = {
			[`${componentGroupM1Key}.display_name`]: newGroupName,
		};

		app.setValue(null, Object.keys(obj), Object.values(obj), () => {
			app.saveDataAndCloseModal('', () => {
				app.fetchAccountTemplates(accountUUID);
			});
		});
	}

	addSystemToComponentGroup(componentGroupM1Key, systemM1Key, cb = () => {}) {
		const {
			pendingUpdates,
			systemConfigMap,
		} = this.state;
	
		const existingComponentGroup = this.getComponentGroupBySystemM1Key(systemM1Key);
		if (existingComponentGroup) {
			const groupRoot = existingComponentGroup[systemM1Key]?.root ?? false;
			const systemList = Object.keys(existingComponentGroup).filter(system => system.startsWith('system.'));
			const mustBeSaved = groupRoot && pendingUpdates[existingComponentGroup.m1Key]?.systems;
	
			if (groupRoot && (mustBeSaved || systemList.length > 1)) {
				this.openModal({
					title: 'Root System',
					jsx: (
						<WarningModal
							closeModal={this.closeModal.bind(this)}
						/>
					),
				});
				return;
			}
	
			const existingComponentGroupM1Key = existingComponentGroup.m1Key;
			if (!pendingUpdates[existingComponentGroupM1Key]) {
				pendingUpdates[existingComponentGroupM1Key] = {
					systems: {},
				};
			}
	
			pendingUpdates[existingComponentGroupM1Key].systems[systemM1Key] = {
				del: true,
			};
		}
	
		let componentGroup = this.getComponentGroupByM1Key(componentGroupM1Key);
		if (!pendingUpdates[componentGroupM1Key]) {
			pendingUpdates[componentGroupM1Key] = {
				systems: {},
			};
		}
	
		let systemCount = 0;
		for (let m1Key of Object.keys(componentGroup)) {
			if (m1Key.startsWith('system.')) {
				systemCount++;
			}
		}
	
		let rootSystem = false;
		if (systemCount === 0) {
			rootSystem = true;
			const systemUUID = systemM1Key.split('.')[1];
			pendingUpdates[componentGroupM1Key].configs = systemConfigMap[systemUUID];
		}
	
		pendingUpdates[componentGroupM1Key].systems[systemM1Key] = {
			add: true,
			del: false,
			root: rootSystem,
		};
	
		this.setState({
			pendingUpdates,
		}, () => {
			this.initConfig();
			return cb();
		});
	}

	delSystemFromComponentGroup(systemM1Key, cb = () => { }) {
		const {
			pendingUpdates,
		} = this.state;

		const existingComponentGroup = this.getComponentGroupBySystemM1Key(systemM1Key);

		if (existingComponentGroup[systemM1Key]) {
			// Determine if the component is a root component; default to false if not specified
			const groupRoot = existingComponentGroup[systemM1Key].root ?? false;
			// Create a list of system keys that start with 'system.' from the component group
			const systemList = Object.keys(existingComponentGroup).filter(system => system.startsWith('system.'));
    		// It must be saved if it's a root component and its "childern" have been unassigned
			const mustBeSaved = groupRoot && pendingUpdates[existingComponentGroup.m1Key]?.systems
		
			if (groupRoot && (mustBeSaved || systemList.length > 1)) {
				this.openModal({
					title: 'Root System',
					jsx: (
						<WarningModal
							closeModal={this.closeModal.bind(this)}
						/>
					),
				});

				return;
			}
		}

		let componentGroupM1Key = existingComponentGroup.m1Key;
		if (!pendingUpdates[componentGroupM1Key]) {
			pendingUpdates[componentGroupM1Key] = {
				systems: {},
			};
		}

		pendingUpdates[componentGroupM1Key].systems[systemM1Key] = {
			del: true,
		};

		this.setState({
			pendingUpdates,
		}, () => {
			this.initConfig();

			return cb();
		});

		return;
	}

	renderContentGroupNames() {
		let activeComponentGroupMap = this.getActiveComponentGroupMap();
		let keys = Object.keys(activeComponentGroupMap);
		keys.sort();

		let contentGroups = [];
		keys.forEach((groupName, i) => {
			let componentGroup = activeComponentGroupMap[groupName];

			let groupConfigs = activeComponentGroupMap[groupName].configs || [];

			let checked = false;
			if (this.state.selectedComponentGroupM1Key === componentGroup.m1Key) {
				checked = true;
			}

			contentGroups.push(
				<div className={style.systemGroup} key={`${groupName}_${i}`}>
					<div
						className={`${style.systemGroupCheckbox} ${style.systemGroupIcon}`}
						onClick={(e) => {
							e.preventDefault();
							this.handleSelectComponentGroupM1Key(componentGroup.m1Key, checked);
						}}
					>
						{checked ?
							<CustomIcon icon="check-square" /> :
							<CustomIcon icon='square' />
						}
					</div>
					<div className={style.systemGroupNameContainer}>
						<div className={style.systemGroupLine}></div>
						<div className={style.systemGroupName}>
							<CustomIcon icon="object-group" />
							{groupName}
							{groupConfigs.length > 0 &&
								<>
									-&nbsp;
									<small
										style={{
											fontFamily: 'monospace',
										}}
									>{(JSON.stringify(groupConfigs))}</small>
								</>
							}
						</div>
					</div>
				</div>
			);
		});

		contentGroups.push(
			<div className={style.systemGroup} key='system_default'>
				<div className={`${style.systemGroupCheckbox} ${style.systemGroupIcon}`}>
					{/* <CustomIcon icon='square'/> */}
				</div>
				<div className={style.systemGroupNameContainer}>
					<div className={style.systemGroupLine}></div>
					<div className={style.systemGroupName}>System Default</div>
				</div>
			</div>
		);

		return contentGroups;
	}

	handleSelectComponentGroupM1Key(label, checked) {
		if (checked) {
			this.setState({
				selectedComponentGroupM1Key: '',
			});

			return;
		}

		this.setState({
			selectedComponentGroupM1Key: label,
		});
	}

	setComponentGroupConfig(componentTypeM1Key, component) {
		if (Object.keys(this.state.pendingUpdates).length > 0) {
			this.openModal({
				title: 'You Have Unsaved Changes',
				jsx:   (
					<PendingUpdatesModal
						submitForm={this.submitForm.bind(this)}
						closeModal={this.closeModal.bind(this)}
						handleDontSave={() => {
							this.props.systemProgramming.setState({
								pendingUpdates: {},
							});

							this.setState({
								activeComponentType:  componentTypeM1Key,
								activeComponentLabel: component,
								pendingUpdates:       {},
							}, () => {
								this.initConfig();
							});
						}}
					/>
				),
			});

			return;
		}

		this.setState({
			activeComponentType:         componentTypeM1Key,
			activeComponentLabel:        component,
			selectedComponentGroupM1Key: '',
			selectedSystemM1Key:         '',
		}, () => {
			this.initConfig();
		});
	}

	openModal(modalProps) {
		// NOTE: Not needed if we can set the first component in the sidebar to be 'active' on load.
		if (!this.state.activeComponentLabel) {
			return;
		}

		this.props.app.openModal({
			modalSize: 1,
			showModal: true,
			modalProps,
		});
	}

	closeModal() {
		this.props.app.closeModal();
	}

	copyS3Data(oldM1Key, newM1Key) {
		return new Promise((resolve, reject) => {
			const env = vultureENV;

			// *M1Key comes in as component.UUID
			const oldUUID = oldM1Key.split('.')[1];
			const newUUID = newM1Key.split('.')[1];

			// List objects in current UUID dir and then copy them to new UUID dir
			s3.listObjects({
				Bucket:    bucket,
				Delimiter: '/',
				Prefix:    `${env}/${oldUUID}/`,
			}, (err, data) => {
				if (err) {
					reject(err);
					return;
				}

				if (data && data.Contents && Array.isArray(data.Contents)) {
					const prefix = data.Prefix;

					const promiseArr = [];

					data.Contents.forEach((content) => {
						const destKey = `${env}/${newUUID}/${content.Key.replace(prefix, '')}`;

						// Create promises for adding to ts-pelican and ts-pelican-resized
						promiseArr.push(
							new Promise((childResolve, childReject) => {
								s3.copyObject({
									ACL:        'public-read',
									Bucket:     bucket,                     // Destination bucket
									CopySource: `${bucket}/${content.Key}`, // Source
									Key:        destKey,                     // Destination Key
								}, (copyErr, copyData) => {
									if (copyErr) {
										console.error(copyErr, `Bucket: ${bucket}`, `CopySource: ${bucket}/${content.Key}`, `Key: ${destKey}`);
										childReject(copyErr);
										return;
									}
									childResolve();
								});
							})
						);
					});

					// Use Promise.all to check if any calls to copyObject failed
					Promise.all(promiseArr)
						.then((promiseRes) => {
							resolve(promiseRes);
						})
						.catch((promiseErr) => {
							// Reject and block the call to /w/edit
							reject(promiseErr);
						});
				} else {
					reject(new Error('data.Contents is invalid: ', data));
				}
			});

			s3.listObjects({
				Bucket:    resizedBucket,
				Delimiter: '/',
				Prefix:    `${env}/${oldUUID}/`,
			}, (err, data) => {
				if (err) {
					reject(err);
					return;
				}

				if (data && data.Contents && Array.isArray(data.Contents)) {
					const prefix = data.Prefix;

					const promiseArr = [];

					data.Contents.forEach((content) => {
						const destKey = `${env}/${newUUID}/${content.Key.replace(prefix, '')}`;

						// Create promises for adding to ts-pelican and ts-pelican-resized
						promiseArr.push(
							new Promise((childResolve, childReject) => {
								s3.copyObject({
									ACL:        'public-read',
									Bucket:     resizedBucket,                // Destination bucket
									CopySource: `${resizedBucket}/${content.Key}`, // Source
									Key:        destKey,                             // Destination Key
								}, (copyErr, copyData) => {
									if (copyErr) {
										console.error(copyErr, `Bucket: ${resizedBucket}`, `CopySource: ${resizedBucket}/${content.Key}`, `Key: ${destKey}`);
										childReject(copyErr);
										return;
									}
									childResolve();
								});
							})
						);
					});

					// Use Promise.all to check if any calls to copyObject failed
					Promise.all(promiseArr)
						.then((promiseRes) => {
							resolve(promiseRes);
						})
						.catch((promiseErr) => {
							// Reject and block the call to /w/edit
							reject(promiseErr);
						});
				} else {
					reject(new Error('data.Contents is invalid: ', data));
				}
			});
		});
	}

	rollbackS3(cloneList) {
		let env = vultureENV;

		cloneList.forEach((clone) => {
			let uuidToDelete = clone.newM1Key;
			if (uuidToDelete) {
				// newM1Key is component.UUID. We only need the UUID.
				let uuid = uuidToDelete.split('.')[1];
				s3.listObjects({
					Bucket:    resizedBucket,
					Delimiter: '/',
					Prefix:    `${env}/${uuid}/`,
				}, (err, data) => {
					if (err) {
						console.error(err);
						return;
					}

					if (data && data.Contents && Array.isArray(data.Contents)) {
						let params = {
							Bucket: resizedBucket,
							Delete: {
								Objects: [],
							},
						};

						data.Contents.forEach((content) => {
							params.Delete.Objects.push(
								{
									Key: content.Key,
								}
							);
						});

						// Delete from the resized bucket.
						s3.deleteObjects(params, (err, data) => {
							if (err) {
								console.error(err);
							}
						});

						// Delete again from the original bucket.
						params.Bucket = bucket;
						s3.deleteObjects(params, (err, data) => {
							if (err) {
								console.error(err);
							}
						});
					}
				});
			}
		});
	}

	handlePendingUpdates(sessionKey) {
		let pendingUpdates = this.state.pendingUpdates;
		let promiseArr = [];
		let bundlesToDelete = [];

		let bundleMap = this.state.bundleMap;
		Object.keys(bundleMap).forEach((bundleKey) => {
			// Skip _PENDING_CLONE bundles for now.
			if (~bundleKey.indexOf('_PENDING_CLONE')) {
				return;
			}

			// If there is a prevUUID, it's a bundle that was emptied.
			// Mark for deletion since it no longer holds an m2_list.
			// NOTE: We could preserve the empty bundle if needed. Could cause issues in other areas of vulture?
			if (bundleMap[bundleKey].prevUUID) {
				bundlesToDelete.push(bundleMap[bundleKey].prevUUID);
			}

			// Avoid server errors from passing invalid keys.
			let {
				m1, m1ID,
			} = window.form.parseEntityName(bundleKey);
			if (m1 !== 'component' || !m1ID) {
				return;
			}

			promiseArr.push({
				keys: [ `${bundleKey}._bundle`, ],
				vals: [[ bundleMap[bundleKey], ], ],
			});
		});

		this.setState({
			bundlesToDelete,
		});

		let systemsPendingRootClone = [];
		let pendingCloneList = [];
		let cloneUUIDList = [];

		Object.keys(pendingUpdates).forEach((componentKey) => {
			// Ignore pendingUpdates that aren't modifying relations. i.e. bundle_root
			if (!pendingUpdates[componentKey].delete) {
				return;
			}

			if (!pendingUpdates[componentKey].clone) {
				let add = pendingUpdates[componentKey].add;
				// Skip _PENDING_CLONE bundles for now.
				if (add && ~add.indexOf('_PENDING_CLONE')) {
					systemsPendingRootClone.push(pendingUpdates[componentKey]);
					return;
				}

				let del = pendingUpdates[componentKey].delete;

				let keys = [ del, add, ];
				let vals = [ false, true, ];

				promiseArr.push({
					keys,
					vals,
				});
			}

			if (pendingUpdates[componentKey].clone) {
				pendingCloneList.push(pendingUpdates[componentKey]);
				cloneUUIDList.push(pendingUpdates[componentKey].m1_id);
			}
		});

		if (cloneUUIDList.length > 0) {
			promiseArr.push(new Promise((resolve, reject) => {
				let uri = `${broncoURL}/w/clone/component`;

				new kali({
					body: {
						data:       cloneUUIDList,
						exclusions: [
							'component_config',
							'component_type',
							'component_version',
							'listing_column',
							'listing_column_type',
							'listing_config',
							'setting',
							'setting_type',
						],
					},
					headers: {
						'content_type':   'application/json',
						'X-Auth-Session': sessionKey,
					},
					method: 'POST',
				}).post(uri, {
					success: (_kali, res, contents) => {
						let m1Keys = contents.keys;
						let keys = [];
						let vals = [];

						let slideshowCloneList = [];

						// Modify clone relation to the parent component.
						pendingCloneList.forEach((pendingClone) => {
							// Loop over m1Keys to find the match of the pendingClone's former m1Key.
							Object.keys(m1Keys).forEach((m1Key) => {
								if (m1Key === `component.${pendingClone.m1_id}`) {
									let parentID = pendingClone.parent_id;
									let del = pendingClone.delete;

									// The first item in the old M1Key array is the new child.
									const newM1Key = m1Keys[m1Key][0];
									let add = `component.${parentID}.${newM1Key}`;

									// Remove the new m1Key in case we're cloning the same component more than once.
									m1Keys[m1Key].shift();

									keys.push(add, del);
									vals.push(true, false);

									// Check if this clone was a bundle root, and update other systems if needed.
									if (pendingClone.tmp_bundle_key) {
										let tmpBundleKey = pendingClone.tmp_bundle_key;
										systemsPendingRootClone.forEach((system) => {
											if (system.add && ~system.add.indexOf(tmpBundleKey)) {
												// Replace tmpBundleKey with newM1Key for add.
												let a = system.add;
												a = a.replace(`component.${tmpBundleKey}`, newM1Key);
												let d = system.delete;
												keys.push(a, d);
												vals.push(true, false);
											}
										});

										// Find tmpBundleKey in bundleMap and replace with newM1Key
										Object.keys(bundleMap).forEach((bundleKey) => {
											if (bundleKey === tmpBundleKey) {
												keys.push(`${newM1Key}._bundle`);
												vals.push([ bundleMap[bundleKey], ]);
											}
										});
									}

									// Check if this clone is a pelican component and push to slideshowCloneList.
									if (this.state.activeComponentLabel === 'Slideshow') {
										slideshowCloneList.push({
											oldM1Key: m1Key,
											newM1Key,
										});
									}
								}
							});
						});

						// Convert FD to DD
						let flatData = contents.data;
						Object.keys(flatData).forEach((m1Key) => {
							if (m1Key.startsWith('component.') || m1Key.startsWith('listing_')) {
								Object.keys(flatData[m1Key]).forEach((m2Key) => {
									// If value is not an object, add the value
									if (typeof flatData[m1Key][m2Key] !== 'object') {
										keys.push(`${m1Key}.${m2Key}`);
										vals.push(flatData[m1Key][m2Key]);
										return;
									}

									// If value is empty object, add a relation
									if (Object.keys(flatData[m1Key][m2Key]).length === 0) {
										keys.push(`${m1Key}.${m2Key}`);
										vals.push(true);
										return;
									}

									// If value is an object with data, add the attr
									let attr = Object.keys(flatData[m1Key][m2Key])[0];
									keys.push(`${m1Key}.${m2Key}.${attr}`);
									vals.push(flatData[m1Key][m2Key][attr]);
								});
							}
						});

						let returnObj = {
							keys,
							vals,
						};

						// If there were slideshow components cloned, copy the original S3 directory content into a new directory per clone.
						if (slideshowCloneList.length > 0) {
							let s3PromiseArr = [];
							slideshowCloneList.forEach((clone) => {
								s3PromiseArr.push(this.copyS3Data(clone.oldM1Key, clone.newM1Key));
							});

							// If all calls to S3 were successful, resolve the promise.
							Promise.all(s3PromiseArr)
								.then((s3Res) => {
									resolve(returnObj);
								})
								.catch((s3Err) => {
									// If any of the S3 calls failed, reject and block the call to /w/edit.
									reject(s3Err);
									this.rollbackS3(slideshowCloneList);
								});
						} else {
							resolve(returnObj);
						}
					},

					failure: (_kali, res, err) => {
						reject(err);

						if (res.status === 503) {
							window.form.props.on503(true);
						}
					},
				});
			}));
		}

		return promiseArr;
	}

	handleSaveButtonClick(cbKey, cbVal) {
		const {
			app,
		} = this.props;

		const {
			pendingUpdates,
		} = this.state;

		let groupingUpdates = false;
		for (let m1Key of Object.keys(pendingUpdates)) {
			if (m1Key.startsWith('component_group.')) {
				groupingUpdates = true;
			}
		}

		if (!groupingUpdates) {
			return;
		}

		if (groupingUpdates) {
			this.openModal({
				title: 'Confirm Save',
				jsx:   (
					<ConfirmSaveModal
						app={app}
						cbKey={cbKey}
						cbVal={cbVal}
						closeModal={this.closeModal.bind(this)}
						submitForm={this.submitForm.bind(this)}
					/>
				),
			});
		}
	}

	submitForm(cbKey, cbVal) {
		this.updateComponentGroup(() => {
			const {
				accountUUID,
				app,
				systemProgramming,
			} = this.props;

			systemProgramming.setState({
				pendingUpdates: {},
			});

			this.setState({
				pendingUpdates: {},
			}, () => {
				app.fetchAccountTemplates(accountUUID);
			});
		});
	}

	deleteComponentGroup() {
		const {
			app,
			accountUUID,
		} = this.props;

		let {
			selectedComponentGroupM1Key,
		} = this.state;

		let componentGroupM1Key = selectedComponentGroupM1Key;
		let componentGroup = this.getComponentGroupByM1Key(componentGroupM1Key);

		let empty = true;
		for (let m2Key of Object.keys(componentGroup)) {
			if (m2Key.startsWith('system.')) {
				empty = false;
				break;
			}
		}

		if (!empty) {
			this.setState({
				confirmModal:        true,
				confirmModalContent:
					<>
						<p>{'Custom Group must be empty before deleting'}</p>
						<div className="confirm-modal-buttons">
							<button className="button" onClick={(e) => {
								this.setState({
									confirmModal: false,
								});
							}}>Okay</button>
						</div>
					</>,
			});
		} else {
			this.setState({
				confirmModal:        true,
				confirmModalContent:
					<>
						<p>{`Are you sure you want to delete "${componentGroup.display_name}"?`}</p>
						<div className="confirm-modal-buttons">
							<button className="button-outlined" onClick={(e) => {
								let [ m1, m1UUID, ] = componentGroupM1Key.split('.');
								app.deleteM1(m1, [ m1UUID, ], () => {
									this.setState({
										confirmModal: false,
									});
									app.fetchAccountTemplates(accountUUID);
								});
							}}>Yes</button>
							<button className="button" onClick={(e) => {
								this.setState({
									confirmModal: false,
								});
							}}>No</button>
						</div>
					</>,
			});
		}
	}

	render() {
		const {
			step,
		} = this.props;

		const {
			activeComponentLabel,
			activeComponentType,
			activeSystemConfig,
			componentMap,
			componentTypeMap,
			configHashMap,
			selectedComponentGroupM1Key,
			selectedSystemM1Key,
			systemConfigMap,
			systemMap,
		} = this.state;

		let activeComponentGroupMap = this.getActiveComponentGroupMap();
		let activeComponentLabelMap = this.getActiveComponentMap();

		let sortedSystemObjList = [];
		for (let systemUUID of Object.keys(systemConfigMap)) {
			let systemM1Key = `system.${systemUUID}`;

			sortedSystemObjList.push({
				uuid:  systemM1Key,
				m1Key: systemM1Key,
				name:  systemMap[systemM1Key],
			});
		}

		sortedSystemObjList.sort((a, b) => {
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				return 1;
			}

			return -1;
		});

		let confirmModal = '';
		if (this.state.confirmModal === true) {
			confirmModal =
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.confirmModalContent}
					</div>
				</div>;
		}

		return (
			<React.Fragment>
				{confirmModal}
				{step === 4 &&
					<div className={`contentGroups-page fade-in`}>

						{/* ============== Component Sidebar ============== */}
						<div className={style.componentSelector}>
							<h2>Components</h2>
							<div className={style.description}>Select one of this projects components</div>
							<div className={`${style.componentLine} ${style.mt3}`}></div>

							{Object.entries(componentMap).map(([ componentTypeM1Key, groupLabels, ]) => {
								return (
									<div
										key={componentTypeM1Key}
									>
										<p><strong>{componentTypeMap[componentTypeM1Key]}</strong></p>
										{Array.from(groupLabels).map((groupLabel) => {
											let groupLabelStyle = style.componentName;
											if (activeComponentType === componentTypeM1Key && activeComponentLabel === groupLabel) {
												groupLabelStyle = style.componentNameActive;
											}

											return (
												<div key={`component_${groupLabel}`} className={style.component}>
													<div
														className={groupLabelStyle}
														onClick={(e) => {
															e.preventDefault();
															this.setComponentGroupConfig(componentTypeM1Key, groupLabel);
														}}
													>{groupLabel}</div>
													<div className={style.componentLine}></div>
												</div>
											);
										})}
									</div>
								);
							})}
						</div>

						{/* ============== System Groups Table ============== */}
						<div className={style.mainContent}>
							<div className={`settings-header`}>
								<div className={style.description}>Assign systems to content groups. Users can assign content to these groups in the Portal UI</div>
							</div>

							<div className={style.systemGroupsTable}>
								<div className={style.systemGroupsLeft}>
									<div className={style.topLeft}>
										<div className={style.groupNavContainer}>
											<div className={`${style.groupNavLabel} ${style.pl2}`}>SYSTEMS</div>
											<div className={style.groupNav}>
												<div
													className={style.btnSystemGroup}
													onClick={(e) => {
														e.preventDefault();
														this.openModal({
															title: 'New Group',
															jsx:   (
																<GroupModal
																	closeModal={this.closeModal.bind(this)}
																	handleNewComponentGroup={this.handleNewComponentGroup.bind(this)}
																	mode={'add'}
																/>
															),
														});
													}}
												>
													<div className={style.btnSlidingLabel}>New Group</div>
													<div className={style.btnIcon}><CustomIcon icon="object-group" /></div>
												</div>
												<div
													className={style.btnSystemGroup}
													onClick={(e) => {
														e.preventDefault();

														if (!selectedComponentGroupM1Key) {
															alert('Please select a Custom Group to rename.');
															return;
														}

														this.openModal({
															title: 'Rename',
															jsx:   (
																<GroupModal
																	closeModal={this.closeModal.bind(this)}
																	handleRenameComponentGroup={this.handleRenameComponentGroup.bind(this)}
																	mode={'edit'}
																/>
															),
														});
													}}
												>
													<div className={style.btnSlidingLabel}>Rename</div>
													<div className={style.btnIcon}><CustomIcon icon="edit" /></div>
												</div>
												{/* <div className={style.btnSystemGroup}>
													<div className={style.btnSlidingLabel}>Duplicate</div>
													<div className={style.btnIcon}><CustomIcon icon="clone"/></div>
												</div> */}
												<div
													className={style.btnSystemGroup}
													onClick={(e) => {
														e.preventDefault();

														if (!selectedComponentGroupM1Key) {
															alert('Please select a Custom Group to delete.');
															return;
														}

														this.deleteComponentGroup();
													}}
												>
													<div className={style.btnSlidingLabel}>Delete</div>
													<div className={style.btnIcon}><CustomIcon icon="trash" /></div>
												</div>
											</div>
										</div>
									</div>

									<div className={style.bottomLeft}>
										{sortedSystemObjList.map((system, i) => {
											let currentSystemIsSelected = false;
											if (selectedSystemM1Key === system.uuid) {
												currentSystemIsSelected = true;
											}

											let iconProps = {};
											if (currentSystemIsSelected) {
												iconProps.color = 'green';
											}

											return (
												<div key={`config_${system}_${i}`}>
													<div className={`${style.systemRow} ${style.pl2}`} key={system.name}>
														<div className={style.systemRowLeft}>
															<div className={style.systemInfo}
																onMouseEnter={async () => {
																	if (currentSystemIsSelected) {
																		this.setState({
																			activeSystemConfig: 'Hover another system to see differences.',
																		});

																		return;
																	}

																	let systemsObj = {};
																	Object.keys(activeComponentLabelMap).forEach((systemM1Key) => {
																		let systemUUID = systemM1Key.split('.')[1];
																		systemsObj[systemUUID] = true;
																	});

																	let req = {
																		component_type_uuid: activeComponentType.split('.')[1],
																		group_label:         activeComponentLabel,
																		root_system_uuid:    system.uuid.split('.')[1],
																		// systems: systemsObj,
																	};

																	let componentTrees = [];
																	if (selectedSystemM1Key) {
																		req.root_system_uuid = selectedSystemM1Key.split('.')[1];

																		let systemUUID = system.uuid.split('.')[1];
																		req.systems = {
																			[systemUUID]: true,
																		};

																		let data = await fetchComponentTreeDiff(req);

																		let hashes = new Set();
																		for (let hash of Object.values(data.component_tree_hashes)) {
																			hashes.add(hash);
																		}

																		if (hashes.size === 1) {
																			this.setState({
																				activeSystemConfig: 'There are no differences.',
																			});

																			return;
																		}

																		for (let [ componentUUID, componentTreeDiffs, ] of Object.entries(data.component_tree_diffs || {})) {
																			// skip root on root diffs
																			if (~data.root_system_components.indexOf(componentUUID)) {
																				continue;
																			}

																			for (let [ rootComponentUUID, componentTree, ] of Object.entries(componentTreeDiffs)) {
																				// skip root on root diffs
																				// if (~data.root_system_components.indexOf(componentUUID)) {
																				// 	if (~data.root_system_components.indexOf(componentTree.component_diff_uuid)) {
																				// 		continue;
																				// 	}
																				// }

																				componentTree.componentUUID = componentUUID;
																				componentTree.hash = data.component_tree_hashes[componentUUID];
																				componentTree.config = configHashMap[componentTree.hash];
																				componentTree.diffConfig = configHashMap[componentTree.component_diff_hash];

																				let hasDiff = false;
																				for (let existingComponentTree of Object.values(componentTrees)) {
																					if (existingComponentTree.config === componentTree.config) {
																						if (existingComponentTree.diffConfig === componentTree.diffConfig) {
																							hasDiff = true;
																							break;
																						}
																					}
																				}

																				if (hasDiff) {
																					continue;
																				}

																				componentTrees.push(componentTree);
																			}
																		}
																	} else {
																		let data = await fetchComponentTreeInfo(req);

																		// let tmpMap = {};
																		// for (let [hash, configStr] of Object.entries(configHashMap)) {
																		// 	tmpMap[configStr] = hash;
																		// }

																		// let componentTrees = {};
																		// for (let configs of Object.values(systemConfigMap)) {
																		// 	for (let configStr of configs) {
																		// 		let hash = tmpMap[configStr];

																		// 		let componentTree = configMap[configStr];
																		// 		componentTrees[componentUUID] = componentTree;
																		// 	}
																		// }

																		// let data = {
																		// 	component_trees:       systemConfigMap,
																		// 	component_tree_hashes: {},
																		// }

																		for (let [ componentUUID, componentTree, ] of Object.entries(data.component_trees || {})) {
																			componentTree.componentUUID = componentUUID;
																			componentTree.hash = data.component_tree_hashes[componentUUID];
																			componentTree.config = configHashMap[componentTree.hash];

																			componentTrees.push(componentTree);
																		}
																	}

																	console.log(componentTrees);
																	componentTrees.sort((a, b) => {
																		let aStr = a.config + (a.diffConfig || '');
																		let bStr = b.config + (b.diffConfig || '');

																		// if (a.config && a.diffConfig) {
																		// 	if (a.config === a.diffConfig) {
																		// 		console.log(aStr, bStr, -1);
																		// 		return -1;
																		// 	}
																		// }

																		// if (b.config && b.diffConfig) {
																		// 	if (b.config === b.diffConfig) {
																		// 		console.log(aStr, bStr, 1);
																		// 		return -1;
																		// 	}
																		// }

																		console.log(aStr, bStr);

																		if (aStr < bStr) {
																			console.log(aStr, bStr, -1);
																			return -1;
																		}

																		if (aStr > bStr) {
																			console.log(aStr, bStr, 1);
																			return 1;
																		}

																		console.log(aStr, bStr, 0);
																		return 0;
																	});

																	let newActiveSystemConfig = (
																		<div>
																			{selectedSystemM1Key &&
																				<div className={style.rolloverHeader}
																					style={{
																						borderBottom: '1px solid black',
																					}}
																				>
																					<span style={{
																						color: 'green',
																					}}>{systemMap[selectedSystemM1Key]}</span>
																					&nbsp;/&nbsp;
																					<span style={{
																						color: 'red',
																					}}>{systemMap[system.uuid]}</span>
																				</div>
																			}

																			{componentTrees.map((componentTree, i) => {
																				return (
																					<ComponentTree
																						key={`0_${i}_${componentTree.hash}`}
																						componentTree={componentTree}
																						showDiffs={selectedSystemM1Key}
																						depth={0}
																						debug={false}
																					/>
																				);
																			})}
																		</div>
																	);

																	// newActiveSystemConfig = 'test';

																	this.setState({
																		activeSystemConfig: newActiveSystemConfig,
																	});
																}}
																onClick={() => {
																	// TODO: disabled diff until sorting is working
																	// NOTE: [A, C, D]: [A, D], [C]
																	// NOTE: [A, D, E]: [A, D], [E]
																	// return;

																	if (currentSystemIsSelected) {
																		this.setState({
																			selectedSystemM1Key: '',
																		});

																		return;
																	}

																	this.setState({
																		activeSystemConfig:  'Hover another system to see differences.',
																		selectedSystemM1Key: system.uuid,
																	});
																}}
															>
																<CustomIcon icon="info-circle" {...iconProps} />
																<div className={style.rolloverBox}>{activeSystemConfig}</div>
															</div>
															<div className={style.systemName}>
																{system.name} -
																<small
																	style={{
																		fontFamily: 'monospace',
																	}}
																>{JSON.stringify(systemConfigMap[system.uuid.split('.')[1]])}</small>
																<br />
																<small
																	style={{
																		fontFamily: 'monospace',
																	}}
																>{system.uuid.split('.')[1]}</small>
															</div>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>

								<div className={style.systemGroupsRight}>
									<div className={style.topRight}>
										{this.renderContentGroupNames(sortedSystemObjList)}
									</div>
									<div className={style.bottomRight}>
										{sortedSystemObjList.map((system, i) => {
											return (
												<div key={system.uuid}>
													<div className={style.systemRow}>
														<div className={style.systemRowRight}>
															{this.renderSystemGridRow(system)}
															<div className={style.selectBox}></div>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>

					</div>
				}
			</React.Fragment>
		);
	}
}

function ComponentTree(props) {
	const {
		componentTree,
	} = props;

	let children = (componentTree.children || []);

	let childCount = children.length, childCountStr = '';
	if (childCount > 0) {
		childCountStr = `(${childCount})`;
	}

	let parentStr = '';
	if (childCount > 0 || props.showSettings) {
		parentStr = ':';
	}

	let lineStyle = {};
	let prefix = '';

	let showSettings = props.showSettings;
	if (props.showSettings) {
		lineStyle.fontWeight = 'bold';
	}
	if (props.showDiffs) {
		showSettings = false;
	}

	if (componentTree.add) {
		lineStyle.color = 'green';
		lineStyle.fontWeight = 'bold';
		prefix = ' +';
	}
	if (componentTree.del) {
		lineStyle.color = 'red';
		lineStyle.fontWeight = 'bold';
		prefix = ' -';
	}

	// let componentName = componentTree?.settings?.[4] || '';

	let line = (
		<span
			style={lineStyle}
		>{prefix}{componentTree.type}{childCountStr}{parentStr}</span>
	);

	let configStyle = {}, rootConfigStyle = {};
	if (componentTree.diffConfig) {
		configStyle.color = 'red';
		rootConfigStyle.color = 'green';
	}

	let diffHeader = componentTree.diffConfig;
	if (props.debug) {
		diffHeader = `${componentTree.diffConfig}_${componentTree.component_diff_uuid}`;
	}

	let configHeader = componentTree.config;
	if (props.debug) {
		configHeader = `${componentTree.config}_${componentTree.componentUUID}`;
	}

	return (
		<>
			{componentTree.config &&
				<div className={style.rolloverHeader}>
					{componentTree.diffConfig &&
						<>
							<span
								style={rootConfigStyle}
							>{diffHeader}</span>
							&nbsp;/&nbsp;
						</>
					}

					<span
						style={configStyle}
					>{configHeader}</span>:

					{(componentTree.config === componentTree.diffConfig) &&
						<span>&nbsp;MATCH</span>
					}
				</div>
			}

			{(componentTree.config !== componentTree.diffConfig || !props.root) &&
				<ul
					style={{
						paddingLeft: '5px',
						listStyle:   'none',
					}}
				>
					<li>
						{line}

						{(props.showSettings) &&
							<ul
								style={{
									paddingLeft: '2.5px',
									listStyle:   'none',
								}}
							>
								{(componentTree.settings || []).map((setting, i) => {
									let settingName = componentTree.setting_names[i];

									return (
										<li
											key={i}
											style={{
												whiteSpace: 'nowrap',
											}}
										>&#x21B3; {settingName}: {setting}</li>
									);
								})}
							</ul>
						}

						{(props.showDiffs && !componentTree.add) &&
							<ul
								style={{
									paddingLeft: '2.5px',
									listStyle:   'none',
								}}
							>
								{(componentTree.settings || []).map((setting, i) => {
									let settingName = setting[0];
									let want = setting[1];
									let have = setting[2];

									let settingLineStyle = {
										whiteSpace: 'nowrap',
									};

									if (prefix.length > 0) {
										settingLineStyle.paddingLeft = '5px';
									}

									return (
										<li
											key={i}
											style={settingLineStyle}
										>
											&#x21B3; {settingName}:

											{typeof have !== 'undefined' &&
												<>
													< br />
													HAVE: <span
														style={{
															color:      'red',
															fontWeight: 'bold',
														}}
													>{have || 'NULL'}</span>
													&nbsp;,&nbsp;
													{/* <br /> */}
												</>
											}

											WANT: <span
												style={{
													color:      'green',
													fontWeight: 'bold',
												}}
											>{want || 'NULL'}</span>
										</li>
									);
								})}
							</ul>
						}

						{children.map((childComponentTree, i) => {
							return (
								<ComponentTree
									key={`${(props.depth + 1)}_${i}_${componentTree.hash}`}
									componentTree={childComponentTree}
									showSettings={showSettings}
									showDiffs={props.showDiffs}
									depth={(props.depth + 1)}
								/>
							);
						})}
					</li>
				</ul>
			}
		</>
	);
}

class GroupModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			groupName: '',
		};
	}

	render() {
		let fn = () => { };
		let text = '';
		switch (this.props.mode) {
		case 'add':
			fn = this.props.handleNewComponentGroup;
			text = 'Add Group';
			break;
		case 'edit':
			fn = this.props.handleRenameComponentGroup;
			text = 'Rename Group';
			break;
		default:
			console.error('Invalid mode: ', this.props.mode);
		}

		return (
			<React.Fragment>
				<div className={`${style.modalContent} ${style.normalizePadding} text-small`}>
					<div className="label">Group Name</div>
					<input
						onChange={(e) => {
							this.setState({
								groupName: e.target.value,
							});
						}}
						value={this.state.groupName}
						type="text"
						placeholder="Enter New Name"
					/>
				</div>
				<div className={style.modalBottomButtons}>
					<button
						onClick={this.props.closeModal}
						className="button button-outlined"
					><CustomIcon icon="ban" />Cancel</button>

					<button
						onClick={(e) => {
							e.preventDefault();
							if (this.state.groupName === '') {
								// TODO: Error message
								return;
							}

							fn(this.state.groupName);
						}}
						className="button"
					><CustomIcon icon="object-group" />{text}</button>
				</div>
			</React.Fragment>
		);
	}
}

function PendingUpdatesModal(props) {
	return (
		<React.Fragment>
			<div className={style.buttonContainer}>
				<button
					onClick={() => {
						props.closeModal();
					}}
					className='button button-outlined'
				><CustomIcon icon='undo' />Go Back</button>

				<button
					onClick={() => {
						props.handleDontSave();
						props.closeModal();
					}}
					className='button'
				><CustomIcon icon='times' />{`Don't Save`}</button>

				<button
					onClick={() => {
						props.closeModal();
						props.submitForm();
					}}
					className='button'
				><CustomIcon icon='save' />Save</button>
			</div>
		</React.Fragment>
	);
}

function WarningModal(props) {
	return (
		<React.Fragment>
			<div className={`${style.modalContent} text-small`}>
				<div className="label">This is the Root System of the Content Group!</div>
				<div className="label">Please unassign the other systems of this Content Group before unassigning this Root system.</div>
				<div className="label">Once all other systems in this Group are unassigned please click SAVE and then you can unassign this Root system!</div>
			</div>
			<div className={style.buttonContainer}>
				<button
					onClick={() => {
						props.closeModal();
					}}
					className='button button-outlined'
				><CustomIcon icon='times' />Close</button>
			</div>
		</React.Fragment>
	);
}

function ConfirmSaveModal(props) {
	return (
		<React.Fragment>
			<div className={`${style.modalContent} text-small`}>
				<div className="label">A Red component will represent the data of everything in the Content Group.</div>
				<div className="label">A Yellow component in the same column as a Red component, will inherit the Red component's data.</div>
				<div className="label">A Yellow component in it's own system column, will no longer be tied to a Content Group's data.</div>
				<div className="label">A Green component will not be changed.</div>
			</div>
			<div className={style.modalBottomButtons}>
				<button
					onClick={(e) => {
						e.preventDefault();
						props.closeModal();
					}}
					className='button button-outlined'
				><CustomIcon icon='undo' />Go Back</button>

				<button
					onClick={(e) => {
						e.preventDefault();
						props.closeModal();
						props.submitForm(props.cbKey, props.cbVal);
					}}
					className='button'
				><CustomIcon icon='save' />Save</button>
			</div>
		</React.Fragment>
	);
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.content.group'] = CrowFieldCustomVultureContentGroups;
export default CrowFieldCustomVultureContentGroups;