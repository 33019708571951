import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import kali from 'kali';
import {
	toCapitalCase,
} from '../../../../../Crow/Common/Crow';
import UserTable from './UserTable.js';
import UserModal from './UserModal.js';


import style from './Users.module.css';
import {
	getSession,
} from 'Vulture/Session';
import {
	broncoURL,
} from 'Vulture/ENV';

class CrowFieldCustomVultureUsers extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			accounts: [],
			columns:  [],
			data:     [],
			filter:   '',
		};
	}

	componentDidMount() {
		this.createCols();
		this.fetchAccountData();

		var script = document.createElement('script');
		var subscript = document.createElement('script');
		script.text = 'window.STONLY_WID = "fabebc3c-71ad-11ec-82f1-064cee365e5a"';
		subscript.text = `!function(e,t,n,s,o,a,i,p){e.StonlyWidget||((i=e.StonlyWidget=function(){i._api?i._api.apply(i,arguments):i.queue.push(arguments)}).scriptPath=s,i.queue=[],(a=t.createElement(n)).async=!0,(p=new XMLHttpRequest).open('GET',s+'version?v='+Date.now(),!0),p.onreadystatechange=function(){4===p.readyState&&(a.src=s+'stonly-widget.js?v='+(200===p.status?p.responseText:Date.now()),(o=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,o))},p.send())}(window,document,'script','https://stonly.com/js/widget/v2/');`;
		document.head.appendChild(script);
		document.head.appendChild(subscript);

	}
	createCols() {
		this.setState({
			columns: [
				{
					Header:   'Active',
					accessor: 'active',
					Cell:     (row) => {
						if (row.value === true) {
							return (
								<div className={style.activeCheckbox}>
									<div className={style.checkboxIcon}>
										<CustomIcon icon="check"/>
									</div>
								</div>
							);
						}
						return (
							<div className={style.disabledSquare}>
								<div className={style.checkboxIcon}>
									<CustomIcon icon="check"/>
								</div>
							</div>
						);
					},
				},
				{
					Header:   '2FA',
					accessor: (d) => {
						if (d.has_2fa) {
							return (
								<div className={style.activeCheckbox}>
									<div className={style.checkboxIcon}>
										<CustomIcon icon="check"/>
									</div>
								</div>
							);
						}
						return (
							<div className={style.disabledSquare}>
								<div className={style.checkboxIcon}>
									<CustomIcon icon="check"/>
								</div>
							</div>
						);
					},
				},
				{
					Header:   'Email',
					accessor: 'email',
				},
				{
					Header:   'Accounts',
					accessor: 'accounts',
					Cell:     (row) => {
						let str = '';
						let accountsArray = [];
						if (Array.isArray(row.value)) {
							row.value.forEach((value, i) => {
								/*if (i === row.value.length - 1) {
									str += `${value.label}`;
									return;
								}*/
								accountsArray.push(
									<>
										<div className={style.propertyIconContainer}><CustomIcon icon='dot-circle' color='#00BAFF'/></div><span>{value.label}</span>
									</>
								);
								/*str += `${value.label}, `;*/
							});
						}
						return (
							<div>{accountsArray}</div>
						);
					},
				},
				{
					Header:   'Permission Group',
					accessor: (d) => {
						if (d.permission_group_display_name === 'internal_user') {
							return <div className={style.internalUser}>Internal User</div>;
						}
						return toCapitalCase(d.permission_group_display_name);
					},
				},
			],
		});
	}

	fetchAccountData() {
		const url = `${broncoURL}/r/list/account`;

		new kali({
			body: {
				'skip_auth_filter': true,
			},
			headers: {
				'content_type':   'application/json',
				'X-Auth-Session': getSession().session,
			},
			method: 'POST',
		}).post(url, {
			success: (_kali, res, contents) => {
				if (contents && contents.data) {
					this.setState({
						accounts: contents.data,
					});
				}
			},

			failure: (_kali, res, err) => {
				console.error(err);

				if (res.status === 503) {
					this.props.form.props.on503(true);
				}
			},
		});
	}

	handleRootUserReadRequest(filter) {
		const url = `${broncoURL}/root_user/r`;
		const {
			app,
		} = this.props;

		app.setState({
			loading: true,
		});
		new kali({
			body: {
				filter,
			},
			headers: {
				'content_type':   'application/json',
				'X-Auth-Session': getSession().session,
			},
			method: 'POST',
		}).post(url, {
			success: (_kali, res, contents) => {
				this.setData(contents);
				app.setState({
					loading: false,
				});
			},
			failure: (_kali, res, err) => {
				app.setState({
					loading: false,
				});
				console.error(err);
				if (res.status === 503) {
					this.props.form.props.on503(true);
				}
			},
		});

		this.setState({
			filter,
		});
	}

	setData(rawData) {
		let data = [];

		Object.keys(rawData).forEach((key) => {
			// Reformat accounts data for React-Select
			if (rawData[key].accounts) {
				rawData[key].accounts.forEach((account, i) => {
					rawData[key].accounts[i] = {
						label: account.display_name,
						value: account.uuid,
					};
				});
			}

			data.push(rawData[key]);
		});

		this.setState({
			data,
		});
	}

	formatReactSelectOptions(data) {
		let newData = [];

		data.forEach((entry) => {
			newData.push({
				value:   entry.uuid,
				label:   entry.display_name,
				has_2fa: entry.has_2fa,
			});
		});

		return newData;
	}

	openModal(row) {
		const {
			app,
		} = this.props;

		const accounts = this.formatReactSelectOptions(this.state.accounts);

		app.openModal({
			modalSize:  2,
			showModal:  true,
			modalProps: {
				title: 'User Details',
				jsx:   (
					<UserModal
						app={app}
						parent={this}
						accounts={accounts}
						isUserModal={true}
						closeModal={app.closeModal.bind(app)}
						filter={this.state.filter}
						handleRootUserReadRequest={this.handleRootUserReadRequest.bind(this)}
						row={row}
					/>
				),
			},
		});
	}

	render() {
		let mode = this.props.mode;
		const pg = getSession().pg;

		return (
			<React.Fragment>
				{mode === 'users' && (pg === 'iu' || pg === 'ia') &&
					<div className='systems-page'>
						<div className='settings-header'>
							<h2>Users</h2>
						</div>
						<UserTable
							columns={this.state.columns}
							data={this.state.data}
							handleRootUserReadRequest={this.handleRootUserReadRequest.bind(this)}
							openModal={this.openModal.bind(this)}
							form={this.props.form}
						/>
					</div>
				}
			</React.Fragment>
		);
	}
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.users'] = CrowFieldCustomVultureUsers;
export default CrowFieldCustomVultureUsers;