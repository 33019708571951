import React from 'react';

import BuildingsTable from './BuildingsTable';

import {
	getSession,
} from 'Vulture/Session';

class BuildingsView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			buildingsDataArray:   props.buildingsDataArray,
			confirmModal:        false,
			confirmModalContent: '',
		};
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.buildingsDataArray) !== JSON.stringify(this.props.buildingsDataArray)) {
			this.setState({
				buildingsDataArray: this.props.buildingsDataArray,
			});
		}
	}

	toggleGroup(group, buildingIndex) {
		const {
			app,
		} = this.props;

		const {
			buildingsDataArray,
		} = this.state;

		const building = buildingsDataArray[buildingIndex];
		const systemGroupM1 = group.column.id;
		const systemGroupUUID = systemGroupM1.split('.')[1];

		const newChanges = {};

		const currentSystemGroupUUID = building.system_group;

		if (systemGroupUUID === currentSystemGroupUUID) {
			return;
		} else {
			newChanges[`${building.m1Key}.system_group_uuid`] = systemGroupUUID;
			building.system_group = systemGroupUUID;
		}

		buildingsDataArray[buildingIndex] = building;

		this.setState({
			buildingsDataArray,
		}, () => {
			app.setValue('buildings', Object.keys(newChanges), Object.values(newChanges));
		});
	}

	toggleSelectAllRows(areAllRowsChecked) {
		const {
			buildingsDataArray,
		} = this.state;

		const allRowsSelected = buildingsDataArray.reduce((previous, current) => {
			return previous && current.isChecked;
		}, true);
		const newBuildingsDataArray = buildingsDataArray;

		const newSelectedBuildings = [];
		newBuildingsDataArray.forEach((element) => {
			element.isChecked = !allRowsSelected;
			if (!areAllRowsChecked) {
				newSelectedBuildings.push(element.uuid);
			}
		});

		this.setState({
			buildingsDataArray: newBuildingsDataArray,
		});
	}

	closeModal() {
		const {
			app,
		} = this.props;

		app.closeModal();
	}

	render() {
		const {
			app,
			fd,
			systemGroupUUID,
		} = this.props;

		const {
			buildingsDataArray,
		} = this.state;

		let confirmModal = '';
		if (this.state.confirmModal === true) {
			confirmModal = (
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.confirmModalContent}
					</div>
				</div>
			);
		}

		const pg = getSession().pg;

		const groupsToHide = [];

		if (pg !== 'iu' && pg !== 'ia' && pg !== 'ea') {
			for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
				if (m1Key.startsWith('system_group.')) {
					groupsToHide.push(m1Key);

					for (const systemKey of Object.keys(m1Data)) {
						if (systemKey.startsWith('system.') || systemKey.startsWith('system_group.')) {
							if (fd[systemKey]) {
								groupsToHide.pop();
								break;
							}
						}
					}
				}
			}
		}

		const columns = Object.entries(fd)
			.filter(([ m1Key, ]) => {
				return !groupsToHide.includes(m1Key);
			})
			.filter(([ m1Key, ]) => {
				if (!systemGroupUUID) {
					return m1Key.startsWith('system_group.');
				}

				return m1Key === 'system_group.' + systemGroupUUID;
			})
			.reduce(((previous, [ m1Key, m1Val, ]) => {
				return {
					...previous,
					[m1Key]: m1Val.display_name,
				};
			}), {});

			buildingsDataArray.sort((a, b) => {
				const buildingNameA = a.vals.buildingName.toLowerCase();
				const buildingNameB = b.vals.buildingName.toLowerCase();
				if (buildingNameA < buildingNameB) {
					return -1;
				}
				if (buildingNameA > buildingNameB) {
					return 1;
				}
				return 0;
			});

		return (
			<>
				{confirmModal}
				<>
					<BuildingsTable
						array={buildingsDataArray}
						columnsToHide={[ 'columnToHide', ]}
						app={app}
						systemGroupUUID={systemGroupUUID}
						fd={fd}
						tableSettings={columns}
						buildingsView={this}
						toggleGroup={this.toggleGroup.bind(this)}
						closeModal={this.closeModal.bind(this)}
						toggleSelectAllRows={this.toggleSelectAllRows.bind(this)}
					/>
				</>
			</>
		);
	}
}

export default BuildingsView;