import React, {
	useState,
} from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon.js';
import Select from 'react-select';
import style from './ListingConfig.module.css';
import TextSmall from 'Vulture/Field/TextSmall.js';
import {
	buildTestData,
} from 'Vulture/TestData.js';
import JSONField from './JSONField.js';

const typeOptions = [
	// {
	// 	label: 'Int',
	// 	value: 'int',
	// },
	{
		label: 'String',
		value: 'string',
	},
	{
		label: 'Number',
		value: 'float',
	},
	// {
	// 	label: 'Bool',
	// 	value: 'bool',
	// },
	// {
	// 	label: 'Text',
	// 	value: 'text',
	// },
	// {
	// 	label: 'Date',
	// 	value: 'date',
	// },
	// {
	// 	label: 'Time',
	// 	value: 'time',
	// },
	// {
	// 	label: 'Datetime',
	// 	value: 'datetime',
	// },
	// {
	// 	label: 'Timestamp',
	// 	value: 'timestamp',
	// },
	// {
	// 	label: 'JSON',
	// 	value: 'json',
	// },
];

const styles = {
	control: (base) => {
		return {
			...base,
			maxHeight: 30,
		};
	},
	dropdownIndicator: (base) => {
		return {
			...base,
			paddingTop:    0,
			paddingBottom: 0,
		};
	},
	clearIndicator: (base) => {
		return {
			...base,
			paddingTop:    0,
			paddingBottom: 0,
		};
	},
	menu: (base) => {
		return {
			...base,
			top:      20,
			position: 'absolute',
		};
	},
};

class ArrayOptions extends React.Component {
	constructor(props) {
		super(props);

		const {
			fd,
			app,
			accountUUID,
			settingMap,
		} = props;

		const arrayOptions = fd[`account.${accountUUID}`].array_options;

		this.state = {};

		if (arrayOptions) {
			this.state.settingsWithOptions = JSON.parse(arrayOptions);
		} else {
			this.state.settingsWithOptions = {
				'': {
					'type':    'string',
					'options': [
						{
							'label': 'The label for humans',
							'value': 'The actual value',
						},
					],
				},
			};

			app.setValue('account_settings', `account.${accountUUID}.array_options`, JSON.stringify(this.state.settingsWithOptions));
		}
	}

	render() {
		const {
			fd,
			app,
			accountUUID,
			settingMap,
		} = this.props;

		const {
			settingsWithOptions,
		} = this.state;

		const labelList = Object.keys(settingsWithOptions);

		return (
			<>
				<h2 className={style.headerText}>Array Options</h2>
				<button
					className={`button ${style.button}`}
					onClick={(e) => {
						e.preventDefault();

						if (!settingsWithOptions['']) {
							settingsWithOptions[''] = {
								'type':    'string',
								'options': [
									{
										'label': 'The label for humans',
										'value': 'The actual value',
									},
								],
							};

							this.setState({
								settingsWithOptions,
							});
							app.setValue('account_settings', `account.${accountUUID}.array_options`, JSON.stringify(settingsWithOptions));
						}
					}}
					style={{
						width: 'fit-content',
					}}
					data-testing-info={buildTestData('button--newArrayOption')}
				>
					<CustomIcon icon='plus' /> New Option List
				</button>
				<div style={{
					display:       'flex',
					flexDirection: 'row',
				}}>
					{Object.entries(settingsWithOptions).map(([
						label, {
							type, options,
						},
					], optionNumber) => {
						return (
							<div className={style.listingContainer} key={label}>
								<ArrayOptionsEntity
									initialLabel={label}
									labelList={labelList}
									type={type}
									options={JSON.stringify(options)}
									app={app}
									accountUUID={accountUUID}
									fd={fd}
									arrayOptions={this}
									settingsWithOptions={settingsWithOptions}
									optionNumber={optionNumber}
									settingMap={settingMap}
								/>
							</div>
						);
					})}
				</div>
			</>
		);
	}
}

const ArrayOptionsEntity = (props) => {
	const {
		app,
		fd,
		initialLabel,
		labelList,
		options,
		type,
		arrayOptions,
		settingsWithOptions,
		optionNumber,
		accountUUID,
		settingMap,
	} = props;

	const [ label, setLabel, ] = useState(initialLabel);

	const listingColumns = [];
	let isDisabled = false;

	let arrayColumnTypeUUID;

	Object.entries(fd).forEach(([ m1Key, m1Data, ]) => {
		if (!arrayColumnTypeUUID && m1Key.startsWith('listing_column_type.')) {
			if (m1Data.display_name === 'Array') {
				arrayColumnTypeUUID = m1Key;
			}
		}
	});

	Object.entries(fd).forEach(([ m1Key, m1Data, ]) => {
		if (m1Key.startsWith('listing_config.')) {
			Object.keys(m1Data).forEach((m2Key) => {
				if (m2Key.startsWith('listing_column.') && fd[m2Key]['display_name'] === label) {
					Object.keys(fd[m2Key]).forEach((m3Key) => {
						if (m3Key === arrayColumnTypeUUID) {
							listingColumns.push(m2Key);
						}
					});
				}
			});
		}
	});

	for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
		if (isDisabled) {
			break;
		}

		if (m1Key.startsWith('listing.') || m1Key.startsWith('listing_column_group.')) {
			for (const m2Key of Object.keys(m1Data)) {
				if (listingColumns.includes(m2Key)) {
					isDisabled = true;
					break;
				}
			}
		}
	}

	const updateSettingValue = (json) => {
		const parsedJson = JSON.parse(json);
		settingsWithOptions[label].options = parsedJson;
		arrayOptions.setState({
			settingsWithOptions,
		});

		const oldJson = fd[`account.${accountUUID}`].array_options;

		if (oldJson !== JSON.stringify(settingsWithOptions)) {
			app.setValue('account_settings', `account.${accountUUID}.array_options`, JSON.stringify(settingsWithOptions));
		}
	};

	return (
		<div
			className={style.listingConfigContainer}
			data-testing-info={buildTestData(`list--arrayOption${optionNumber}`)}
		>
			<div className={style.listingConfigLabel}>
				<h1>Array Options
					{!isDisabled && <div
						className={style.deleteIcon}
						key='delete-btn'
						onClick={() => {
							Reflect.deleteProperty(settingsWithOptions, label);
							arrayOptions.setState({
								settingsWithOptions,
							});
							app.setValue('account_settings', `account.${accountUUID}.array_options`, JSON.stringify(settingsWithOptions));
						}}
					>
						<CustomIcon icon='minus-circle' />
					</div>}
				</h1>
			</div>

			<div className={style.listingConfigData}>
				<div className={style.listingConfigDataRow}>
					<div className={style.listingConfigDataRowInput}>
						<div>Data Name</div>
						<div className={style.crowForm}>
							<TextSmall
								value={label}
								isDisabled={isDisabled}
								setValue={(newLabel) => {
									let nextLabel = newLabel;
									while (labelList.includes(nextLabel)) {
										nextLabel = nextLabel + '_';
									}

									setLabel(nextLabel);
								}}
								onBlurCB={(newLabel) => {
									if (!settingsWithOptions[newLabel]) {
										settingsWithOptions[newLabel] = settingsWithOptions[initialLabel];
										Reflect.deleteProperty(settingsWithOptions, initialLabel);
										arrayOptions.setState({
											settingsWithOptions,
										});
										app.setValue('account_settings', `account.${accountUUID}.array_options`, JSON.stringify(settingsWithOptions));
									}
								}}
							/>
						</div>
					</div>
					<div className={style.listingConfigDataRowInput}>
						<div>Type</div>
						<div className={style.listingConfigSubcontainer}>
							<Select
								className={style.listingConfigSelectLabel}
								defaultValue={typeOptions[0]}
								isDisabled={isDisabled}
								options={typeOptions}
								styles={styles}
								onChange={(newType) => {
									settingsWithOptions[label].type = newType.value;
									arrayOptions.setState({
										settingsWithOptions,
									});
									app.setValue('account_settings', `account.${accountUUID}.array_options`, JSON.stringify(settingsWithOptions));
								}}
								value={typeOptions.find((option) => {
									return option.value === type;
								})}
							/>
						</div>
					</div>
				</div>
				<JSONField
					handleChange={updateSettingValue}
					value={options}
				/>
			</div>
		</div>
	);
};

export default ArrayOptions;