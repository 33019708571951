import axios from 'axios';

import {
	getSession,
} from 'Vulture/Session.js';

import {
	broncoURL,
	commitHash,
} from 'Vulture/ENV.js';

export const fetchBuildings = async (accountUUID, systemGroupUUID) => {
	const session = getSession();

	let url = `${broncoURL}/buildings`;
	let body = {
		account_uuid: accountUUID,
		system_group_uuid: systemGroupUUID,
		user_uuid: session.user,
	};

	const headers = {
		headers: {
			'X-Auth-Session':      session.session,
			'X-Spark-API-version': commitHash,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		return {
			'error':   true,
			'message': err.message,
		};
	}
};

export const createBuildings = async (accountUUID, buildingName, selectedSystemGroup) => {
	const session = getSession();

	const url = `${broncoURL}/w/edit`;
	const buildingUUID = '_0';
	const body = {
		data: {
			[`building.${buildingUUID}.account_uuid`]: accountUUID,
			[`building.${buildingUUID}.system_group_uuid`]: selectedSystemGroup,
			[`building.${buildingUUID}.display_name`]: buildingName,
		},
		data_format: 'DD',
	};

	const headers = {
		headers: {
			'X-Auth-Session':      session.session,
			'X-Spark-API-version': commitHash,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		return {
			'error':   true,
			'message': err.message,
		};
	}
};

export const deleteBuildings = async (buildingList) => {
	const session = getSession();

	const promiseList = buildingList.map((building) => {
		const buildingUUID = building.m1Key.split('.')[1];
		return fetch(`${broncoURL}/w/delete/building/${buildingUUID}`, {
			method:  'POST',
			headers: {
				'X-Auth-Session':      session.session,
				'X-Spark-API-version': commitHash,
			},
			body: JSON.stringify({}),
		});
	});

	await Promise.allSettled(promiseList);

	return;
};