import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import UserTable from './UserTable.js';
import UserModal from './UserModal.js';
import kali from 'kali';
import {
	toCapitalCase,
} from '../../../../../Crow/Common/Crow';

import style from './AccountUsers.module.css';
import {
	getFDValue,
} from 'Vulture/Helpers';
import {
	getSession,
} from 'Vulture/Session';

import API from 'Vulture/lib/API.js';

const broncoURL = window._getEnv('BRONCO_URL');

class CrowFieldCustomVultureAccountUsers extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeAccount: '',
			columns:       [],
			data:          [],
		};
	}

	componentDidMount() {
		this.createCols();
		this.getUsers();
	}

	componentDidUpdate(prevProps) {
		const {
			accountUUID,
			fd,
		} = this.props;

		if (prevProps.accountUUID !== accountUUID) {
			this.setState({
				activeAccount: accountUUID,
			}, () => {
				this.getUsers();
			});
		}

		if (JSON.stringify(prevProps.fd) !== JSON.stringify(fd)) {
			if (Object.keys(fd).length > 0) {
				this.getUsers();
			}
		}
	}

	setData(rawData) {
		let data = [];

		Object.keys(rawData).forEach((key) => {
			// Reformat accounts data for React-Select
			if (rawData[key].accounts) {
				rawData[key].accounts.forEach((acc, i) => {
					rawData[key].accounts[i] = {
						label: acc.display_name,
						value: acc.uuid,
					};
				});
			}

			data.push(rawData[key]);
		});

		this.setState({
			data,
		}, () => {
			this.createCols();
		});
	}

	getUsers() {
		const account = this.props.accountUUID;

		const url = `${broncoURL}/account/${account}/users`;

		new kali({
			body:    {},
			headers: {
				'content_type':   'application/json',
				'X-Auth-Session': getSession().session,
			},
			method: 'POST',
		}).post(url, {
			success: (_kali, res, data) => {
				this.setData(data);
			},

			failure: (_kali, res, err) => {
				console.error(err);
			},
		});
	}

	createCols() {
		const {
			fd,
		} = this.props;

		this.setState({
			columns: [
				{
					Header:   'Active',
					accessor: 'active',
					Cell:     (row) => {
						if (row.value === true) {
							return (
								<div className={style.activeCheckbox}>
									<div className={style.checkboxIcon}>
										<CustomIcon icon="check"/>
									</div>
								</div>
							);
						}
						return (
							<div className={style.disabledSquare}>
								<div className={style.checkboxIcon}>
									<CustomIcon icon="check"/>
								</div>
							</div>
						);
					},
				},
				{
					Header:   '2FA',
					accessor: (d) => {
						if (d.has_2fa) {
							return (
								<div className={style.activeCheckbox}>
									<div className={style.checkboxIcon}>
										<CustomIcon icon="check"/>
									</div>
								</div>
							);
						}
						return (
							<div className={style.disabledSquare}>
								<div className={style.checkboxIcon}>
									<CustomIcon icon="check"/>
								</div>
							</div>
						);
					},
				},
				{
					Header:   'Email',
					accessor: 'email',
				},
				{
					Header:   'Property Names',
					accessor: 'system_groups',
					Cell:     (row) => {
						let propertiesArray = [];
						let propertiesToSort = [];

						if (row.value && Array.isArray(row.value)) {
							row.value.forEach((value) => {
								let systemGroupDisplayName = getFDValue(fd, `system_group.${value}.display_name`);
								propertiesToSort.push(systemGroupDisplayName);
							});
							propertiesToSort.sort((a, b) => {
								if (a > b) {
									return 1;
								}
								return -1;
							});
							propertiesToSort.forEach((systemGroupDisplayName) => {
								propertiesArray.push(
									<>
										<div className={style.propertyIconContainer}><CustomIcon icon='dot-circle' color='#00BAFF'/></div><span>{systemGroupDisplayName}</span>
									</>
								);
							});
						}
						if (String(propertiesArray) === '[]') {
							propertiesArray.push(<><span>All</span></>);
						}
						return (
							<div>{propertiesArray}</div>
						);
					},
				},
				{
					Header:   'Role',
					accessor: (d) => {
						if (d.permission_group_display_name === 'external_admin' || d.permission_group_display_name === 'internal_user') {
							return <div>Administrator <CustomIcon icon='crown' color='#00BAFF'/></div>;
						}
						return toCapitalCase(d.permission_group_display_name);
					},
				},
			],
		});
	}

	getChildSystemGroups(systemGroupInfo, parentUUIDs) {
		const {
			form,
			fd,
		} = this.props;
		let newParentUUIDs = [];

		parentUUIDs.forEach((parentUUID) => {
			const matchingKeys = [];

			for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
				if (m1Key === `system_group.${parentUUID}`) {
					for (const m2Key of Object.keys(m1Data)) {
						const sections = m2Key.split('.').length;
						const entityKey = `${m1Key}.${m2Key}`;
						if (m2Key.startsWith('system_group.') && sections === 2) {
							matchingKeys.push(entityKey);
						}
					}
				}
			}

			matchingKeys.forEach((entityKey) => {
				const {
					m2ID: systemGroupUUID,
				} = form.parseEntityName(entityKey);
				const systemGroupName = getFDValue(fd, `system_group.${systemGroupUUID}.display_name`);

				systemGroupInfo.push(
					{
						name:    systemGroupName,
						uuid:    systemGroupUUID,
						checked: false,
					}
				);
				newParentUUIDs.push(systemGroupUUID);
			});
		});

		if (newParentUUIDs.length > 0) {
			return this.getChildSystemGroups(systemGroupInfo, newParentUUIDs);
		}

		return systemGroupInfo;
	}

	getSystemGroupInfo() {
		const {
			form,
			fd,
		} = this.props;
		const account = this.props.accountUUID;

		let systemGroupInfo = [];
		let parentUUIDs = [];

		const matchingKeys = [];

		for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key === `account.${account}`) {
				for (let m2Key of Object.keys(m1Data)) {
					const sections = m2Key.split('.').length;
					const entityKey = `${m1Key}.${m2Key}`;
					if (m2Key.startsWith('system_group.') && sections === 2) {
						matchingKeys.push(entityKey);
					}
				}
			}
		}

		matchingKeys.forEach((entityKey) => {
			let {
				m2ID: systemGroupUUID,
			} = form.parseEntityName(entityKey);
			let systemGroupName = getFDValue(fd, `system_group.${systemGroupUUID}.display_name`);

			systemGroupInfo.push(
				{
					name:    systemGroupName,
					uuid:    systemGroupUUID,
					checked: false,
				}
			);
			parentUUIDs.push(systemGroupUUID);
		});

		return this.getChildSystemGroups(systemGroupInfo, parentUUIDs);
	}

	async updateUserToSystemGroupMaps(userUUID, previousGroups = [], currentGroups = []) {
		const deletes = previousGroups.filter((previousGroupUUID) => {
			return !currentGroups.includes(previousGroupUUID);
		});
		for (const deleteUUID of deletes) {
			try {
				const data = await API.deleteMapRecord('user', userUUID, 'system_group', deleteUUID);

				console.log(data);
			} catch (err) {
				console.error(err);

				alert('Error removing user system_group.');
				return;
			}
		}

		const creates = currentGroups.filter((currentGroupUUID) => {
			return !previousGroups.includes(currentGroupUUID);
		});
		for (const createUUID of creates) {
			try {
				const data = await API.createMapRecord('user', userUUID, 'system_group', {
					system_group_uuid: createUUID,
				});

				console.log(data);
			} catch (err) {
				console.error(err);

				alert('Error adding user system_group.');
				return;
			}
		}

		this.getUsers();
	}

	restoreRelations(relations) {
		const {
			form,
			app,
		} = this.props;

		relations.forEach((relation) => {
			app.setValue('account', relation, true);
		});

		form.postFormData(null, this.getUsers.bind(this));
	}

	accountUserRelationsToDelete(userUUID) {
		const {
			form,
			fd,
			app,
		} = this.props;
		let account = this.props.accountUUID;

		let deletedRelations = [];

		const matchingKeys = [];

		for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key === `user.${userUUID}`) {
				for (const m2Key of Object.keys(m1Data)) {
					const sections = m2Key.split('.').length;
					const entityKey = `${m1Key}.${m2Key}`;
					if (m2Key.startsWith('system_group.') && sections === 2) {
						matchingKeys.push(entityKey);
					}
				}
			}
		}

		matchingKeys.forEach((entityKey) => {
			const {
				m2,
				m2ID,
			} = form.parseEntityName(entityKey);

			app.setValue('account', `user.${userUUID}.${m2}.${m2ID}`, false);
			deletedRelations.push(`user.${userUUID}.${m2}.${m2ID}`);
		});

		app.setValue('account', `user.${userUUID}.account.${account}`, false);
		deletedRelations.push(`user.${userUUID}.account.${account}`);

		form.postFormData(null, this.getUsers.bind(this));

		return deletedRelations;
	}

	openModal(row) {
		const {
			app,
		} = this.props;
		const account = this.props.accountUUID;

		let systemGroupInfo = this.getSystemGroupInfo();

		app.openModal({
			modalSize:  2,
			showModal:  true,
			modalProps: {
				title: 'User Details',
				jsx:   (
					<UserModal
						app={this.props.app}
						parent={this}
						closeModal={app.closeModal.bind(app)}
						filter={this.state.filter}
						row={row}
						isAccountUsers={true}
						systemGroupInfo={systemGroupInfo}
						updateUserToSystemGroupMaps={this.updateUserToSystemGroupMaps.bind(this)}
						accountUUID={account}
						accountUserRelationsToDelete={this.accountUserRelationsToDelete.bind(this)}
						restoreRelations={this.restoreRelations.bind(this)}
					/>
				),
			},
		});
	}

	render() {
		let mode = this.props.mode;

		return (
			<React.Fragment>
				{mode === 'accountUsers' &&
					<div className={`systems-page fade-in`}>
						<div className='settings-header'>
							<h2>Users</h2>
						</div>
						<UserTable
							columns={this.state.columns}
							data={this.state.data}
							openModal={this.openModal.bind(this)}
							isAccountUser={true}
						/>
					</div>
				}
			</React.Fragment>
		);
	}
}

window.CrowFormField['custom.vulture.account.users'] = CrowFieldCustomVultureAccountUsers;
export default CrowFieldCustomVultureAccountUsers;